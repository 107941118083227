/* SKIPNAV */
.skipNav {
    background: #fff;
    border: 1px solid #1f1f1f;
    color: #1f1f1f;
    display: block;
    font-size: 1em;
    left: 50%;
    line-height: 1.2em;
    padding: .5em;
    position: absolute;
    margin: 0 0 0 -5em;
    text-align: center;
    text-decoration: none;
    top: -20em;
    width: 10em;
    z-index: -1;
}

    .skipNav.back {
        bottom: -200px;
        position: fixed;
        top: auto;
    }

    .skipNav:focus {
        top: 0;
        z-index: 9999;
    }
/* NAV */
.nav-seat {
    display: block;
    height: 3.8em;
    position: relative;
    width: 100%;
}

.login-page .nav-seat {
    display: none;
}

.nav-screen-dim {
    background: rgba(0,0,0,.2);
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: .25s;
    visibility: hidden;
    width: 100%;
    z-index: 500;
}

.nav-wrap {
    background: rgba(255,255,255, .95);
    backdrop-filter: blur(20px);
    box-shadow: 0 .2em 1em rgb(0 0 0 / 25%);
    display: block;
    left: 0;
    position: fixed;
    top: 0;
    transition: .25s; /*position: relative;*/
    width: 100%;
    z-index: 500;

}

    .nav-wrap.docked {
        background: transparent;
        box-shadow: none;
    }

.nav-center {
    align-items: center;
    align-content: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 80em;
    padding: 0 1.2em;
    position: relative;
    width: 100%;
}

.nav-item-wrap {
    align-items: center;
    align-content: center;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.nav-logo {
    align-items: center;
    align-content: center;
    display: flex;
    justify-content: space-between;
    padding: 0;
    position: relative;
    text-decoration: none;
    vertical-align: middle;
    max-width: 13.05em;
    width: auto;
}

/*.nav-logo-img {
    display: block;
    height: auto;
    max-height: 2.25em;
    max-width: 7.5em;
    position: relative;
    transition: .25s;
    width: auto;
}*/

.logo-title::before,
.logo-title:before {
    background: #5895A6;
    content: '';
    display: block;
    height: 100%;
    left: .75em;
    position: absolute;
    width: 1px;
    z-index: 1;
}

.logo-title {
    color: #5895A6;
    display: inline-block;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.1em;
    padding-left: 1.5em;
    position: relative;
    text-decoration: none;
    transition: .25s;
    max-width: 6em;
}

.nav-middle {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.nav-right {
    align-items: center;
    align-content: center;
    display: flex;
    gap: .4em;
    justify-content: space-between;
    position: relative;
    vertical-align: middle;
}

.nav-link {
    color: #1f1f1f;
    display: inline-block;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.2em;
    padding: 0;
    position: relative;
    text-decoration: none;
    transition: .25s;
}

.nav-link-cond {
    display: inline-block;
    max-width: 8.25em;
    position: relative;
    text-align: center;
    vertical-align: middle;
}

.nav-middle:hover .nav-link {
    opacity: .6;
}

    .nav-middle:hover .nav-link:hover {
        opacity: 1;
    }

.nav-middle .nav-link {
    padding: 1.3em 2.5em;
}

a.nav-link:hover,
a.nav-link:focus-visible {
    text-decoration: none;
}

.nav-link .icon-wrap {
    background: #fff;
    border: 1px solid #1f1f1f;
    border-radius: 200px;
    color: #1f1f1f;
    display: inline-block;
    display: flex;
    align-items: center;
    align-content: center;
    padding: .4em .7em;
    position: relative;
    text-decoration: none;
    transition: .25s;
}

    .nav-link .icon-wrap.dark {
        background: #1f1f1f;
        color: #fff;
    }

.nav-link:hover .icon-wrap,
.nav-link:focus-visible .icon-wrap {
    background: #1f1f1f;
    color: #fff;
    text-decoration: none;
}

.material-symbols-rounded {
    color: inherit;
    display: inline-block;
    font-size: 1em;
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
    height: auto;
    line-height: 1em;
    pointer-events: none;
    text-decoration: none;
}

.cart-numbers,
.favorite-numbers {
    color: inherit;
    display: inline-block;
    font-size: .9em;
    font-weight: 700;
    line-height: 1em;
    position: relative;
}

    .cart-numbers.applied,
    .favorite-numbers.applied {
        margin-left: .4em;
    }

.nav-link:hover .icon-wrap .material-symbols-rounded,
a.nav-link:hover .icon-wrap .material-symbols-rounded {
    text-decoration: none;
}

.nav-link:hover .icon-wrap.dark,
.nav-link:focus-visible .icon-wrap.dark {
    background: #fff;
    color: #1f1f1f;
    text-decoration: none;
}

.mobile-nav-menu-trigger {
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 3em;
    z-index: 1;
}

    .mobile-nav-menu-trigger::before,
    .mobile-nav-menu-trigger:before,
    .mobile-nav-menu-trigger::after,
    .mobile-nav-menu-trigger:after,
    .mobile-nav-menu-trigger span {
        background: #1C1B1F;
        border-radius: 1em;
        bottom: 0;
        display: block;
        height: .1em;
        left: 0;
        line-height: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        transition: .25s;
        width: 1.1em;
    }

    .mobile-nav-menu-trigger::before,
    .mobile-nav-menu-trigger:before,
    .mobile-nav-menu-trigger::after,
    .mobile-nav-menu-trigger:after {
        content: '';
    }

    .mobile-nav-menu-trigger::before,
    .mobile-nav-menu-trigger:before {
        -webkit-transform: translateY(-.3em);
        -ms-transform: translateY(-.3em);
        transform: translateY(-.3em);
    }

    .mobile-nav-menu-trigger::after,
    .mobile-nav-menu-trigger:after {
        -webkit-transform: translateY(.3em);
        -ms-transform: translateY(.3em);
        transform: translateY(.3em);
    }

.nav-overlay,
.nav-overlay-product {
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 500;
}

.layout-nav-open .nav-overlay,
.product-nav-open .nav-overlay-product,
.layout-typical .nav-overlay.typical-layout,
.layout-product .nav-overlay.product-type,
.layout-room .nav-overlay.room-type {
    display: block;
}


#productTypicalTypes .nav-overlay-product {
    display: block;
}


.nav-overlay-content-inner {
    display: block;
    height: 100%;
    max-height: calc(100vh - 2em);
    overflow: auto;
    position: relative;
    width: 100%;
}

    .nav-overlay-content-inner.scrollbar-outer > .scroll-content.scroll-scrolly_visible {
        left: unset;
        margin: unset;
    }

.scroll-wrapper.nav-overlay-content-inner {
    overflow: hidden;
    padding: 0 0 2rem 0;
    position: relative;
    -webkit-overflow-scrolling: touch;
}

.nav-overlay-center {
    display: block;
    height: 100%;
    margin: auto;
    max-width: 80em;
    overflow: auto;
    padding: 0 2em;
    position: relative;
    width: 100%;
}

.nav-overlay-product .nav-overlay-center {
    max-width: 67em;
}

.nav-overlay-close {
    background: transparent;
    display: none;
    font-size: 1rem;
    height: 2.8em;
    position: absolute;
    right: .8em;
    text-decoration: none;
    -webkit-transform: perspective(1px) translateZ(0);
    -ms-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    top: 1.4em;
    width: 2.8em;
    z-index: 501;
}

.nav-overlay-close-icon {
    display: block;
    height: 2em;
    position: absolute;
    right: .4em;
    top: .3em;
    width: 2em;
}

.nav-overlay-close .material-symbols-rounded {
    color: #1F1F1F;
    display: block;
    font-size: 2em;
    font-family: 'Material Symbols Rounded';
    line-height: 1em;
    position: relative;
    text-decoration: none;
}

.layout-nav-open .nav-overlay-close,
.product-nav-open .nav-overlay-close,
.layout-typical .nav-overlay-close,
.layout-product .nav-overlay-close,
.layout-room .nav-overlay-close {
    display: block;
}

html.layout-nav-open,
html.layout-nav-open body,
html.product-nav-open,
html.product-nav-open body,
html.layout-typical,
html.layout-typical body,
html.layout-product,
html.layout-product body,
html.layout-room,
html.layout-room body {
    overflow: hidden;
}

.nav-drop-layout-wrap {
    align-items: flex-start;
    align-content: flex-start;
    display: flex;
    gap: .9em;
    justify-content: space-between;
    padding: 6.1em 0 0;
    position: relative;
    width: 100%;
}

.nav-overlay-product .nav-drop-layout-wrap {
    gap: 9.3em;
    padding: 5.8em 0 0;
}

.nav-drop-title {
    display: inline-block;
    max-width: 11.5em;
    position: relative;
    width: 15.3%;
}

.nav-block-wrap {
    align-items: stretch;
    align-content: stretch;
    display: flex;
    flex-wrap: wrap;
    gap: .5em;
    justify-content: flex-start;
    position: relative;
    width: 84.7%;
}

.nav-overlay-product .nav-block-wrap {
    gap: .3em;
    width: 50%;
}

.nav-drop-title {
    color: #000;
    display: block;
    font-size: 1.8em;
    font-weight: 700;
    line-height: 1.2em;
    padding-right: 2em;
    position: relative;
}

.nav-overlay.typical-layout .nav-drop-title,
.nav-overlay.product-type .nav-drop-title,
.nav-overlay.room-type .nav-drop-title {
    line-height: 1em;
    padding-right: 0;
    padding-top: .2em;
}

.nav-overlay.typical-layout .nav-drop-title {
    width: 17%;
}

.nav-overlay-product .nav-drop-title {
    flex: 0 0 100%;
    line-height: 1.1em;
    max-width: 100%;
    padding: 0 8em .7em 0;
    width: 100%;
}

.nav-block-item {
    display: inline-block;
    flex: 0 1 16.1%;
    margin-bottom: 2em;
    position: relative;
    text-decoration: none;
}

#browseproductType .nav-block-item:nth-child(1) {
    flex: 0 1 33.4%;
    max-width: 20.65em;
}

.nav-overlay-product .nav-block-item {
    flex: 0 1 30%;
    margin-bottom: 2.2em;
}

.nav-overlay-product .nav-block-wrap:nth-child(1)::before,
.nav-overlay-product .nav-block-wrap:nth-child(1):before {
    background: #E3E3E3;
    content: '';
    display: block;
    height: 100%;
    right: -3.5em;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 2px;
}

.nav-block-img-wrap {
    display: block;
    border-radius: .3em;
    margin-bottom: .4em;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.nav-overlay-product .nav-block-img-wrap {
    margin-bottom: .7em;
}

.nav-block-img-wrap::before,
.nav-block-img-wrap:before {
    content: '';
    display: block;
    height: 0;
    padding: 97% 0 0 0;
    position: relative;
    width: 0;
}

.nav-overlay-product .nav-block-img-wrap::before,
.nav-overlay-product .nav-block-img-wrap:before {
    padding: 92% 0 0 0;
}

.nav-block-cover-thumb {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
}

.nav-block-content {
    display: block;
    position: relative;
    width: 100%;
}

.nav-block-title {
    color: #1F1F1F;
    display: block;
    font-size: .8em;
    font-weight: 700;
    line-height: 1.2em;
    position: relative;
    text-decoration: none;
}
/* SCROLLBAR-OUTER */
body .scrollbar-outer > .scroll-element.scroll-x.scroll-scrollx_visible,
body .scrollbar-outer > .scroll-element.scroll-y.scroll-scrolly_visible {
    display: block;
}

.body .scrollbar-outer > .scroll-element.scroll-x {
    bottom: 0;
    display: none;
    height: 12px;
    left: 0;
    width: 100%;
}

body .scrollbar-outer > .scroll-element.scroll-y {
    bottom: 1.9em;
    height: 100%;
    right: 12px;
    top: 5.4em;
    width: 0;
}

body .scrollbar-outer > .scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
}

body .scrollbar-outer > .scroll-element .scroll-element_track {
    background-color: #D9D9D9;
}

body .scrollbar-outer > .scroll-element .scroll-bar {
    background-color: #1F1F1F;
}

.scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
    left: -1.6em;
    width: 4px;
}

.mobile-nav-overlay {
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    box-shadow: 0 .1em .5em rgb(0 0 0 / 25%);
    display: block;
    height: 100%;
    left: -100%;
    max-width: 25em;
    position: fixed;
    transition: .25s all;
    -webkit-transition: .25s all;
    top: 0;
    width: 100%;
    z-index: 501;
}

.mobile-nav-open .mobile-nav-overlay {
    left: 0%;
}

.mobile-nav-page.mobile-nav-page-open {
    left: 0%;
}

.mobile-nav-base {
    display: block;
    height: 100%;
    position: relative;
}

.mobile-nav-center {
    background: #fff;
    display: block;
    margin: 0 auto;
    height: 100vh;
    overflow: auto;
    opacity: 0.95;
    padding: 2.8em 0.825em;
    position: fixed;
    width: 100%;
    top: 0;
    z-index:501;
}

.mobile-nav-page .mobile-nav-center {
    padding: 3.6em .825em;
}

.mobile-nav-page {
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background: rgba(255, 255, 255, 0.85);
    display: block;
    height: 100%;
    left: -101%;
    overflow: auto;
    pointer-events: all;
    position: absolute;
    top: 0;
    transition: .25s;
    width: 100%;
    z-index: 501;
}

.mobile-nav-page-close {
    align-items: center;
    color: #1F1F1F;
    border: 0;
    background: transparent;
    display: flex;
    font-size: 1.35rem;
    height: 1.3em;
    justify-content: center;
    position: absolute;
    right: .3em;
    text-decoration: none;
    top: .35em;
    width: 1.3em;
}

    .mobile-nav-page-close .material-symbols-rounded {
        display: block;
        font-size: 1em;
        font-family: 'Material Symbols Rounded';
        line-height: 1em;
        padding: .475em 0;
        position: relative;
        text-decoration: none;
    }

.mobile-nav-page-back {
    border: 0;
    background: transparent;
    color: #757575;
    display: block;
    font-size: .8em;
    line-height: 1.2em;
    position: absolute;
    text-decoration: none;
    top: 2.35em;
    left: 2em;
}


    .mobile-nav-page-back::before,
    .mobile-nav-page-back:before {
        content: '\e5e0';
        display: block;
        font-family: "Material Symbols Rounded";
        font-size: 1em;
        line-height: 1em;
        height: 0.75em;
        left: -1.05em;
        position: absolute;
        width: 0.42em;
    }

.mobile-nav-link {
    color: #1F1F1F;
    display: block;
    font-size: 1.2em;
    font-weight: 700;
    line-height: .8em;
    padding: .65em 0;
    position: relative;
    text-decoration: none;
}

.mobile-nav-link-title {
    color: #1F1F1F;
    display: block;
    font-size: .9em;
    font-weight: 700;
    line-height: .8em;
    padding: .65em 0 .45em;
    position: relative;
    text-decoration: none;
}

    .mobile-nav-link::after,
    .mobile-nav-link:after,
    .mobile-nav-link-title::after,
    .mobile-nav-link-title:after {
        color: #1c1b1f;
        content: '\e5e1';
        display: block;
        font-family: 'Material Symbols Rounded';
        font-size: .8rem;
        font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
        height: 1em;
        line-height: 1em;
        padding: .65em 0;
        position: absolute;
        right: 0;
        text-decoration: none;
        top: .2em;
        width: 1em;
    }

    .mobile-nav-link-title.no-link::after,
    .mobile-nav-link-title.no-link:after {
        display: none;
    }

.mobile-nav-link-small {
    color: #1F1F1F;
    display: block;
    font-size: .8em;
    line-height: .9em;
    padding: 1.175em 0 .3em 1.5em;
    position: relative;
    text-decoration: none;
}

.mobile-nav-page-mask {
    display: none;
    height: 100%;
    left: 0;
    overflow: hidden;
    max-width: 25em;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 502;
}
/* SCROLLBAR STYLING */
.track-vertical {
    background: rgba(217, 217, 217, .5);
    bottom: 20px;
    border-radius: 3px;
    top: 75px;
    right: 4px;
    width: 4px !important;
}

.nav-overlay-inner .track-vertical {
    right: 42px;
}

.track-vertical .thumb-vertical {
    background-color: rgba(31, 31, 31, 1);
    border-radius: inherit;
    cursor: pointer;
    display: block;
    max-width: 4px;
    position: relative;
    width: 100%;
}

.track-horizontal {
    bottom: 2px;
    border-radius: 3px;
    display: none;
    height: 6px;
    left: 2px;
    position: absolute;
    right: 2px;
}

    .track-horizontal .thumb-horizontal {
        background-color: rgba(125, 149, 255, 0.993);
        border-radius: inherit;
        cursor: pointer;
        display: block;
        height: 100%;
        position: relative;
    }

.view {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: scroll;
    margin-right: -15px;
    margin-bottom: -17px !important;
}
.mobile-back-button-wrap {
    padding-top: 0.75em;
}
.mobile-nav-center {
display:none;
}

.nav-logo-wrap {
    cursor: pointer;
    display: block;
    position: relative;
    width: 100%;
}




    .nav-logo-wrap img {
        display: block;
        height: 100%;
        object-fit: contain;
        object-position: left center;
        width: 100%;
        max-width: 7.5em;
        height: 3.45em;
    }
    
@media screen and (max-width: 1500px) {
    /* NAV */
    .nav-seat {
        height: 3.8em;
    }

    .nav-logo-img {
        max-width: 7em;
    }

    .logo-title {
        font-size: .9em;
    }

    .nav-middle .nav-link {
        padding: 1.3em 1.5em;
    }

    .nav-link {
        font-size: .9em;
    }
    /* SCROLLBAR-OUTER */
    .nav-drop-layout-wrap,
    .nav-overlay-product .nav-drop-layout-wrap {
        padding: 5em 0 0;
    }

    .nav-drop-title {
        font-size: 1.5em;
    }

    .nav-block-item {
        flex: 0 1 20.1%;
    }

    .nav-overlay-product .nav-drop-title {
        padding: 0 9em .7em 0;
    }
}

@media screen and (max-width: 1200px), print {
    /* NAV */
    .nav-seat {
        height: 3.8em;
    }

    .nav-logo {
        width: 10.95em;
    }

    .nav-logo-img {
        max-width: 5.2em;
    }

    .logo-title {
        font-size: .85em;
        padding-left: 1.5em;
    }

    .nav-middle .nav-link {
        font-size: .75em;
        padding: 1em .5em;
        margin-right: 0.5rem
    }

    .nav-link .icon-wrap {
        padding: .3em .5em;
    }
    /* SCROLLBAR-OUTER */
    .nav-drop-layout-wrap,
    .nav-overlay-product .nav-drop-layout-wrap {
        padding: 4em 0 0;
    }

    .nav-block-item {
        flex: 0 1 30.1%;
    }

    .nav-block-title {
        font-size: .8em;
    }

    .nav-overlay-product .nav-block-item {
        flex: 0 1 49%;
    }

    .nav-overlay-product .nav-drop-title {
        padding: 0 0 .7em 0;
    }

    .nav-overlay-product .nav-drop-layout-wrap {
        gap: 7.3em;
    }

    .nav-overlay-product .nav-overlay-center {
        max-width: 48em;
    }
}
@media screen and (max-width: 991px) {
    .mobile-nav-center {
        display: block;
        transition: .25s ease-in-out;
    }

    .nav-overlay-product .nav-drop-title {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 900px), print {
    /* NAV */
    .nav-seat {
        height: 2.9em;
    }

    .mobile-nav-menu-trigger {
        display: block;
    }

    .nav-logo-img {
        max-width: 4em;
    }

    .nav-logo {
        background: #fff;
        margin: auto;
        max-width: 8.2em;
        padding: 0;
        width: 100%;
    }

    .logo-title {
        font-size: .6em;
        padding-left: 2.8em;
    }

        .logo-title::before,
        .logo-title:before {
            left: 1.4em;
        }

    .nav-item-wrap.nav-middle {
        display: none;
    }

    .nav-right .nav-link {
        display: none;
    }

    .nav-right .nav-link.cart, .nav-right .nav-link.notification-bell {
        display: inline-block;
    }
    
    .notification-bell.nav-link .icon-wrap {
        border: 0;
    }
    .nav-link.cart .icon-wrap.dark {
        background: transparent;
        border: none;
        color: #1C1B1F;
    }

    .nav-link.cart .icon-wrap.dark .material-symbols-rounded {
        color: #1C1B1F;
        font-size: 1.5em;
    }
    .nav-link.notification-bell .pending-count{
        line-height:1;
    }
    .cart-numbers,
    .favorite-numbers, .nav-link.notification-bell .pending-count {
        background-color: #1C1B1F;
        border-radius: 100%;
        color: #fff;
        display: block;
        font-size: .5em;
        min-width: 1.8em;
        padding: .4em;
        position: absolute;
        right: .5em;
        text-align: center;
        top: .4em;
    }

    .nav-link:hover .icon-wrap.dark .cart-numbers,
    a.nav-link:hover .icon-wrap.dark .cart-numbers {
        background-color: #1C1B1F;
        color: #fff;
    }

    .nav-right {
        margin-right: 0;
        position: absolute;
        right: .3em;
        gap:0;
    }

    .mobile-nav-open .nav-overlay-close {
        display: none;
        height: 1.3em;
        right: 2.25em;
        right: .45em;
        top: .9em;
        width: 1.3em;
        z-index: 501;
    }

    .mobile-nav-open .nav-overlay-close-icon {
        height: 1.3em;
        right: 0;
        top: 0;
        width: 1.3em;
    }

    .mobile-nav-open .nav-overlay-close .material-symbols-rounded {
        font-size: 1.3em;
        line-height: .8em;
    }

    .nav-overlay .nav-overlay-inner .nav-overlay-center {
        padding: 0 .8em;
    }

    .mobile-nav-open .mobile-nav-page-mask {
        display: block;
    }

    .nav-overlay-inner .track-vertical {
        right: 26px;
    }

    #browseproductType .nav-block-item:nth-child(1) {
        flex: 0 1 65.4%;
        max-width: 20.65em;
    }
}


@media screen and (max-width: 600px) {
    #browseproductType .nav-block-item:nth-child(1) {
        flex: 0 1 100%;
    }
    .nav-overlay-center {
        padding: 0 0.825rem;
    }
    .nav-overlay-close .material-symbols-rounded{
        font-size:1.5rem;
    }
    .nav-overlay-close-icon,
    .nav-overlay-close {
        width: 1.5rem;
        height: 1.5rem;
    }
    .nav-link.cart .icon-wrap.dark,
    .notification-bell.nav-link .icon-wrap {
        padding-left: 0;
    }
    .logo-title::before, .logo-title:before{
        left:0.5rem;
    }
    .logo-title{
        padding-left:1rem;
    }
}

/* Print media Style */

@media print {
    header .nav-wrap {
        position: relative;
        box-shadow: none;
    }
    .mobile-nav-menu-trigger,
    .nav-seat {
        display: none;
    }
    .nav-center {
        padding: 0;
    }
    .nav-item-wrap.nav-logo {
        margin: 0 auto 0 0;
    }
    .cart-numbers, .favorite-numbers, .nav-link.notification-bell .pending-count {
        border: 1px solid #000;
    }
}