/* PRODUCT */
.sort-by-wrap { bottom: 0; display: block; position: absolute; right: 0; }

.sort-by-label { color: #757575; display: inline-block; font-size: .7em; line-height: 1em; position: relative; }

.sort-by-select { -webkit-appearance: none; -moz-appearance: none; appearance: none; background: url(../../assets/arrow-up-select.png) no-repeat right 0 center; background-size: .89em auto; border: none; border-radius: 6px; display: inline-block; font-size: .8em; font-weight: 700; line-height: 1em; height: 100%; margin: .55em 0 0 0; max-height: 2.6em; outline: none; padding: .7em 1.4em .7em 1.1em; position: relative; white-space: nowrap; }

.choices { display: inline-block; position: relative; font-size: .8em; font-weight: 700; margin-bottom: 0; vertical-align: top; }

.choices__list--dropdown .choices__item--selectable,
.choices__list[aria-expanded] .choices__item--selectable { padding-right: .3em; }

    .choices__list--dropdown .choices__item--selectable::after,
    .choices__list[aria-expanded] .choices__item--selectable::after { display: none; }

.choices__inner { background: transparent; border: none; display: inline-block; font-size: 14px; min-height: 20px; overflow: hidden; padding: .2em .5em .2em 0; vertical-align: top; width: 100%; }

    .choices__inner::after,
    .choices__inner:after { color: #1f1f1f; content: '\e5e0'; border: none; font-family: 'Material Symbols Rounded'; font-size: .9em; height: 1.4em; margin-top: unset; pointer-events: none; position: absolute; right: .4em; top: .6em; transform: rotate(90deg); transition:0.25s; width: 1.4em; text-align:end; transform-origin: center;}

.choices[data-type*=select-one].is-open::after,
.choices[data-type*=select-one].is-open:after { border: none; margin-top: unset; }

.choices__list--single { display: inline-block; padding: 4px 34px 4px 4px; text-transform: capitalize; cursor:default; width: 100%; }

.choices[data-type*=select-one] .choices__inner { padding-bottom: 1.5px; }

.choices__inner .content{display:none;}
.choices__inner.expanded .content{display:block;}
.choices__inner.expanded:after{transform:rotate(270deg);}
.choices__list--dropdown { border: none; border-radius: 6px; background-color: #fff; border: 1px solid #ddd; box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.15); display: block; margin-top: -1px; max-height: 300px; position: absolute; right: 0; top: 100%; overflow: auto; visibility: visible; word-break: break-all; width: 7.95rem; will-change: visibility; z-index: 1; }

    .choices__list--dropdown::-webkit-scrollbar { display: none; }

.choices[data-type*=select-one] .choices__input { display: none; }

.choices__list--dropdown .choices__list,
.choices__list[aria-expanded] .choices__list { height: 100%; }

.choices__item { cursor: default; position: relative; padding: 10px; font-size: 14px; }

    .choices__item:hover,
    .choices__item:focus-within { background-color: #f2f2f2; }

/*#sortByDropDown {
    width: 2.5em;
    }*/
#width-tmp-select { display: none; }

.product-wrap { align-items: flex-start; display: flex; justify-content: flex-start; gap: 2.8em; padding: 0 0 2.4em 0; position: relative; width: 100%; }

.product-inner-item.product-nav { display: inline-block; max-width: 14.45em; position: relative; width: 100%; }

.product-inner-item { display: block; position: relative; width: 100%; }

.product-item { display: block; padding: 1.3em 0; position: relative; }

    .product-item.filter-results-wrap { padding: .2em 0 .8em; }

    .product-item + .product-item { border-bottom: 1px solid #E3E3E3; }

    .product-item + .product-item-filter-wrap { border-bottom: none; }

    .product-item.product-item-filter-wrap { padding: 0; }

.product-main-title,
.page-main-title { color: #1f1f1f; display: block; font-size: 3em; font-weight: 700; line-height: 1.2em; position: relative; width: 100%; }

.breadcrumb.shopping::before,
.breadcrumb.shopping:before { width: 1em; }

.product-breadcrumbs-wrap a.breadcrumb:hover,
.product-breadcrumbs-wrap a.breadcrumb:focus-visible { text-decoration: underline; }

    .product-breadcrumbs-wrap a.breadcrumb:hover.disable,
    .product-breadcrumbs-wrap a.breadcrumb:focus-visible.disable { text-decoration: none; }

.product-title { color: #1f1f1f; display: block; font-size: 1.2em; font-weight: 700; line-height: 1.2em; padding-bottom: .3em; position: relative; }

.product-filter-controls-wrap { align-items: center; align-content: center; display: flex; justify-content: space-between; position: relative; }

.product-results,
.product-clear { color: #1f1f1f; display: inline-block; font-size: .8em; line-height: 1.2em; position: relative; }

.product-clear { text-decoration: underline; }

    .product-clear:hover,
    .product-clear:focus-visible { text-decoration: none; }

.filter-list { display: block; max-width: 15em; position: relative; }
.filter-list-innner{
    display:flex;
    align-items:center;
    flex-wrap:wrap;
    gap:0.25rem .2rem;
    position:relative;
}
.product-filter-active {
    background: #E3E3E3;
    border-radius: 2em;
    color: #1f1f1f;
    cursor: pointer;
    display: inline-block;
    font-size: .8em;
    line-height: 1.2em;
    padding: .651em 3em .651em 1.4em;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
}
.product-inner-overlay-bar {
    border-bottom: 1px solid #e3e3e3;
}

.product-filter-selected {
    border-bottom: 1px solid #e3e3e3;
    padding: 1.3rem 0;
}
.product-filter-active:last-of-type {
    margin-bottom: 0;
}

    .product-filter-active::after,
    .product-filter-active:after { color: #1f1f1f; content: '\e5cd'; display: block; font-family: "Material Symbols Rounded"; font-size: 1.4em; font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 200, 'opsz' 48; height: auto; line-height: 1.2em; position: absolute; right: .7em; top: .3em; transition: .25s; }

.product-item-filter-wrap { display: block; position: relative; width: 100%; }

.product-item-filter.collapsed { border-bottom: 1px solid #E3E3E3; display: block; position: relative; width: 100%; }

    .product-item-filter.collapsed:last-of-type { border-bottom: none; }

.product-item-filter-trigger { color: #1f1f1f; display: block; font-size: .9em; font-weight: 700; line-height: 1.2em; padding: 1.4em 3em .8em 0; position: relative; text-decoration: none; }

    .product-item-filter-trigger::before,
    .product-item-filter-trigger:before { color: #1C1B1F; content: '\e5cf'; display: block; font-family: 'Material Symbols Rounded'; font-size: 1.8em; font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48; height: auto; line-height: 1em; margin: -.5em 0 0 0; position: absolute; transition: .25s; transform-origin: center; right: 0; top: 1.1em; /*top: 60%;*/ }

.expanded .product-item-filter-trigger::before,
.expanded .product-item-filter-trigger:before { -webkit-transform: rotate(-180deg); -ms-transform: rotate(-180deg); transform: rotate(-180deg); }

.product-item-filter-trigger.showing::before,
.product-item-filter-trigger.showing:before { color: #1C1B1F; content: '\e5ce'; font-family: 'Material Symbols Rounded'; font-size: 2em; height: auto; line-height: 1em; margin: -.5em 0 0 0; position: absolute; transform-origin: center; right: 0; top: 50%; }

.product-item-filter-collapse { display: block; margin: 0 0 2.05em 0; max-height: 7.2em; overflow: hidden; padding: .4em 0; position: relative; transition: .25s; }

.product-item-filter:nth-child(3) .product-item-filter-collapse { margin: 0 0 .85em 0; }

.show-text .product-item-filter-collapse { margin: 0; max-height: 35em; }

.show-all-wrap { bottom: 1.5em; display: none; left: 0; position: absolute; transition: .25s; width: 100%; z-index: 1; }

.expanded .show-all-wrap { display: block; }

.show-all-wrap::before,
.show-all-wrap:before { background: #fff; content: ''; display: block; height: 2em; left: 0; position: absolute; top: -.5em; width: 100%; }

.show-text .show-all-wrap { display: none; }

.product-checkboxes,
.product-radios { display: block; padding-top: 0; visibility: visible; }

.container-checkbox,
.container-radio { color: #1F1F1F; cursor: pointer; display: block; font-size: .9em; line-height: 1em; margin-bottom: .8em; position: relative; padding-left: 2.3em; padding-right: 1em; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.checkbox-title,
.radio-title { display: block; position: relative; }

.container-checkbox {
    margin-bottom: .5rem;
    max-width: max-content;
}

.checkbox-title { line-height: 1.2em; }

.container-checkbox input,
.container-radio input { cursor: pointer; height: 0; opacity: 0; position: absolute; width: 0; }

.checkmark { background-color: #fff; border: 1px solid #757575; border-radius: 2px; height: 1.3em; left: 0; position: absolute; top: -.1em; width: 1.3em; }

.container-checkbox input:checked ~ .checkmark { background-color: #1F1F1F; border-color: #1F1F1F; }

    .container-checkbox input:checked ~ .checkmark::before,
    .container-checkbox input:checked ~ .checkmark:before { display: block; color: #fff; content: '\e876'; font-family: "Material Symbols Rounded"; font-size: 1.2em; line-height: 1em; position: absolute; top: 0; left: 0; }

.radio { align-items: center; background-color: #fff; border: 2px solid #1C1B1F; border-radius: 50%; display: flex; height: 1.05em; justify-content: center; left: 0; position: absolute; top: 0; width: 1.05em; }

.container-radio:hover input ~ .radio { background-color: #ccc; }

.container-radio input:checked ~ .radio { background-color: #fff; }

.radio::after,
.radio:after { content: ''; display: none; position: absolute; }

.container-radio input:checked ~ .radio:after,
.container-radio input:checked ~ .radio::after { display: block; }

.container-radio .radio:after,
.container-radio .radio::after { background: #1C1B1F; border-radius: 50%; border: 1px solid #fff; height: 100%; left: 0; top: 0; width: 100%; }

.product-gallery-inner { position:relative; align-items: stretch; align-content: stretch; display: flex; flex-wrap: wrap; gap: 1em;/*gap: .5em;*/ justify-content: flex-start; /*justify-content: space-between;*/ position: relative; width: 100%; }

.show-all-title {
    background: transparent;
    border: none;
    color: #1F1F1F;
    cursor: pointer;
    display: block;
    font-size: .9em;
    line-height: 1em;
    margin: 1em 2.3em .8em;
    outline: none; /* padding-left: 2.3em;*/
    position: relative;
    text-decoration: underline;
}

    .show-all-title:hover,
    .show-all-title:focus-visible { text-decoration: none; }

.product-gallery-item { display: inline-block; flex: 0 0 31.9%; margin: 0 0 1.5em 0; padding: 0; position: relative; text-decoration: none; vertical-align: top; width: 32%; }

.product-item-image,
.product-item-recommend-inner {
    background: #ffff;
    border: 1px solid #e1e1e1;
    border-radius: .3em;
    cursor: pointer;
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.product-gallery-image-wrap .product-item-image {
    background: #ffff;
    border: none;
}
    .product-item-image:hover,
    .product-item-image:focus-visible,
    .product-item-recommend-inner:hover,
    .product-item-recommend-inner:focus-visible {
        box-shadow: 0 0.1em 0.5em rgba(0, 0, 0, 0.25);
    }

.product-item-recommend-inner { align-items: stretch; /*align-items: flex-start;*/ display: flex; flex-wrap: wrap; justify-content: space-between; position: relative; padding: 1em; }

.product-item-image.product-item-recommend-inner::before,
.product-item-image.product-item-recommend-inner:before { display: none; }
/*.product-item-image{width:100%; height:280px;}*/
img { display: inline-block; height: 100%; }

.product-item-reaction { align-items: center; display: flex; gap: .75em; padding: .8em; position: absolute; right: 0; top: 0; }

.bought-together-tile-image-wrap .product-item-reaction { padding: .7em .6em; }

.product-item-reaction.left-large { left: 0; padding: 0 .25em 1.1em; position: relative; }

.product-item-icon { align-items: center; /*display: flex;*/ display: none; justify-content: center; position: relative; }

    .product-item-icon .material-symbols-rounded { color: #757575; display: block; font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48; font-size: 1.3em; position: relative; }

.bought-together-tile-image-wrap .product-item-icon .material-symbols-rounded { font-size: 1em; }

.product-item-reaction.left-large .product-item-icon .material-symbols-rounded { font-size: 2.5em; font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 48; }

.product-gallery-item .product-item-icon:hover .material-symbols-rounded,
.product-gallery-item .product-item-icon:focus-visible .material-symbols-rounded,
.bought-together-tile .product-item-icon:hover .material-symbols-rounded,
.bought-together-tile .product-item-icon:focus-visible .material-symbols-rounded { font-variation-settings: 'FILL' 1; }

.favorite .product-item-icon .material-symbols-rounded { color: #1F1F1F; font-variation-settings: 'FILL' 1; }

.product-gallery-item.product-recommended:hover .product-item-icon .material-symbols-rounded,
.product-gallery-item.product-recommended:focus-visible .product-item-icon .material-symbols-rounded { font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 48;; }

.product-item-content { display: block; cursor: default; position: relative; width: 100%; }

.product-title-amount-wrap { align-items: flex-start; display: flex; flex-wrap: nowrap; gap: .4em; position: relative; width: 100%; }

.product-item-title { color: #1F1F1F; display: inline-block; font-size: 1em; font-weight: 700; line-height: 1.2em; padding: .9em 0 .6em 0; position: relative; vertical-align: top; width: 100%; }

.product-item-amount { color: #1F1F1F; display: inline-block; font-size: 1em; line-height: 1.2em; padding: .9em .2em .6em 0; position: relative; vertical-align: top; }

.product-details-color-wrap .color-dots {
    border: 1px solid #e3e3e3;
    border-radius: 50%;
    display: inline-block;
    height: 20px;
    overflow: hidden;
    position: relative;
    width: 20px;
}
.color-dots {
    border: 1px solid #e3e3e3;
    border-radius: 50%;
    display: inline-block;
    height: 20px;
    overflow: hidden;
    position: relative;
    width: 20px;
}
.color-dots .color-dots {
  border: none;
}
.color-dots img {
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}


    .color-dots.large { margin-bottom: .3em; }

.add::before,
.add:before { color: #757575; content: '\e145'; display: block; font-family: "Material Symbols Rounded"; font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 200, 'opsz' 48; font-size: .7em; left: 51%; position: absolute; top: 50%; transform: translate(-50%, -50%); }

.mobile-product-filters,
.mobile-product-results,
.mobile-product-sort-wrap { display: none; }

.mobile-product-overlay-dim { background: rgba(31, 31, 31, .9); content: ''; display: none; height: 100%; left: 0; pointer-events: none; position: fixed; top: 0; transition: .25s; visibility: visible; width: 100%; z-index: 501; }

.mobile-product-nav-close { display: none; }

.default-cta { background: transparent; border: 1px solid #E3E3E3; border-radius: 1.5em; color: #1F1F1F; display: inline-block; font-size: .8em; font-weight: 700; line-height: 1.2em; padding: 1em 1.5em 1.1em 1.5em; position: relative; text-align: center; text-decoration: none; vertical-align: top; }

    .default-cta.power { background: #1F1F1F; color: #fff; }

.product-item-filter-inner-wrap { align-items: flex-start; display: flex; padding: .8em 0; position: relative; }

.cta-wrapper { display: none; }

.product-layout-wrapper { align-items: flex-end; align-content: flex-end; bottom: 0; display: flex; flex-direction: column; height: 100%; justify-content: flex-end; left: 0; padding: 0 1.6em 1.5em; position: absolute; width: 100%; }

.product-item-image.product-item-recommend-inner .product-layout-wrapper { padding: 19% .65em .75em; padding-top: calc(100% - 14.1em); position: relative; }

.product-layout-title { color: #1F1F1F; display: block; font-size: 1.6em; font-weight: 700; line-height: 1.1em; padding: 0 0 .2em 0; position: relative; }

.product-layout-desc { color: #1F1F1F; display: block; font-size: .9em; line-height: 1.3em; padding: 0 0 1.2em 0; position: relative; }

.product-layout-cta { background: #1F1F1F; border: 1px solid #1F1F1F; border-radius: 1.5em; color: #fff; display: inline-block; font-size: .9em; line-height: 1.2em; margin-top: .65em; padding: .9em 2.5em .8em 1.7em; position: relative; text-align: center; text-decoration: none; transition: .25s; vertical-align: top; }

    .product-layout-cta::after,
    .product-layout-cta:after { color: #fff; content: '\e5c8'; display: block; font-family: 'Material Symbols Rounded'; font-size: 1.5em; font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 48; position: absolute; right: .6em; top: .6em; }

    .product-layout-cta:hover,
    .product-layout-cta:focus-visible { background: transparent; color: #1f1f1f; }

        .product-layout-cta:hover::after,
        .product-layout-cta:hover:after,
        .product-layout-cta:focus-visible::after,
        .product-layout-cta:focus-visible:after { color: #1f1f1f; }

.mobile-sort-nav { display: none; background: #fff; border-radius: 1em 1em 0 0; bottom: 0; left: 0; max-width: 100%; padding: 1em 0 .95em 0; position: fixed; width: 100vw; z-index: 2001; }
.errorImage {
    align-items: center;
    background: #fff;
    color: #1f1f1f;
    display: inline-flex;
    font-size: .9em;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%
}
.product-item-image > .product-item-image::before, 
.product-item-image > .product-item-image:before {
    content: '';
    display: block;
    height: 0;
    padding: 110% 0 0 0;
    position: relative;
    width: 0;
}
img.cover-thumb {
    display: block;
    height: 100%;
    left: 0;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%;
}

.product-checkbox.disabled .checkmark {
    background-color: #E3E3E3;
}
.filters-overlay {
    align-items: center;
    background: rgba(31,31,31,.9);
    border-right: 1px solid #f2f2f2;
    display: none;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    pointer-events: all;
    position: relative;
    transition: .25s;
    width: 20em;
    z-index:999;
}
.filters-overlay-inner {
    background: #fff;
    border-radius: 1rem 1rem 0 0;
    bottom: 0;
    top: auto;
    overflow: hidden;
    position: absolute;
    width: 100%;
}

.prod-detail-content a {
    text-decoration: none;
}

.product-main-title-wrap {
    align-items: end;
    display: flex;
    position: relative;
    width: 100%;
}
.product-main-logout-button {
    border-radius: 2em;
    border: 1px solid #1F1F1F;
    color: #1F1F1F;
    cursor: pointer;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1.11em;
    padding: 0.45em 0.85em;
}
.product-main-logout-button:hover {
     background-color: #1F1F1F;
    color: #fff;
}
@media screen and (max-width: 1800px) {
    /* PRODUCT */
    .product-recommended .product-item-image::before,
    .pdoduct-recommended .product-item-image:before {
            padding: 111% 0 0 0;
        }
    }

    @media screen and (max-width: 1500px) {

        /* PRODUCT */
        .page-header-wrap {
            padding: 1.1em 0 2.4em 0;
        }

        .product-main-title,
        .page-main-title {
            font-size: 2.4em;
        }

        .product-title {
            font-size: 1.1em;
        }

        .product-item-title,
        .product-item-amount {
            font-size: .9em;
        }

        .product-layout-title {
            font-size: 1.4em;
        }

        .product-item-image.product-item-recommend-inner .product-layout-wrapper {
            padding: 25% .65em .75em;
        }

        .product-layout-cta,
        .product-layout-desc {
            font-size: .8em;
        }

        .product-layout-desc {
            padding: 0 0 1em 0;
        }

        .product-layout-cta {
            margin-top: 0;
        }

        .product-item-reaction.left-large .product-item-icon .material-symbols-rounded {
            font-size: 2.1em;
        }

        .product-layout-wrapper {
            padding: 0 1.3em 1.5em;
        }

        .product-item-reaction.left-large {
            padding: 0 .25em .7em;
        }

        .product-layout-cta::after,
        .product-layout-cta:after {
            right: .5em;
            top: .6em;
        }

        .product-item-filter-collapse {
            max-height: 6.9em;
        }

        .show-all-wrap::before,
        .show-all-wrap:before {
            top: -.85em;
        }

        .product-gallery-inner {
            gap: .85em;
        }

        .product-item-image img {
            display: block;
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
        }

            .product-item-image img.cover-thumb {
                object-fit: contain;
            }
    }

    @media screen and (max-width: 1200px) {

        /* PRODUCT */
        .product-wrap {
            padding: 0 0 1.4em 0;
        }

        .product-filter-active {
            padding: .5em 2.2em .5em .9em;
        }

            .product-filter-active::after,
            .product-filter-active:after {
                right: .5em;
                top: .2em;
            }

        .product-gallery-item {
            width: 32.1%;
        }

        .product-title {
            font-size: 1.1em;
        }

        .product-layout-cta {
            font-size: .7em;
            padding: 0.65em 2.5em 0.65em 1em;
        }

        .product-item-title,
        .product-item-amount {
            font-size: .7em;
        }

        .product-layout-cta::after,
        .product-layout-cta:after {
            font-size: 1.4em;
            right: .5em;
            top: .4em;
        }

        .product-inner-item.product-nav {
            max-width: 10.45em;
        }

        .container-checkbox,
        .container-radio {
            font-size: .8em;
            line-height: 1.1em;
            padding-right: 0;
        }

        .product-item-filter-trigger {
            font-size: .9em;
        }

        .product-item-image.product-item-recommend-inner .product-layout-wrapper {
            padding: 18% .25em .75em;
            align-items: flex-end;
            justify-content: center; /*padding: 50.7% .65em .75em;*/
        }

        .product-item-reaction.left-large .product-item-icon .material-symbols-rounded {
            font-size: 1.8em;
        }

        .product-layout-title {
            margin-right: auto;
            max-width: 9em;
        }

        .product-layout-desc {
            margin-right: auto;
        }

        .product-title {
            font-size: 1em;
        }

        .product-layout-title {
            font-size: 1.1em;
        }

        .product-layout-desc {
            font-size: .7em;
        }

        .product-results,
        .product-clear {
            font-size: .6em;
        }

        .product-filter-active,
        .product-item-filter-trigger,
        .container-checkbox,
        .container-radio,
        .show-all-title {
            font-size: .7em;
        }

        .product-main-title,
        .page-main-title {
            font-size: 2.3em;
        }

        /*    .product-breadcrumbs-wrap a.breadcrumb,
    .product-breadcrumbs-wrap a.breadcrumb + span { font-size: .6em; }
*/
        .product-item-filter-collapse {
            max-height: 6.6em;
        }

        .show-all-wrap::before,
        .show-all-wrap:before {
            top: -1.43em;
        }

        .product-item-reaction.left-large {
            padding: 0 .25em .7em 0;
        }

        .product-gallery-inner {
            gap: .45em;
        }
    }

    @media screen and (max-width: 900px) {

        /* PRODUCT */
        .default-center {
            padding: 0 .8em;
        }

        .page-header-wrap {
            padding: .9em 0 .6em 0;
        }

        .product-main-title,
        .page-main-title {
            font-size: 1.8em;
        }

        .product-mobile-results-wrap {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
            margin-top: .7em;
            position: relative;
            width: 100%;
        }

        .product-controls-wrap {
            display: block;
            position: relative;
        }

        .mobile-product-filters,
        .mobile-product-sort-wrap {
            background: transparent;
            border: 1px solid #1F1F1F;
            border-radius: 1.5em;
            color: #1F1F1F;
            display: inline-block;
            font-size: .7em;
            font-weight: 700;
            line-height: 1.2em;
            padding: .5em 1.8em .5em .7em;
            position: relative;
            text-align: center;
            text-decoration: none;
            vertical-align: top;
        }

        .mobile-product-filters {
            margin-right: .3em;
        }

        .mobile-product-sort-wrap {
            border: 1px solid #E3E3E3;
        }

        .mobile-product-filters::after,
        .mobile-product-filters:after {
            color: #1F1F1F;
            content: '\e429';
            display: block;
            font-family: 'Material Symbols Rounded';
            font-size: 1em;
            position: absolute;
            right: .7em;
            top: .5em;
        }

        .mobile-product-sort-wrap::after,
        .mobile-product-sort-wrap:after {
            color: #1F1F1F;
            content: '\e5cf';
            display: block;
            font-family: 'Material Symbols Rounded';
            font-size: 1.4em;
            font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
            position: absolute;
            right: .2em;
            top: .35em;
        }

        .sort-by-wrap {
            display: none;
        }

        .mobile-product-results {
            display: inline-block;
            font-size: .7em;
            margin-top: 1.4em;
            position: relative;
            text-decoration: none;
            vertical-align: baseline;
        }

        .product-wrap {
            display: block;
        }

        .product-inner-item.product-nav {
            display: none;
        }

        .menu-open .product-overlay-wrap.product-inner-item.mobile-filter-nav .product-inner-item.product-nav {
            background: #fff;
            border-radius: 1em 1em 0 0;
            bottom: 0;
            display: inline-block;
            max-width: 100%;
            overflow: hidden;
            padding: 0;
            position: absolute;
            top: auto;
            vertical-align: middle;
            width: 100%;
        }

        .product-overlay-wrap.product-inner-item.mobile-filter-nav {
            display: none;
            height: 100%;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 900;
        }

        .product-overlay-wrap.product-inner-item.product-nav {
            background: #fff;
            border-radius: 1em 1em 0 0;
            bottom: 0;
            display: none;
            max-width: 100%;
            top: auto;
            width: 100%;
        }

        .product-overlay-wrap .scrollbar-outer > .scroll-content.scroll-scrolly_visible {
            left: unset;
            margin-left: unset;
        }

        .product-overlay-wrap .scrollbar-outer > .scroll-element.scroll-y {
            bottom: 1.9em;
            height: auto;
            overflow: auto;
            right: .1em;
            top: 4em;
            width: 12px;
        }

            .product-overlay-wrap .scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
                left: 2px;
                width: 3px;
            }

        .product-overlay-wrap .scrollbar-outer > .scroll-element {
            background-color: transparent;
        }

        .product-content-center {
            display: block;
            /* overflow: auto;*/
            padding: 3.5em 0 1em 0;
            position: relative;
        }

        /*.prod-detail-content.expanded {
            padding: 0 .8em;
        }*/

        .product-item.product-item-filter-wrap {
            height: 100%;
            max-height: calc(100vh - 4em);
            overflow-y: auto;
            padding: 0;
        }
        /* .product-item.product-item-filter-wrap.scrollbar-outer{
        visibility:hidden;
    }*/
        .filter-active .product-overlay-wrap.product-inner-item.mobile-filter-nav {
            display: block;
        }

        .filter-active .mobile-product-overlay-dim,
        .sort-active .mobile-product-overlay-dim {
            display: block;
        }

        .product-filter-controls-wrap {
            display: none;
        }

        .product-item .filter-list,
        .product-filter-selected {
            display: none;
        }

        .product-inner-overlay-bar {
            background: #fff;
            display: block;
            left: 0;
            padding: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 502;
        }

        .product-item.filter-results-wrap {
            border-bottom: 1px solid #E3E3E3;
        }

        .mobile-product-nav-close {
            background-color: transparent;
            border: none;
            color: #757575;
            display: block;
            font-size: 1.3em;
            height: 1.4em;
            position: absolute;
            right: .5em;
            text-decoration: none;
            top: .9rem;
            width: 1.4em;
            z-index: 1;
        }

        .product-overlay-wrap.product-inner-item.mobile-filter-nav .mobile-product-nav-close {
            top: .8em;
        }

        .mobile-sort-nav .mobile-product-nav-close {
            top: .9em;
        }

        .product-item.filter-results-wrap,
        .product-filter-sort-wrap {
            padding: 1rem .85em .7em;
            pointer-events: none;
        }

        .product-title {
            padding: 0;
        }

        .product-item-filter.collapsed,
        .product-item-sort-wrapper,
        .sorting-overlay .product-item-filter.cta-wrapper {
            padding: 0 .8em;
        }

        .product-item-sort-wrapper {
            border-bottom: 1px solid #E3E3E3;
        }

        .product-item-filter-trigger {
            padding: 1.25em 3em 1.25em 0;
        }

        .expanded .product-item-filter-trigger {
            padding: 1.25em 3em .5em 0;
        }

        .product-item-filter-trigger::before,
        .product-item-filter-trigger:before {
            font-size: 1.4em;
            right: -.25em;
            top: 57%;
        }

        .container-checkbox,
        .show-all-title,
        .container-radio {
            font-size: .8em;
            margin-bottom: .8em;
            padding-left: 2.1em;
        }

        .product-inner-overlay-bar {
            border-bottom: none;
        }

        .product-item-filter-collapse {
            max-height: 9.6em;
            padding: .4em 0;
        }

        .product-item + .product-item:last-of-type {
            border: none;
        }

        .product-item-filter.cta-wrapper {
            border-bottom: none;
            display: block;
            position: relative;
            border-top: 1px solid #e3e3e3;
        }

        .default-cta,
        .default-cta.power {
            padding: .7em 2.5em .7em 2.5em;
        }

            .default-cta.power {
                padding: .7em 3.2em .7em 3.2em;
            }

        .product-item-filter-inner-wrap .default-cta {
            padding: .75em 3em .7em 3em;
        }

            .product-item-filter-inner-wrap .default-cta.power {
                padding: .75em 3.3em .7em 3.3em;
            }

        .product-item-filter-inner-wrap {
            align-items: flex-start;
            display: flex;
            gap: 0.4rem;
            justify-content: center;
            padding: .8em 0;
            position: relative;
            width: 100%;
        }

        .prod-detail-content .prod-detail-content-trigger {
            padding: 1em 3em 0.45em 0;
        }

        .product-item-filter-collapse-wrap {
            margin: 0;
        }

        .checkmark {
            top: -.15em;
        }

        .product-breadcrumbs-wrap {
            display: none;
        }

        .product-details-default-wrap .product-breadcrumbs-wrap {
            display: block;
        }

        .product-gallery-item {
            flex: 0 0 48.2%;
            width: 48.3%;
        }

        .product-item-reaction {
            padding: .278em;
        }

        .product-item-icon .material-symbols-rounded {
            font-size: 1.2em;
        }

        .product-item-title {
            line-height: 1.1em;
            padding: .9em 0 .6em 0;
        }

        .product-item-amount {
            font-size: .7em;
            padding: 1em 0;
        }

        .product-color-wrap {
            margin-top: -.4em;
        }

        .color-dots {
            height: .8em;
            width: .8em;
        }

        .product-gallery-item {
            margin: 0;
        }

            .product-gallery-item .product-layout-cta {
                position: relative;
            }

        .product-layout-cta {
            padding: .5em 2.5em .5em 1em;
            position: absolute;
            right: 1.2em;
        }

            .product-layout-cta::after,
            .product-layout-cta:after {
                font-size: 1.2em;
                right: .5em;
                top: .45em;
            }

        .product-layout-title {
            font-size: 1.3em;
        }

        .product-layout-desc {
            font-size: .7em;
            padding: 0 0 3.5em 0;
        }

        .product-layout-wrapper {
            padding: 0 1em 1.4em;
        }

        .product-layout-wrapper {
            align-items: flex-start;
            align-items: flex-start;
        }

        .product-item-reaction.left-large {
            padding: .2em .8em .7em;
        }

        .product-layout-wrapper {
            padding: 0 .8em 1em;
        }

        .product-item-image.product-item-recommend-inner .product-layout-wrapper {
            padding: 47.2% .8em 1em;
            padding-top: calc(100% - 10.8em);
            margin: auto;
        }

        .product-recommended .product-item-image {
            align-items: flex-start;
            justify-content: space-between;
            padding: 0;
        }

        .product-item-reaction.left-large {
            margin-left: -.1em;
            padding: 0.2em 0.6em 0.7em;
        }

        .sort-active .mobile-sort-nav {
            display: block;
        }

        .product-filter-sort-wrap {
            border-bottom: 1px solid #E3E3E3;
        }

        .default-full {
            width: 100%;
        }

        .product-item-sort {
            padding: 1.3em 0 .6em;
        }

        .add::before,
        .add:before {
            font-size: .65em;
            left: 50%;
        }

        .product-title {
            font-size: 1.3em;
        }

        .show-all-wrap::before,
        .show-all-wrap:before {
            height: 3em;
        }

        .show-all-title {
            padding-left: 0
        }

        .product-gallery-inner {
            gap: .5em;
        }

        .filters-overlay {
            border-radius: 0;
            display: block;
            height: 100vh;
            max-height: 100%;
            width: 100%;
            position: fixed;
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 999;
        }

            .filters-overlay .prod-detail-content .prod-detail-content-trigger::before,
            .filters-overlay .prod-detail-content .prod-detail-content-trigger:before {
                right: -.21rem;
            }

        /* .product-gallery-info-inner {
    display: block;
    } */
    }

    @media screen and (max-width: 600px) {

        /* PRODUCT */
        .product-item-reaction.left-large .product-item-icon .material-symbols-rounded {
            font-size: 1.6em;
        }

        .product-layout-title {
            font-size: .95em;
            line-height: 1.1em;
            padding: 0 0 .4em 0;
        }

        .product-layout-desc {
            font-size: .7em;
            line-height: 1.1em;
            padding: 0 0 .9em 0;
        }

        .product-layout-wrapper {
            padding: 0 .5em .5em;
        }

        .product-layout-cta {
            font-size: .7em;
            padding: .65em 1.5em .65em .6em;
            position: relative;
            right: 0;
            width: 100%;
        }

        .product-item-reaction.left-large {
            padding: .2em .4em .7em;
        }

        .product-layout-cta::after,
        .product-layout-cta:after {
            font-size: 1.1em;
            right: .7em;
            top: .65em;
        }

        .product-item-image.product-item-recommend-inner .product-layout-wrapper {
            padding-top: calc(100% - 9.4em);
        }
    }

    @media screen and (max-width: 450px) {

        /*PRODUCT*/
        .product-item-image.product-item-recommend-inner .product-layout-wrapper {
            padding: 2em .45em .8em;
            padding-top: calc(100% - 9.4em);
        }

        .product-item-filter-inner-wrap {
            padding: 0.8em 1em;
            font-size: 0.8em;
            gap: 0.9rem;
        }

            .product-item-filter-inner-wrap .default-cta {
                font-size: 0.875em;
            }
    }

    @media screen and (max-width: 375px) {
        .product-gallery-item {
            flex: 0 0 100%;
            width: 100%;
        }
    }

    @media screen and (max-width: 374px) {

        /* PRODUCT */
        .product-gallery-item {
            width: 100%;
        }
    }

