.contact-wrapper {
    display: block;
    height: 100%;
    padding: 2rem 0 0 0;
    position: relative;
    width: 100%;
}
.contact-blocks-wrapper {
    align-items: flex-start;
    display: flex;
    gap: 8.25rem;
    padding: 1.7rem 0 0 0;
    position: relative;
}
.contact-blocks-wrap{
    display:inline-block;
    flex:0 1 13rem;
    position:relative;
   /* width:100%;*/
}

.contact-blocks {
    display: block;
    position: relative;
    padding: 0 0 2rem 0;
    width: 100%;
}
.contact-blocks p.contact-title {
    color: #1F1F1F;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1em;
    padding: 0 0 .7rem 0;
    position: relative;
}
.contact-blocks p {
    color: #757575;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2em;
    max-width: 13rem;
    padding:0 0 .1rem 0;
    position:relative;
}
.contact-blocks a{
    color: #757575;
    display:block;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25em;
    padding:0 0 .1rem 0;
    position:relative;
    text-decoration:none;
}
.contact-blocks a.contact-email{
     text-decoration: underline;
     word-wrap:break-word;
     white-space:nowrap;
}
.contact-blocks a:hover {
    color: #444444;
    text-decoration: underline;
}

.survey-item{padding:0 0 30px 50px;}
.survey-item p{display:block; padding:0 0 10px 0;}

.survey-header-title{
    padding-bottom:3.5em;
}
.survey-blocks-wrap{
    display:flex;
    flex-wrap:wrap;
}
.survey-blocks-wrap .survey-item{
    padding:0;
    margin-bottom: 6.3em;
    width:50%;
    padding-right:1em;
}
.survey-blocks-wrap .survey-item h3, .survey-blocks-wrap .survey-item span{
    max-width:24.6rem;
}
.survey-blocks-wrap .survey-item h3{
    font-size:1.6em;
    padding-bottom:0.5625em;
    line-height:1;
}
.survey-blocks-wrap .survey-item span p{
    padding:0;
}
.survey-blocks-wrap .survey-item span a, .survey-blocks-wrap .survey-item span p a{
     color:inherit;
 }
.survey-blocks-wrap .survey-item span{
    color: #757575;
    font-size: 0.9em;
    font-weight: 400;
    line-height: 1.44;
    padding-bottom:1.33335em;
    display:block;
}
.survey-blocks-wrapper .default-cta.power{
    background-color:#ffffff;
    border:0.05em solid #1f1f1f;
    border-radius:0.3em;
    width: 9.375em;
    color:#1f1f1f;
    padding:0.5em;
    height:3em;
    display:flex;
    align-items:center;
    justify-content:center;
}
.survey-blocks-wrapper .default-cta.power:hover {
    background-color: #1f1f1f;
    color:#ffffff;
}
.survey-blocks-wrapper .default-cta.power:hover img{
    filter: brightness(0) invert(1);
}
.survey-blocks-wrapper .default-cta.power img {
    width: 0.875em;
    height: 0.875em;
    margin-left: 0.5625em;
}
.delete-product-modal.admin-form-cell {
    margin: 0;
    justify-content: flex-end;
    display: flex;
    gap: 0.65em;
}
.delete-product-modal .edit-delete-product .admin-modal-close {
    right: 1.2em;
    top: 1.05em;
}
@media screen and (max-width:900px) {
    .contact-blocks-wrapper{
        flex-wrap:wrap;
        gap:1em;
    }
    .contact-blocks-wrap{
        width:100%;
    }
    .contact-blocks a, .contact-blocks p.contact-title, .contact-blocks p {
        font-size: 0.9rem;
    }
    .survey-blocks-wrap .survey-item h3 {
        font-size: 1.2em;
    }
    .survey-blocks-wrap .survey-item{
        margin-bottom:2.5em;
        width: 100%;
    }
    .survey-blocks-wrap .survey-item span{
        padding-bottom:1em;
        font-size:0.8em;
    }
    .survey-header-title {
      padding-bottom: 2.5em
    }
    .admin-modal.edit-delete-product {
        padding: 1.5em;
    }
    
}

