.mediaGallery {
  display: inline-block;
  position: relative;
  width: 100%;
}
.admin-media-wrap .mediaGallery {
    align-items: flex-start;
    display: inline-flex;
    gap: .5em;
    justify-content: flex-start;
    max-width: fit-content;
    position: relative;
    vertical-align: top;
}

.galleryItem {
  border: 1px solid #e3e3e3;
  border-radius: .3em;
  display: inline-block;
  height: 6.65em;
  margin: 0;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  width: 10.3em;
}
.admin-draggable-editable-option .galleryItem,
.admin-draggable-editable-option .galleryItem {
  height: 6.5em;
  width: 5em;
}
.galleryImage {
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
} 
.mediaAddImage input[type="file"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 1px solid #e3e3e3;
  border-radius: .3rem;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.mediaAddImage input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}
.mediaAddImage,
.mediaAddVideo {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  align-items: center;
  background: #FFF;
  border: 1px solid #e3e3e3;
  border-radius: .3em;
  cursor: pointer;
  display: inline-flex;
  height: 6.65em;
  justify-content: center;
  position: relative;
  vertical-align: top;
  width: 10.3em;
}
.admin-draggable-editable-option .mediaAddImage,
.admin-draggable-editable-option .mediaAddVideo {
  height: 5em;
  width: 5em;
}
.admin-modal .mediaAddImage {
  height: auto;
  min-height: 6.4em;
}
.mediaAddImage {
  border: none;
}
.mediaAdd-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  border: 1px solid #757575;
  border-radius: 3em;
  color: #757575;
  cursor: pointer;
  display: inline-block;
  font-size: .75em;
  font-weight: 700;
  line-height: 1.2em;
  padding: .5333em 1em;
  position: relative;
  min-width: 8.4em;
  text-align: center;
  vertical-align: middle;
}
.admin-draggable-editable-option .mediaAdd-button {
  min-width: 0;
}
.mediaAddImage:hover .mediaAdd-button,
.mediaAddImage:focus-visible .mediaAdd-button,
.mediaAddImage input[type="file"]:hover + .mediaAdd-button,
.mediaAddImage input[type="file"]:focus-visible + .mediaAdd-button,
.mediaAddVideo:hover .mediaAdd-button,
.mediaAddVideo:focus-visible .mediaAdd-button {
  background: #2B2B2B;
  border-color: #2B2B2B;
  color: #FFF;
}
.uploadImageWrapper,
.videoThumbWrap {
  display: inline-block;
  height: auto;
  max-height: 40vh;
  max-width: 100%;
  position: relative;
  width: auto;
}
.admin-default-input-text + .uploadImageWrapper,
.admin-default-input-text + .videoThumbWrap {
  margin-top: 1.35em;
}
.ReactCrop {
  max-height: 40vh;
}
.uploadImageWrapper img,
.videoThumbWrap img {
  display: block;
  max-width: 100%;
  max-height: inherit;
}
.select-handler {
    cursor: grab;
} 
.galleryItem.videoThumbWrap {
    border: 1px solid #e3e3e3;
    border-radius: .3em;
    display: inline-block;
    height: 6.65em;
    margin: 0;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    width: 10.3em;
}
