@charset 'UTF-8';

/* Arrows */
.slick-prev,
.slick-next {
    border-radius: 50%;
    background: #ffffff;
    bottom: 6.1em;

    opacity: 0.8;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
    transform: rotate(180deg);
    font-size: 1em;
    line-height: 0;
    position: absolute;
    /* top: 50%; */
    display: block;
    width: 3.1em;
    height: 3.1em;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    z-index: 1;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 1;
}

.slick-prev:before,
.slick-next:before {
    /* font-family: 'slick'; */
    font-size: 5em;
    line-height: 1;
    font-weight: 900;
    opacity: 1;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    right: 4.4em;
}

[dir='rtl'] .slick-prev {
    right: 4.4em;
    left: auto;
}

.slick-prev:before {
    content: "\e5c4";
    color: #000000;
    display: inline-block;
    font-size: 1em;
    font-family: "Material Symbols Rounded";
    font-weight: 600;
    position: absolute;
    pointer-events: none;
    right: 0;
    left: 0;
    transform: translateY(-50%);
    top: 50%;
}

[dir='rtl'] .slick-prev:before {
    content: "\e5c4";
    color: #000000;
    display: inline-block;
    font-size: 1em;
    font-family: "Material Symbols Rounded";
    font-weight: 600;
    position: absolute;
    pointer-events: none;
    right: 0;
    left: 0;
    transform: translateY(-50%);
    top: 50%;
}

.slick-next {
    right: 0.7em;

}

[dir='rtl'] .slick-next {
    right: auto;
    left: 0;
}

.slick-next:before {
    content: "\e5c8";
    color: #000000;
    display: inline-block;
    font-size: 1em;
    font-family: "Material Symbols Rounded";
    font-weight: 600;
    position: absolute;
    pointer-events: none;
    right: 0;
    left: 0;
    transform: translateY(-50%);
    top: 50%;
}

[dir='rtl'] .slick-next:before {
    content: "\e5c8";
    color: #000000;
    display: inline-block;
    font-size: 1em;
    font-family: "Material Symbols Rounded";
    font-weight: 600;
    position: absolute;
    pointer-events: none;
    right: 0;
    left: 0;
    transform: translateY(-50%);
    top: 50%;
}




/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;

    bottom: -44px;

    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    z-index: 1;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}

.slick-dots li button:before {
    /* font-family: 'slick'; */
    font-size: 40px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
}

/* .slick-slide.slick-active{
    max-width: 18.35em;
} */
@media screen and (max-width: 900px) {

    .product-gallery-info-inner.slick-arrow.slick-prev,
    .bought-together-default .slick-arrow.slick-prev {
        display: none;
    }

    .product-gallery-info-inner .slick-arrow.slick-next,
    .bought-together-default .slick-arrow.slick-next {
        display: none;
    }

    .slider-section .room-slider-wrap .slick-arrow.slick-prev {
        display: block;
    }

}