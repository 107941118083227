.myaccount-item-inner {
    background: #fff;
    border-radius: 0.5em;
    display: block;
    margin-bottom: 1.4em;
    padding: 1.5em 1.15em 1.9em 1.6em;
    position: relative;
}
.page-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.footer-wrap {
    margin-top: auto;
}
.my-product-title-table {
    display: table;
    position: relative;
    padding-top: 1.15em;
    width: 100%;
}
.my-product-title-row {
    display: table-row;
    position: relative;
    vertical-align: middle;
    width: 100%;
}
.my-product-title-coll.product-details-title {
    padding-left: 2.4em;
}
.myaccount-table-center {
    display: block;
    margin: 0 auto;
    max-width: 80em;
    padding: 0 1.2em;
    position: relative;
    width: 100%;
}
.my-account-btn {
    margin: 0 0 3em;
}

    .my-account-btn a {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-decoration-line: underline;
        color: #000;
    }
.myaccount-table-center .admin-table-wrap.admin-table-data {
    height: 100%;
/*    min-height: calc(100vh - 21.8em);*/
    max-width: none;
    margin-bottom: 1em;
    width: 100%;
    
}

.myaccount-table-center .admin-table-wrap.admin-table-data .admin-table{
    width:100%;
}
.myaccount-table-center .default-cta a{
    color: #2C2C2C;
    text-decoration: none;
}
.myaccount-table-center .default-cta{
    min-width: 4rem;
    padding: 0.5em 1.3em 0.5em 1.3em;
}
.myaccount-table-center .default-cta:hover{
      background-color:#2C2C2C;
      color: #fff;
  
}
.account-quote{
    width:7rem;
}
.account-submit-date {
    width: 13rem;
}
.account-in-items {
    width: 10rem;
}

@media screen and (max-width:900px){
    .myaccount-table-center .product-breadcrumbs-wrap {
        display: block;
    }
        .myaccount-table-center .product-breadcrumbs-wrap p {
            font-size: .7rem;
        }
}