.custom-select-dropdown {
    background: #fff;
    border-radius: .3em;
    box-shadow: 0 .2em 1em rgba(0, 0, 0, .25);
    display: none;
    flex-direction: column;
    gap: 0;
    justify-content: space-between;
    left: 0;
    margin: -.1em 0 0 0;
    max-height: 13.5em;
    min-width: 12em;
    overflow: hidden;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;
}
.custom-select-overflow {
    max-height: 300px;
    overflow: auto;
}

.custom-select-wrap {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 100%;
}

.custom-select-trigger {
    align-content: center;
    align-items: center;
    background: #ececec url() no-repeat right .75em center;
    background-size: 0.62em auto;
    border: 1px solid #ececec;
    border-radius: 0.33em;
    color: #2c2c2c;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.9em;
    font-weight: 700;
    height: 2.778em;
    line-height: 1.2em;
    min-width: 4.44em;
    padding: 0 0.75em 0 0.75em;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    width: 100%;
}

.custom-select-open .custom-select-trigger {
    background-color: #fff;
    border-color: #e9e9e9;
}

.custom-select-value {
    display: block;
    font-size: 1em;
    line-height: 1.2em;
    margin: 0;
    overflow: hidden;
    padding: 0.1em 0 0 0;
    position: relative;
    text-overflow: ellipsis;
    webkit-line-clamp: 1;
    white-space: nowrap;
    width: 100%;
}



.custom-select-open .custom-select-dropdown {
    display: flex;
}


.custom-select-scroll-option {
    color: #000;
    display: block;
    font-size: .9em;
    line-height: 1.2em;
    padding: .23em .889em;
    position: relative;
    text-decoration: none;
}

    .custom-select-scroll-option:hover,
    .custom-select-scroll-option:focus-visible {
        background: #eee;
    }

    .custom-select-scroll-option.focused {
        background: #eee;
    }

    .custom-select-scroll-option .material-symbols-rounded {
        display: inline-block;
        vertical-align: middle;
    }

.custom-select-value .material-symbols-rounded {
    display: inline-block;
    vertical-align: middle;
}