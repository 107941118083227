.project-status{color:#eb0000; margin:-10px 0 15px 0; font-size:0.75rem; font-weight:bold;}
.project-summary-link{font-size:0.8rem; border-bottom:solid 1px rgba(0,0,0,0.15); margin-bottom:10px; cursor:pointer; }
.project-summary-link a:link, .project-summary-link a:visited{color:#222; text-decoration:none;}
.project-summary-link a:hover, .project-summary-link a:active{color:#222; text-decoration:none;}
.project-summary-link:hover, .project-summary-link:active{
   /* border-bottom-color:rgba(0,0,0,0.4); */
    color:#000;
}
.project-summary-button{padding-top:10px;}
.project-status.status-color-0, .project-status.status-color-0, .project-status.status-color-5{color:#eb0000;} /* pending, reviewing, denied */
.project-status.status-color-2, .project-status.status-color-3{color:#00660F;} /* approved, completed */
.project-status.status-color-4{color:#808080;} /* archived */
.cart-side-notes.for-quote{padding-bottom:45px;}
.cart-side-notes.for-quote .cart-side-input{font-size:0.9rem;}