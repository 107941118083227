@font-face {
    font-family: 'MetaPro';
    src: url('../../assets/fonts/MetaPro-BoldItalic.woff2') format('woff2'), url('../../assets/fonts/MetaPro-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'MetaPro';
    src: url('../../assets/fonts/MetaPro-Bold.woff2') format('woff2'), url('../../assets/fonts/MetaPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MetaPro';
    src: url('../../assets/fonts/MetaPro-NormalItalic.woff2') format('woff2'), url('../../assets/fonts/MetaPro-NormalItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MetaPro';
    src: url('../../assets/fonts/MetaPro-Normal.woff2') format('woff2'), url('../../assets/fonts/MetaPro-Normal.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.normal-metaPro {
    font-family: 'MetaPro';
    font-weight: 400;
    font-variant-numeric: lining-nums proportional-nums;
}
.bold-metaPro {
    font-family: 'MetaPro';
    font-weight: 700;
    font-variant-numeric: lining-nums proportional-nums;
}
.default-outer-wrapper-inner {
    background: #f5f5f5;
    display: block;
    position: relative;
    width: 100%;
}
.d-none{
    display:none;
}
/* TYPICAL */
.project-title-input{
    padding:0 0.5rem;
}
.prod-grid-header {
    padding-bottom: 0.8em;
}

.prod-grid-header .prod-grid-col {
    flex: 1 1 auto;
}

.prod-grid-header .prod-grid-col.prod-desc {
    flex: 0 1 100%;
    margin-left: 4.7em;
    max-width: 29rem;
}

.prod-grid-desc-wrap {
    max-width: 28rem;
    padding: 0;
}

.prod-grid-select-wrap {
    margin: 0 1.5em 0 1.5em;
}
.cart-accordion-bar button.admin-table-action{
    font-size:inherit;
    display:inline-flex;
    align-items:center;
    padding:0;
}
.cart-accordion-bar button.admin-table-action span {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.50);
}
.cart-default-center .project-item-title button.admin-table-action span.check-mark-icon,
.cart-accordion-bar button.admin-table-action span.check-mark-icon{
    font-size: 1.1rem;
}
/*TODO need js to add this - checked*/
.square-default-cta {
    border: 1px solid #1f1f1f;
    border-radius: 0.3em;
    color: #1f1f1f;
    display: inline-block;
    font-size: 0.8rem;
    line-height: 1.1em;
    padding: 0.9em 3em 0.8em 1em;
    position: relative;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
}

.square-default-cta::after,
.square-default-cta:after {
    color: #1f1f1f;
    content: "\e5c8";
    display: block;
    font-family: "Material Symbols Rounded";
    font-size: 1.6em;
    font-variation-settings: "FILL" 0, "wght" 200, "GRAD" 0, "opsz" 48;
    position: absolute;
    right: 0.7em;
    top: 0.6em;
}

.prod-pricing-label-wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-right: 6em;
    /*Watch this working responsive*/
    position: relative;
    width: 11.05rem;
}

.prod-pricing-label {
    font-size: 0.8rem;
}

.pricing-total-wrap {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-right: 1.7em;
    padding: 0;
    position: relative;
    width: 11.5rem;
}

.prod-desc-color span.lime-bak::after,
.prod-desc-color span.lime-bak:after,
.cart-desc-color span.lime-bak::after,
.cart-desc-color span.lime-bak:after {
    background: #cfdb54;
}

.prod-desc-color span.orange-bak::after,
.prod-desc-color span.orange-bak:after,
.cart-desc-color span.orange-bak::after,
.cart-desc-color span.orange-bak:after {
    background: #db6827;
}

.prod-desc-color span.lt-gray-bak::after,
.prod-desc-color span.lt-gray-bak:after,
.cart-desc-color span.lt-gray-bak::after,
.cart-desc-color span.lt-gray-bak:after {
    background: #d4c4b9;
}

.subtotal-buttons-wrap {
    padding: 1.2em 0;
}

.subtotal-simple-link:hover,
.subtotal-simple-link:focus-visible {
    text-decoration: none;
}

.add-cart-cta:hover,
.add-cart-cta:focus-visible {
    background: #fff;
    color: #1f1f1f;
}

.prod-grid-select,
.cart-grid-select {
    border-radius: 0.3em;
    line-height: 1.1em;
    font-weight: 700;
    font-size: 1rem;
    min-width: 4.2em;
    height: 2.3rem;
}

.room-slider-nav {
    display: block;
    height: 17em;
    pointer-events: none;
    position: absolute;
    top: 12em;
    width: 100%;
    z-index: 7;
}

.room-slider-wrap .slick-arrow,
.bought-together-inner .slick-arrow {
    background: #fff;
    border: none;
    border-radius: 50%;
    bottom: 0.4rem;
    box-shadow: 0.1rem 0.1rem 0.6rem rgb(0 0 0 / 15%);
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 3.1rem;
    right: 3.25rem;
    margin: -1.225rem 0 0 0;
    position: absolute;
    top: auto;
    width: 3.1rem;
    z-index: 2;
}

.room-slider-wrap .slick-arrow:hover,
.room-slider-wrap .slick-arrow:focus-visible {
    background: #f5f5f5;
}

.bought-together-inner .slick-arrow {
    background: #f5f5f5;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
    bottom: 6rem;
    right: -4.6rem;
}

.bought-together-inner .slick-arrow:hover,
.bought-together-inner .slick-arrow:focus-visible {
    background: #fff;
}

.room-slider-wrap .slick-arrow::after,
.room-slider-wrap .slick-arrow:after,
.bought-together-inner .slick-arrow::after,
.bought-together-inner .slick-arrow:after {
    bottom: 0;
    color: #1f1f1f;
    content: "\e5c4";
    display: block;
    font-family: "Material Symbols Rounded";
    font-size: 1.2rem;
    height: 1em;
    left: 0;
    line-height: 1em;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 1em;
}

.room-slider-wrap .slick-next::after,
.room-slider-wrap .slick-next:after,
.bought-together-inner .slick-next::after,
.bought-together-inner .slick-next:after {
    content: "\e5c8";
}

.room-slider-wrap .slick-prev,
.bought-together-inner .slick-prev {
    left: auto;
    right: 6.9rem;
}

.bought-together-inner .slick-prev {
    right: -1rem;
}

.room-slider-wrap.slick-slider .slick-track {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.95em;
}

.product-overlay-wrap {
    display: none;
}

/* LOGIN */
.prod-grid-header-title,
.cart-grid-header-title {
    color: #757575;
    display: inline-block;
    font-size: 0.8rem;
    left: 2.6em;
    line-height: 1.35em;
    position: absolute;
    top: -2.3em;
}

.prod-desc .prod-grid-header-title {
    left: 0;
}

.select-wrap .prod-grid-header-title {
    left: auto;
    right: 1.7em;
}

.prod-grid-header-title.header-right {
    left: auto;
    right: 0;
}

.prod-grid-header-wrap,
.cart-grid-header-wrap {
    align-items: center;
    display: flex;
    position: absolute;
    justify-content: space-between;
    top: 0;
    width: 11.5rem;
}

/* MYPROJECT */
.cart-default-center.default-center {
    max-width: 79.2em;
}

.cart-default-wrapper .default-center {
    max-width: 79.2em;
    padding: 0;
}

.project {
    background: #f5f5f5;
}

.project .page-header-wrap {
    padding: 1.8em 0 1.8em 0;
}

.cart-default-wrapper .project-wrap {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    gap: 1.05em;
    padding: 0 0 2.4em 0;
    position: relative;
    width: 100%;
}

.project-item.summary-wrap {
    display: inline-block;
    max-width: 19.15em;
    position: relative;
    width: 100%;
}

.cart-default-wrapper .project-item-inner {
    background: #fff;
    border-radius: 0.5em;
    display: block;
    margin-bottom: 1.4em;
    padding: 1.5em 1.15em 1.9em 1.6em;
    position: relative;
}

.project-item.summary-wrap .project-item-inner {
    padding: 1.05em 1.25em 1.5em 1.25em;
}

.project-item-title {
    color: #1f1f1f;
    display: block;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.75em;
    margin-bottom:0.75rem;
    position: relative;
    width: 100%;
}
.cart-default-center .project-item-title button.admin-table-action {
    display: inline-flex;
    align-items: center;
}
.cart-default-center .project-item-title button.admin-table-action{
    padding:0;
}
.cart-default-center .project-item-title button.admin-table-action span{
    font-size:1rem;
    color:rgba(117, 117, 117, 0.50);
    margin-left:0.75rem;
}
.my-products--table-row .color-dots.large {
    height: 1rem;
    margin: -.05em 0 0 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1rem;
}
 
.cart-layout-wrap {
    display: block;
    position: relative;
    width: 100%;
}

.cart-grid-row {
    border-top: 1px solid #c4c4c4;
    justify-content: flex-start;
    padding: 1.3em 0 1.3em;
}

.cart-grid-row.wishlist-row {
    padding: 1.4em 0 1.4em;
}

.cart-grid-row.wishlist-row:last-child {
    padding: 1.4em 0 1.1em;
}

.cart-grid-col:nth-child(1) {
    margin-left: 0;
}

.cart-grid-col:nth-child(3) {
    margin-left: 0.9em;
}

.cart-grid-col:nth-child(4) {
    margin-left: 4.8em;
}

.cart-grid-image-wrap {
    border-radius: 0.4em;
    border: 1px solid #e1e1e1;
    display: block;
    min-height: 140px;
    overflow: hidden;
    position: relative;
    transition: 0.25s;
    width: 100%;
}

    .cart-grid-image-wrap span {
        border: 0;
        height: 6.9rem;
        width: 7.3rem;
    }

    .cart-grid-image-wrap .errorImage {
        font-size: 0.7em;
        padding: 1em;
    }
        .cart-grid-image-wrap img {
    display: block;
    height: 100%;
    left: 0;
    object-fit: contain;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
}

.cart-grid-header-wrap {
    display: none;
    width: 14.75rem;
}

.cart-grid-header-title {
    top: -4.2em;
    left: 0;
    right: 0;
}

.cart-grid-col.quantity .cart-grid-header-title {
    left: 1.3em;
}

.cart-grid-header-wrap .cart-grid-header-title {
    left: 4em;
}

.cart-grid-header-title.header-right {
    left: auto;
    right: 1.5em;
}

.cart-grid-desc-wrap {
    display: inline-block;
}

.cart-desc-title {
    line-height: 1.2em;
}
.cart-grid-desc-wrap p {
    color: #757575;
    display: block;
    font-size: 0.8rem;
    line-height: 1.3125em;
    position: relative;
}
.cart-grid-select {
    padding: 0.55em 1.25em 0.55em 1.1em;
}

.cart-total-wrap {
    padding: 0;
    position: relative;
}

.cart-grid-total.left-unit-price {
    /* padding-right: 2.15em;*/
    padding-right: 3.75em;
}

.cart-grid-total-price-wrap {
    display: inline-block;
    position: relative;
}

.cart-grid-total {
    color: #1f1f1f;
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.1em;
    margin-top: 0.7em;
    text-align: center;
    position: relative;
}

.cart-grid-unit-price-wrap {
    display: inline-block;
    margin-left: 0.2em;
    position: relative;
}

.cart-grid-unit-buttons {
    align-items: center;
    display: inline-flex;
    flex: 0 0 100%;
    gap: 0.25em;
    justify-content: flex-end;
    position: relative;
    width: 100%;
}

/*footer accordion*/
.cart-accordion-footer.prod-detail-content-trigger {
    align-items:center;
    border-radius: 0px 0px 10px 10px;
    border-top: 2px solid #ffffff;
    display: flex;
    font-family: 'MetaPro';
    font-weight: 400;
    font-variant-numeric: lining-nums proportional-nums;
    justify-content: space-between;
    padding: 0rem 1.25rem;
    background: #757575;
}
.cart-accordion .cart-accordion-footer span.grand-total {
    padding-right: 0;
}
.cart-accordion .cart-accordion-footer .accordion-delete,
.cart-accordion .cart-accordion-footer .accordion-subtotal,
.cart-accordion .cart-accordion-footer .accordion-quantity {
    float: inherit;
    margin:0;
    padding:0;
}.cart-accordion .cart-accordion-footer .accordion-quantity{
     padding:0 0.5rem;
 }
.cart-accordion-footer.prod-detail-content-trigger:before {
    display:none;
}
.my-products--coll.tag-box {
    padding-right: 0.25rem;
    min-width:4rem;
}
.tag-box input {
    border-radius: 6px;
    border: 1px solid #C3C3C3;
    font-size:1rem;
    font-weight:700;
    font-family: 'MetaPro';
    color:#000000;
    max-width: 6.5rem;
    width: 100%;
    padding:0.25rem 0.5rem;
    height:2.3rem;
}
.tag-box input:focus, .tag-box input:focus-visible{
    outline:none;
}
.tag-box input::placeholder{
    color:#757575;
}
.cart-default-wrapper .cart-grid-unit-buttons .myproduct-icon.wishlist-cell .cta-cell-wrap::after,
.cart-default-wrapper .cart-grid-unit-buttons .myproduct-icon.wishlist-cell .cta-cell-wrap:after {
    content: "\e8cc";
}
.cart-default-wrapper .cart-grid-unit-buttons .myproduct-icon.icon-cta {
    transition: 0.25s;
}
.cart-default-wrapper .cart-grid-unit-buttons .icon-cta:hover .cta-cell-wrap::after,
.cart-default-wrapper .cart-grid-unit-buttons .icon-cta:hover .cta-cell-wrap:after {
    color: #fff
}
.cart-default-wrapper .accordion-title-input .admin-default-input-text {
    font-size: 1.25rem;
    padding: 0.4em 0.8em;
    height: 2.3rem;
    font-weight: 700;
}
/*.cart-grid-total-price-wrap .cart-grid-inner {
    text-align: center;
}
*/
.cart-grid-total.left-unit-price {
    padding: 0;
    text-align: right;
}
.cart-grid-unit-price-wrap .cart-grid-inner {
    text-align: right;
}

.icon-cta {
    border: 1px solid #c4c4c4;
    border-radius: 0.3em;
    color: #757575;
    font-size: 0.8rem;
    margin-top: 3.4em;
    padding: 0.71em 0.9em 0.71em 0.9em;
    text-align: left;
    text-decoration: none;
    transition: 0.25s;
}

.icon-cta.cart-cell {
    max-width: 9.9rem;
}

.icon-cta.wishlist-cell {
    display: flex;
    width: fit-content;
}

.icon-cta.delete-cell {
    max-width: 5.5rem;
}

.icon-cta:hover,
.icon-cta:focus-visible {
    background: #1f1f1f;
    color: #fff;
    border-color: #1f1f1f;
}
.icon-cta:hover .cta-cell-wrap:after{
    color:#fff;
}

.wishlist-cell.icon-cta span,
.delete-cell.icon-cta span {
    position: relative;
    white-space: nowrap;
    font-family: 'MetaPro';
    font-variant-numeric: lining-nums proportional-nums;
    font-weight: 400;
}

.wishlist-cell .cta-cell-wrap::after,
.wishlist-cell .cta-cell-wrap:after {
    color: #757575;
    content: "\e87d";
    display: inline-block;
    font-family: "Material Symbols Rounded";
    font-size: 1.4em;
    font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;
    position: absolute;
    right: -1.3em;
    top: 0;
}

.delete-cell .cta-cell-wrap::after,
.delete-cell .cta-cell-wrap:after {
    color: #757575;
    content: "\e872";
    display: inline-block;
    font-family: "Material Symbols Rounded";
    font-size: 1.5em;
    font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;
    position: absolute;
    right: -1.3em;
    top: 0;
    transition:0.25s;
}

.cart-cell .cta-cell-wrap::after,
.cart-cell .cta-cell-wrap:after {
    color: #757575;
    content: "\e8cc";
    display: inline-block;
    font-family: "Material Symbols Rounded";
    font-size: 1.4em;
    font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;
    position: absolute;
    right: -1.4em;
    top: 0;
}

.icon-cta:hover .cart-cell span,
.icon-cta:focus-visible .cart-cell span,
.icon-cta:hover .delete-cell span,
.icon-cta:focus-visible .delete-cell span,
.icon-cta:hover .wishlist-cell span,
.icon-cta:focus-visible .wishlist-cell span {
    color: #fff;
}

.cart-grid-row.estimate {
    padding: 1.25em 0 0;
    border-top:0;
}

.cart-estimate-cell {
    display: inline-block;
    position: relative;
    text-align: right;
    vertical-align: middle;
    width: 100%;
}

.cart-estimate-content {
    color: #1f1f1f;
    display: block;
    font-size: 1.1em;
    line-height: 1.1em;
    position: relative;
}
.cart-estimate-content .cart-estimate-cost {
    font-size: 1.5rem;
}

.summary-est-wrap {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: 1.1em 0 1em;
    position: relative;
    width: 100%;
}

.summary-est-subhead {
    color: #1f1f1f;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1.25em;
    position: relative;
}

.summary-est-desc {
    color: #757575;
    display: block;
    font-size: 0.8em;
    line-height: 1.3em;
    padding: 0 0 1.6em 0;
    position: relative;
}

.summary-est-desc+.summary-est-desc {
    border-top: 1px solid #c4c4c4;
    padding: 1.2em 0 0.6em 0;
}

.summary-wrap .add-cart-cta {
    padding: 0.8em 1em 0.8em 1em;
}

.icon-cta.delete-cell .mobile-icon {
    display: none;
}

.product-breadcrumbs-wrap a.breadcrumb {
    line-height: 1.35em;
}

.cart-select-wrapper {
    appearance: none;
    border-radius: 0.3em;
    line-height: 1.1em;
    font-weight: 700;
    font-size: 1rem;
    min-width: 4.2em;
    position: relative;
    width: 100%;
}

.cart-select-arrow {
    appearance: none;
    background: none;
    border: 1px solid #C3C3C3;
    cursor: pointer;
    position: relative;
    width: 100%;
}

.cart-select-wrapper.arrow-button:after {
    content: "\e5cf";
    color: #757575;
    display: inline-block;
    font-size: 1.2em;
    font-family: "Material Symbols Rounded";
    font-weight: 400;
    position: absolute;
    pointer-events: none;
    right: 0.5em;
    transform: translateY(-50%);
    top: 52%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.cart-cell.cart-move {
    max-width: 8.05rem;
}

.hero-subtitle.card-h2 {
    color: #ffffff;
    display: block;
    font-size: 4.25rem;
    font-weight: 700;
    line-height: 1.059em;
    margin: 0 auto;
    max-width: 17.5em;
    position: relative;
    text-align: left;
    text-decoration: none;
    width: 100%;
}

.card-content-inner {
    display: none;
    max-width: 17.5rem;
    padding-top: 1.4em;
    position: relative;
    width: 100%;
}

.types-card:hover .card-content-inner {
    display: block;
}

.active .hero-tile-image {
    opacity: 1;
}

.hero-tile-image img {
    bottom: -50em;
    display: block;
    cursor: pointer;
    left: -50em;
    margin: auto;
    max-height: 150%;
    max-width: 150%;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    position: absolute;
    right: -50em;
    top: -50em;
    transform-origin: center;
    transition: 0.25s;
}

.hero-tile-image.one img {
    object-position: -8%;
}

.hero-tile-image.two img {
    object-position: 50%;
}

.hero-tile-image.three img {
    object-position: 116%;
}

.hero-tile-content {
    display: none;
    max-width: 17.85em;
    position: relative;
    transition: 0.25s;
    width: 100%;
}

.hero-tile-count {
    color: #fff;
    display: inline-block;
    font-size: 0.991em;
    letter-spacing: 0.1em;
    line-height: 1em;
    margin: 0 0 1.4em 0;
    padding: 0.5em 0;
    position: relative;
    text-transform: uppercase;
}

.hero-tile-title {
    color: #fff;
    display: block;
    font-size: 4em;
    font-weight: 700;
    letter-spacing: 0.1em;
    line-height: 1em;
    padding: 0 0 0.66em 0;
    position: relative;
    text-transform: uppercase;
}

.hero-border-overlay,
.hero-noborder-overlay {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    min-height: 100%;
    position: absolute;
    width: 100%;
}

.hero-border-overlay:hover,
.hero-border-overlay:active,
.hero-noborder-overlay:hover,
.hero-noborder-overlay:active {
    background: linear-gradient(90.23deg, rgba(0, 0, 0, 0.2301) 0.21%, rgba(0, 0, 0, 0) 104.27%);
}

.hero-border-overlay::after,
.hero-border-overlay:after {
    background: rgba(255, 255, 255, 0.4);
    bottom: 0;
    content: "";
    display: block;
    height: 91.2%;
    position: absolute;
    right: 0;
    width: 1px;
}

.hero-tile-inner {
    display: block;
    padding: 18.9em 8em 0;
    margin-top: 87px;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 100%;
}

.hero-arrow {
    display: none;
    position: absolute;
    top: 8em;
    left: -2em;
    width: 100%;
}

.hero-tile-inner:hover .hero-arrow {
    display: block;
}

.hero-tile:hover .hero-tile-content,
.hero-tile:active .hero-tile-content {
    display: block;
}

.hero-tile-desc {
    color: #ffffff;
    display: block;
    font-size: 0.8rem;
    line-height: 1.4em;
    position: relative;
}

.hero-button-wrap {
    display: block;
    padding: 0;
    position: relative;
    width: 100%;
}

.hero-tile-link {
    background: #fff;
    border-radius: 1.5em;
    color: #1f1f1f;
    display: inline-block;
    font-size: 0.9rem;
    font-weight: 700;
    margin-top: 2.7em;
    padding: 0.7em 2.7em 0.7em 1.55em;
    position: relative;
    text-decoration: none;
}

.hero-tile-link::before,
.hero-tile-link:before {
    color: #1f1f1f;
    content: "\e5c8";
    display: block;
    font-family: "Material Symbols Rounded";
    font-size: 1.3em;
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
    height: 1.2em;
    position: absolute;
    right: 0.6em;
    top: 0.4em;
    width: 1.2em;
}

.hero-tile-link:hover,
.hero-tile-link:active {
    color: #fff;
    background: #1f1f1f;
}

.hero-tile-link:hover:before,
.hero-tile-link:hover:before,
.hero-tile-link:active::before,
.hero-tile-link:active:before {
    color: #fff;
}

.img-banner.video-banner {
    bottom: -50em;
    display: block;
    height: auto;
    left: -50em;
    margin: auto;
    min-height: 150%;
    min-width: 150%;
    object-fit: cover;
    position: absolute;
    right: -50em;
    top: -50em;
}

.img-banner.video-banner {
    height: 100%;
    width: 2000px;
}

/* LANDING OVERLAY */
.product-layout-title.inline-block-flex {
    font-size: 1em;
    padding: 0 0 0.4em 0;
}

.product-layout-desc.inline-block-flex {
    padding: 0 0 1.6em 0;
}

.product-type .nav-block-item .product-layout-wrapper.inline-block-flex {
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    padding: 0;
}

.product-type .nav-block-item .product-layout-wrapper.inline-block-flex .product-layout-inner {
    background: #f3f3f3;
    border-radius: 0.3em;
    display: inline-block;
    padding: 1.2em 1.9em 0.95em 1.6em;
    position: relative;
}

.product-type .nav-block-item:nth-child(1) .product-layout-cta.inline-block-flex {
    clear: both;
    float: right;
    margin: 0 0 0 auto;
    padding: 0.6em 2.5em 0.65em 1.4em;
}

.product-layout-cta.inline-block-flex::after,
.product-layout-cta.inline-block-flex:after {
    font-size: 1.5em;
    right: 0.4em;
    top: 0.4em;
}

.type-back-link {
    color: #757575;
    display: inline-block;
    font-size: 0.8em;
    left: 0.1em;
    line-height: 1.2em;
    position: absolute;
    text-decoration: none;
    top: 4.8em;
    vertical-align: middle;
}

.type-back-link .material-symbols-rounded {
    display: inline-block;
    margin-top: 0.1em;
    position: relative;
    vertical-align: top;
}

.product-type .nav-block-item,
.typical-layout .nav-block-item,
.room-type .nav-block-item {
    max-width: 10em;
    margin-bottom: 1.1em;
}

.product-type .nav-block-wrap {
    gap: 0.8em;
}

.typical-layout .nav-drop-layout-wrap {
    gap: 1.7em;
}

.typical-layout .nav-block-wrap,
.room-type .nav-block-wrap {
    gap: 0.7em;
}

.typical-layout .nav-block-img-wrap::before,
.typical-layout .nav-block-img-wrap:before {
    padding: 99.1% 0 0 0;
}

.room-type .nav-drop-layout-wrap {
    gap: 1em;
    justify-content: flex-start;
}

.room-type .nav-drop-title {
    width: 13.4%;
}

.room-type .nav-block-wrap {
    width: 83.5%;
}

.room-type .nav-block-img-wrap::before,
.room-type .nav-block-img-wrap:before {
    padding: 100% 0 0 0;
}

.room-type .nav-block-item {
    margin-bottom: 1.8em;
}

.typical-layout .nav-block-item {
    margin-bottom: 2em;
}

.cart-grid-select.cart-select-arrow:focus-visible {
    outline: 0;
    /* border: 0; */
}

.cart-default-wrapper .page-header-wrap {
    padding: 2.1em 0 1.75em 0;
}

.product-title-wrap {
    display: block;
    position: relative;
    width: 100%;
}

.my-product-title-table {
    display: table;
    position: relative;
    padding-top: 1.15em;
    width: 100%;
}

.my-product-title-row {
    display: table-row;
    position: relative;
    vertical-align: middle;
    width: 100%;
}

.my-product-title-coll {
    color: #757575;
    display: table-cell;
    font-size: 0.8rem;
    text-align: left;
}

.my-product-title-coll.product-details-title {
    padding-left: 2.4em;
}

/*.table-cellprice {
    padding-right: 9.9em;
}*/

.table-cellprice,
.table-cell-totalammount {
    display: inline-block;
    width: 50%;
}

.my-product-title-coll.product-quantity {
    padding: 0 0 0 0.6em;
}

.my-product-title-coll.unit-price {
    text-align: right;
}

.my-product-title-coll.total {
    left: 3.35em;
}

.my-products--table {
    display: table;
    position: relative;
    padding-top: 1.15em;
    width: 100%;
}

.my-products--table-row {
    display: table-row;
}

.my-products--coll {
    border-top: 1px solid #c3c3c3;
    background: #fff;
    color: #757575;
    display: table-cell;
    padding: 1.2em 0 1.3em;
    vertical-align: top;
}

.my-products--coll:nth-child(2) {
    padding: 1.2em 1.3em 1.3em 1.9em;
    max-width: 17.9em;
}

.my-products--coll.cart-img {
    width: 7.3em;
    padding-left: 0;
}

p.cart-grid-total {
    padding: 0 0 0 1em;
    margin-top:0;
}

.project-item {
    display: inline-block;
    position: relative;
    width: 100%;
}

.send-code,
.icon-cta {
    appearance: none;
    background: #fff;
    border: 1px solid #1f1f1f;
    border-radius: 0.2em;
    color: #2d2d2d;
    cursor: pointer;
    display: block;
    font-size: 0.8em;
    font-weight: 400;
    line-height: 1.5em;
    padding: 0.725rem 2.6rem 0.725rem;
    position: relative;
    width: 100%;
}

.icon-cta {
    border: 1px solid #c4c4c4;
    border-radius: 0.3em;
    color: #757575;
    font-size: 0.8rem;
    margin-top: 3.4em;
    padding: 0.625em 2.6em 0.625em 0.9em;
    text-align: left;
    text-decoration: none;
    transition: 0.25s;
}

.cart-datawrap {
    text-align: right;
    /*padding-right: 0.5em;*/
}
.cart-grid-total-price-wrap,
.cart-grid-unit-price-wrap {
    display: inline-block;
    width: 43%;
}
span.table-cell-totalammount {
    padding: 0 0 0 0.2em;
}
.cart-grid-select-wrap {
    vertical-align:top;
}
.wishlist-table .cart-grid-total-price-wrap,
.wishlist-table .cart-grid-unit-price-wrap {
    width: 47%;
}
.wishlist-table .my-product-title-coll.product-quantity {
    padding-left:0;
}
/* MESSAGE */
.message-wrap {
    background: #1f1f1f;
    display: block;
    /* display: none; */
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 501;
}
.message-box.message-wrap {
  display: block;
}
.message-box.nav-wrap.docked {
    top: 2.7em;
}
.message-wrap-inner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: .5em auto;
  max-width: 80em;
  padding: 0 1.2em;
  position: relative;
  width: 100%;
}
.message-item-msg {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.message-item-msg p,
.message-item-msg {
  color: #ffffff;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2em;
  padding-right: .5em;
  position: relative;
}
.message-item-btn {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.message-btn {
    align-items: center;
    appearance: none;
    background: #1f1f1f;
    border: 1px solid #fff;
    border-radius: 3em;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: .9em;
    font-weight: 700;
    gap: 0.5em;
    line-height: 1em;
    padding: 0.622em 1.22em;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: .25s;
    white-space: nowrap;
}
.btn-outline {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}
.message-btn:hover {
    background: #fff;
    color: #1f1f1f;
}
.message-box .page-content {
  margin-top: 2.9em;
}
.message-box .nav-wrap.docked {
    top: 2.7em;
}
.text-right{
    text-align:right;
}
.my-quote-table-wrapper .cart-quantity {
    text-align: right;
}
.cart-grid-desc-wrap p.color-label-wrap {
    align-items:center;
    display: inline-flex;
    gap: .5rem;
}
.desc-inline-block,
.cart-grid-desc-wrap p.desc-inline-block {
   /* align-content: center;
    display: flex;
    gap: .5rem;*/
    display:block;
    padding: 0;
    position: relative;
    width:100%;
}
.desc-inline-title,
p.desc-inline-title {
    display:inline;
    position:relative;
}
.color-label-wrap {
    /* align-content: center;
    display: flex;
    gap: .5rem;*/
    display: inline;
    position: relative;
}
.desc-inline-block p.cart-desc-content{
    display:inline;
}

.product-description-title {
    cursor: pointer;
}

.accordion-title {
    display: inline-block;
    font-size: 1.25rem;
    line-height: 1.5;
    margin-right: 0.6rem;
}
.cart-accordion {
    /*border: 2px solid #757575;
    border-radius: 0.75rem;
    border-bottom: 5px solid #f5f5f5;*/
    margin-bottom: 20px;
}
.cart-default-wrapper .cart-accordion.expanded .prod-detail-content-collapse-wrap {
    border:1px solid #757575;
    border-top:0;
    border-bottom:0;
}
.cart-accordion-bar {
    border-radius: 10px 10px 0px 0px;
    background: #4B4B4B;
    color: #ffffff;
    display:flex;
    height: 3.5rem;
    padding: 0.3rem 3em 0.3em 1.5em;
}
.cart-accordion .my-product-title-table {
    padding:0rem 1rem;
}
.flex-between{
    display:flex;
    justify-content:space-between;
}
.total-quantity-box {
    color: #1F1F1F;
    text-align: right;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    font-family: 'MetaPro';
    font-weight: 400;
    font-variant-numeric: lining-nums proportional-nums;
    line-height: 1;
    display: flex;
    align-items: center;
}
.wishlist-table .my-product-title-coll{
    padding-bottom:0.8rem;
}
.cart-default-wrapper .prod-detail-content-trigger::before,
.cart-default-wrapper .prod-detail-content-trigger:before {
    color: #ffffff;
    right: 1rem;
}
.cart-accordion .accordion-title-input {
    align-items:center;
    display: inline-flex;
    margin: 12px 15px 12px 0;
}
.cart-accordion .accordion-title-input.label-only{display:inline-flex; margin:0px 15px 0 0;}
.cart-accordion .accordion-delete{display:inline-block; float:right; margin-top:9px;}
.cart-accordion .accordion-delete .delete-cell{margin-top:0;}
.cart-accordion .accordion-subtotal .subtotal{font-weight:normal;font-size:0.8rem;line-height:1.1rem;} 
.cart-accordion .accordion-subtotal{display:inline-flex; float:right; padding:20px 0 0 0px;}
.cart-accordion .accordion-subtotal span{display:inline-block;padding:0 10px 0 0;}
.cart-accordion .accordion-quantity{align-items:center;display:inline-flex; float:right; margin-top:9px;}
.cart-accordion .accordion-quantity.label-only{margin-top:20px;}
.cart-accordion .accordion-quantity span{display:inline-block; padding-right:1rem;font-size:0.8rem; font-weight:normal;}
.cart-accordion .grand-total {
    font-size:1.1rem;
    font-weight:700;
    line-height:1.1rem;
}
/*.cart-default-wrapper .prod-detail-content.expanded {
    border-bottom:2px solid #757575;
}*/
.cart-accordion .accordion-quantity select {
    background-color: #fff;
}
.cart-accordion .cart-total-wrap{margin-right:10px;}
.cart-accordion .cart-grid-total{text-align:right; color:#1f1f1f;}
.cart-accordion .mobile-price-label{display:none;}
.cart-accordion span.table-cell-totalammount{padding:0 0.5em 0 0.2em;}
/*.cart-accordion .cart-img{padding-left:10px;}*/

.tag-help{padding:0 0 0 5px; position:relative; top:3px; cursor:pointer; pointer-events:visible;}
.quote-page-wrapper .cart-quantity{
    padding-left:0.8rem;
}
.cart-side-notes{padding:0.5em 0 1.5em 0;}
.cart-side-label{
    color:#757575; font-size:0.8rem; padding:1em 0 0.2em 0;
    display:block;
}
.cart-side-input textarea{width:100%; font-size:0.8rem; padding:0.5em; border-radius:0.5rem; border:1px solid #C3C3C3 }
.cart-side-input input[type="text"]{
    border-radius: 6px;
    border: 1px solid #C3C3C3;
    font-size:0.8rem;
    font-weight:100;
    font-family: 'MetaPro';
    color:#000000;
    width: 100%;
    padding:0.25rem 0.5rem;
    height:2.3rem;
}
.cart-side-input .cart-select-arrow{font-weight:100; padding:5px 10px; font-size:0.8rem !important;}
.demo-cart-popup input[type="text"]{width:100%;}
.internal-error{font-size:0.8rem;}

@media screen and (max-width: 1800px) {
    p.cart-grid-total {
        padding-left: 1rem;
        font-size: 0.95rem;
    }
    .quote-page-wrapper .cart-grid-total-price-wrap .cart-grid-total.left-unit-price {
        padding-left: 0;
    }
}
@media screen and (max-width: 1500px) {
    .cart-default-wrapper .project-item-inner {
        padding: 1.25em 1em 1.5em 1em;
    }
    .my-product-title-coll.product-details-title{
        padding-left:2em;
    }
    .message-item-msg p, .message-btn {
        font-size: .85rem;
    }
    .type-back-link {
        top: 3.4em;
    }
    .cart-default-wrapper .my-product-title-coll.product-details-title {
        padding-left: 1em;
    }
    .cart-default-wrapper .my-products--coll:nth-child(2) {
        padding-left: 1em;
    }
    .cart-default-wrapper .cart-grid-desc-wrap p.cart-desc-title,
    .cart-default-wrapper .cart-accordion .cart-grid-total,
    .cart-default-wrapper .cart-select-arrow, .tag-box input {
        font-size: 0.9rem;
    }

    .quote-page-wrapper p.cart-grid-total {
        padding-left: 0.5rem;
        font-size: 0.9rem;
    }
}

@media screen and (max-width:1300px){
    .cart-grid-image-wrap {
        min-width: 6em;
    }
    .project-item.summary-wrap {
        max-width: 17.15em;
    }
}

@media screen and (max-width: 1280px), print {
    /*.cart-default-wrapper .project-wrap {
        flex-direction: column;
    }
    .cart-default-wrapper .project-item {
        order: 2;
    }
    .cart-default-wrapper .project-item.summary-wrap {
        order: 1;
        width: 100%;
        max-width: 100%;
    }*/
    .cart-grid-image-wrap {
        align-items:center;
        display:flex;
        justify-content:center;
        min-width: 5em;
        min-height:5rem;
    }
    .cart-grid-image-wrap span{
        width:5rem;
        height:5rem;
    }
    .my-products--coll.cart-img{
        width:5rem;
    }
    .cart-estimate-content .cart-estimate-cost{
        font-size:1.3rem;
    }
}
@media screen and (max-width: 1200px), print {
  .message-item-msg p,
  .message-btn {
    font-size: .8rem;
  }
    .type-back-link {
        top: 2.5em;
    }
    .cart-grid-image-wrap span {
        height: 5rem;
    }
        .my-products--coll.cart-quantity{
        position:absolute;
        left:0;
    }
   .my-product-title-row,
    .my-product-title-coll.product-details-title {
        display: none !important;
    }
    .cart-grid-image-wrap span {
        height: 89px;
    }
    .my-products--coll.cart-price {
        display: inline-block;
    }
    .my-products--coll.cart-price {
        display: inline-block;
        /*padding-left: 2rem !important;*/
        padding-left: 7.8rem !important;
        width: calc(90% - 1rem);
    }
    .my-products--table-row .my-products--coll.cart-detail.cart-grid-col {
        width: calc(100% - 1rem);
        max-width: calc(100% - 5.68rem);
        padding-left: 7.8rem !important;
    }
    .cart-total-wrap .cart-datawrap {
        flex-wrap: nowrap;
        justify-content: flex-end;
        padding: .1em 0 0 0;
    }
    .cart-grid-unit-buttons {
        align-items: flex-start;
        flex-direction: row-reverse;
        padding-left: 0;
    }
    .my-products--coll{
        border:none;
    }
    .my-product-title-table {
        display: block !important;
        width: 100%;
    }
    .my-products--table-row {
        border-top: 1px solid #c4c4c4;
        display: block;
        padding: 1.2rem 0;
        width: 100%;
    }
    .cart-default-wrapper .project-item-inner {
        padding: 1.5em .7rem 1.9em .7rem;
    }
    .cart-grid-inner p.cart-grid-total {
        font-size: .8rem;
        margin-top: 0.5em;
    }
    .my-products--coll.cart-quantity {
        position:absolute;
        top: 6.36em;
    }
    .cart-grid-row.estimate {
        padding: 1em 0 0;
    }
    .cart-estimate-content{
        font-size:.9rem;
    }
    
    .my-products--coll.cart-img {
        display: inline-block;
        position: absolute;
    }
    .project-item.summary-wrap .project-item-inner {
        padding: 1.05em .7rem;
    }
    .cart-grid-desc-wrap {
        padding: 0;
    }
    .cart-grid-image-wrap img{
        object-fit:contain;
    }
    .icon-cta.wishlist-cell,
    .icon-cta.delete-cell{
        margin-top:1rem;
    }
    .cart-grid-total-price-wrap, 
    .cart-grid-unit-price-wrap {
        width: auto;
    }

    /*product cart css start*/
    .cart-default-wrapper .my-products--coll.cart-img {
        position:unset;
        max-width:fit-content;
    }
    .cart-grid-image-wrap span{
        height:5rem;
    }
    .product-item-image img {
        top: 0;
        max-width: fit-content;
        bottom: 0;
        right: 0;
        margin: auto;
    }
    .cart-default-wrapper .my-products--table-row .my-products--coll.cart-detail.cart-grid-col {
        width: calc(100% - 1rem);
        max-width: calc(100% - 5rem);
        padding-left: 1.5rem !important;
    }
    .cart-default-wrapper .my-products--table-row {
        display:flex;
        flex-wrap:wrap;
    }
    .cart-default-wrapper .my-products--coll.cart-quantity {
        position:unset;
        margin-left:10px;
    }
    .cart-default-wrapper .prod-shipping-select {
        height:auto;
    }
    .cart-default-wrapper .my-products--coll.cart-price {
        display: flex;
        padding-left: 0.5rem !important;
        width: auto;
        margin-left:auto;
    }
    .cart-default-wrapper .cart-accordion .cart-total-wrap {
        margin-right: 0px;
        display: flex;
        align-items: center;
        width: auto;
        max-width: fit-content;
    }
    .cart-default-wrapper .icon-cta.wishlist-cell, .icon-cta.delete-cell {
        margin-top:0
    }
    .cart-default-wrapper .cart-grid-inner p.cart-grid-total {
        margin-top:0;
    }
    .cart-default-wrapper .cart-grid-unit-buttons {
        flex:1;
        padding-left:1rem;
    }
    .cart-default-wrapper .project-item-title {
        font-size:1.3em;
    }
    .cart-default-wrapper .wishlist-table .my-products--coll.cart-quantity {
        margin-left: 0
    }
    .cart-default-wrapper .wishlist-table .cart-total-wrap {
        align-items:center;
        display:flex;
        flex-wrap:wrap;
    }
    .cart-default-wrapper .wishlist-table .my-products--coll.cart-price {
        padding-top:0 !important;
    }
    .project-wrap .cart-total-wrap {
        display: flex;
        align-items: center;
    }
    .project-wrap .cart-total-wrap .cart-datawrap {
        justify-content: flex-start;
        gap: 0.5rem;
    }
    .project-wrap .my-products--coll.cart-price {
        padding: 0.5em 0 0 0 !important;
    }
}
@media screen and (max-width: 1024px) {
    .cart-grid-select-wrap {
        vertical-align: middle;
    }
    .summary-est-wrap{
        padding-top:0.5em;
    }
    .tag-box input {
        height: 1.8rem;
    }
    .cart-default-wrapper .icon-cta.wishlist-cell, .icon-cta.delete-cell {
        padding: 0.3em 2.1em 0.3em 0.5em;
    }
    .cart-default-wrapper .cart-accordion-footer .icon-cta.wishlist-cell, .icon-cta.delete-cell {
        padding: 0.3em 0.5em 0.3em 0.5em;
    }
    .cart-default-wrapper .delete-cell .cta-cell-wrap::after, .delete-cell .cta-cell-wrap:after {
        font-size:1.2em;
    }
    .cart-default-wrapper .cart-select-arrow,
    .tag-box input {
        font-size: 0.8rem;
    }
    .cart-default-wrapper .project-item-title {
        font-size: 1.1em;
    }
    .cart-default-wrapper .accordion-title {
        font-size:1rem;
    }
    .cart-default-wrapper .cart-accordion-bar button.admin-table-action span {
        font-size: 0.8rem;
    }
    .cart-default-wrapper .cart-accordion .my-product-title-table,
    .cart-accordion-footer.prod-detail-content-trigger {
        padding: 0 0.75rem;
    }
    .total-quantity-box{
        font-size:0.9rem;
    }
    .cart-accordion .cart-accordion-footer .accordion-quantity span {
        padding-right:0.5rem;
    }
    .cart-accordion .cart-accordion-footer .grand-total {
        font-size: 0.9rem;
    }
    .cart-estimate-content .cart-estimate-cost {
        font-size: 1.2rem;
    }
    .cart-default-wrapper .wishlist-table .cart-grid-unit-buttons {
        padding-left:0.5rem;
    }

}

@media screen and (max-width: 900px) {
  .message-item-msg p,
  .message-btn {
    font-size: .7rem;
  }
    .send-code, .icon-cta {
        padding: 0.7rem 2.6rem 0.7rem 0.9rem;
    }
    /* MY PROJECT*/
    .my-product-title-row,
    .my-product-title-coll.product-details-title {
        display: none !important;
    }
    .cart-grid-image-wrap span {
        height: 89px;
    }
    .my-products--coll.cart-price{
        display:block;
    }
    .my-products--coll.cart-price {
        display: block;
        padding-left: 5.5rem !important;
        width: 100%;
    }
    .my-products--table-row .my-products--coll.cart-detail.cart-grid-col {
        padding-left: 5.5rem !important;
        max-width: 100%;
        width: 100%;
    }
    .cart-total-wrap .cart-datawrap{
        flex-wrap:nowrap;
        justify-content: flex-end;
        gap: 1rem;
    }
    .cart-grid-unit-buttons {
        align-items: flex-start;
        flex-direction: row-reverse;
        justify-content: flex-start;
        padding-left: 0;
    }
    .cart-total-wrap .mobile-price-label {
      text-align: end;
      width: 100%;
    }
    .cart-grid-inner p.cart-grid-total {
      padding-right: 0;
    }
    .my-products--coll{
        border:none;
    }
    .my-product-title-table {
        display: block !important;
        width: 100%;
    }
    .my-products--table-row {
        border-top: 1px solid #c4c4c4;
        display: block;
        padding: .8rem 0;
        width: 100%;
    }
    .cart-grid-inner p.cart-grid-total {
        font-size: .8rem;
        margin-top: 0.5em;
    }
    .my-products--coll.cart-quantity {
        position:absolute;
        top: 5.66em;
    }
    .cart-grid-row.estimate {
        padding: 0;
    }
    .cart-estimate-content{
        font-size:.9rem;
    }
    .project-item.summary-wrap{
        max-width:25em;
    }
    .my-products--coll.cart-img{
        position:absolute;
    }
    .project-item.summary-wrap .project-item-inner {
        padding: 1.05em .7rem;
    }
    .cart-grid-desc-wrap {
        padding: 0;
    }
    .delete-cell .cell-text{
        display:none;
    }
    .delete-cell .cta-cell-wrap::after, 
    .delete-cell .cta-cell-wrap:after{
        position:relative;
        right:auto;
        left:auto;
        top:.15rem;
    }
    .icon-cta.delete-cell {
        display: block;
        line-height: 1em;
        min-width: auto;
        max-width: none;
        padding: 0.175rem 0.375rem;
        width: auto;
    }
    .cart-grid-image-wrap {
        min-width: 4.6em;
    }
    .cart-grid-unit-buttons .icon-cta.wishlist-cell {
      padding: .484em .5em .354em 1.8em;
    }
    .cart-grid-unit-buttons .icon-cta.wishlist-cell {
        max-width: unset;
        padding-right: 3em;
        width: auto;
    }
    .cart-grid-unit-buttons .icon-cta.wishlist-cell.delete-cell {
      padding: 0.35em;
    }
    .cart-grid-unit-buttons .icon-cta.delete-cell {
        padding-right: 0.375rem;
    }
    .cart-grid-select {
        padding: 0.3em 1.25em 0.2em 1.1em;
    }
    .prod-grid-select, .cart-grid-select {
        font-size: 0.7rem;
        height: 1.8rem;
    }
    .project-item-title{
        font-size:1.1rem;
    }
    .desc-inline-title {
      display: inline-block;
      padding-right: .5em;
      position: relative;
    }
    .cart-grid-desc-wrap p {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      font-size: .7rem;
      line-height: 1.25em;
      padding-bottom: .3em;
      /*padding-right: .5em;*/
    }
    .cart-desc-options {
        font-size: .7rem;
        line-height: 1.25em;
    }
    .cart-grid-desc-wrap p.cart-desc-content {
        padding-bottom: 0;
    }
    .cart-grid-desc-wrap p > span.color-label-wrap {
      display: inline-block;
      display: flex;
      align-items: flex-start;
      vertical-align: top;
    }
    .cart-grid-desc-wrap .cart-desc-content > .color-dots {
      position: absolute;
      display: inline-block;
      right: 0;
      top: 0;
    }
    .my-products--table-row .color-dots.large {
      right: -.3em;
      top: -.1em;
    }

    .cart-total-wrap .mobile-price-label {
        text-align: center;
        width: 100%;
        display: block;
    }
    .cart-default-wrapper .cart-grid-inner{
        text-align:center;
    }
    .cart-default-wrapper .cart-grid-inner p.cart-grid-total{
        width:auto;
        text-align:center;
    }
    .cart-default-wrapper .project-item, .project-item.summary-wrap {
        max-width: 30rem;
    }
    .cart-default-wrapper .prod-detail-content .prod-detail-content-trigger {
        padding: 0.75rem;
    }
    .cart-estimate-content .cart-estimate-cost{
        font-size:1rem;
    }
    .cart-default-wrapper .wishlist-table .my-products--coll.cart-img {
        margin-bottom:0.5rem;
    }
    .wishlist-cell.icon-cta span.cta-cell-wrap .cell-text {
        display: none;
    }
    .cart-grid-unit-buttons .icon-cta.wishlist-cell {
        width: 1.5rem;
        height: 1.5rem;
        min-width: unset;
        padding: 0.3em;
    }
    .wishlist-cell .cta-cell-wrap::after, .wishlist-cell .cta-cell-wrap:after{
        left:0;
        right:0;
    }
    .cart-default-wrapper .page-header-wrap{
        max-width:30rem;
        margin-top:1rem;
    }
    .project-wrap .my-products--coll.cart-price {
        padding: 0em 0 0 0 !important;
    }
}

@media screen and (max-width: 600px) {
    .project-wrap .my-products--coll.cart-price {
        padding: 0.8em 0 0 0 !important;
    }
    .cart-default-wrapper .prod-detail-content-trigger::before, 
    .cart-default-wrapper .prod-detail-content-trigger:before{
        right:0.5rem;
    }
    .cart-accordion .accordion-title-input {
        margin: 12px 35px 12px 0;
    }
    .cart-accordion .cart-accordion-footer .accordion-quantity span {
        padding-right: 0;
        font-size: 0.75rem;
    }
    .project-item-inner .summary-est-desc + .summary-est-desc {
        padding: 0.7em 0 1.5em 0;
    }

    .project-item-inner .summary-est-desc {
        padding: 0 0 0.9em 0;
    }

    .project-item-inner .my-product-title-table {
        padding-top: 0.35em;
    }
    .icon-cta.delete-cell {
      line-height: 1.1em;  
    }
    .cart-grid-desc-wrap p.cart-desc-title {
      font-size: .8rem;
      line-height: 1.2em;
      padding-bottom: .3em;
    }
    .cart-default-wrapper .project-item-inner {
      padding: .7em .7rem .9em .7rem;  
    }
    .cart-grid-desc-wrap p {
      font-size: .7rem;
      line-height: 1.2em;
    }
    .my-products--table-row .color-dots.large {
      display: inline-block;
      position: relative;
      top: -.2em;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      vertical-align: top;
    }
    
    .cart-grid-unit-buttons .icon-cta.wishlist-cell {
      padding-right: 2.8em;
    }
    .cart-default-wrapper .tag-box{
        width:48%;
    }
    .cart-grid-unit-buttons .icon-cta.wishlist-cell {
        padding: 0.45em 0.3em;
    }
    .cart-default-wrapper .my-products--coll.cart-quantity {
        margin-left: auto;
        width: 48%;
    }
    .cart-default-wrapper .prod-shipping-select{
        width:100%;
    }
    .cart-default-wrapper .tag-box input {
      max-width: unset;
      width:100%;
    }
    .cart-default-wrapper .cart-accordion .cart-total-wrap {
        max-width: unset;
        width: 100%;
    }
    .cart-default-wrapper .my-products--coll.cart-price{
        max-width:inherit;
        width:100%;
        padding-left:0 !important;
    }
    .cart-default-wrapper .cart-grid-inner p.cart-grid-total{
        padding-left:0;
    }
    .cart-default-wrapper .my-products--table-row .my-products--coll.cart-detail.cart-grid-col{
        padding-left:0.75rem !important;
    }
    .cart-default-wrapper .cart-grid-desc-wrap p.cart-desc-title, 
    .cart-default-wrapper .cart-accordion .cart-grid-total, 
    .cart-default-wrapper .cart-select-arrow{
        font-size:0.8rem;
    }
    .cart-default-wrapper .cart-grid-image-wrap, .my-products--coll.cart-img,
    .cart-grid-image-wrap span {
        min-width: 4rem;
        min-height: 4rem;
        width: 4rem;
        height:4rem;
    }
    .cart-default-wrapper .project-item-title {
        font-size: 1em;
    }
    .cart-default-center .project-item-title button.admin-table-action span{
        margin-left:0.25rem;
        font-size:0.8rem;
    }
    .cart-default-wrapper .accordion-title {
        font-size: 0.9rem;
        margin-right: 0.25rem;
    }
    .total-quantity-box {
        font-size: 0.8rem;
        white-space:nowrap;
        padding:0.25rem;
    }
    .project-item-title .flex-between{
        flex-wrap:wrap;
    }
    .cart-accordion .accordion-subtotal{
        flex-direction:column;
    }
    .cart-default-wrapper .wishlist-table .cart-total-wrap{
        max-width:inherit;
        padding-top:0.5rem;
    }
    .icon-cta.wishlist-cell.myproduct-icon .cell-text {
        display: none;
    }
    
    .wishlist-cell .cta-cell-wrap::after, .wishlist-cell .cta-cell-wrap:after{
        left:0;
        right:0;
    }
    .icon-cta.wishlist-cell.myproduct-icon {
        width: 1.5rem;
        height: 1.5rem;
        min-width: unset;
        /*padding: 0.21em;*/
    }
    .cart-default-wrapper .project-item-title {
        margin-bottom:0.25rem;
    }
    .cart-grid-unit-buttons .icon-cta.wishlist-cell.delete-cell {
        padding: 0.4em 0.45em;
    }
}

@media screen and (max-width: 360px) {
    .cart-accordion .cart-accordion-footer .accordion-quantity span {
        font-size: 0.7rem;
    }
    .cart-default-wrapper .prod-detail-content .prod-detail-content-trigger,
    .cart-default-wrapper .cart-accordion .my-product-title-table, 
    .cart-accordion-footer.prod-detail-content-trigger {
        padding: 0.5rem;
    }
    .cart-default-wrapper .cart-accordion-footer .accordion-quantity {
        width:7rem;
    }
    .cart-default-wrapper .cart-accordion-footer .cart-grid-select-wrap,
    .cart-default-wrapper .cart-accordion-footer .cart-select-wrapper,
    .cart-default-wrapper .cart-accordion-footer .cart-grid-select {
        min-width: 3em;
    }
    .cart-default-wrapper .cart-accordion-footer .cart-grid-select {
        padding-left:0.5em;
    }
    .cart-default-wrapper .cart-accordion-footer .cart-select-wrapper.arrow-button:after {
        right: 0.1rem;
    }
}

@media print {
    .project-item.summary-wrap.normal-metaPro,
    .product-breadcrumbs-wrap p,
    .admin-table-action {
        display: none !important;
    }
    .project-item-inner,
    .cart-default-center .prod-detail-content .prod-detail-content-trigger {
        border: 1px solid #757575;
    }
    .cart-default-wrapper .project-wrap {
        padding: 0;
        margin-bottom: 2em;
    }
    .cart-accordion {
        margin-bottom: 20px;
    }
}
