.contact-wrapper {
    display: block;
    height: 100%;
    padding: 2rem 0 0 0;
    position: relative;
    width: 100%;
}
.contact-blocks-wrapper {
    align-items: flex-start;
    display: flex;
    gap: 8.25rem;
    padding: 1.7rem 0 0 0;
    position: relative;
}
.contact-blocks-wrap{
    display:inline-block;
    flex:0 1 13rem;
    position:relative;
   /* width:100%;*/
}
.contact-blocks {
    display: block;
    position: relative;
    padding: 0 0 2rem 0;
    width: 100%;
}
.contact-blocks p.contact-title {
    color: #1F1F1F;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1em;
    padding: 0 0 .7rem 0;
    position: relative;
}
.contact-blocks p {
    color: #757575;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2em;
    max-width: 13rem;
    padding:0 0 .1rem 0;
    position:relative;
}
.contact-blocks a{
    color: #757575;
    display:block;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25em;
    padding:0 0 .1rem 0;
    position:relative;
    text-decoration:none;
}
.contact-blocks a.contact-email{
     text-decoration: underline;
     word-wrap:break-word;
}
.contact-blocks a:hover {
    color: #444444;
    text-decoration: underline;
}

@media screen and (max-width:900px){
    .contact-blocks-wrapper{
        flex-wrap:wrap;
        gap:0;
    }
    .contact-blocks-wrap{
        width:100%;
    }
}