.product-breadcrumbs-wrap {
    display: block;
    position: relative;
}

    .product-breadcrumbs-wrap p {
        font-size: .8rem;
    }

    .product-breadcrumbs-wrap a {
        color: #1f1f1f;
        cursor: pointer;
        display: inline-block;
        font-size: .8rem;
        line-height: 1.2em;
        position: relative;
        text-decoration: none;
    }

    .product-breadcrumbs-wrap a {
        color: #1f1f1f;
        cursor: pointer;
        display: inline-block; /* font-size: .8em; */
        line-height: 1.2em;
        position: relative;
        text-decoration: none;
    }

        .product-breadcrumbs-wrap a.breadcrumb {
            color: #1f1f1f; /*cursor: default; */
        }

.add-cart-cta, a:link.add-cart-cta {
    background: #1f1f1f;
    border: 1px solid #1f1f1f;
    border-radius: 0.3em;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.1em;
    padding: 0.8em 1em 0.9em 1em;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: 0.25s;
    vertical-align: top;
    width: 100%;
}

.prod-grid-header,
.cart-grid-header,
.cart-grid-row {
    align-items: stretch;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.default-center {
    display: block;
    margin: 0 auto;
    max-width: 80em;
    padding: 0 1.2em;
    position: relative;
    width: 100%;
}

.page-header-wrap {
    display: block;
    padding: 1.3em 0 2.4em 0;
    position: relative;
}

.breadcrumb.shopping::before,
.breadcrumb.shopping:before {
    color: #1f1f1f;
    content: '\e5cf';
    display: inline-block;
    font-size: 1.4em;
    font-family: "Material Symbols Rounded";
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
    height: 1.2em;
    margin-right: 0em;
    position: relative;
    transform: rotate(90deg);
    vertical-align: middle;
    top: -0.05rem
}

.prod-desc-color,
.cart-desc-color {
    font-weight: 500;
    padding-bottom: 0;
}

.prod-desc-content,
.prod-desc-color,
.cart-desc-content,
.cart-desc-color {
    color: #757575;
    display: block;
    font-size: 0.8rem;
    line-height: 1.35em; /* padding-bottom: 0.6em; */
    position: relative;
}
.cart-desc-options { color: #757575; display: block; font-size: 0.8rem; line-height: 1.35em; /* padding-bottom: 0.6em; */ position: relative; }

    .prod-desc-color span,
    .cart-desc-color span { /* display: inline-block; */
        position: relative;
    }

        .prod-desc-color span.prod-desc-color-label,
        .prod-desc-color-label,
        .cart-desc-color span.prod-desc-color-label {
            display: none;
        }

        .prod-desc-color span.teal-bak::after,
        .prod-desc-color span.teal-bak:after,
        .cart-desc-color span.teal-bak::after,
        .cart-desc-color span.teal-back:after {
            background: #49a5b1;
        }

        .prod-desc-color span::after,
        .prod-desc-color span:after,
        .cart-desc-color span::after,
        .cart-desc-color span:after { /*background: #1f1f1f;*/
            border-radius: 100%;
            content: "";
            display: inline-block;
            height: 1rem;
            position: absolute;
            top: 0;
            right: -1.4rem;
            width: 1rem;
        }

.prod-grid-image-wrap,
.prod-grid-desc-wrap,
.prod-grid-select-wrap,
.prod-grid-price-wrap,
.prod-grid-total-wrap,
.prod-grid-button-wrap,
.prod-grid-action-wrap,
.cart-grid-select-wrap {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.mobile-price-label,
.mobile-unit-label,
.mobile-label {
    display: none;
}

.product-color-wrap,
.bought-together-color-wrap,
.product-dimension-color-wrap {
    align-items: flex-start;
    align-content: flex-start;
    display: flex;
    gap: .1em;
    flex: 1 1 100%;
    margin-top: -.7em;
    padding: .6em 0;
    position: relative;
}
.product-color-wrap {
    flex-wrap:wrap;
}
.product-color-wrap .color-plus-icon {
    align-items: center;
    color:#757575;
    display: flex;
    justify-content: center;
}
.product-color-wrap .color-plus-icon span {
    font-size: 0.8em;
}

.product-dimension-color-wrap {
    flex-wrap: wrap;
    gap: .5em;
    margin-top: -.8em;
    max-width: 25em;
}

.color-dots.large.black.color-select::after,
.color-dots.large.black.color-select:after,
.color-dots.large.blue.color-select::after,
.color-dots.large.blue.color-select:after,
.color-dots.large.dark-purple.color-select::after,
.color-dots.large.dark-purple.color-select:after,
.color-dots.large.purple.color-select::after,
.color-dots.large.purple.color-select:after,
.color-dots.large.red.color-select::after,
.color-dots.large.red.color-select:after,
.color-dots.large.green.color-select::after,
.color-dots.large.green.color-select:after,
.color-dots.large.medium-gray.color-select::after,
.color-dots.large.medium-gray.color-select:after {
    color: #fff;
}

.black {
    background-color: #252525;
    border: 1px solid #252525;
}

.light-gray {
    background-color: #d1d1d1;
    border: 1px solid #d1d1d1;
}

.medium-gray {
    background-color: #929292;
    border: 1px solid #929292;
}

.gray {
    background-color: #757575;
    border: 1px solid #757575;
}

.lime {
    background-color: #CFDB54;
    border: 1px solid #CFDB54;
}

.red {
    background-color: #C93030;
    border: 1px solid #C93030;
}

.dark-purple {
    background-color: #32314b;
    border: 1px solid #32314b;
}

.purple {
    background-color: #54417E;
    border: 1px solid #54417E;
}

.teal {
    background-color: #49A5B1;
    border: 1px solid #49A5B1;
}

.orange {
    background-color: #E07043;
    border: 1px solid #E07043;
}

.blue {
    background-color: #3a77d1;
    border: 1px solid #3a77d1;
}

.green {
    background-color: #276153;
    border: 1px solid #276153;
}

.add {
    background-color: #fff;
    border: 1px solid #E3E3E3;
}

.default-outer-wrapper {
    background: #fff;
}

.default-outer-wrapper {
    display: block;
    max-height: fit-content;
    height: 100%;
    min-height: calc(100vh - 8.8em);
    position: relative;
    width: 100%;
}

.typical-header-wrap .product-breadcrumbs-wrap a {
    font-size: 0.7em;
}

.product-breadcrumbs-wrap p a.breadcrumb.active {
    color: #1f1f1f;
    padding: 0;
    text-decoration: none;
}

.product-breadcrumbs-wrap a.breadcrumb + span,
.product-breadcrumbs-wrap a.breadcrumb + span + span .breadcrumb.active {
    color: #757575;
    /*font-size: .7em;*/
    font-size: inherit;
    margin: 0 0 0 .2em;
}

    .product-breadcrumbs-wrap .breadcrumb.active,
    .product-breadcrumbs-wrap a.breadcrumb + span + span .breadcrumb.active {
        color: #1f1f1f;
        font-weight: 700;
    }

.wishlist-cta:hover,
.wishlist-cta:focus-visible {
    border: 1px solid #1f1f1f;
    background: #1f1f1f;
    color: #fff;
}

.wishlist-cta span {
    display: inline-block;
    vertical-align: middle;
}

    .wishlist-cta span::after,
    .wishlist-cta span:after {
        color: #757575;
        content: "\e87d";
        display: inline-block;
        font-family: "Material Symbols Rounded";
        font-size: 1.6em;
        font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;
        line-height: 0;
        position: relative;
        right: -0.2rem;
        top: 0.5rem;
    }

.prod-desc-title,
.cart-grid-desc-wrap p.cart-desc-title {
    color: #1f1f1f;
    display: block;
    font-size: 1rem;
    font-weight: 700; /* line-height: 1em; */
    padding-bottom: 0.4em;
    position: relative;
}

.breadcrumb {
    cursor: pointer;
}

/*THANK YOU PAGE*/
.thank-you-page-wrap {
    display: block;
    height: calc(100vh - 8.8em);
    position: relative;
    width: 100%;
}

.thank-you-page-center {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    justify-content: center;
    margin: auto;
    max-width: 35em;
    text-align: center;
    width: 100%;
}

.thank-you-heading {
    line-height: 1.3em;
}

.page-content .loading, .loading-data {
    align-items: center;
    display: flex;
    font-size: 1.6em;
    font-weight: bold;
    height: calc(100vh - 5.8em);
    justify-content: center;
    position: relative;
    width: 100%;
}

.product-deatis-page-wrap {
    display: block;
    height: 100%;
    position: relative;
    min-height: calc(100vh - 8.8em);
    max-height: fit-content;
    width: 100%;
}
.product-detail-breadcrumb {
    color: #1f1f1f;
    cursor: pointer;
    display: inline-block;
    font-size: .8rem;
    line-height: 1.2em;
    position: relative;
    text-decoration: none;
}
.product-detail-breadcrumb::before,
.product-detail-breadcrumb:before{
    color: #1f1f1f;
    content: '\e5cf';
    display: inline-block;
    font-size: 1.2em;
    font-family: "Material Symbols Rounded";
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
    height: 1.2em;
    margin-right: 0.3em;
    position: relative;
    transform: rotate(90deg);
    vertical-align: middle;
    top: 0;
}
.product-detail-loader-spinner{
    align-items:center;
    display:flex;
    justify-content:center;
    position:relative;
    width:100%;
}
.product-detail-loader-spinner .spinner {
    display: grid;
    place-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: conic-gradient( from 180deg at 50% 50%, rgba(82, 0, 255, 0) 0deg, #1f1f1f 360deg );
    animation: spin 2s infinite linear;
}
.product-detail-loader-spinner .spinner::before,
.product-detail-loader-spinner .spinner:before{
  content: "";
  border-radius: 50%;
  width: 80%;
  height: 80%;
  background-color: #fff;
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

/* MEDIA */
@media screen and (max-width: 1800px) {
    /* PRODUCT */
    .product-recommended .product-item-image::before, .pdoduct-recommended .product-item-image:before {
        padding: 111% 0 0 0;
    }

    /* PRODUCT DETAIL */
    .product-detail-right {
        padding: 3.5em 0 0 2em;
    }


    /* TYPICAL */
    .prod-grid-desc-wrap {
        padding: 0.5em 0.75em;
    }

    .square-default-cta {
        padding: 0.9em 2.5em 0.8em 0.8em;
    }

        .square-default-cta::after,
        .square-default-cta:after {
            right: 0.5em;
        }

    .prod-pricing-label-wrap {
        width: 11.05rem;
    }

    .pricing-total-wrap {
        width: 10.5rem;
    }

    .prod-grid-header-wrap {
        width: 10.5rem;
    }

    .prod-grid-header-title {
        left: 1.3em;
    }

        .prod-grid-header-title.header-right {
            right: 0;
        }

    .cart-grid-col:nth-child(4) {
        margin-left: 2.6em;
    }
}

.breadcrumbs-product-details {
    color: #1f1f1f;
    cursor: pointer;
    display: inline-block;
    font-size: 0.8em;
    line-height: 1.2em;
    position: relative;
    text-decoration: none;
}

    .breadcrumbs-product-details .breadcrumb {
        cursor: pointer;
    }

        .breadcrumbs-product-details .breadcrumb.active {
            font-weight: 700;
        }

.pages-not-found {
    display: block;
    height: calc(100vh - 8.8em);
    position: relative;
    width: 100%;
}

.pages-not-found-wrap {
    align-items: center;
    display: flex;
    height: 100%;
}

.pages-not-found-center {
    display: block;
    position: relative;
    max-width: 28em;
    margin: 0 auto;
    padding: 0 0.75em;
    text-align: center;
    width: 100%;
}

.pages-not-found-img {
    display: block;
    position: relative;
    width: 100%;
    text-align: center;
}

.pages-not-found-title {
    color: #1f1f1f;
    font-size: 1.65rem;
    font-weight: 700;
    line-height: 1.3em;
    position: relative;
    vertical-align: baseline;
    padding: 0.3em 0;
}

.pages-not-found-content {
    color: #666;
    font-size: 0.95rem;
    line-height: normal;
    position: relative;
}

.pages-not-found-outer p {
    font-size: 9.3em;
    line-height: 1.1em;
    font-weight: 700;
}

@media screen and (max-width: 1600px) {
    .my-product-title-coll.total {
        left: 1.85em;
    }

    .my-product-title-coll.product-details-title {
        left: -1.1em;
    }

    .my-product-title-coll.product-quantity {
        left: 7em;
    }
}


@media screen and (max-width: 1500px) {
    .cart-grid-total-price-wrap, .icon-cta.wishlist-cell {
        max-width: unset;
        min-width: 4.15rem;
    }

    /*.table-cellprice { padding-right: 4em; }*/

    .cart-grid-total.left-unit-price {
        padding-right: 0;
        padding-left: 0;
    }

    /* NAV */
    .nav-seat {
        height: 3.8em;
    }

    .nav-logo-img {
        max-width: 7em;
    }

    .logo-title {
        font-size: 0.9em;
    }

        .logo-title::before,
        .logo-title:before {
            left: 1.1em;
        }

    .nav-middle .nav-link {
        padding: 1.3em 1.6em;
    }

    .nav-link {
        font-size: 0.9em;
    }

    /* SCROLLBAR-OUTER */
    .nav-drop-layout-wrap,
    .nav-overlay-product .nav-drop-layout-wrap {
        padding: 5em 0 0;
    }

    .nav-drop-title {
        font-size: 1.5em;
    }

    .nav-block-item {
        flex: 0 1 20.1%;
    }

    .nav-overlay-product .nav-drop-title {
        padding: 0 9em 0.7em 0;
    }

    /* PRODUCT */
    .page-header-wrap {
        padding: 1.1em 0 2.4em 0;
    }

    .product-main-title,
    .page-main-title {
        font-size: 2.4em;
    }

    .product-title {
        font-size: 1.1em;
    }

    .product-item-title,
    .product-item-amount {
        font-size: 0.9em;
    }

    .product-layout-title {
        font-size: 1.4em;
    }

    .product-item-image.product-item-recommend-inner .product-layout-wrapper {
        padding: 25% 0.65em 0.75em;
    }

    .product-layout-cta,
    .product-layout-desc {
        font-size: 0.8em;
    }

    .product-layout-desc {
        padding: 0 0 1em 0;
    }

    .product-layout-cta {
        margin-top: 0;
    }

    .product-item-reaction.left-large .product-item-icon .material-symbols-rounded {
        font-size: 2.1em;
    }

    .product-layout-wrapper {
        padding: 0 1.3em 1.5em;
    }

    .product-item-reaction.left-large {
        padding: 0 0.25em 0.7em;
    }

    .product-layout-cta::after,
    .product-layout-cta:after {
        right: 0.5em;
        top: 0.6em;
    }

    .product-item-filter-collapse {
        max-height: 6.9em;
    }

    .show-all-wrap::before,
    .show-all-wrap:before {
        top: -0.85em;
    }

    /* PRODUCT DETAIL */
    .product-detail-left {
        max-width: 35.95em;
    }

    .product-detail-right {
        padding: 3.5em 0 0 1.7em;
        width: 40%;
    }

    .product-detail-title {
        font-size: 2.2em;
    }

    .product-detail-price-amount {
        font-size: 1.1rem;
    }




    .color-dots.large.color-select::before,
    .color-dots.large.color-select:before,
    .color-dots.large.color-select::after,
    .color-dots.large.color-select:after {
        height: 3rem;
        width: 3rem;
    }

    .prod-detail-content-wrap {
        padding-bottom: 3.9em;
    }

    .prod-detail-cta {
        font-size: 0.9em;
    }

    .product-dimension-desc {
        font-size: 0.65em;
    }

    .bought-together-title {
        font-size: 1.3em;
    }

    .bought-together-tile-image-wrap {
        height: 17.75rem;
    }

    /* .product-gallery-info-inner {
    max-width: 39.4em;
    width: 100%;
    } */
    .product-results,
    .product-clear {
        font-size: 0.7em;
    }

    .product-filter-active,
    .product-item-filter-trigger,
    .container-checkbox,
    .container-radio,
    .show-all-title {
        font-size: 0.8em;
    }

    /* TYPICAL */
    .room-tile {
        /*width: 36.65em;*/
        width:100%;
    }

    .room-tile-image-wrap {
        height: 27.3rem;
    }

    .prod-grid-select,
    .prod-grid-total,
    .prod-desc-title {
        font-size: 0.9rem;
    }

    .prod-desc-content,
    .prod-desc-color {
        font-size: 0.75rem;
    }

    .square-default-cta {
        font-size: 0.75rem;
        white-space: nowrap;
    }

        .square-default-cta::after,
        .square-default-cta:after {
            font-size: 1.5em;
            right: 0.5em;
        }

    .prod-desc-color span::after,
    .prod-desc-color span:after {
        height: 0.9rem;
        width: 0.9rem;
    }

    .prod-grid-icon {
        height: 1.7rem;
        width: 1.7rem;
    }

        .prod-grid-icon .material-symbols-rounded {
            font-size: 0.9rem;
        }

    .prod-grid-col {
        font-size: 0.75em;
    }

        .prod-grid-col.prod-unit-price {
            margin-left: 5em;
        }

        .prod-grid-col.prod-image-area {
            align-items: stretch;
            justify-content: center;
        }

    .table-layout-wrap {
        padding: 4.8em 0 1em;
    }

    .subtotal-cart-title,
    .subtotal-cart-desc {
        font-size: 0.9em;
    }

    .subtotal-wrap {
        padding: 0;
    }

    .subtotal-simple-link {
        font-size: 0.75em;
        margin-right: 0;
    }

    .add-cart-cta,
    .wishlist-cta {
        font-size: 0.95em;
    }

        .wishlist-cta span::after,
        .wishlist-cta span:after {
            font-size: 1.4em; /*right: 3rem;*/
            top: 0.35rem;
        }

    .subtotal-cart-item {
        padding: 2.1em 0 0.4em;
    }

    .prod-grid-price-wrap,
    .prod-grid-total-wrap,
    .prod-grid-button-wrap {
        padding: 0 0 0 0.5em;
    }

    .prod-grid-button-wrap {
        margin: 0 0.4em 0 1.5em;
    }

    .support-tip-content {
        padding: 2.8em 0 0.6em;
    }

    .support-tips-wrap {
        gap: 1.2em;
    }

    .support-tip-icon.battery-icon .material-symbols-rounded {
        font-size: 1.6em;
    }

    .support-tip-icon .material-symbols-rounded {
        font-size: 2em;
    }

    .typical-header-wrap {
        padding: 1.1em 0 0 0;
    }

    .prod-pricing-label-wrap {
        margin-right: 7em;
        width: 10.05rem;
    }

    .prod-grid-action-wrap {
        width: 4.4rem;
    }

    .prod-quantity {
        margin-left: 0;
    }

    .prod-grid-header .prod-grid-col.prod-desc {
        max-width: 27rem;
    }

    .prod-grid-header .prod-grid-col.prod-desc {
        margin-left: 6em;
    }

    .support-tip-desc {
        font-size: 0.8em;
    }

    .support-tip-title {
        font-size: 0.8rem;
    }

    .typical-page .default-center {
        padding: 0 2em;
    }

    .prod-grid-desc-wrap {
        max-width: 26rem;
    }

    .pricing-total-wrap,
    .prod-grid-header-wrap {
        width: 11.5rem;
    }

    .prod-desc .prod-grid-header-title {
        left: 0.8em;
    }

    .page-section {
        margin: 0 0 0.6em 0;
    }

    .subtotal-cart-item {
        padding: 0 1.3em 0 0.7em;
    }

    .pricing-total-wrap {
        margin-left: 0;
    }

    .prod-grid-header-title {
        left: 2.6em;
    }

        .prod-grid-header-title.header-right {
            right: 0;
        }

    /* MYPROJECT */
    .project-item-title {
        font-size: 1.3em;
    }

    .cart-grid-header-title {
        font-size: 0.75rem;
    }

    .cart-desc-title {
        font-size: 0.95rem;
    }

    .cart-desc-content,
    .cart-desc-color {
        font-size: 0.75rem;
    }

    .summary-est-desc {
        font-size: 0.75rem;
    }

    .cart-grid-select {
        font-size: 0.9rem;
    }

    .cart-grid-total {
        font-size: 0.9rem;
    }

    .icon-cta {
        font-size: 0.7rem;
    }

    .wishlist-cell span::after,
    .wishlist-cell span:after,
    .cart-cell span::after,
    .cart-cell span:after {
        font-size: 1.3em;
    }

    .cart-cell::after,
    .cart-cell:after {
        right: -1.1em;
    }

    .delete-cell span::after,
    .delete-cell span:after {
        font-size: 1.4em;
    }

    .icon-cta.cart-cell {
        padding: 0.625em 1.4em 0.625em 0.7em;
    }

    .cart-estimate-content {
        font-size: 1em;
    }

    .cart-grid-image-wrap {
        min-width: 7.9em;
        width: 100%;
    }

    .cart-grid-col:nth-child(4) {
        margin-left: 1.7em;
    }

    .my-products--coll:nth-child(2) {
        max-width: 12.3em;
    }

    .cart-total-wrap,
    .cart-grid-header-wrap { /*max-width: 14.75em;*/
        width: 100%;
    }

    .cart-grid-total-price-wrap {
        max-width: unset;
        min-width: 4.15rem;
    }

    .cart-grid-unit-buttons {
        padding: 0;
    }

    .my-product-title-coll.product-quantity {
        padding: 0;
    }

    /* .cart-grid-unit-price-wrap,
    .icon-cta.delete-cell { max-width: 4.7rem; }*/

    .my-product-title-coll.product-details-title {
        padding-left: 2.2em;
    }

    .cart-grid-row {
        justify-content: space-between;
    }

    /* CART */
    .my-product-title-coll.product-quantity {
        left: 7.8em;
    }

    .my-product-title-coll.unit-price {
        left: 4.95em;
    }

    .my-product-title-coll.total {
        left: 0.95em;
    }

    /* LANDING */
    .hero-subtitle.card-h2 {
        font-size: 3.75rem;
    }

    .types .card-content {
        padding: 0 1.3rem 0 1.7rem;
    }

    .types .types-card:nth-child(3) .card-content {
        padding: 0 1.3rem 0 2.7rem;
    }

    .hero-tile-link {
        font-size: 0.8rem;
    }

    /* OVERLAY */
    .type-back-link {
        top: 4em;
    }

    #browseproductType .nav-block-item:nth-child(1) {
        flex: 0 1 40.2%;
    }

    .product-layout-title.inline-block-flex {
        font-size: 0.9em;
    }

    .product-layout-desc.inline-block-flex {
        font-size: 0.75em;
    }

    /* LOGIN */
    .login-form-wrap {
        margin: 0.8em auto;
    }

    .login-form-inner,
    .login-form-inner.bottom-wrap {
        padding: 1em 2.3em 1.15em;
    }

    .login-logo-cell {
        padding: 0.5em 0;
    }

    .cart-grid-row.estimate {
        padding: 1.5em 0 0;
    }
}

@media screen and (max-width: 1200px) {

    .project-item-inner .add-cart-cta,
    .wishlist-cta {
        font-size: 0.9em;
    }

    .my-products--coll.cart-price { /*margin-left: 4.3rem;*/ /*padding: 0.7em 0 0 0 !important;*/
        padding-left: 5.5rem !important;
    }

    /*.my-products--coll.cart-detail.cart-grid-col { margin-left: 1.3em; }*/

    .my-products--coll.cart-img .cart-grid-col.cart-image-area {
        position: unset;
    }

    .cart-grid-image-wrap span {
        height: 5rem;
    }

    .my-products--coll.cart-img {
        max-width: 4.7em;
        width: 100%; /*position: absolute*/
        position: relative
    }

    .my-products--coll {
        display: inline-block;
    }

    .my-products--coll {
        padding: 0 !important;
        border-top: 0px solid #c4c4c4;
    }

    .my-products--table-row {
        display: block; /* flex-wrap: wrap; */
        border-top: 1px solid #c4c4c4;
        padding: 0.9em 0;
        position: relative;
    }

    /* NAV */
    .nav-seat {
        height: 3.8em;
    }

    .nav-logo {
        width: 12.05em;
    }

    .nav-logo-img {
        max-width: 6em;
    }

    .logo-title {
        font-size: 0.9em;
        padding-left: 1.5em;
    }

        .logo-title::before,
        .logo-title:before {
            left: 0.9em;
        }

    .nav-middle .nav-link {
        font-size: 0.8em;
        padding: 1em 0.8em;
    }

    /* SCROLLBAR-OUTER */
    .nav-drop-layout-wrap,
    .nav-overlay-product .nav-drop-layout-wrap {
        padding: 4em 0 0;
    }

    .nav-block-item {
        flex: 0 1 30.1%;
    }

    .nav-block-title {
        font-size: 0.8em;
    }

    .nav-overlay-product .nav-block-item {
        flex: 0 1 49%;
    }

    .nav-overlay-product .nav-drop-title {
        padding: 0 0 0.7em 0;
    }

    .nav-overlay-product .nav-drop-layout-wrap {
        gap: 7.3em;
    }

    .nav-overlay-product .nav-overlay-center {
        max-width: 48em;
    }

    /* PRODUCT */
    .product-wrap {
        padding: 0 0 1.4em 0;
    }

    .product-filter-active {
        padding: 0.5em 2.3em 0.5em 0.9em;
    }

        .product-filter-active::after,
        .product-filter-active:after {
            right: 0.5em;
            top: 0.2em;
        }

    .product-gallery-item {
        width: 32.1%;
    }

    .product-title {
        font-size: 1.1em;
    }

    .product-layout-cta {
        font-size: 0.7em;
        padding: 0.65em 2.5em 0.65em 1em;
    }

    .product-item-title,
    .product-item-amount {
        font-size: 0.7em;
    }

    .product-layout-cta::after,
    .product-layout-cta:after {
        font-size: 1.4em;
        right: 0.5em;
        top: 0.4em;
    }

    .product-inner-item.product-nav {
        max-width: 10.45em;
    }

    .container-checkbox,
    .container-radio {
        font-size: 0.8em;
        line-height: 1.1em;
        padding-right: 0;
    }

    .product-item-filter-trigger {
        font-size: 0.9em;
    }

    .product-item-image.product-item-recommend-inner .product-layout-wrapper {
        align-items: flex-end;
        justify-content: center;
        padding: 18% 0.25em 0.75em;
    }

    .product-item-reaction.left-large .product-item-icon .material-symbols-rounded {
        font-size: 1.8em;
    }

    .product-layout-title {
        margin-right: auto;
        max-width: 9em;
    }

    .product-layout-desc {
        margin-right: auto;
    }

    .product-title {
        font-size: 1em;
    }

    .product-layout-title {
        font-size: 1.1em;
    }

    .product-layout-desc {
        font-size: 0.7em;
    }

    .product-results,
    .product-clear {
        font-size: 0.6em;
    }

    .product-filter-active,
    .product-item-filter-trigger,
    .container-checkbox,
    .container-radio,
    .show-all-title {
        font-size: 0.7em;
    }

    .product-main-title,
    .page-main-title {
        font-size: 2.3em;
    }

    /*  .product-breadcrumbs-wrap a.breadcrumb,
    .product-breadcrumbs-wrap a.breadcrumb + span { font-size: 0.6em; }*/

    .product-item-filter-collapse {
        max-height: 6.6em;
    }

    .show-all-wrap::before,
    .show-all-wrap:before {
        top: -1.43em;
    }

    .product-item-reaction.left-large {
        padding: 0 0.25em 0.7em 0;
    }

    /* PRODUCT DETAIL */
    .product-dimension-title {
        font-size: 1em;
    }

    .page-main-title {
        font-size: 2.1em;
    }

    .product-detail-right {
        padding: 3.5em 0 0 0.9em;
        width: 46.2%;
    }

    .product-dimension-number-wrap {
        flex-wrap: wrap;
    }

    .product-detail-title {
        font-size: 1.9em;
    }

    .product-detail-price-amount {
        font-size: 1rem;
    }

    .bought-together-title {
        font-size: 1.1em;
    }

    .bought-together-tile {
        width: 14.35rem;
    }

    .product-detail-list li {
        font-size: 0.7em;
    }


    .color-dots.large.color-select::before,
    .color-dots.large.color-select:before,
    .color-dots.large.color-select::after,
    .color-dots.large.color-select:after {
        height: 2.8rem;
        width: 2.8rem;
    }

    .product-detail-add-on-wrap {
        align-items: stretch;
    }

    .product-add-on-image-wrap {
        height: auto;
    }

    .product-add-on-method-inner {
        height: 1.3rem;
        width: 1.3rem;
    }

    .prod-detail-content-trigger,
    .prod-shipping-est-title {
        font-size: 0.8em;
    }

    .prod-shipping-select {
        font-size: 1em;
        height: 2.7em;
        min-width: 4.45em;
    }

    .bought-together-tile-image-wrap {
        height: 15.75rem;
    }

    /* .product-gallery-info-inner {
    max-width: 31.5em;
    } */
    /* .product-gallery-container {
    width: 28.4em;
    } */

    .product-detail-icon-wrap {
        height: 1.7rem;
        width: 1.7rem;
    }

    .product-detail-right .product-detail-icon-wrap::after,
    .product-detail-right .product-detail-icon-wrap:after {
        font-size: 1.6em;
        line-height: 0;
        right: 0.27em;
        top: 0.8em;
    }

    /* TYPICAL */
    .support-tip-desc,
    .support-tip-title {
        font-size: 0.7rem;
    }

    .room-tile {
        /*width: 31.65em;*/
        width: 100%;
    }

    .room-tile-image-wrap {
        height: 22.3rem;
    }

    .room-slider-wrap .slick-arrow,
    .bought-together-inner .slick-arrow {
        height: 2.8rem;
        width: 2.8rem;
    }

    .bought-together-inner .slick-arrow {
        right: -2.6rem;
    }

    .bought-together-inner .slick-prev {
        right: 0.7rem;
    }

    .room-slider-wrap .slick-arrow::after,
    .room-slider-wrap .slick-arrow:after {
        font-size: 1.1rem;
    }

    .table-layout-wrap {
        padding: 4.1em 0 1em;
    }

    .prod-grid-desc-wrap {
        max-width: 21rem;
    }

    .prod-desc-title {
        font-size: 0.8rem;
    }

    .prod-desc-content,
    .prod-desc-color {
        font-size: 0.7rem;
    }

    .prod-grid-select {
        font-size: 0.8rem;
    }

    .prod-grid-total {
        font-size: 0.8rem;
    }

    .square-default-cta {
        font-size: 0.7rem;
    }

    .prod-grid-icon {
        height: 1.6rem;
        width: 1.6rem;
    }

    .subtotal-wrap {
        padding: 0;
    }

    .subtotal-simple-link {
        font-size: 0.7em;
        margin-right: 0;
    }

    .subtotal-cart-title,
    .subtotal-cart-desc {
        font-size: 0.8em;
    }

    .add-cart-cta,
    .wishlist-cta {
        font-size: 0.9em;
    }

    .wishlist-cta {
        padding: 0.8em 1em 0.864em 1em;
    }

        .wishlist-cta span::after,
        .wishlist-cta span:after {
            font-size: 1.4em; /*right: .7rem;*/
        }

    .prod-desc-title {
        font-size: 0.75rem;
    }

    .prod-desc-content,
    .prod-desc-color {
        font-size: 0.65rem;
    }

    .pricing-total-wrap,
    .prod-grid-header-wrap {
        margin-left: 0.7em;
        margin-right: 0;
        width: 8.6rem;
    }

    .subtotal-inner {
        max-width: 13.6em;
    }

    /*.wishlist-cta {
    padding: .72em 2.2em .8em .4em;
    }*/
    .select-wrap .prod-grid-header-title {
        right: 0.9em;
    }

    .prod-grid-button-wrap {
        padding: 0;
    }

    .prod-grid-action-wrap {
        padding: 0 1.5em 0 0.75em;
        width: 3.6rem;
    }

    .prod-grid-select-wrap {
        margin: 0 0.7em;
    }

    .prod-grid-col.prod-image-area {
        align-items: stretch;
    }

    .typical-header-wrap {
        padding: 0.7em 0 0 0;
    }

    .page-section {
        margin: 0 0 0.1em 0;
    }

    .subtotal-cart-row:nth-child(2) {
        padding-bottom: 0.5em;
    }

    .subtotal-cart-item {
        padding: 0.625em 0 0.4em;
    }

    .prod-grid-header-title {
        left: 0.3em;
    }

        .prod-grid-header-title.header-right {
            right: 0.1em;
        }

    .prod-grid-col.prod-unit-price {
        margin-left: 3.8em;
    }

    span.table-cell-totalammount {
        padding: 0 0.6em 0 0.2em;
    }

    .cart-datawrap {
        text-align: right;
        padding-right: 0.5em;
    }

    .product-breadcrumbs-wrap .breadcrumb.shopping::before,
    .product-breadcrumbs-wrap .breadcrumb.shopping:before {
        font-size: 1.4em;
    }

    /* LOGIN */
    .login-form-wrap {
        margin: 1em auto;
        max-height: 100%;
    }

    .login-form-inner,
    .login-form-inner.bottom-wrap {
        padding: 1.2em 2.3em 1.75em;
    }

    /* MYPROJECT */
    .cart-default-wrapper {
        display: block;
        position: relative;
        width: 100%;
    }

    .cart-layout-wrap {
        padding: 1em 0 0 0;
    }

    .cart-grid-row {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .cart-grid-select {
        background-position: right 4% center;
        font-size: 0.8rem;
        height: 1.8rem;
        min-width: 5.875em;
        padding: 0.55em 1.15em 0.55em 0.75em;
        width: 100%;
    }

    .mobile-price-label {
        color: #757575;
        display: block;
        font-size: 0.7rem;
        line-height: 1em;
    }

    .typical-page .mobile-price-label {
        display: none;
    }

    .cart-grid-total {
        font-size: 0.8rem;
        line-height: 1.5em;
        margin-top: 0;
        text-align: center;
        width: 4rem;
    }

    .cart-grid-header-wrap.price-label {
        display: none;
    }

    .quantity .cart-grid-header-title,
    .cart-grid-header-title {
        display: none;
    }

    .cart-grid-image-wrap {
        min-width: 4.7em;
    }

    .cart-grid {
        margin-left: 1.5em;
    }

    .cart-grid-col:nth-child(4) {
        margin-left: 0;
    }

    .cart-grid-col:nth-child(4) {
        flex: 1 1 100%;
        justify-content: flex-start;
        margin-left: 5.8rem;
        padding: 0.3em 0 0 0;
    }

    .icon-cta {
        margin-top: 1.35em;
    }

    .cart-grid-desc-wrap {
        width: 100%;
    }

    .cart-grid-desc-wrap {
        padding: 0 1.9rem;
    }

    .my-products--coll.cart-detail.cart-grid-col {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 0; /*margin-left: 5.68em*/
        ;
        max-width: fit-content;
        width: 100%;
    }

    .cart-grid-total-price-wrap, .icon-cta.wishlist-cell {
        white-space: nowrap;
    }
    /*.my-products--coll.cart-quantity { display: inline-block; left: 0; position: absolute; top: 5.96em; z-index: 1; }*/

    .cart-total-wrap .cart-datawrap {
        display: flex;
        justify-content: space-between;
        padding: 0.1em 0em 0 1.5em;
        text-align: left;
    }

    .cart-grid-inner p.cart-grid-total {
        padding: 0;
        width: fit-content;
    }

    .cart-grid-unit-buttons {
        justify-content: flex-start;
        padding-left: 1.2em;
    }

    .cart-grid-desc-wrap .cart-desc-title {
        font-size: 0.8rem;
    }

    .summary-est-subhead {
        font-size: 0.85rem;
    }

    .cart-desc-content {
        font-size: 0.7rem;
    }

    .summary-est-desc {
        font-size: 0.7rem;
    }

    .cart-grid-col.cart-image-area {
        display: inline-block;
        left: 0;
        position: absolute;
    }

    .cart-grid-col.cart-desc {
        display: inline-block;
        margin-left: 7.1em;
        width: 70%;
    }

    .cart-grid-col:nth-child(3) {
        display: inline-block;
        margin-left: 0;
        position: absolute;
        top: 7.56em;
        z-index: 1;
    }

    .cart-grid-select-wrap {
        min-width: 4.6em;
    }

    .cart-grid-total-price-wrap .mobile-price-label { /* text-align: left; */
        width: fit-content;
    }

    .cart-grid-unit-price-wrap .mobile-price-label,
    .cart-grid-unit-price-wrap .cart-grid-total {
        margin: 0 0 0 auto;
        text-align: right;
    }

    .cart-estimate-content {
        font-size: 0.95em;
    }

    /* Cart */
    /*   .cart-default-wrapper .product-breadcrumbs-wrap a.breadcrumb,
    .product-breadcrumbs-wrap a.breadcrumb + span { font-size: 0.8em; }*/

    .my-product-title-row {
        display: none;
    }

    /* LANDING */
    .hero-subtitle.card-h2 {
        font-size: 3rem;
    }

    .hero-tile-desc {
        font-size: 0.75rem;
    }

    .hero-tile-link {
        font-size: 0.75rem;
        margin-top: 3em;
    }

        .hero-tile-link::before,
        .hero-tile-link:before {
            font-size: 1.2em;
            top: 0.5em;
        }

    /* OVERLAY */
    .nav-overlay.typical-layout .nav-drop-title,
    .nav-overlay.room-type .nav-drop-title,
    .nav-overlay.product-type .nav-drop-title {
        font-size: 1.2em;
    }

    .type-back-link {
        font-size: 0.75em;
        top: 3em;
    }

    .nav-overlay.typical-layout .nav-block-title,
    .nav-overlay.product-type .nav-block-title,
    .nav-overlay.room-type .nav-block-title {
        font-size: 0.75em;
    }

    #browseproductType .nav-block-item:nth-child(1) {
        flex: 0 1 100%;
        max-width: 31.4em;
        min-height: 10em;
        width: 100%;
    }

    .product-type .nav-block-item .product-layout-wrapper.inline-block-flex .product-layout-inner {
        height: calc(100% - 0.4em);
        width: 100%;
    }

    .cart-default-wrapper .page-header-wrap {
        display: block;
        padding: 1.85em 0 0.9em 0;
    }

    p.cart-grid-total.left-unit-price {
        padding-right: 1em;
    }
}

@media screen and (max-width: 1000px) {

    /* PRODUCT */
    .product-item-reaction.left-large {
        margin-left: -0.3em;
        padding: 0 0 0.7em;
    }

    .my-products--coll.cart-price {
        padding: 0.8em 0 0 0 !important;
    }

    .cart-grid-unit-buttons {
        padding-left: 0.6em;
    }

    .cart-grid-select-wrap {
        min-width: 4.7em;
    }

    .cart-total-wrap .cart-datawrap {
        text-align: end; /*padding: 0.1px 0 0 0;*/
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .product-item-image.product-item-recommend-inner .product-layout-wrapper {
        margin-left: 0;
        padding: 0 0 0.75em;
    }

    .product-item-recommend-inner {
        justify-content: flex-start;
        padding: 1em 0.8em;
    }

    .cart-default-center .default-center {
        padding: 0;
    }

    .my-products--coll:nth-child(2) {
        max-width: 14.4em;
        width: 100%;
    }

    .my-products--coll.cart-quantity {
        top: 5.96em;
    }

    .my-products--coll.cart-price {
        padding: 0.5em 0 0 0 !important;
    }

    .typical-prod-grid-col .prod-grid-action-wrap {
        padding: 0 0 0 0.75em;
        width: 2.1rem;
    }
}

@media screen and (max-width: 900px) {

    /* NAV */
    .nav-seat {
        height: 2.9em;
    }

    .mobile-nav-menu-trigger {
        display: block;
    }

    .nav-logo-img {
        max-width: 4em;
    }

    .nav-logo {
        background: #fff;
        margin: auto;
        max-width: 8.2em;
        padding: 0;
        width: 100%;
    }

    .logo-title {
        font-size: 0.6em;
        padding-left: 2.8em;
    }

        .logo-title::before,
        .logo-title:before {
            left: 1.45em;
        }

    .nav-item-wrap.nav-middle {
        display: none;
    }

    .nav-right .nav-link {
        display: none;
    }

        .nav-right .nav-link.cart {
            display: inline-block;
        }

    .nav-link.cart .icon-wrap.dark {
        background: transparent;
        border: none;
        color: #1c1b1f;
    }

        .nav-link.cart .icon-wrap.dark .material-symbols-rounded {
            color: #1c1b1f;
            font-size: 1.5em;
        }

    .cart-numbers,
    .favorite-numbers {
        background-color: #1c1b1f;
        border-radius: 100%;
        color: #fff;
        display: block;
        font-size: 0.5em;
        padding: 0.4em;
        position: absolute;
        right: 1.2em;
        top: 0.4em;
    }

    .nav-link:hover .icon-wrap.dark .cart-numbers,
    a.nav-link:hover .icon-wrap.dark .cart-numbers {
        background-color: #1c1b1f;
        color: #fff;
    }

    .nav-right {
        margin-right: 0;
        position: absolute;
        right: 0.3em;
    }

    .mobile-nav-open .nav-overlay-close {
        display: none;
        height: 1.3em;
        right: 2.25em;
        right: 0.45em;
        top: 0.9em;
        width: 1.3em;
        z-index: 501;
    }

    .mobile-nav-open .nav-overlay-close-icon {
        height: 1.3em;
        right: 0;
        top: 0;
        width: 1.3em;
    }

    .mobile-nav-open .nav-overlay-close .material-symbols-rounded {
        font-size: 1.3em;
        line-height: 0.8em;
    }

    .nav-overlay .nav-overlay-inner .nav-overlay-center {
        padding: 0 0.8em;
    }

    /* FOOTER */
    .footer-center {
        padding: 0 0.8em;
    }

    /* SCROLLBAR-OUTER */
    .mobile-nav-page-mask {
        display: block;
    }

    /* PRODUCT */
    .default-center {
        padding: 0 0.8em;
    }

    .product-breadcrumbs-wrap {
        display: none;
    }

    .cart-default-center .default-center {
        padding: 0;
    }

    .page-header-wrap {
        padding: 0.9em 0 0.6em 0;
    }

    .product-main-title,
    .page-main-title {
        font-size: 1.8em;
    }

    .product-mobile-results-wrap {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        margin-top: 0.7em;
        position: relative;
        width: 100%;
    }

    .product-controls-wrap {
        display: block;
        position: relative;
    }

    .mobile-product-filters,
    .mobile-product-sort-wrap {
        background: transparent;
        border: 1px solid #1f1f1f;
        border-radius: 1.5em;
        color: #1f1f1f;
        display: inline-block;
        font-size: 0.7em;
        font-weight: 700;
        line-height: 1.2em;
        padding: 0.5em 1.8em 0.5em 0.7em;
        position: relative;
        text-align: center;
        text-decoration: none;
        vertical-align: top;
    }

    .mobile-product-sort-wrap {
        border: 1px solid #e3e3e3;
    }

    .mobile-product-filters::after,
    .mobile-product-filters:after {
        color: #1f1f1f;
        content: "\e429";
        display: block;
        font-family: "Material Symbols Rounded";
        font-size: 1em;
        position: absolute;
        right: 0.7em;
        top: 0.5em;
    }

    .mobile-product-sort-wrap::after,
    .mobile-product-sort-wrap:after {
        color: #1f1f1f;
        content: "\e5cf";
        display: block;
        font-family: "Material Symbols Rounded";
        font-size: 1.4em;
        font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
        position: absolute;
        right: 0.2em;
        top: 0.35em;
    }

    .sort-by-wrap {
        display: none;
    }

    .mobile-product-results {
        display: inline-block;
        font-size: 0.7em;
        margin-top: 1.4em;
        position: relative;
        text-decoration: none;
        vertical-align: baseline;
    }

    .product-wrap {
        display: block;
    }

    .product-inner-item.product-nav {
        display: none;
    }

    .menu-open .product-overlay-wrap.product-inner-item.mobile-filter-nav .product-inner-item.product-nav {
        background: #fff;
        border-radius: 1em 1em 0 0;
        bottom: 0;
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        padding: 0;
        position: absolute;
        top: auto;
        vertical-align: middle;
        width: 100%;
    }

    .product-overlay-wrap.product-inner-item.mobile-filter-nav {
        display: none;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 900;
    }

    .product-overlay-wrap.product-inner-item.product-nav {
        background: #fff;
        border-radius: 1em 1em 0 0;
        bottom: 0;
        display: none;
        max-width: 100%;
        top: auto;
        width: 100%;
    }

    .product-overlay-wrap .scrollbar-outer > .scroll-content.scroll-scrolly_visible {
        left: unset;
        margin-left: unset;
    }

    .product-overlay-wrap .scrollbar-outer > .scroll-element.scroll-y {
        bottom: 1.9em;
        height: auto;
        overflow: auto;
        right: 0.1em;
        top: 4em;
        width: 12px;
    }

        .product-overlay-wrap .scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
            left: 2px;
            width: 3px;
        }

    .product-overlay-wrap .scrollbar-outer > .scroll-element {
        background-color: transparent;
    }

    .product-content-center {
        display: block;
        overflow: auto;
        padding: 3em 0 1em 0;
        position: relative;
    }

    .product-item.product-item-filter-wrap {
        height: 100%;
        max-height: calc(100vh - 4em);
        overflow: hidden;
        padding: 0;
    }

    .filter-active .product-overlay-wrap.product-inner-item.mobile-filter-nav {
        display: block;
    }

    .filter-active .mobile-product-overlay-dim,
    .sort-active .mobile-product-overlay-dim {
        display: block;
    }

    .product-filter-controls-wrap {
        display: none;
    }

    .product-item .filter-list,
    .product-filter-selected {
        display: none;
    }

    .product-inner-overlay-bar {
        background: #fff;
        display: block;
        left: 0;
        padding: 0.95em 0 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 502;
    }

    .product-item.filter-results-wrap {
        border-bottom: 1px solid #e3e3e3;
    }

    .mobile-product-nav-close {
        color: #757575;
        display: block;
        font-size: 1.3em;
        height: 1.4em;
        position: absolute;
        right: 0.3em;
        text-decoration: none;
        top: 0;
        width: 1.4em;
        z-index: 1;
    }

    .product-overlay-wrap.product-inner-item.mobile-filter-nav .mobile-product-nav-close {
        top: 0.8em;
    }

    .mobile-sort-nav .mobile-product-nav-close {
        top: 0.9em;
    }

    .product-item.filter-results-wrap,
    .product-filter-sort-wrap {
        padding: 0 0.85em 0.7em;
        pointer-events: none;
    }

    .product-title {
        padding: 0;
    }

    .product-item-filter.collapsed,
    .product-item-sort-wrapper {
        padding: 0 0.85em;
    }

    .product-item-filter-trigger {
        padding: 1.25em 3em 1.25em 0;
    }

    .expanded .product-item-filter-trigger {
        padding: 1.25em 3em 0.5em 0;
    }

    .product-item-filter-trigger::before,
    .product-item-filter-trigger:before {
        font-size: 1.4em;
        right: -0.25em;
        top: 57%;
    }

    .container-checkbox,
    .show-all-title,
    .container-radio {
        font-size: 0.8em;
        margin-bottom: 1em;
        padding-left: 2.1em;
    }

    .product-item-filter-collapse {
        max-height: 9.6em;
        padding: 0.4em 0;
    }

    .product-item + .product-item:last-of-type {
        border: none;
    }

    .product-item-filter.cta-wrapper {
        border-bottom: none;
        display: block;
        position: relative;
    }

    .default-cta,
    .default-cta.power {
        padding: 0.7em 2.5em 0.7em 2.5em;
    }

        .default-cta.power {
            padding: 0.7em 3.2em 0.7em 3.2em;
        }

    .product-item-filter-inner-wrap .default-cta {
        padding: 0.75em 3em 0.7em 3em;
    }

        .product-item-filter-inner-wrap .default-cta.power {
            padding: 0.75em 3.3em 0.7em 3.3em;
        }

    .product-item-filter-inner-wrap {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        padding: 0.8em 0.75em 0.5em;
        position: relative;
        width: 100%;
    }

    .product-item-filter-collapse-wrap {
        margin: 0;
    }

    .checkmark {
        top: -0.15em;
    }

    .cart-default-wrapper .product-breadcrumbs-wrap {
        display: block;
    }

    .cart-default-wrapper .page-header-wrap {
        display: block;
        max-width: 25em;
        margin: 0 auto;
        padding: 1.55em 0 0.9em 0;
    }

    .product-gallery-item {
        width: 48.3%;
    }

    .product-item-reaction {
        padding: 0.278em;
    }

    .product-item-icon .material-symbols-rounded {
        font-size: 1.2em;
    }

    .product-item-title {
        line-height: 1.1em;
        padding: 0.9em 0 0.6em 0;
    }

    .product-item-amount {
        font-size: 0.7em;
        padding: 1em 0;
    }

    .product-color-wrap {
        margin-top: -0.4em;
    }

    .product-gallery-item {
        margin: 0;
    }

        .product-gallery-item .product-layout-cta {
            position: relative;
        }

    .product-layout-cta {
        padding: 0.5em 2.5em 0.5em 1em;
        position: absolute;
        right: 1.2em;
    }

        .product-layout-cta::after,
        .product-layout-cta:after {
            font-size: 1.2em;
            right: 0.5em;
            top: 0.45em;
        }

    .product-layout-title {
        font-size: 1.3em;
    }

    .product-layout-desc {
        font-size: 0.7em;
        padding: 0 0 3.5em 0;
    }

    .product-layout-wrapper {
        padding: 0 1em 1.4em;
    }

    .product-layout-wrapper {
        align-items: flex-start;
        align-items: flex-start;
    }

    .product-item-reaction.left-large {
        padding: 0.2em 0.8em 0.7em;
    }

    .product-layout-wrapper {
        padding: 0 0.8em 1em;
    }

    .product-item-image.product-item-recommend-inner .product-layout-wrapper {
        padding: 47.2% 0.8em 1em;
        padding-top: calc(100% - 10.8em);
        margin: auto;
    }

    .product-recommended .product-item-image {
        align-items: flex-start;
        justify-content: space-between;
        padding: 0;
    }

    .product-item-reaction.left-large {
        margin-left: -0.1em;
        padding: 0.2em 0.6em 0.7em;
    }

    .sort-active .mobile-sort-nav {
        display: block;
    }

    .product-filter-sort-wrap {
        border-bottom: 1px solid #e3e3e3;
    }

    .default-full {
        width: 100%;
    }

    .product-item-sort {
        padding: 1.5em 0 0.6em;
    }

    .add::before,
    .add:before {
        font-size: 0.65em;
        left: 50%;
    }

    .product-title {
        font-size: 1.3em;
    }

    .show-all-wrap::before,
    .show-all-wrap:before {
        height: 3em;
    }

    .show-all-title {
        padding-left: 3.2em;
    }

    /* PRODUCT DETAIL */
    .product-header-wrap {
        padding: 1.5em 0 0 0;
    }

    .product-detail-right {
        max-width: 25em;
        margin: auto;
        width: 100%;
        padding: 0;
    }

    .bought-together-tile {
        width: 12.35rem;
    }

    .desktop-gallery {
        display: none;
    }

    .mobile-gallery {
        display: block;
    }

    .product-gallery-thumb-row {
        bottom: 0;
        flex-direction: row;
        height: auto;
        order: 2;
        top: auto;
        width: 100%;
    }

    .product-credit-desc {
        font-size: 0.55em;
        max-width: 100%;
        padding-bottom: 0.6em;
    }

    .product-detail-title {
        font-size: 1.6em;
        line-height: 0.9em;
    }

    .product-detail-price-amount {
        font-size: 0.9rem;
    }

    .product-gallery-slide::before,
    .product-gallery-slide:before {
        padding: 89% 0 0 0;
    }

    .product-gallery-container {
        padding-left: 0;
        width: 100%;
        margin: auto;
    }

    .product-gallery-slide img {
        object-fit: contain;
    }

    .product-detail-list li {
        max-width: 100%;
        padding: 0.47em 0 0.27em 2.1em;
    }

        .product-detail-list li::before,
        .product-detail-list li:before {
            left: -0.1em;
            top: 0.4em;
        }

    .product-dimension-title {
        padding-bottom: 0.6em;
    }

    .product-dimension-number-wrap {
        gap: 0.25em;
    }

    /* .product-dimension-color-wrap {
    gap: 0.4em;
    margin-top: 0;
    padding: 0.3em 0 0;
    } */

    .color-dots.large.color-select::before,
    .color-dots.large.color-select:before,
    .color-dots.large.color-select::after,
    .color-dots.large.color-select:after {
        height: 2.65rem;
        width: 2.65rem;
    }

    .product-add-on-method-inner {
        margin: 0 0.4em 0 0.5em;
    }

    .product-detail-add-on-wrap {
        margin-bottom: 0.3em;
    }

    .product-add-on-content-wrap {
        padding: 0.25em 0.4em;
    }

    .prod-detail-content-wrap {
        max-width: 100%;
        padding: 0 0 1.5em;
    }

    .prod-detail-content-trigger::before,
    .prod-detail-content-trigger:before {
        right: 0.4em;
        top: 60%;
    }

    .prod-shipping-wrap {
        padding-top: 1.4em;
    }

    .prod-shipping-select {
        background-position: right 10% center;
        padding: 0.733em 1.6em 0.733em 0.6em;
    }

    .bought-together-outer {
        padding: 3em 0 2.8em 0;
    }

    .bought-together-title {
        font-size: 0.9em;
    }

    .bought-together-tile-image-wrap {
        height: 11.15rem;
    }

    .bought-together-outer .product-item-image::before,
    .bought-together-outer .product-item-image:before {
        padding: 110% 0 0 0;
    }

    .product-detail-icon-wrap {
        height: 1.7rem;
        width: 1.7rem;
    }

    .product-detail-right .product-detail-icon-wrap::after,
    .product-detail-right .product-detail-icon-wrap:after {
        font-size: 1.7em;
        right: 0.35em;
        top: 0.9em;
    }

    .product-gallery-thumb-row {
        display: none;
    }

    .prod-dim-inner {
        width: 100%;
    }

    .product-dimension-item {
        flex: 0 1 48.7%;
    }


    /* TYPICAL */
    .typical-header-wrap {
        padding: 0.7em 0 0.4em 0;
    }

    .support-tip-icon .material-symbols-rounded,
    .support-tip-icon.battery-icon .material-symbols-rounded {
        font-size: 1.5em;
    }

    .support-tip-content {
        padding: 1.9em 0 0.6em;
    }

    .prod-grid-row {
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin: 0 auto 0.4em;
        max-width: 25em;
        padding: 0.6em 0.65em 0.95em;
        width: 100%;
    }

    .prod-grid-header,
    .prod-grid-col.prod-cta-wrap {
        display: none;
    }

    .prod-grid-col {
        flex: 0;
    }

    .prod-grid-image-wrap {
        border-radius: 4px;
    }

    .prod-image-area,
    .prod-grid-col.select-wrap {
        order: 1;
        width: 50%;
    }

    .prod-grid-col.select-wrap {
        left: 0.65em;
        position: absolute;
        top: 7.16em;
    }

    .prod-grid-select {
        background-position: right 4% center;
        font-size: 0.7rem;
        height: 1.8rem;
        min-width: 4.45em;
        padding: 0.55em 1.15em 0.55em 0.75em;
        width: 100%;
    }

    .prod-grid-col.prod-desc,
    .prod-grid-col.pricing-wrap {
        order: 2;
    }

    .prod-grid-col.prod-desc {
        flex: 6;
        margin-left: 6.7em;
        width: 100%;
    }

    .pricing-total-wrap,
    .prod-grid-header-wrap {
        justify-content: space-between;
        margin-left: 0;
    }

    .prod-grid-col.pricing-wrap {
        align-items: flex-start;
        display: flex;
        flex: 6;
        justify-content: flex-start;
        margin-left: 6.6em;
        position: relative;
        width: 16rem;
    }

    .prod-grid-price-wrap,
    .prod-grid-total-wrap {
        padding: 1.9em 0 0 0;
    }

    .prod-grid-total {
        font-size: 0.8rem;
        text-align: center;
        width: 4rem;
    }

    .mobile-price-label,
    .mobile-unit-label,
    .mobile-label {
        color: #757575;
        display: block;
        font-size: 0.7rem;
        line-height: 1em;
        padding: 0 0 0.4em;
    }

    .prod-grid-col.icon-wrap {
        order: 3;
        position: absolute;
        right: 0.25em;
        top: 0.75em;
    }

    .prod-grid-image-wrap,
    .prod-grid-select-wrap {
        display: block;
        left: 0.05em;
        min-width: unset;
        position: absolute;
        width: 6.05em;
    }

    .prod-grid-select-wrap {
        margin: 0;
    }

    .prod-grid-image-wrap::before,
    .prod-grid-image-wrap:before {
        padding: 99.15% 0 0 0;
    }

    .prod-grid-desc-wrap {
        width: 18rem;
    }

    .prod-grid-col {
        display: inline-block;
    }

        .prod-grid-col.prod-total-price,
        .prod-grid-col.prod-unit-price {
            display: inline-block;
            padding-left: 1.1em;
        }

    .prod-desc-title {
        font-size: 0.8rem;
        line-height: 1.05em;
        padding-bottom: 0.5em;
        padding-right: 2em;
    }

    .typical-prod-desc .prod-desc-title {
        padding-right: 0.8em;
    }

    .prod-desc-content {
        font-size: 0.7rem;
        line-height: 1.4em;
        padding-bottom: 0.4em;
    }

    .prod-desc-color span.prod-desc-color-label,
    .prod-desc-color-label {
        color: inherit;
        display: inline-block;
        font-size: inherit;
        font-weight: 400;
    }

    .support-tips-wrap {
        flex-wrap: wrap;
        gap: 0.8em;
    }

    .support-tip-desc {
        font-size: 0.7em;
        line-height: 1.3em;
    }

    .support-tip-title {
        display: unset;
        font-size: unset;
        line-height: inherit;
    }

    .support-tip-item {
        flex: 1 1 47%;
    }

    .room-tile {
        width: 100%;
    }

    .room-slider-wrap .slick-arrow,
    .bought-together-inner .slick-arrow {
        bottom: 0.2rem;
        height: 2.1rem;
        right: 0.85rem;
        width: 2.1rem;
    }

    .bought-together-inner .slick-arrow {
        bottom: 4.5rem;
    }

    .bought-together-inner .slick-prev {
        right: 3.35rem;
    }

    .room-slider-wrap .slick-prev {
        right: 3.3rem;
    }

    .room-slider-wrap .slick-arrow::after,
    .room-slider-wrap .slick-arrow:after {
        font-size: 0.9rem;
    }

    .table-layout-wrap {
        padding: 0.6em 0 0;
    }

    .subtotal-wrap {
        justify-content: center;
        padding: 0.6em 0 1em;
    }

    .subtotal-inner {
        margin: 0 auto;
        max-width: 25em;
    }

    .room-tile-image-wrap {
        height: 18.4rem;
    }

    .subtotal-cart-item {
        padding: 1.9em 0 0;
    }

        .subtotal-cart-item.subtotal-buttons-wrap {
            padding: 0.9em 0 0 0;
        }

    .add-cart-cta {
        padding: 1em 1em 0.9em 1em;
    }

    .wishlist-cta { /*padding: .45em 3em .7em 1em;*/
        padding: 0.945em 1em 0.9em 1em;
    }

    .add-cart-cta,
    .wishlist-cta {
        width: 100%;
    }

        .wishlist-cta span::after,
        .wishlist-cta span:after {
            font-size: 1.7em;
            font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;
            position: relative;
            top: 0.5rem;
        }

    .prod-grid-header-title,
    .prod-grid-header-wrap {
        display: none;
    }

    .prod-grid-action-wrap {
        width: 2.6rem;
    }

    .typical-products--coll .prod-grid-action-wrap {
        width: 2.6rem;
    }

    .typical-page .mobile-price-label {
        display: block;
    }

    .prod-grid-col.prod-image-area {
        position: absolute;
        top: 0.75em;
        left: 0.65em;
    }

    .subtotal-simple-link {
        margin-right: 0;
    }

    .typical-page .default-center {
        padding: 0;
    }

    .typical-page .page-header-wrap,
    .typical-page .page-section {
        padding: 0 0.8em;
    }

        .typical-page .page-section.slider-section {
            padding: 0;
        }

    /* LOGIN */
    .login-form-wrap {
        max-width: 25em;
        top: 46%;
    }

    .login-form-inner {
        padding: 1.2em 1.15em 2.5em;
    }

        .login-form-inner.bottom-wrap {
            margin-top: 0.7em;
            padding: 1.2em 1.15em 1.3em;
        }

    /* MYPROJECT */
    .cart-default-wrapper .project-wrap {
        flex-direction: column;
        gap: 0.9em;
    }

    .my-product-title-table {
        padding-top: 0.35em;
    }

    .project-item,
    .project-item.summary-wrap {
        display: block;
        max-width: 25em;
        margin: 0 auto;
        position: relative;
    }

        .project-item.summary-wrap {
            order: 1;
        }

    .project-item {
        order: 2;
    }

    .project .page-header-wrap {
        padding: 1.4em 0;
    }

    .project-item.summary-wrap .project-item-inner {
        margin-bottom: 0;
        padding: 1.25em 1.3em;
    }

    .project-item-inner {
        padding: 1.15em 1em;
    }

    .project-item-title {
        font-size: 1.1em;
    }

    .summary-est-wrap {
        padding: 0.8em 0 1.1em;
    }

    .summary-est-desc {
        padding: 0 0 0.8em 0;
    }

        .summary-est-desc + .summary-est-desc {
            padding: 0.6em 0 1em 0;
        }

    .summary-wrap .add-cart-cta {
        padding: 0.9em 1em 0.8em 1em;
    }

    .cart-layout-wrap {
        padding: 0.7em 0 0 0;
    }

    .cart-grid-row {
        padding: 0.9em 0 0.8em;
    }

    .icon-cta.delete-cell {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-left: 0;
        max-width: 1.9rem;
        padding: 0.384em 0.7em 0.384em;
        order: 1;
    }

    .icon-cta.cart-cell {
        font-size: 0.7rem;
        order: 2;
        margin-left: 0.2em;
        max-width: 6.9rem;
        min-width: 4.15rem; /*padding: .425em .5em .425em .6em;*/
        padding: 0.384em 0.5em 0.384em 0.6em;
    }

    .icon-cta.delete-cell,
    .icon-cta.wishlist-cell,
    .icon-cta.cart-cell {
        margin-top: 1em;
    }

    .icon-cta.delete-cell {
        margin-top: 1.3em;
    }

    .icon-cta.wishlist-cell {
        font-size: 0.7rem;
        order: 2;
        padding: 0.465em 2.7em 0.465em 1em;
    }

    .icon-cta.delete-cell span.wording {
        font-size: 0;
    }

    .icon-cta.delete-cell .mobile-icon {
        display: inline-block;
    }

    .delete-cell span::after,
    .delete-cell span:after {
        font-size: 1.4em;
        top: -0.5em;
        right: -0.5em;
    }

    .mobile-price-label,
    .mobile-unit-label {
        padding: 0 0 0.5em;
        text-align: left;
    }

    .mobile-unit-label {
        padding: 0 0 0.5em 0.4em;
    }

    .cart-total-wrap {
      /*  max-width: 20em;*/
        width: 100%; /* width: 100%; */
    }

    .cart-grid-col:nth-child(3) {
        top: 7.16em;
    }

    .wishlist-row .cart-grid-col:nth-child(3) {
        top: 7.76em;
    }

    .cart-estimate-content {
        font-size: 0.9em;
    }

    .project .page-header-wrap {
        margin: 0 auto;
        max-width: 25em;
        padding: 0.5em 0 0.8em;
    }

    .project .product-breadcrumbs-wrap {
        display: block;
    }

        .project .product-breadcrumbs-wrap .breadcrumb.shopping::before,
        .project .product-breadcrumbs-wrap .breadcrumb.shopping:before {
            left: -0.1em;
            margin-left: -0.5em;
        }

    .cart-grid-unit-buttons {
        justify-content: flex-start;
        padding-left: 1.1em;
        flex-wrap: wrap;
    }

    /* LANDING */
    .types-inner {
        height: 33.05em;
        min-height: 100%;
    }

    .types-list {
        align-items: flex-start;
        flex-wrap: wrap;
        height: calc(100vh - 11.75em);
        justify-content: flex-start;
    }

    .types .card {
        display: flex;
        height: 11.1rem;
        position: relative;
        width: 100%;
    }

    .types .card-content {
        display: block;
        margin: auto;
        max-width: 25em;
        min-height: 39px;
        padding: 0 1.4rem;
        width: 100%;
    }

    .types .types-card:nth-child(2) .card-content,
    .types .types-card:nth-child(3) .card-content {
        padding: 0 1.4rem;
    }

    .types .types-list .card {
        border-top: 1px solid #333;
    }

    .types-list:hover .types-card .card-content,
    .types-list:active .types-card .card-content {
        opacity: unset;
    }

    .types-list .types-card:hover .card-content,
    .types-list .types-card:active .card-content {
        opacity: unset;
    }

    .hero-subtitle.card-h2 {
        font-size: 2rem;
    }

    .hero-tile-desc {
        font-size: 0.7rem;
    }

    .hero-tile-link {
        font-size: 0.8rem;
        margin-top: 1.1em;
        padding: 0.5em 2.4em 0.6em 1.35em;
    }

        .hero-tile-link::before,
        .hero-tile-link:before {
            top: 0.4em;
        }

    .types-list::before,
    .types-list:before,
    .types-list::after,
    .types-list:after {
        height: 1px;
        left: 0;
        top: calc(100% / 3);
        width: 100%;
    }

    .types .types-list.active .card.active {
        align-items: flex-end;
        height: 69.4%;
        width: 100%;
    }

    .types .types-list.active .card {
        border-top: 1px solid #333;
        height: 15.3%;
        width: calc(100% / 1);
    }

    .types-card:hover .card-content-inner {
        display: none;
    }

    .types-list::before,
    .types-list:before,
    .types-list::after,
    .types-list:after {
        display: none;
    }

    .types-list.active .card.active .card-content-inner {
        display: block;
        padding-top: 0.2em;
        padding-bottom: 1.5em;
    }

    .types .card-opacity .card-content,
    .types-list:hover .types-card .card-content,
    .types-list:active .types-card .card-content {
        opacity: 1;
        padding: 0 1.4rem 0;
    }

    .types .card-opacity.active .card-content {
        padding: 0 1.4rem 1.6rem;
    }

    /* OVERLAY */
    .typical-layout .nav-drop-layout-wrap,
    .room-type .nav-drop-layout-wrap,
    .product-type .nav-drop-layout-wrap {
        flex-wrap: wrap;
    }

    .nav-overlay.typical-layout .nav-drop-title,
    .nav-overlay.room-type .nav-drop-title,
    .nav-overlay.product-type .nav-drop-title {
        font-size: 1.1em;
        width: 100%;
    }

    .type-back-link {
        font-size: 0.7em;
    }

    .nav-overlay.typical-layout .nav-block-title,
    .nav-overlay.product-type .nav-block-title,
    .nav-overlay.room-type .nav-block-title {
        font-size: 0.7em;
    }

    .nav-overlay.typical-layout .nav-block-wrap {
        width: 95%;
    }

    .product-type .nav-block-item {
        flex: 0 1 30.83%;
    }

    #browserproductType .nav-block-item:nth-child(1) {
        flex: 0 1 95.6%;
        max-width: 100%;
    }

    .types .card-mask {
        background: #1b1b1b;
        opacity: 0.65;
    }

    .types .card-bg {
        background-position: center !important;
    }

    /* OVERLAY */
    .nav-overlay .scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
        left: -0.9em;
    }

    .nav-overlay .nav-overlay-close {
        right: 0;
    }

    .cart-grid-row.estimate {
        padding: 1em 0 0;
    }

    .mobile-price-label,
    .mobile-unit-label,
    .mobile-label {
        color: #757575;
        display: block;
        font-size: 0.7rem;
        line-height: 1em;
        padding: 0 0 0;
    }

    .send-code, .icon-cta {
        padding: 0.7rem 2.6rem 0.7rem 0.9rem;
    }
    /* MY PROJECT*/
    .my-product-title-row,
    .my-product-title-coll.product-details-title {
        display: none;
    }

    .cart-grid-image-wrap span {
        height: 89px;
    }

    .my-products--coll.cart-price {
        display: block;
    }

    .pages-not-found-outer p {
        font-size: 7em;
    }

    .pages-not-found-title {
        font-size: 1.3rem;
        padding: 0.2em 0;
    }

    .pages-not-found-content {
        font-size: 0.8rem;
    }

    .myaccount-table-center .admin-table-wrap.admin-table-data {
        padding: 2em 0;
    }
}

@media screen and (max-width: 600px) {
    .cart-grid-unit-buttons {
        justify-content: space-around;
        padding-left: 0;
        flex-wrap: wrap;
    }

    .my-products--coll.cart-quantity {
        top: 5.86em;
    }

    .my-product-title-table {
        padding-top: 0.35em;
    }

    .cart-default-wrapper .project-wrap {
        gap: 0.9em;
    }

    .cart-default-wrapper .page-header-wrap {
        padding: 0.85em 0 0.85em 0;
    }

    .summary-est-desc {
        line-height: 1.3em;
    }

    .cart-default-wrapper .page-header-wrap {
        position: relative;
        left: -12px;
    }

    .summary-est-wrap {
        padding: 0.45em 0 1.2em;
    }

    .summary-est-desc {
        padding: 0 0 0.9em 0;
    }

        .summary-est-desc + .summary-est-desc {
            padding: 0.7em 0 1.5em 0;
        }

    /* FOOTER */
    .footer-inner {
        justify-content: center;
        padding: 0.1em 0 2.1em 0;
    }

    .footer-item {
        text-align: center;
        width: 100%;
    }

    .footer-contact-text {
        line-height: 0.9em;
    }

    .footer-logo-area-wrap {
        top: -0.3rem;
    }

    /* PRODUCT */
    .product-item-reaction.left-large .product-item-icon .material-symbols-rounded {
        font-size: 1.6em;
    }

    .product-layout-title {
        font-size: 0.95em;
        line-height: 1.1em;
        padding: 0 0 0.4em 0;
    }

    .product-layout-desc {
        font-size: 0.7em;
        line-height: 1.1em;
        padding: 0 0 0.9em 0;
    }

    .product-layout-wrapper {
        padding: 0 0.5em 0.5em;
    }

    .product-layout-cta {
        font-size: 0.7em;
        padding: 0.65em 1.5em 0.65em 0.6em;
        position: relative;
        right: 0;
        width: 100%;
    }

    .product-item-reaction.left-large {
        padding: 0.2em 0.4em 0.7em;
    }

    .product-layout-cta::after,
    .product-layout-cta:after {
        font-size: 1.1em;
        right: 0.7em;
        top: 0.65em;
    }

    .product-item-image.product-item-recommend-inner .product-layout-wrapper {
        padding-top: calc(100% - 9.4em);
    }

    /* PRODUCT DETAIL */
    .bought-together-tile {
        width: 9.75rem;
    }

    .bought-together-tile-title,
    .bought-together-amount {
        font-size: 0.7em;
    }

    .bought-together-tile-title {
        line-height: 1.1em;
    }

    .bought-together-content {
        padding-top: 0.2em;
    }

    .bought-together-color-wrap {
        gap: 0.2em;
        margin-top: -0.5em;
    }

        .bought-together-color-wrap .color-dots {
            height: 0.9em;
            width: 0.9em;
        }

    .bought-together-inner .slick-arrow {
        right: 0;
    }

    .bought-together-inner .slick-prev {
        right: 2.5rem;
    }

    /* TYPICAL */
    .prod-grid-desc-wrap {
        width: 100%;
    }

    .support-tips-wrap {
        gap: 0;
    }

    .support-tip-item {
        padding-right: 0.5em;
    }

    .support-tip-content {
        padding: 1.9em 0 1.2em;
    }

    .room-tile-image-wrap {
        height: 14.4rem;
    }

    /* LOGIN */
    .login-page-wrap {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .login-form-wrap {
        margin: 1em 1.2em;
        position: relative;
        transform: unset;
        top: 0;
    }

    .login-form-inner {
        border-radius: 0.6em;
        padding: 0.9em 0.9em 2.2em;
    }

        .login-form-inner.bottom-wrap {
            margin-top: 0.6em;
            padding: 0.9em 0.9em 1.3em;
        }

    .login-logo-cell {
        max-width: 11.35em;
        padding: 0.7em 0 0.9em;
    }

        .login-logo-cell .login-form-logo {
            max-width: 5.8em;
        }

        .login-logo-cell .logo-title::before,
        .login-logo-cell .logo-title:before {
            left: 1.45em;
        }

    .login-form-row {
        margin: 0.4em 0 0 0;
    }

    .login-input {
        margin-bottom: 0;
        padding: 1.2em 1em 0.5em 1em;
    }

    .login-form-row.forgot-cell {
        margin-top: 0.3em;
    }

    .login-form-row.separator-cell {
        margin-bottom: 0.5em;
    }

    .send-code,
    .outline-submit {
        padding: 1em 1em 0.7em 1em;
    }

    .login-form-disclaimer {
        padding: 0.9em 0 0;
    }

    .login-form-row .login-logo-cell .logo-title {
        font-size: 0.7em;
        padding-left: 2.6em;
    }

    /* TYPICAL */
    .project-item.summary-wrap .project-item-inner {
        margin-bottom: 0;
        padding: 0.9em 0.65em;
    }

    .project-item-inner {
        padding: 1.15em 0.7em;
    }

    /* LANDING */
    .icon-cta.wishlist-cell,
    .icon-cta.cart-cell {
        font-size: 0.65rem;
        padding: 0.56em 0.5em 0.56em 1em;
    }

    .icon-cta.delete-cell {
        margin-top: 1.2em;
    }

    /* OVERLAY */
    .type-back-link {
        font-size: 0.6em;
        top: 3.5em;
    }

    .typical-layout .nav-block-item,
    .room-type .nav-block-item {
        flex: 0 1 46%;
    }

    .nav-overlay.typical-layout .nav-block-title,
    .nav-overlay.product-type .nav-block-title,
    .nav-overlay.room-type .nav-block-title {
        font-size: 0.6em;
    }

    #browseproductType .nav-block-item:nth-child(1) {
        height: 100%;
        min-height: 11em;
    }

    .product-type .nav-block-item {
        flex: 0 1 47%;
    }

    .cart-grid-unit-price-wrap .mobile-price-label,
    .cart-grid-unit-price-wrap .cart-grid-total {
        text-align: left;
    }

    .icon-cta.cart-cell {
        font-size: 0.7rem;
        order: 2; /* margin-left: 0.2em; */
        max-width: 7.9rem;
    }

    .cart-grid-select-wrap {
        min-width: 4.7em;
    }

    .cart-default-wrapper .project-item-inner {
        padding: 1em 0.65em;
    }

    .cart-grid-inner p.cart-grid-total {
        padding: 0 1em;
    }

    .summary-est-desc {
        padding: 0 0 0.9em 0;
        line-height: 1.3em;
    }
}

@media screen and (max-width: 450px) {

    /*PRODUCT*/
    .cart-grid-select-wrap {
        min-width: 4.45em;
    }

    .product-item-image.product-item-recommend-inner .product-layout-wrapper {
        padding: 2em 0.45em 0.8em;
        padding-top: calc(100% - 9.4em);
    }

    /* OVERLAY */
    .product-type .nav-block-item {
        flex: 0 1 46.5%;
    }

    /* Cart */
    .cart-grid-row.estimate {
        padding: 1.1em 0 0;
    }

    .cart-grid-inner p.cart-grid-total {
        padding: 0 1em;
    }
}

@media screen and (max-width: 375px) {

    .icon-cta.wishlist-cell,
    .icon-cta.cart-cell {
        max-width: 9em;
        margin-left: 0;
        padding: 0.484em 0.7em 0.354em 0.7em;
    }

    .icon-cta.cart-cell {
        font-size: 0.7rem;
        order: 2; /* margin-left: 0.2em; */
        max-width: 6.9rem;
    }

    .my-products--coll.cart-price {
        padding: 0.75em 0 0 0 !important;
    }
}

@media screen and (max-width: 374px) {

    /* PRODUCT */
    .product-gallery-item {
        width: 100%;
    }
}
