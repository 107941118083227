/* FOOTER */
.footer-wrap {
    display: block;
    position: relative;
    width: 100%;
    z-index: 0;
}
.footer-center {
    display: block;
    margin: 0 auto;
    max-width: 80em;
    padding: 0 1.2em;
    position: relative;
    width: 100%;
}
.footer-inner {
    align-items: center;
    border-top: 1px solid #D9D9D9;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: .3em 0 2.1em 0;
    position: relative;
    width: 100%;
}
.footer-item {
    display: block;
    position: relative;
}
.footer-content-wrap {
    display: inline-block;
    position: relative;
    vertical-align: top;
}
.footer-logo-area-wrap {
    display: inline-block;
    position: relative;
    text-align: right;
    vertical-align: top;
}
.footer-logo-wrap {
	width:100%; 
    display: inline-block;
    position: relative;
    vertical-align: middle;
}
.footer-btn{
	float:right;
	margin: auto;
	max-width: 17.15rem;
}
.footer-contact-text {
    color: #2277A1;
    display: inline-block;
    font-size: .6em;
    line-height: 1.2em;
    position: relative;
    padding-right: 0.7em;
    vertical-align: inherit;
    margin-top:10px;
}
.footer-contact-text a.footer-link {
    color: inherit;
    text-decoration: none;
}
a.footer-link:hover,
a.footer-link:focus-visible {
    text-decoration: underline;
}
.footer-logo {
    display: block;
    max-width: 8.5em;
    position: relative;
    width: 100%;
}
@media screen and (max-width: 900px) {
	.footer-center {
		padding: 0 .8em;
	}
}
@media screen and (max-width: 600px) {
	.footer-inner {
		justify-content: center;
		padding: 0.1em 0;
	}
	.footer-item {
		text-align: center;
		width: 100%;
	}
	.footer-contact-text {
		line-height: .9em;
	}
	.footer-logo-area-wrap {
		top: -.3rem;
	}
}
