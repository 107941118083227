/* RESET */
body {
    min-height: 100%;
}

html,
body {
    font-family: "Roboto", "GreycliffCF", "Greycliff CF", Arial, sans-serif;
    height: 100%;

}

#root {
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
}

.full-wrap {
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
}

.nowrap {
    white-space: nowrap;
}
/* ADMIN PAGES */
.admin-full-wrap {
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
}

.admin-page-wrap {
    display: block;
    height: 100%;
    overflow: auto;
    padding: 0 0 0 11.6em;
    position: relative;
    width: 100%;
}
/* DRAWER */
.admin-drawer-wrap {
    background: #2B2B2B;
    color: #FFF;
    display: block;
    height: 100%;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 11.6em;
    z-index: 500;
}

.admin-drawer-view-page {
    background: #00AC30;
    color: #FFF;
    display: block;
    font-size: .7em;
    font-weight: 500;
    line-height: 1.2em;
    padding: .829em 3.143em .829em 1.928em;
    position: relative;
    text-decoration: none;
    transition: .25s;
}

    .admin-drawer-view-page::after,
    .admin-drawer-view-page:after {
        align-items: center;
        background: rgba(0,0,0,.25);
        border-radius: 50%;
        color: #FFF;
        content: '\e5c8';
        display: flex;
        font-family: "Material Symbols Rounded";
        font-size: .964286em;
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
        height: 1.63em;
        justify-content: center;
        line-height: 1em;
        margin: -.815em 0 0 0;
        position: absolute;
        right: .815em;
        text-align: center;
        top: 50%;
        width: 1.63em;
    }

    .admin-drawer-view-page:hover,
    .admin-drawer-view-page:focus-visible {
        background: #008A27;
    }


.admin-drawer-info-wrap {
    display: block;
    padding: 1.2em 1.35em;
    position: relative;
}
.project-status-summary{
    position:relative;
    padding-right:1.6rem;
}
.project-status-arrow{
    margin: -0.5em 0 0 0;
    position: absolute;
    right: 0.15rem;
    top:0.75rem;
    height:0.55rem;
}
.admin-drawer-info-title {
    color: #FFF;
    display: block;
    font-size: 1em;
    font-weight: 700;
    line-height: 1em;
    margin: 0 0 .45em 0;
    position: relative;
}
    .admin-drawer-info-title.decoration {
        text-decoration: none;
    }
.admin-drawer-info-user {
    color: #AFAFAF;
    display: block;
    font-size: .7em;
    font-weight: 500;
    line-height: 1.286em;
    padding: 0 0 0 2em;
    position: relative;
}

    .admin-drawer-info-user::before,
    .admin-drawer-info-user:before {
        content: '\e853';
        display: block;
        font-family: 'Material Symbols Rounded';
        font-size: 1.286em;
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
        height: 1em;
        left: 0;
        line-height: 1em;
        position: absolute;
        top: 0;
        width: 1em;
    }

.admin-drawer-link-wrap {
    display: block;
    padding: 2.5em 0;
    position: relative;
    width: 100%;
}

.admin-drawer-link {
    color: #AFAFAF;
    display: block;
    font-size: .7em;
    font-weight: 500;
    line-height: 1.4286em;
    padding: .5em 2em .5em 3.9286em;
    position: relative;
    text-decoration: none;
    transition: .25s;
}

a.admin-drawer-link:link,
a.admin-drawer-link:visited{
color: #AFAFAF;
}

    .admin-drawer-link:hover,
    .admin-drawer-link:focus-visible {
        color: #FFF;
    }

.admin-drawer-link-icon {
    display: block;
    font-size: 1.286em;
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
    height: 1em;
    left: 1.5em;
    line-height: 1em;
    position: absolute;
    top: .4em;
    width: 1em;
}

/*edit role modal*/
.admin-default-label.edit-user-role {
    color: #000000;
    font-weight: 500;
    font-size:0.75rem;
    margin-bottom: 0.75rem;
    display:none;
}
.admin-default-label.edit-role-info .role-value {
    display: block;
    color: #2b2b2b;
    font-size: 0.9rem;
    font-weight:500;
    padding-top: 0.25rem;
    margin-bottom: 1rem
}
.admin-default-label.edit-role-info {
    margin-bottom: 0.5rem;
}
.edit-role-info .role-lable {
}
/* TOP BAR */
.admin-top-bar-wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    line-height: 0;
    min-height: 1em;
    padding: 0 0 0 1.6em;
    position: relative;
    width: 100%;
}

.admin-top-bar-left,
.admin-top-bar-right {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.admin-top-bar-back {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: none;
    border-radius: 0;
    color: #2B2B2B;
    cursor: pointer;
    display: inline-block;
    font-size: .8em;
    font-weight: 500;
    line-height: 1.25em;
    padding: 1.1875em 1.125em;
    position: relative;
    text-decoration: none;
    vertical-align: middle;
}

    .admin-top-bar-back::before,
    .admin-top-bar-back:before {
        content: '\e2ea';
        display: inline-block;
        font-family: "Material Symbols Rounded";
        font-size: .8em;
        font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
        height: 1em;
        line-height: 1em;
        margin: 0 .5em 0 0;
        position: relative;
        vertical-align: middle;
        width: 1em;
    }

    
.topBarLink {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #212121;
  text-decoration: none;
  border: none;
  cursor: pointer;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
}
.topBarLink::after, 
.topBarLink:after {
  background: url(../../assets/expand_more.svg) no-repeat center;
  background-size: contain;
  content: '';
  display: inline-block;
  height: 1.2em;
  position: relative;
  right: 0;
  top: .3em;
  width: 1.2em;
}
.topBarLink .fa {
  margin: 0 8px 0 0;
  font-size: 12px;
}
/*.topBarLink:hover, .rollbackWrap:hover .topBarLink {
  color: #fff;
  background: #3c3c3c;
}*/
.topBarSave:hover {
  background: #23a700;
}
.topBarCancel:hover {
  background: #cc0000;
}
.rollbackWrap {
  position: relative;
  display: inline-block;
  vertical-align: top;
  z-index:999;
}
.rollbackDropdown {
  background: #fff;
  border: 1px solid #b6b6b6;
  border-radius: .6em;
  display: none;
  max-height: 13.85em;
  min-width: 268px;
  overflow: auto;
  position: absolute;
  right: .6em;
  top: 100%;
  /*right: -1px;*/
  /*background: #3c3c3c;*/
  /*border-top: none;*/
}
.rollbackWrap:hover .rollbackDropdown {
  display: block;
}
.rollbackDropdown a {
  background: transparent;
  border-top: 1px solid #d9d9d9;
  color: #2b2b2b;
  cursor:pointer;
  display: block;
  font-size: 16px;
  line-height: 16px;
  margin-right: 1.4em;
  padding: 9px 15px;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  /*color: #fff;*/
  /*background: #3c3c3c;*/
}
.rollbackDropdown a:first-child {
  border: none;
}

/* MANUFACTURERS & PRODUCTS */
.admin-page-content {
    display: block;
    padding: 1.5em 2.5em 5.1em 2.5em;
    position: relative;
    width: 100%;
}

.admin-page-title-wrap {
    position: relative;
}

.admin-page-heading {
    color: #2B2B2B;
    display: block;
    font-size: 2em;
    font-weight: 700;
    line-height: 1.2em;
    margin: 0 0 1em 0;
    position: relative;
}

.admin-page-link {
    color: #2B2B2B;
    display: inline-block;
    font-size: 1em;
    line-height: 1.2em;
    position: relative;
    text-decoration: underline;
    vertical-align: top;
}

.admin-section {
    display: inline-block;
    max-width: 100%;
    min-width: 35.5%;
    position: relative;
    width: auto;
}

.admin-new-section {
    margin: 2.5em 0 0 0;
}

.admin-section-title-bar {
    align-items: center;
    display: flex;
    gap: 1em;
    justify-content: space-between;
    max-width: 100%;
    position: relative;
    width: auto;
}

.admin-section-headline {
    color: #2B2B2B;
    display: block;
    font-size: 1.4em;
    font-weight: 700;
    line-height: 1.2em;
    position: relative;
}

.admin-section-title-action-wrap {
    align-items: center;
    display: inline-flex;
    justify-items: flex-end;
    gap: 1em;
    position: relative;
}

.admin-section-title-action {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #FFF;
    border: 1px solid #D9D9D9;
    border-radius: 3em;
    color: #2B2B2B;
    cursor: pointer;
    display: inline-block;
    font-size: .9em;
    font-weight: 700;
    line-height: 1.2em;
    padding: .622em 1.278em;
    position: relative;
    text-align: center;
    transition: .25s;
    vertical-align: middle;
    white-space: nowrap;
}

    .admin-section-title-action:hover,
    .admin-section-title-action:focus-visible {
        background: #2B2B2B;
        border-color: #2B2B2B;
        color: #FFF;
    }

    .admin-section-title-action .material-symbols-rounded {
        position: relative;
        display: inline-block;
        vertical-align: top;
    }

.admin-tall-list-wrap {
    display: inline-block;
    margin: .2em 0 0 0;
    position: relative;
    vertical-align: top;
    width: 100%;
}

.admin-tall-list-item {
    align-items: center;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    gap: 2em;
    justify-content: space-between;
    padding: .45em 0;
    position: relative;
    width: 100%;
}

.admin-tall-list-name {
    color: #2B2B2B;
    display: block;
    font-size: .9em;
    font-weight: 700;
    line-height: 1.2em;
    padding: .566em 0;
    position: relative;
}

.admin-tall-list-delete {
    cursor: pointer;
}

.admin-tall-list-edit {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: none;
    border-radius: 0;
    color: #757575;
    cursor: pointer;
    display: inline-block;
    padding: .55em;
    text-decoration: none;
    transition: .25s;
    vertical-align: middle;
}

    .admin-tall-list-edit:hover,
    .admin-tall-list-edit:focus-visible {
        color: #2B2B2B;
    }

    .admin-tall-list-edit .material-symbols-rounded {
        position: relative;
        display: block;
        font-size: 1em;
        font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
        line-height: 1em;
        height: 1em;
        width: 1em;
    }

.admin-tab-wrap {
    align-items: flex-end;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    justify-content: flex-start;
    margin: -.6em 0 0 0;
    position: relative;
    width: 100%;
}

.admin-tab {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: none;
    border-radius: 0;
    color: #757575;
    cursor: pointer;
    display: block;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.2em;
    padding: .6em 1.4em;
    position: relative;
}

    .admin-tab:first-child {
        padding-left: 0;
    }

    .admin-tab.active {
        color: #2B2B2B;
    }

        .admin-tab.active::before,
        .admin-tab.active:before {
            background: #2B2B2B;
            bottom: 0;
            content: '';
            display: block;
            height: .2em;
            left: 1.4em;
            right: 1.4em;
            position: absolute;
        }

        .admin-tab.active:first-child::before,
        .admin-tab.active:first-child:before {
            left: 0;
        }

.product-tab {
    display: block;
    position: relative;
    width: 100%;
}

.admin-tab-content {
    display: block;
    padding: 2.2em 0;
    position: relative;
    width: 100%;
}

.admin-button-wrap {
    align-items: center;
    display: flex;
    gap: .35em;
    justify-content: flex-start;
    margin: 0 0 1em 0;
    position: relative;
    width: 100%;
}

.admin-bottom-bar {
    align-items: flex-end;
    display: flex;
    gap: 1em;
    justify-content: space-between;
    margin: 0 0 1em 0;
    position: relative;
    width: 100%;
}

.admin-bottom-bar-left {
    display: inline-flex;
    gap: .35em;
    justify-content: flex-start;
    position: relative;
    vertical-align: top;
}

.admin-bottom-bar-right {
    display: inline-flex;
    gap: .35em;
    justify-content: flex-end;
    position: relative;
    vertical-align: top;
}

.admin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    align-items: center;
    background: #FFF;
    border: 1px solid #D9D9D9;
    border-radius: 3em;
    color: #2B2B2B;
    cursor: pointer;
    display: inline-flex;
    font-size: .9em;
    font-weight: 700;
    gap: .5em;
    line-height: 1.2em;
    padding: .622em 1.22em;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: .25s;
}

    .admin-button:hover,
    .admin-button:focus-visible {
        background: #2B2B2B;
        border-color: #2B2B2B;
        color: #FFF;
    }

    .admin-button.add {
        min-width: 12.25em;
        padding-right: 2.5em;
    }

        .admin-button.add::before,
        .admin-button.add:before {
            content: '';
            display: none;
        }

        .admin-button.add::after,
        .admin-button.add:after {
            bottom: 0;
            content: '\e145';
            display: block;
            font-family: 'Material Symbols Rounded';
            font-size: 1.1em;
            font-variation-settings: 'FILL' 0, 'wght' 900, 'GRAD' 0, 'opsz' 48;
            height: 1em;
            line-height: 1em;
            margin: auto;
            position: absolute;
            right: .9em;
            top: 0;
            width: 1em;
        }

.admin-button-text {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.admin-button .material-symbols-rounded {
    display: inline-block;
    font-variation-settings: 'FILL' 0, 'wght' 900, 'GRAD' 0, 'opsz' 48;
    font-size: 1.1em;
    height: 1em;
    line-height: 1em;
    position: relative;
    vertical-align: middle;
    width: 1em;
}

.admin-button.power {
    background: #2B2B2B;
    border-color: #2B2B2B;
    color: #FFF;
}

    .admin-button.power:hover {
        background: #FFF;
        border-color: #2B2B2B;
        color: #2B2B2B;
    }

.admin-page-title-wrap .admin-button {
    position: absolute;
    top: 0;
    right: 0;
}

.admin-table-wrap {
    display: block;
    max-width: 100%;
    overflow: auto;
    position: relative;
}

.admin-section-title-bar + .admin-table-wrap {
    margin-top: 1em;
}

.admin-section .admin-table-wrap {
    width: 100%;
}

.admin-table {
    border-spacing: 0;
    display: inline-table;
    min-width: 53.842%;
    position: relative;
    vertical-align: top;
    width: auto;
}

.admin-table-wrap.admin-table-data {
    max-width: 80em;
    padding: 2em 0em;
    margin: auto;
}

    .admin-table-wrap.admin-table-data .admin-table {
        white-space: nowrap;
    }

.admin-section .admin-table {
    width: 100%;
}

.admin-table th,
.admin-table thead td {
    border-bottom: 1px solid #1F1F1F;
    color: #757575;
    display: table-cell;
    font-size: .7em;
    line-height: 1.2em;
    padding: .1em 1.14286em;
    position: relative;
    text-align: left;
    vertical-align: bottom;
}

.admin-table td {
    border-bottom: 1px solid #D9D9D9;
    color: #2B2B2B;
    display: table-cell;
    font-size: .8em;
    font-weight: 700;
    line-height: 1.2em;
    padding: .59em 1em;
    position: relative;
    text-align: left;
    vertical-align: middle;
}

    .admin-table td.nowrap {
        text-align: right;
    }

    .admin-table th.no-border,
    .admin-table td.no-border {
        border-bottom: none;
    }

    .admin-table th:first-child,
    .admin-table td:first-child {
        padding-left: 0;
    }

.admin-table.product-table th:first-child,
.admin-table.product-table td:first-child {
    width: 33%;
}

.admin-table th.admin-table-action-cell,
.admin-table td.admin-table-action-cell {
    padding: .4em 0;
    text-align: center;
    width: 2.85em;
}

.admin-table-input-text {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid #D9D9D9;
    border-radius: .333em;
    color: #2B2B2B;
    display: inline-block;
    font-size: 1.125em;
    font-weight: 700;
    line-height: 1.2em;
    padding: .2333em .56em;
    position: relative;
    vertical-align: middle;
}

    .admin-table-input-text::-webkit-input-placeholder {
        color: #757575;
    }

    .admin-table-input-text::-moz-placeholder {
        color: #757575;
    }

    .admin-table-input-text:-ms-input-placeholder {
        color: #757575;
    }

    .admin-table-input-text:-moz-placeholder {
        color: #757575;
    }

.admin-table-text {
    display: block;
    font-size: 1em;
    line-height: 1.2em;
    padding: .4em 0;
    position: relative;
}

.admin-table-action {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #757575;
    cursor: pointer;
    display: inline-block;
    font-size: 1.25em;
    line-height: 1em;
    padding: .25em;
}

    .admin-table-action .material-symbols-rounded {
        display: inline-block;
        font-size: 1em;
        font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
        height: 1em;
        line-height: 1em;
        position: relative;
        vertical-align: top;
        width: 1em;
    }


.admin-table th.sortable {
    cursor: pointer;
}

    .admin-table th.sortable::after,
    .admin-table th.sortable:after {
        content: '\e5d7';
        display: inline-block;
        font-family: "Material Symbols Rounded";
        font-size: 1em;
        font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
        line-height: 1em;
        height: 1em;
        vertical-align: top;
        width: 1em;
    }

.admin-table th.sorted-dn::after,
.admin-table th.sorted-dn:after {
    content: '\e5cf';
    display: inline-block;
    font-family: "Material Symbols Rounded";
    font-size: 1em;
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
    line-height: 1em;
    height: 1em;
    vertical-align: top;
    width: 1em;
}

.admin-table th.sorted-up::after,
.admin-table th.sorted-up:after {
    content: '\e5ce';
    display: inline-block;
    font-family: "Material Symbols Rounded";
    font-size: 1em;
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
    line-height: 1em;
    height: 1em;
    vertical-align: top;
    width: 1em;
}

.admin-table-check {
    display: inline-block;
    font-size: 1.25em;
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
    height: 1em;
    line-height: 1em;
    vertical-align: baseline;
    width: 1em;
}

.admin-draggable-editable-option .mediaAdd-button {
    margin: 0 .4em;
}

/* ADMIN FLOATING BUTTONS */
.admin-floating-button-wrap {
    align-items: center;
    bottom: 0;
    display: flex;
    gap: .65em;
    justify-content: space-between;
    padding: 2.5em 0;
    position: fixed;
    right: 2.5em;
    z-index: 100;
}

    .admin-floating-button-wrap::before,
    .admin-floating-button-wrap:before {
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 40%,rgba(255,255,255,1) 100%);
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 40%,rgba(255,255,255,1) 100%);
        content: '';
        display: block;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

.admin-floating-button {
    background: #FFF;
    border: 1px solid #2B2B2B;
    border-radius: 3em;
    cursor: pointer;
    display: inline-block;
    font-size: .9em;
    font-weight: 700;
    line-height: 1.2em;
    padding: .622em 1.333em;
    position: relative;
    transition: .25s;
    vertical-align: middle;
}

    .admin-floating-button:hover,
    .admin-floating-button:focus-visible {
        background: #2B2B2B;
        color: #FFF;
    }

    .admin-floating-button.power {
        background: #2B2B2B;
        color: #FFF;
    }

        .admin-floating-button.power:hover,
        .admin-floating-button.power:focus-visible {
            background: #FFF;
            color: #2B2B2B;
        }
/* ADMIN SWATCHES */
.admin-tab-top-action-wrap {
    display: block;
    padding: 2.2em 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.admin-tab-top-button {
    align-items: center;
    background: #fff;
    border: 1px solid #D9D9D9;
    border-radius: 3em;
    color: #2B2B2B;
    cursor: pointer;
    display: inline-flex;
    font-size: .9em;
    font-weight: 700;
    gap: .6em;
    justify-content: space-between;
    line-height: 1.11em;
    padding: .678em 1.2em;
    position: relative;
    transition: .25s;
}

    .admin-tab-top-button:hover,
    .admin-tab-top-button:focus-visible {
        background: #2B2B2B;
        border-color: #2B2B2B;
        color: #fff;
    }

    .admin-tab-top-button.power {
        background: #2B2B2B;
        border-color: #2B2B2B;
        color: #FFF;
    }

        .admin-tab-top-button.power:hover,
        .admin-tab-top-button.power:focus-visible {
            background: #fff;
            color: #2B2B2B;
        }

.admin-tab-top-button-text {
    display: inline-block;
    font-size: 1em;
    line-height: 1.2em;
    position: relative;
    vertical-align: middle;
}

.admin-tab-top-button .material-symbols-rounded {
    display: inline-block;
    font-size: 1em;
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
    height: 1em;
    line-height: 1em;
    position: relative;
    width: 1em;
}

.admin-round-swatch-section {
    display: block;
    margin: 0 0 2em 0;
    max-width: 50em;
    position: relative;
    width: 100%;
}

.admin-round-swatch-title {
    align-items: center;
    color: #2B2B2B;
    display: flex;
    font-size: 1em;
    font-weight: 700;
    gap: .2em;
    justify-content: flex-start;
    line-height: 1em;
    margin: 0 0 .8em 0;
    position: relative;
}

.admin-round-swatch-title-text {
    color: #2B2B2B;
    display: block;
    font-size: 1.4em;
    font-weight: 700;
    line-height: 1em;
    padding: .1428em .5em .1428em 0;
    position: relative;
}

.admin-round-swatch-title-action-wrap {
    align-items: center;
    display: inline-flex;
    gap: .2em;
    padding: 0;
    position: relative;
    vertical-align: middle;
}

.admin-round-swatch-title-action {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid #D9D9D9;
    border-radius: .375em;
    color: #757575;
    cursor: pointer;
    display: inline-block;
    font-size: .8em;
    height: 2.25em;
    line-height: 1em;
    position: relative;
    transition: .25s;
    vertical-align: middle;
    width: 2.25em;
}

    .admin-round-swatch-title-action:hover,
    .admin-round-swatch-title-action:focus-visible {
        background: #2B2B2B;
        border-color: #2B2B2B;
        color: #FFF;
    }

    .admin-round-swatch-title-action .material-symbols-rounded {
        display: inline-block;
        font-size: 1em;
        font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
        height: 1em;
        line-height: 1em;
        position: relative;
        vertical-align: middle;
        width: 1em;
    }

.admin-round-swatch-wrap {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 1.2em 0;
    justify-content: flex-start;
    position: relative;
}

.admin-round-swatch {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 5.5em;
    flex-wrap: wrap;
    gap: .3em;
    justify-content: center;
    position: relative;
    vertical-align: top;
    width: 5.5em;
}

.admin-round-swatch-preview {
    border-radius: 50%;
    background: #D9D9D9;
    display: block;
    height: 4em;
    margin: 0;
    overflow: hidden;
    position: relative;
    width: 4em;
}

.admin-round-swatch-preview-background {
    display: block;
    height: 100%;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
}
    .admin-round-swatch-preview-background img {
        display: block;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
    }

.admin-round-swatch-edit {
    align-items: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    color: #FFF;
    cursor: pointer;
    display: flex;
    font-size: 1em;
    height: 100%;
    justify-content: center;
    left: 0;
    line-height: 1em;
    outline: none;
    opacity: 0;
    position: absolute;
    text-shadow: 0 0 .75em #000;
    top: 0;
    transition: .25s;
    width: 100%;
}

    .admin-round-swatch:hover .admin-round-swatch-edit,
    .admin-round-swatch-edit:hover,
    .admin-round-swatch-edit:focus-visible {
        opacity: 1;
    }

    .admin-round-swatch-edit .material-symbols-rounded {
        background: #2B2B2B;
        border-radius: 50%;
        display: inline-block;
        font-size: 1em;
        font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
        height: 2em;
        line-height: 1em;
        padding: .5em;
        position: relative;
        width: 2em;
    }

.admin-round-swatch-name {
    color: #2B2B2B;
    display: block;
    font-size: .65em;
    font-weight: 500;
    line-height: 1.23em;
    padding: 0 .5em;
    position: relative;
    text-align: center;
}

.admin-round-swatch-add {
    align-items: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid #757575;
    border-radius: 50%;
    color: #757575;
    cursor: pointer;
    display: flex;
    height: 4em;
    justify-content: center;
    position: relative;
    transition: .25s;
    width: 4em;
}

    .admin-round-swatch-add:hover,
    .admin-round-swatch-add:focus-visible {
        background: #2B2B2B;
        border-color: #2B2B2B;
        color: #FFF;
    }

    .admin-round-swatch-add .material-symbols-rounded {
        display: inline-block;
        font-size: 1.1em;
        font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
        height: 1em;
        line-height: 1em;
        position: relative;
        vertical-align: middle;
        width: 1em;
    }

.admin-form-cell {
    display: block;
    margin: 0 0 1em 0;
    position: relative;
}

.admin-form-label {
    color: #757575;
    display: block;
    font-size: .65em;
    line-height: 1.2em;
    margin: 0 0 .4em 0;
    position: relative;
}

.admin-form-textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #FFF;
    border: 1px solid #D9D9D9;
    border-radius: .333em;
    color: #2B2B2B;
    display: block;
    font-size: .9em;
    font-weight: 700;
    line-height: 1.2em;
    max-width: 100%;
    padding: .7222em 1em;
    position: relative;
    width: 30em;
}

.admin-form-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #FFF;
    border: 1px solid #D9D9D9;
    border-radius: .333em;
    color: #2B2B2B;
    display: block;
    font-size: .9em;
    font-weight: 700;
    line-height: 1.2em;
    max-width: 100%;
    padding: .7222em 1em;
    position: relative;
    width: 30em;
}

.admin-form-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid #D9D9D9;
    border-radius: 3em;
    color: #2B2B2B;
    cursor: pointer;
    display: inline-block;
    font-size: .9em;
    font-weight: 700;
    line-height: 1.2em;
    padding: .6222em 1.5em;
    position: relative;
    text-align: center;
    transition: .25s;
}

    .admin-form-button:hover,
    .admin-form-button:focus-visible {
        background: #2B2B2B;
        border-color: #2B2B2B;
        color: #FFF;
    }

    .admin-form-button.power {
        background: #2B2B2B;
        border-color: #2B2B2B;
        color: #FFF;
    }

        .admin-form-button.power:hover,
        .admin-form-button.power:focus-visible {
            background: #FFF;
            color: #2B2B2B;
        }

        .admin-form-button.power.red {
            background: #CC0000;
            border-color: #CC0000;
            color: #FFF;
        }

            .admin-form-button.power.red:hover,
            .admin-form-button.power.red:focus-visible {
                background: #FFF;
                color: #CC0000;
            }

.admin-round-swatch-sort-arrow-wrap {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: .85em;
}

.admin-round-swatch-sort-arrow {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 1px solid #D9D9D9;
    color: #757575;
    cursor: pointer;
    display: block;
    font-size: 1em;
    line-height: 1em;
    position: relative;
    transition: .25s;
}

    .admin-round-swatch-sort-arrow:hover,
    .admin-round-swatch-sort-arrow:focus-visible {
        background: #2B2B2B;
        border-color: #2B2B2B;
        color: #FFF;
    }

    .admin-round-swatch-sort-arrow:first-child {
        border-top-left-radius: .2em;
        border-top-right-radius: .2em;
    }

    .admin-round-swatch-sort-arrow:last-child {
        border-bottom-left-radius: .2em;
        border-bottom-right-radius: .2em;
    }

    .admin-round-swatch-sort-arrow + .admin-round-swatch-sort-arrow {
        border-top: none;
    }

    .admin-round-swatch-sort-arrow .material-symbols-rounded {
        display: block;
        font-size: .75em;
        font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
        height: 1em;
        line-height: 1em;
        position: relative;
        width: 1em;
    }
/* ADMIN MODAL */
.admin-modal-overlay {
    align-items: center;
    background: rgba(0,0,0,.9);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 500;
}

.admin-modal {
    background: #fff;
    border-radius: 1.2em;
    display: block;
    max-height: 100%;
    max-width: 28.25em;
    overflow: auto;
    padding: 1.5em 2em;
    position: relative;
    width: 100%;
}

    .admin-modal.wide {
        max-width: 40em;
    }

    .admin-modal.large {
        max-width: 70.5em;
    }

    .admin-modal.grow {
        display: inline-block;
        max-width: calc(100% - 25.5em);
        width: unset;
        /*max-width: unset;*/
        /*width: calc(100% - 28.25em);*/
    }

.admin-modal-close {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    align-items: center;
    background: #F2F2F2;
    border: 2px solid #F2F2F2;
    border-radius: 50%;
    color: #2B2B2B;
    cursor: pointer;
    display: flex;
    height: 2.1em;
    justify-content: center;
    position: absolute;
    right: .7em;
    top: 0.2em;
    transition: .25s;
    width: 2.1em;
    z-index: 1;
}

    .admin-modal-close:hover,
    .admin-modal-close:focus-visible {
        background: #2B2B2B;
        border-color: #2B2B2B;
        color: #FFF;
    }

    .admin-modal-close .material-symbols-rounded {
        display: inline-block;
        font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
        font-size: 1.2em;
        height: 1em;
        line-height: 1em;
        position: relative;
        vertical-align: middle;
        width: 1em;
    }

.admin-modal-heading {
    color: #2B2B2B;
    display: block;
    font-size: 1.4em;
    font-weight: 700;
    line-height: 1.2em;
    margin: 0 1em 1.2em 0;
    position: relative;
}

.admin-modal-row {
    align-items: flex-start;
    display: inline-flex;
    gap: .55em;
    justify-content: flex-start;
    max-width: 29em;
    padding: 0 0 1.35em 0;
    position: relative;
    vertical-align: top;
    width: 100%;
}

    .admin-modal-row.full {
        max-width: 100%;
        width: 100%;
    }

    .admin-modal-row.wrap {
        flex-wrap: wrap;
    }

.admin-modal-row-separator {
    clear: both;
    display: block;
    font-size: 0;
    height: 0;
    line-height: 0;
    margin: 0;
    position: relative;
    width: 100%;
}

.admin-modal-cell {
    display: inline-block;
    flex: 1 1 auto;
    position: relative;
    vertical-align: top;
}

   /* .admin-modal-cell.wide {
        flex: 0 1 48%;
    }*/

    .admin-modal-cell.flex {
        align-self: stretch;
        align-items: center;
        display: inline-flex;
    }

    .admin-modal-cell.full {
        flex: 1 1 100%;
    }
.video-modal-wrapper .admin-modal-close{
    top:1.2em;
    right:1.2em;
}
.video-modal-wrapper .admin-modal-button-wrap {
    right:auto;
}

.admin-modal-input-text-wrap {
    display: block;
    position: relative;
}

    .admin-modal-input-text-wrap.inline {
        align-items: center;
        display: flex;
        gap: .5em;
    }
.admin-default-popup-style{
    display:flex;
    flex-direction:column;
    gap:0.35em;
}
.admin-default-label {
    color: #757575;
    display: block;
    font-size: .65em;
    line-height: 1.2em;
    margin: 0 0 .385em 0;
    position: relative;
}

.admin-legend-label {
    color: #2a2a2a;
    font-size: .9em;
    font-weight: 700;
}

.admin-default-input-text-wrap.inline .admin-modal-label {
    margin: 0;
}

.admin-large-inline-label {
    border: 1px solid transparent;
    color: #2B2B2B;
    display: inline-block;
    font-size: .9em;
    font-weight: 400;
    line-height: 1.2em;
    padding: .622em 0;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
}

.admin-default-input-text,
.admin-default-select,
.admin-default-uneditable,
.admin-default-search {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #FFF;
    border: 1px solid #D9D9D9;
    border-radius: .333em;
    color: #2B2B2B;
    display: block;
    font-size: .9em;
    font-weight: 700;
    line-height: 1.2em;
    padding: .622em .944em;
    position: relative;
    width: 100%;
}

.admin-default-select {
    background: #FFF url(../../assets/admin/select-arrow.svg) no-repeat right 1em center;
    background-size: .85em auto;
    padding-right: 2.8em;
}

.admin-default-search {
    background: #FFF url(../../assets/admin/search.svg) no-repeat right .8em center;
    background-size: 1.25em auto;
    padding-right: 2.8em;
}

.admin-default-uneditable {
    background: none;
    border: none;
    padding: 0;
}

    .small .admin-default-input-text,
    .admin-default-input-text.small,
    .small .admin-default-select,
    .admin-default-select.small,
    .small .admin-default-uneditable,
    .admin-default-uneditable.small,
    .small .admin-default-search,
    .admin-default-search.small {
        font-size: .65em;
        padding: .3615em .6153em;
    }

.small .admin-default-uneditable {
    padding: 0;
}

.input-validation-error .admin-default-label {
    color: red;
}

.input-validation-error .admin-default-input-text,
.input-validation-error .admin-form-input,
.input-validation-error .admin-select,
.input-validation-error .admin-default-textarea {
    border-color: red;
}

.input-validation-error .validation-error-message {
    display: block;
    font-size: .65em;
    line-height: 1.2em;
    margin: 0 0 .385em 0;
    position: relative;
    color: red;
}

.admin-modal-button-wrap {
    align-items: center;
    display: flex;
    gap: .65em;
    justify-content: flex-end;
    position: relative;
    right: -1.3em;
    width: 100%;
}

.admin-modal-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #FFF;
    border: 1px solid #2B2B2B;
    border-radius: 3em;
    color: #2B2B2B;
    cursor: pointer;
    display: inline-block;
    font-size: .9em;
    font-weight: 700;
    line-height: 1.2em;
    padding: .622em 1.5em;
    position: relative;
    transition: .25s;
    vertical-align: middle;
}

    .admin-modal-button:hover,
    .admin-modal-button:focus-visible {
        background: #2B2B2B;
        color: #FFF;
    }

    .admin-modal-button.red:hover,
    .admin-modal-button.red:focus-visible {
        background: #CC0000;
        border-color: #CC0000;
    }

    .admin-modal-button.power {
        background: #2B2B2B;
        color: #FFF;
    }

        .admin-modal-button.power:hover,
        .admin-modal-button.power:focus-visible {
            background: #FFF;
            color: #2B2B2B;
        }

    .admin-modal-button.red.power {
        background: #CC0000;
    }

        .admin-modal-button.red.power:hover,
        .admin-modal-button.red.power:focus-visible {
            background: #fff;
            border-color: #CC0000;
            color: #CC0000;
        }

.admin-modal-cell input {
    background: #fff;
    border-radius: 6px;
    border: 1px solid #D9D9D9;
    color: #282828;
    display: inline-block;
    font-size: .9em;
    font-weight: 700;
    line-height: 1.2em;
    padding: .622em .944em;
    /*padding: .35em .5em;*/
    position: relative;
    width: 100%;
}

    .admin-modal-cell input[type="radio"] {
        left: -1.3em;
        top: 0;
        width: auto;
    }

    .admin-modal-cell input[type="checkbox"] {
        position: absolute;
    }

.admin-modal-overlay .admin-modal-cell input[type="checkbox"] {
    left: 0;
    width: auto;
}
/* COLOR PICKER */
.admin-modal-color-picker-wrap {
    border: none;
    display: block;
    margin: 0;
    padding: 0;
    position: relative;
}

.admin-modal-color-picker-flex {
    align-items: flex-start;
    display: flex;
    gap: .75em;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.admin-modal-color-picker-preview {
    border-radius: 50%;
    display: inline-block;
    flex: 0 0 3.75em;
    height: 3.75em;
    margin: 0 0 .25em 0;
    position: relative;
    width: 3.75em;
}

.admin-modal-color-picker-column {
    display: flex;
    flex-direction: column;
    gap: .25em;
    margin: 0 0 .25em 0;
    min-width: 3.65em;
    position: relative;
}

.admin-modal-upload-file-box {
    align-items: center;
    border: 1px solid #D9D9D9;
    border-radius: .3em;
    display: inline-flex;
    height: 5em;
    justify-content: center;
    padding: .3em;
    position: relative;
    vertical-align: top;
    width: 5em;
}

.admin-modal-upload-file-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #FFFFFF;
    border: 1px solid #757575;
    border-radius: 3em;
    color: #757575;
    cursor: pointer;
    display: inline-block;
    font-size: .75em;
    font-weight: 700;
    line-height: 1.2em;
    margin: .5em;
    padding: .5333em .9em;
    position: relative;
    text-align: center;
    transition: .25s;
    vertical-align: middle;
}

    .admin-modal-upload-file-button:hover,
    .admin-modal-upload-file-button:focus-visible {
        background: #2B2B2B;
        border-color: #2B2B2B;
        color: #FFFFFF;
    }

.admin-modal-upload-file-delete {
    align-items: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #757575;
    border: 1px solid #757575;
    border-radius: 50%;
    bottom: .25em;
    color: #FFF;
    cursor: pointer;
    display: flex;
    font-size: 1em;
    height: 1.3em;
    justify-content: center;
    line-height: 1em;
    position: absolute;
    right: .25em;
    transition: .25s;
    width: 1.3em;
    z-index: 1;
}

    .admin-modal-upload-file-delete:hover,
    .admin-modal-upload-file-delete:focus-visible {
        background: #2B2B2B;
        border-color: #2B2B2B;
    }

    .admin-modal-upload-file-delete .material-symbols-rounded {
        display: inline-block;
        font-size: .75em;
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
        height: 1em;
        line-height: 1em;
        position: relative;
        width: 1em;
    }

.admin-modal-upload-file-box img {
    display: inline-block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    position: relative;
    vertical-align: top;
    width: auto;
}

.admin-modal-or {
    color: #757575;
    display: block;
    font-size: .9em;
    line-height: 1.2em;
    margin: 0 1em;
    position: relative;
}
/* PRODUCT ADD */
.admin-section-row {
    align-items: flex-start;
    display: flex;
    gap: .85em;
    justify-content: flex-start;
    margin: 1.1em 0 0 0;
    max-width: 100%;
    position: relative;
    width: auto;
}

    .admin-section-row.access {
        margin: 1.5em 0 0 0;
    }

.admin-section-cell {
    display: inline-block;
    flex: 0 1 23.75%;
    position: relative;
    vertical-align: top;
    width: 10.35em;
}

    .admin-section-cell.wide {
        flex: 0 1 48.5%;
        width: 21.15em;
    }

.admin-modal-row .admin-section-cell.wide {
    flex: 0 1 100%;
    width: 100%;
}

.admin-section-cell.extra-wide {
    flex: 0 1 74.28%;
    width: 32.35em;
}

.admin-section-cell.full {
    flex: 0 1 100%;
    width: 43.55em;
}

.admin-select-wrap {
    display: block;
    position: relative;
}

.admin-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #FFF url(../../assets/admin/arrow_back_ios_new.svg) no-repeat right .667em center;
    background-size: 1.11em auto;
    border: 1px solid #D9D9D9;
    border-radius: .333em;
    color: #2B2B2B;
    display: block;
    font-size: .9em;
    font-weight: 700;
    line-height: 1.2em;
    padding: .622em 2em .622em .944em;
    position: relative;
    width: 100%;
}

.admin-default-textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid #D9D9D9;
    border-radius: .333em;
    color: #2B2B2B;
    display: block;
    font-size: .9em;
    font-weight: 700;
    line-height: 1.2em;
    padding: .622em .944em;
    position: relative;
    width: 100%;
}

.admin-section-separator {
    border-bottom: 1px solid #D9D9D9;
    display: block;
    height: 0;
    line-height: 0;
    margin: 1.1em 0 0 0;
    position: relative;
    width: 100%;
}

.admin-section-small-headline {
    color: #2B2B2B;
    display: block;
    font-size: .9em;
    font-weight: 700;
    line-height: 1.2em;
    margin: 0 0 .35em 0;
    position: relative;
}

.admin-small-upload-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid #757575;
    border-radius: 3em;
    color: #757575;
    cursor: pointer;
    display: inline-block;
    font-size: .75em;
    font-weight: 700;
    line-height: 1.2em;
    padding: .534em 1em;
    transition: .25s;
}

    .admin-small-upload-button:hover,
    .admin-small-upload-button:focus-visible {
        background: #2B2B2B;
        border-color: #2B2B2B;
        color: #FFF;
    }
/* SORTABLE FILES */
.admin-sortable-wrap {
    display: block;
    margin: 0 0 .5em 0;
    position: relative;
    width: 100%;
}

.admin-sortable-file {
    align-items: center;
    color: #2B2B2B;
    cursor: move;
    display: flex;
    font-size: .75em;
    gap: .2em;
    justify-content: flex-start;
    line-height: 1.25em;
    padding: 0 0 1em 0;
    position: relative;
    width: 100%;
}

    .admin-sortable-file.ghost {
        opacity: .5;
    }

.admin-sortable-file-name {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.admin-sortable-file-delete {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #2B2B2B;
    border: none;
    border-radius: 50%;
    color: #FFF;
    cursor: pointer;
    display: inline-block;
    height: 1em;
    margin-left: .4em;
    position: relative;
    transition: .25s;
    vertical-align: middle;
    width: 1em;
}

    .admin-sortable-file-delete:hover,
    .admin-sortable-file-delete:focus-visible {
        background: #CC0000;
    }

.admin-sortable-file.ghost .admin-sortable-file-delete {
    display: none;
}

.admin-sortable-file-delete .material-symbols-rounded {
    bottom: 0;
    display: block;
    font-size: .75em;
    height: 1em;
    left: 0;
    line-height: 1em;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 1em;
}
/* ADMIN CHECKBOX COLUMNS */
.admin-column-wrap {
    align-items: flex-start;
    display: flex;
    gap: 2em;
    justify-content: flex-start;
    margin: 1em 0 0 0;
    position: relative;
}

    .admin-column-wrap.allow-wrapping {
        flex-wrap: wrap;
        gap: 2.3em;
    }

.admin-column {
    display: inline-block;
    flex: 1 1 auto;
    position: relative;
    vertical-align: top;
}

.allow-wrapping .admin-column {
    flex: 0 0 calc(20% - 2.3em);
    width: calc(20% - 2.3em);
}

.admin-modal.large.grow .allow-wrapping .admin-column {
    width: calc(100% - 2.3em);
    /*flex-basis: calc(20% - 2.3em);*/
    flex-basis: calc(13.5em - 2.3em);
}

.admin-inline-label {
    color: #2B2B2B;
    cursor: pointer;
    display: block;
    font-size: .8em;
    line-height: 1.125em;
    padding: .3125em 0 .3125em 1.5em;
    position: relative;
}

.admin-section-cell .admin-inline-label {
    padding: .3125em 0 .3125em 1.2em;
}

.admin-inline-checkbox {
    accent-color: #2B2B2B;
    color: #2B2B2B;
    display: block;
    font-size: .875em;
    height: 1em;
    left: 0;
    position: absolute;
    top: .45em;
    width: 1em;
}

.admin-inline-label-text {
    display: inline-block;
    position: relative;
    vertical-align: top;
}

.admin-inline-label input[type="checkbox"] {
    left: .5em;
    opacity: 0;
    top: .75em;
}

    .admin-inline-label input[type="checkbox"] + .admin-inline-label-text {
        cursor: pointer;
        display: inline-block;
        line-height: 1.1em;
        padding: 0 0 0 .5em;
        /*padding: .3125em 0 .3125em .5em;*/
        position: relative;
    }

        .admin-inline-label input[type="checkbox"] + .admin-inline-label-text::before,
        .admin-inline-label input[type="checkbox"] + .admin-inline-label-text:before {
            background: #fff;
            border-radius: 2px;
            content: '\e835';
            font-family: 'Material Symbols Rounded';
            font-size: 1.2em;
            font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
            line-height: 1.2em;
            height: 1em;
            left: -1.1em;
            position: absolute;
            top: -.2em;
            width: 1em;
        }

    .admin-inline-label input[type="checkbox"]:checked + .admin-inline-label-text::before,
    .admin-inline-label input[type="checkbox"]:checked + .admin-inline-label-text:before {
        content: '';
        height: 1em;
        left: -1.2em;
        pointer-events: none;
        position: absolute;
        top: .4em;
        width: 1em;
    }

    .admin-inline-label input[type="checkbox"]:checked + .admin-inline-label-text::after,
    .admin-inline-label input[type="checkbox"]:checked + .admin-inline-label-text:after {
        content: '';
        content: '\e834';
        font-family: 'Material Symbols Rounded';
        font-size: 1.2em;
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
        line-height: 1.2em;
        height: 1em;
        left: -1.1em;
        pointer-events: none;
        position: absolute;
        top: -.2em;
        width: 1em;
    }

.admin-inline-label.ind-dash input[type="checkbox"]:checked + .admin-inline-label-text::after,
.admin-inline-label.ind-dash input[type="checkbox"]:checked + .admin-inline-label-text:after {
    content: '';
    content: '\e909';
    font-family: 'Material Symbols Rounded';
    font-size: 1.2em;
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
    line-height: 1.2em;
    height: 1em;
    left: -1.1em;
    pointer-events: none;
    position: absolute;
    top: -.2em;
    width: 1em;
}

/* DRAG BOX */
.admin-drag-box-wrap {
    display: inline-block;
    min-width: 28.2em;
    max-width: 100%;
    position: relative;
    vertical-align: top;
    width: auto;
}

.admin-section-headline + .admin-drag-box-wrap {
    margin: 1em 0 0 0;
}

.draggable {
    width: calc(100% - 16.6em);
}

.admin-drag-box {
    align-items: center;
    background: #FFF;
    border: 1px solid #D9D9D9;
    border-radius: .3em;
    cursor: move;
    display: flex;
    height: 100%;
    gap: .5em;
    justify-content: space-between;
    margin: 0 0 .4em 0;
    position: relative;
    width: 100%;
}

.admin-drag-box-name {
    color: #2B2B2B;
    display: inline-block;
    font-size: .9em;
    font-weight: 700;
    line-height: 1.2em;
    position: relative;
    padding: .728em 0 .628em 1.889em;
    vertical-align: middle;
}

    .admin-drag-box-name::before,
    .admin-drag-box-name:before {
        bottom: 0;
        color: #2B2B2B;
        content: '\e945';
        display: block;
        font-family: 'Material Symbols Rounded';
        font-size: 1.333em;
        font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
        height: 1em;
        left: .2em;
        line-height: 1em;
        margin: auto;
        position: absolute;
        top: 0;
    }

.admin-drag-option-wrap {
    display: inline-block;
    padding: 0 .5em;
    position: relative;
    vertical-align: middle;
}

.admin-drag-option {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: none;
    color: #757575;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    line-height: 1em;
    height: 2.3em;
    padding: .2em 0;
    position: relative;
    transition: .25s;
    vertical-align: middle;
    width: 1.5em;
}

    .admin-drag-option:hover,
    .admin-drag-option:focus-visible {
        color: #2B2B2B;
    }

    .admin-drag-option.delete:hover,
    .admin-drag-option.delete:focus-visible {
        color: #CC0000;
    }

    .admin-drag-option .material-symbols-rounded {
        bottom: 0;
        display: block;
        font-size: 1em;
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
        height: 1em;
        left: 0;
        line-height: 1em;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 1em;
    }

.branding-heading-label {
    color: #757575;
    display: block;
    font-size: .7em;
    line-height: 1.2em;
    margin: 0 0 0.4em 0;
    position: relative;
}

.branding-top-spacing {
    padding-top: 2.5rem;
}

.admin-table td.admin-table-branding {
    text-align: right;
}

.searchquoterequest {
    margin: 2em 0 0;
    max-width: 14em;
}

.quoteradius {
    border-radius: 50px;
}

.percentage-input {
    position: relative;
}

.percentage-symbol {
    position: absolute;
    right: 10px; /* Adjust the positioning as needed */
    top: 50%;
    transform: translateY(-50%);
}
/* MEDIA UPLOADER & OVERLAY */
.admin-media-wrap {
    align-items: flex-start;
    display: flex;
    gap: .5em;
    justify-content: flex-start;
    position: relative;
    width: 100%;
}

.mediaOptions {
    align-items: flex-start;
    display: inline-flex;
    justify-content: flex-start;
    gap: .5em;
    position: relative;
    vertical-align: top;
}

.media-thumbnail-action-wrap {
    align-items: center;
    bottom: 0;
    display: flex;
    gap: .15em;
    justify-content: flex-end;
    max-width: 100%;
    padding: .2em;
    position: absolute;
    right: 0;
    width: auto;
}

.media-thumbnail-action {
    align-items: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: rgba(0,0,0,.5);
    border: none;
    border-radius: 50%;
    color: #FFF;
    cursor: pointer;
    display: inline-flex;
    font-size: 1em;
    height: 1.3em;
    justify-content: center;
    line-height: 1em;
    position: relative;
    vertical-align: middle;
    width: 1.3em;
}

    .media-thumbnail-action:hover,
    .media-thumbnail-action:focus-visible {
        background: #000;
    }

    .media-thumbnail-action.red:hover,
    .media-thumbnail-action.red:focus-visible {
        background: #CC0000;
    }

    .media-thumbnail-action .material-symbols-rounded {
        display: inline-block;
        font-size: .7em;
        font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
        height: 1em;
        line-height: 1em;
        position: relative;
        width: 1em;
    }
/* MODAL OPTION STEPS */
.admin-radio-label,
.admin-checkbox-label {
    color: #2B2B2B;
    display: block;
    font-size: .9em;
    font-weight: 700;
    line-height: 1.2em;
    padding: .29em 1em .29em 1.4em;
    position: relative;
}

.admin-radio,
.admin-checkbox {
    accent-color: #2B2B2B;
    display: block;
    height: .833em;
    left: 0;
    position: absolute;
    top: .425em;
    width: .833em;
}

.admin-label-preview {
    border: 1px solid #D9D9D9;
    border-radius: 50%;
    display: inline-block;
    font-size: 1.111em;
    height: 1em;
    margin: 0 0 0 .25em;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    width: 1em;
}

    .admin-label-preview img {
        display: block;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }

.admin-draggable-editable-option-wrap {
    border-top: 1px solid #D9d9D9;
    border-bottom: 1px solid #D9D9D9;
    display: block;
    padding: .3em 0;
    position: relative;
    width: 100%;
}

.admin-draggable-editable-header-row,
.admin-draggable-editable-option {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    gap: .6em;
    line-height: 0;
    padding: 0 0 0 1.5em;
    position: relative;
    width: 100%;
}

.admin-draggable-editable-option {
    cursor: move;
    padding: .7em 0 .7em 1.5em;
}

    .admin-draggable-editable-option.display-seperator {
        border-top: 1px solid #D9D9D9;
    }

    .admin-draggable-editable-option::before,
    .admin-draggable-editable-option:before {
        color: #2B2B2B;
        content: '\e945';
        display: block;
        font-family: "Material Symbols Rounded";
        font-size: 1.2em;
        height: 1em;
        left: 0;
        line-height: 1em;
        margin: -.5em 0 0 0;
        position: absolute;
        top: 62%;
        transform: translateY(-50%);
        width: 1.25em;
    }

.draggable .admin-draggable-editable-option {
    background: #fff;
    padding: 10px 20px;
    box-sizing: border-box;
}

.admin-draggable-editable-header-cell,
.admin-draggable-editable-option-cell {
    display: inline-block;
    flex: 1 1 1%;
    position: relative;
    vertical-align: middle;
}

    .admin-draggable-editable-header-cell.auto,
    .admin-draggable-editable-option-cell.auto {
        flex: 1 1 auto;
    }

    .admin-draggable-editable-header-cell.actions,
    .admin-draggable-editable-option-cell.actions {
        align-self: center;
        flex: 0 0 2.3em;
    }

.admin-draggable-editable-header {
    color: #757575;
    display: block;
    font-size: .65em;
    line-height: 1.2em;
    position: relative;
}

.admin-draggable-editable-option-label {
    display: block;
    font-size: 0;
    opacity: 0;
    position: absolute;
    top: 0;
}

.admin-draggable-editable-option-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid #D9D9D9;
    border-radius: .333em;
    color: #2B2B2B;
    font-size: .9em;
    font-weight: 700;
    line-height: 1.2em;
    padding: .622em 1em;
    position: relative;
    width: 100%;
}

.admin-draggable-editable-action {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #FFF;
    border: 1px solid #D9D9D9;
    border-radius: .3em;
    color: #757575;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    height: 2.3em;
    line-height: 1em;
    position: relative;
    vertical-align: middle;
    width: 2.3em;
}

    .admin-draggable-editable-action:hover,
    .admin-draggable-editable-action:focus-visible {
        background: #2B2B2B;
        border-color: #2B2B2B;
        color: #fff;
    }

    .admin-draggable-editable-action.red:hover,
    .admin-draggable-editable-action.red:focus-visible {
        background: #CC0000;
        border-color: #CC0000;
    }

    .admin-draggable-editable-action .material-symbols-rounded {
        display: inline-block;
        font-size: 1em;
        font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
        height: 1em;
        line-height: 1em;
        position: relative;
        width: 1em;
    }

.admin-add-option {
    align-items: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid #D9D9D9;
    border-radius: .3em;
    color: #757575;
    cursor: pointer;
    display: inline-flex;
    height: 2.3em;
    justify-content: center;
    position: relative;
    width: 2.3em;
}

    .admin-add-option:hover,
    .admin-add-option:focus-visible {
        background: #2B2B2B;
        border-color: #2B2B2B;
        color: #FFF;
    }

    .admin-add-option .material-symbols-rounded {
        display: inline-block;
        font-size: 1.2em;
        height: 1em;
        line-height: 1em;
        position: relative;
        width: 1em;
    }

.admin-draggable-editable-option-form {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: .7em;
    justify-content: flex-start;
    position: relative;
    width: 100%;
}

.admin-draggable-editable-option-form-row {
    align-items: center;
    display: flex;
    gap: .7em;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.admin-draggable-editable-option-form-cell {
    display: inline-block;
    flex: 1 1 1%;
    position: relative;
    vertical-align: top;
}

    .admin-draggable-editable-option-form-cell.wide {
        display: inline-block;
        flex: 2 1 2%;
        position: relative;
        vertical-align: top;
    }

.success-message {
    color: #00AC30;
    display: block;
    font-size: .65em;
    left: 0;
    line-height: 1.2em;
    position: absolute;
    top: 1.2em;
}
/* ADMIN HOME */
.admin-link-tile-wrap {
    align-items: stretch;
    display: grid;
    gap: 1em;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
    max-width: 50.5em;
    position: relative;
    width: 100%;
}

.admin-link-tile {
    align-content: flex-end;
    align-items: flex-end;
    background: #FFF;
    border: 1px solid #D9D9D9;
    border-radius: .3em;
    color: #2B2B2B;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 0;
    justify-content: flex-start;
    min-height: 10.5em;
    padding: 1.4em;
    position: relative;
    text-decoration: none;
}

    .admin-link-tile:hover,
    .admin-link-tile:focus-visible {
        border-color: #2B2B2B;
    }

.admin-link-tile-icon {
    color: #D9D9D9;
    display: inline-block;
    font-size: 1.6em;
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
    height: 1em;
    margin: 0 0 .47em 0;
    line-height: 1em;
    position: relative;
    width: 1em;
}

.admin-link-tile:hover .admin-link-tile-icon,
.admin-link-tile:focus-visible .admin-link-tile-icon {
    color: #2B2B2B;
}

.admin-link-tile-name {
    color: #2B2B2B;
    display: block;
    font-size: 1.4em;
    font-weight: 700;
    line-height: 1em;
    padding: 0 1.4em 0 0;
    position: relative;
    width: 100%;
}

.admin-link-tile-badge {
    background: #FF1010;
    border-radius: 50%;
    color: #fff;
    display: block;
    font-size: .8em;
    font-weight: 700;
    height: 2.625em;
    line-height: 1.225em;
    padding: .7em 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    -webkit-transform: translate(.4em, -.4em);
    -moz-transform: translate(.4em, -.4em);
    transform: translate(.4em, -.4em);
    width: 2.625em;
}

.admin-quotes-style {
    border-radius: 0.9em;
    font-size: 0.9em;
    height: 1.75em;
    left: 8.3em;
    line-height: 0.7em;
    top: 0.7em;
    min-width: -moz-fit-content;
    min-width: fit-content;
    padding: 0.59em;
    width: 1.3em;
}

/*.admin-quotes-box {
    top: 1.2em;
    right: 1.3em;
    font-size: 0.7em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}*/

.admin-modal-school-logo {
    max-width: 460px;
    max-height: 360px;
}

.admin-product-checkbox-section {
    display: block;
    padding: 0 0 1.5em 0;
    position: relative;
    width: 100%;
}

    .admin-product-checkbox-section + .admin-product-checkbox-section {
        border-top: 1px solid #D9D9D9;
        padding-top: 1.5em;
    }

    .admin-product-checkbox-section .product-label {
        text-decoration: underline;
        cursor: pointer;
    }

.admin-inline-label .total-items {
    text-decoration: none !important;
}

.internal-error {
    border-radius: 5px;
    background-color: #e25656;
    color: #ffffff;
    padding: 5px 10px;
    margin-bottom: 20px;
}

    .internal-error .close-button {
        float: right;
        margin-top: 5px;
    }


/*.slick-track.slick-comming-soon-img {
    width: 72px;
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
}*/
.product-gallery-thumb-row.thamb-galler-comming-soon {
    visibility: hidden;
}

.admin-modal.admin-add-role {
    display: block;
    position: relative;
    /* max-height: 100%; */
    overflow: hidden;
    max-width: 28.25em;
    width: 100%;
    padding: 1em 0.5em;
}

.descriptions-box-row {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em 0.6em;
    justify-content: flex-start;
    margin: 2.3em 0;
    max-width: 57em;
    position: relative;
    width: 100%;
}
.descriptions-box-row .admin-tall-list-item {
    align-items: stretch;
    background: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 0.2em;
    color: #343434;
    display: inline-flex;
    flex-basis: calc(33.33% - 0.4em);
    flex-grow: 0;
    flex-shrink: 1;
    justify-content: space-between;
    position: relative;
    vertical-align: top;
    width: calc(33.33% - 0.4em);
}
.descriptions-box-row .admin-tall-list-item .admin-title-symbol{
    display:flex;
    align-items:center;
}
.descriptions-box-row .admin-tall-list-item .desc-icon {
    margin-right: 0.4em;
}
.descriptions-box-row .admin-skill-tile-name {
    color: #2b2b2b;
    display: inline-block;
    font-size: .8em;
    font-weight: 500;
    line-height: 1.2em;
    padding: 0em 0.55em;
    position: relative;
    vertical-align: top;
}
.descriptions-box-row .admin-tall-list-edit{
    padding: 0 0.55em;
}
.descriptions-box-row .admin-skill-tile-action-wrap {
    align-items: stretch;
    display: inline-flex;
    gap: 0;
    justify-content: space-between;
    position: relative;
    vertical-align: top;
}
/*------------*/
/*  .admin-modal.admin-add-role.admin-user-width {
        max-width: 48.5em;
    }*/
.admin-modal-scroll {
    overflow-y: auto;
    height: 100%;
    overflow-x: hidden;
    max-height: 85vh;
    display: block;
    position: relative;
    width: 100%;
    padding: 0.5em 1.5em;
}
    .admin-modal-scroll::-webkit-scrollbar {
        width: 0.15em;
    }

    .admin-modal-scroll::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    .admin-modal-scroll::-webkit-scrollbar-thumb {
        background-color: #2A2A2A;
        outline: 1px solid #D9D9D9;
    }
    .admin-modal-scroll .admin-modal-button-wrap {
        right: -0.8em;
    }

.product-media-model .admin-modal .financial-skill-tile .admin-modal-button {
    position: relative;
}

.product-media-model .admin-modal .financial-skill-tile .admin-modal-button input[type="file"] {
    appearance: none;
    background: transparent;
    border: 1px solid #e3e3e3;
    border-radius: .3rem;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.product-media-model .admin-modal .financial-skill-tile {
    margin-bottom: 1em;
    width: 100%;
}
.video-priew-section {
    max-width: 85%;
}
.video-priew {
    max-width: 100%;
    position: relative;
}
    .video-priew img, .video-priew video {
        border-radius: .5em;
        max-width: 100%;
    }
    .video-priew .media-thumbnail-action {
        background: #fff;
        color: #000;
        position: absolute;
        right: .5em;
        top: .5em;
    }
.media-thumbnail-action .material-symbols-rounded {
    display: inline-block;
    font-size: .7em;
    font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
    height: 1em;
    line-height: 1em;
    position: relative;
    width: 1em;
}
.video-priew .media-thumbnail-action.play-icon {
    background: #00000080;
    bottom: 0;
    color: #fff;
    left: 0;
    margin: auto;
    right: 0;
    top: 0;
}
.media-thumbnail-action .material-symbols-rounded {
    display: inline-block;
    font-size: .7em;
    font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
    height: 1em;
    line-height: 1em;
    position: relative;
    width: 1em;
}
.product-media-model .admin-modal .video-thumbnail-cell .videoThumbWrap {
    background: #0000;
    border: 1px solid #e3e3e3;
    border-radius: .3rem;
    height: 6.65em;
    margin-top: .3em;
    overflow: hidden;
    width: 10.3em;
}
    .product-media-model .admin-modal .video-thumbnail-cell .videoThumbWrap img {
        color: #888;
        font-size: 14px;
        height: 100%;
        line-height: 120px;
        object-fit: cover;
        text-align: center;
        width: 100%;
    }
.error-messagess {
    color: red;
    font-size: .75em;
    font-weight: 700;
    margin-top: 5px;
}
.product-media-model .input-validation-error {
    border-color: red !important;
}
.product-media-model .admin-modal .video-thumbnail-cell .videoThumbWrap .media-thumbnail-action {
    margin: 0;
    position: static;
}
circle .media-thumbnail-action-wrap {
    bottom: 50%;
    gap: .2em;
    justify-content: center;
    transform: translateY(50%);
    width: 100%;
}
 .description-drop-down-icon {
    position: absolute;
    right: 0.2em;
    font-size: 30px;
}
.description-drop-down-select {
    padding-right: 2em;
}
.report-table-wrapper{
    align-items:flex-start;
    display:flex;
    grid-column-gap:2rem;
}
.report-districts {
    width: fit-content;
    display: inline-block;
    max-width: 15rem;
    min-width: 14rem;
    display: inline-block;
}
.report-districts hr{
    position:relative;
}
.report-dates{width:calc(70% - 2rem); display:inline-flex;align-items:flex-end;}
.report-dates .col{
    padding-right:1.5rem;
    font-size:0.9rem;
}
.report-dates .col:last-child{
    padding-right:0;
}

.report-dates .col a{
    align-items:center;
    display:flex;
    text-decoration:none;
    white-space:nowrap;
}
.report-dates .col a:hover{
    border-bottom:0.05rem solid #000000;
}
.report-dates .col a span{
    margin-left:5px;
    font-size:1.1rem;
}

.date-picker-wrap .date-picker-label {
    color: #757575;
    font-size: 0.65rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    margin-bottom:0.25rem;
}
.date-picker-box {
    position: relative;
    width:auto;
}
.report-dates .date-picker-box input {
    width: 9rem;
    padding: 0.6rem 0.5rem 0.7rem 0.7rem;
    color: #2A2A2A;
    font-family: Roboto;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
    height: 2.3rem;
    border-radius: 0.3rem;
    border: 0.05rem solid #D9D9D9;
}
.date-picker-box input[type="date"]::-webkit-calendar-picker-indicator {
   cursor:pointer;
   position:absolute;
   z-index:1;
   top:0;
   bottom:0;
   left:0;
   right:0;
   width:100%;
   height:100%;
   opacity:0;
}
.date-picker-box::after {
    background-color:#ffffff;
    content: url('../../assets/icons/calendar_month.svg'); 
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 1.2rem; 
    height: 1.2rem;
    pointer-events:none;
    padding-top:0.075rem;
}


.admin-view-pass{
    position: absolute;
    right: 0.7rem;
    top: 1.6rem;
    cursor:pointer;
}

@media screen and (max-width: 1500px) {
    .allow-wrapping .admin-column {
            flex-basis: calc(25% - 2.3em);
            width: calc(25% - 2.3em);
        }
    .report-dates .col{
        padding-right:1rem;
    }
    .report-districts{
        padding-left:0;
    }
    .report-dates .date-picker-box input {
        width: 8rem;
        padding: 0.5rem 0.5rem 0.65rem 0.6rem;
        font-size: 0.8rem;
        line-height: 0.9rem;
        height: 2rem;
    }
    .date-picker-box::after {
        padding-top:0.05rem;
    }
}

    @media screen and (max-width: 1200px) {
        .allow-wrapping .admin-column {
            flex-basis: calc(33.33% - 2.3em);
            width: calc(33.33% - 2.3em);
        }
        .contact-blocks a, .contact-blocks p.contact-title, .contact-blocks p {
            font-size: 1rem;
        }
        .report-dates{
            padding-right:0;           
            width: calc(70% - 1.5rem);
        }
        .report-dates .col{
            padding-right:0.5rem;
            font-size:0.8rem;
        }
        .admin-page-heading{
            font-size:1.7rem;
        }
        .report-table-wrapper {
            grid-column-gap: 1.5rem;
        }
        .report-dates .date-picker-box input {
            width: 7.3rem;
            padding: 0.5rem 0.3rem;
            font-size: 0.7rem;
            line-height: 0.9rem;
            height: 2rem;
            font-weight: 500;
        }
        .date-picker-box::after {
            content: url('../../assets/icons/calendar_month-sm.svg');
            height: 1rem;
            width: 1rem;
        }
        .report-dates .col a{
            font-weight:500
        }
        .report-districts {
            min-width: 11rem;
        }
        .report-dates .col:last-child{
            padding-left:0;
        }
    }

    .ck-content ol, .ck-content ul{padding-left:20px;}
@media screen and (max-width:1023px) {
    .report-table-wrapper{
        flex-direction:column-reverse;
    }
    .report-dates {
        flex-wrap: wrap;
        margin-bottom: 1rem;
        order: 1;
        width: 100%;
    }
    .report-districts{
        max-width:20rem;
        padding-right:0;
        width:100%;
    }
    .report-dates .col{
        margin-bottom:0.4rem;
    }
    .report-dates .col:last-child {
        padding: 0;
    }
}
@media screen and (max-width:900px) {
    .contact-blocks a, .contact-blocks p.contact-title, .contact-blocks p {
        font-size: 0.9rem;
    }
    .contact-blocks{
        padding-bottom:1.5rem;
    }
    .report-dates .col:last-child {
        margin-left: 0;
    }
}
@media screen and (max-width:480px) {
    .contact-blocks a, .contact-blocks p.contact-title, .contact-blocks p {
        font-size: 0.75rem;
    }
    .contact-blocks p.contact-title{
        padding-bottom:0.25rem;
    }
    .contact-blocks-wrapper{
        gap:0.5rem;
    }
}