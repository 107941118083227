.main-landing-page {
    font-family: "Lato", sans-serif;
}
.banner-wrapper {
    background-image: url(../../assets/home-banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #1c2427;
    border-radius: 3rem;
    margin-top: -6.5rem;
    overflow: hidden;
}
.w-100{
	width: 100%;
}
.relative{
	position: relative;
}
.absolute{
	position: absolute;
}
.pointer{
    cursor:pointer;
}
.dnone{
    display:none;
}
.body-right-bg{
	max-width: 25%;
	right: 0;
	top: -10.75rem;
}
.body-left-bg{
	bottom: -4rem;
	left: -2rem;
	max-width: 25%;
	z-index: -1;
}
.fe-headline{
	color: #1F232E;
	font-size: 3.4rem;
	font-weight: 800;
	line-height: 3.6rem;
}
.banner-heading-container{
	flex-basis: 37%;
}
.subheadline-btn.black-text{
	color: #000000;
}
.tab-img-container {
    align-items: flex-end;
    display: flex;
    flex-basis: 57%;
    position: relative;
    top: 8.8rem
}
.tab-img{
	height: auto;
	object-fit: contain;
	position: absolute;
	top: 0;
	width: 100%;
}
.banner-wrapper .main-hero-section {
    display: flex;
    justify-content: space-between;
}
.banner-heading-container{
	padding: 14.5rem 0 10.25rem;
}
.standard-btn, a.standard-btn {
    align-items: center;
    border: 0.05rem solid #3A823A;
    border-radius: 1.5rem;
    color: rgba(248,248,248, 0.75);
    display: inline-flex;
    font-family: "Lato", sans-serif;
    font-size: 0.7rem;
    font-weight: 500;
    height: 1.55rem;
    justify-content: center;
    line-height: 1.3rem;
    max-width: 10.5rem;
    padding: 0 0.5rem;
    text-decoration: none;
    width: 100%;
}
.banner-title{
	padding: 1.3rem 0 2.2rem;
}
.banner-title .main-banner-title{
	color: #FFF;
	font-family: "Lato", sans-serif;
	font-size: 2.7rem;
	font-style: normal;
	font-weight: 800;
	line-height: 2.9rem;
}
.banner-title .main-banner-title span{
	color: #BDD8FF;
	display: block;
}
.banner-discription{
	color: rgba(255,255,255,0.65);
	font-family: "Lato", sans-serif;
	font-size: 0.9rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	max-width: 21.25rem;
	padding-top: 0.55rem;
	width: 100%;
}
button.request-btn {
    background: transparent;
    border: 0.05rem solid #FFF;
    border-radius: 5rem;
    color: #FFF;
    cursor: pointer;
    display: inline-flex;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 700;
    justify-content: center;
    line-height: 1.3rem;
    max-width: 9.6rem;
    padding:0.6rem 0.65rem;
    text-align: center;
    text-decoration: none;
    transition: ease-in-out 0.2s;
    width: 100%;
}
button.request-btn:hover {
    background: #3A823A;
    border-color:#3A823A;
}
.brand-wrapper {
    align-items: center;
    background-color:#21232f;
    background-image: url(../../assets/brand-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 3rem;
    margin: 4rem 0;
    padding: 4rem 6.5rem;
}
.brand-box{
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	margin: auto;
	max-width: 29rem;
	width: 100%;
}
.brand-box{
	color: #F8F8F8;
	text-align: center;
}
.mo-brand-logo{
    display:none;
}
.brand-headline {
    color: #f8f8f8;
    margin-bottom: 1.1rem;
    margin-top: 1.55rem;
}
.brand-discription{
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.5;
	opacity: 0.7;
}
.subheadline-btn{
	align-items: center;
	border: 0.05rem solid #3A823A;
	border-radius: 1.5rem;
	color: rgba(248,248,248,0.75);
	display: inline-flex;
	font-size: 0.8rem;
	font-weight: 500;
	height: 1.55rem;
	justify-content: center;
	line-height: 1.3rem;
	margin-left: auto;
	margin-right: auto;
	padding: 0 0.75rem;
}
.brand-img-box{
	flex-basis: 33.33%;
	width: 100%;
}
.brand-img-box img{
	height: auto;
	width: 100%;
}
.procurement-wrapper{
	padding: 4rem 0 9rem;
}
.procurement-row{
	align-items: center;
	display: flex;
	justify-content: center;
    grid-column-gap:0.5rem;
}
.procurement-row img{
    max-width:90%;
}
.procurement-row .subheadline-btn{
	color: #1F232E;
}
.pro-headline {
    max-width: fit-content;
    padding: 1.1rem 0;
}
.pro-discription{
	color: #757575;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.5;
	max-width: 30rem;
	width: 100%;
}
a.solid-primary-btn{
    color:#ffffff;
}
.procurement-row a.solid-primary-btn:hover {
    color: #3A823A;
}
.choose-section .main-container {
    grid-column-gap: 1rem;
    justify-content: space-between;
}
.standard-point-wrapper{
	flex-basis: 41.6rem;
	padding-top: 2.9rem;
}
.standard-title-wrapper{
	flex-basis: 30.5rem;
}
.choose-section .subheadline-btn{
	color: #000000;
	margin-bottom: 1.35rem;
	max-width: 8.2rem;
	width: 100%;
}
.choose-section{
	padding: 7rem 0;
}
.standard-point{
	margin-bottom: 2rem;
	position: relative;
}
.standard-point:last-child{
	margin-bottom: 0;
}
.standard-bullet-point{
	height: 1.5rem;
	position: absolute;
	width: 1.5rem;
}
.standard-point p{
	padding-left: 2.6rem;
}
.standard-point .standard-point-title{
	color: #000000;
	font-size: 1.3rem;
	font-weight: 800;
	line-height: 1.5rem;
	margin-bottom: 0.4rem;
}
.standard-point .standard-point-dis{
	color: #757575;
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 1.5;
}

/*user-friendly-section css*/
.user-friendly-section{
    overflow:hidden;
	padding: 7rem 0 4rem;
}
.user-friendly-section .main-container{
	grid-gap: 1.5rem;
	justify-content: space-between;
}
.user-friendly-section .user-friendly-discription{
    max-width:33rem;
}
.user-friendly-discription,
.features-title-discription {
    color: #757575;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 0.75rem;
    text-shadow: 0.1rem 0.1rem 0.2rem rgba(255, 255, 255, 0.5);
    /*max-width: 27rem*/
}
.user-friendly-section .subheadline-btn{
	margin-bottom: 1.1rem;
}
.user-friendly-title-wrapper{
	flex-basis: 37rem;
    position:relative;
    z-index:2;
}
.user-friendly-wrapper{
	flex-basis: 39rem;
}
.user-friendly-card-wrapper{
	display: flex;
	flex-wrap: wrap;
	grid-column-gap: 14%;
}
.user-friendly-card-wrapper .user-card{
	flex-basis: 14rem;
    position:relative;
}
.user-friendly-card-wrapper .user-card .card-box{
    background: #fff;
	border-radius: 1rem;
	box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.16);
	margin-bottom: 1.25rem;
	padding: 1.2rem;
	width: 10.15rem;
}
.user-friendly-card-wrapper .user-card .card-box .card-icon{
	align-items: center;
	background-color: #1F232E;
	border-radius: 50%;
	display: flex;
	height: 1.9rem;
	justify-content: center;
	margin-bottom: 0.45rem;
	width: 1.9rem;
}
.user-friendly-card-wrapper .user-card .card-box .card-icon img{
	height: auto;
	width: 1.2rem;
}
.user-friendly-card-wrapper .user-card .card-box .card-title{
	color: #1F232E;
	font-size: 1.1rem;
	font-weight: 800;
	line-height: 1.2rem;
}
.user-friendly-card-wrapper .user-card .card-discription{
	color: #757575;
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 1.5;
}
.user-friendly-card-wrapper .user-card:first-child{
	margin-bottom: 6.25rem;
}
.user-friendly-card-wrapper{
    position:relative;
    z-index:1;
}
.user-card:nth-child(2), .user-card:nth-child(2){
    position:unset;
}
.user-card:nth-child(2):after, .user-card:nth-child(2)
.user-card:nth-child(1):after, .user-card:nth-child(1):after,
.user-card:nth-child(3):after, .user-card:nth-child(3):after {
    background-color: #3A823A;
    border-radius: 50%;
    content: '';
    height: 0.7rem;
    position: absolute;
    right: -0.25rem;
    top: 10.8rem;
    width: 0.7rem;
}
.user-card:nth-child(1), .user-card:nth-child(3) {
    position: relative;
}
.user-card:nth-child(1):after, .user-card:nth-child(1):after {
    left: 103.5%;
    top: 2.5rem;
}
.user-card:nth-child(3):after, .user-card:nth-child(3):after {
    left: 103.5%;
    top: 2.85rem;
}
.user-friendly-card-wrapper:before, .user-friendly-card-wrapper::before {
    border: 0.3rem dotted #D9D9D9;
    border-left: 0;
    border-radius: 0rem 3rem 3rem 0;
    content: '';
    height: 16.5rem;
    position: absolute;
    right: 0;
    top: 2.7rem;
    width: 100%;
    z-index: -1;
}
.user-friendly-card-wrapper:after, .user-friendly-card-wrapper::after {
    border: 0.3rem dotted #D9D9D9;
    border-width: 0 0 0.25rem 0;
    content: '';
    height: 16.5rem;
    position: absolute;
    right: 100%;
    top: 2.7rem;
    width: 70vw;
    z-index: -1;
}
/*features css*/
.features-section{
	padding: 7rem 0;
}
.features-section .main-container{
	flex-direction: column;
}
.features-section .features-title-wrapper{
	max-width: 30.5rem;
    margin-bottom:4.15rem;
}
.features-section .features-title-wrapper .fe-headline{
	margin: 0.8rem 0 0;
}
.features-section .features-card-wrapper{
	display: flex;
	gap: 20px;
}
.features-section .features-card-wrapper .features-card{
	flex: 1;
	position: relative;
	/*transition: flex 0.3s;*/
    cursor:pointer;
}
.feature-card-inner-wrapper {
    transition: 0.3s;
}
.features-section .features-card-wrapper .features-card:hover .feature-card-inner-wrapper {
    box-shadow: 0 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.15);
    margin-top:-0.25rem;
}
.features-section .features-card-wrapper .features-card .arrow-circle {
    transition: 0.3s;
}
.features-section .features-card-wrapper .expandFeature.features-card .arrow-circle{
    transition:0;
}

.features-section .features-card-wrapper .features-card:hover .feature-card-inner-wrapper .arrow-circle {
    margin-top: -0.25rem;
}
.features-section .features-card-wrapper .expandFeature.features-card:hover .feature-card-inner-wrapper .arrow-circle {
    margin-top: 0;
}
.feature-card-inner-wrapper{
	background: #ffffff;
	border-radius: 1.5rem;
	box-shadow: 0 0.2rem 0.6rem 0.2rem rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	min-height: 12.25rem;
	padding: 1.2rem;
	width: 100%;
}
.feature-point{
	color: #BDBDBD;
    display:none;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.3;
	margin-bottom: 0.25rem;
	margin-top: auto;
}
.feature-card-title{
	color: #1F232E;
	font-size: 1.3rem;
	font-weight: 800;
	line-height: 1.4rem;
	max-width: 13rem;
    margin-top:auto;
    padding-top:0.25rem;
}
.arrow-circle{
	align-items: center;
	border: 0.05rem solid #3A823A;
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	height: 2.2rem;
	justify-content: center;
	position: absolute;
	right: 1.2rem;
	top: 1.2rem;
	width: 2.2rem;
}
.arrow-circle img{
	height: auto;
	width: 1.2rem;
}
.feature-discription{
	color: #757575;
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 1.5;
	padding-top: 0.3rem;
}
.feature-img{
	margin: 0 auto;
	padding: 0 2rem;
	text-align: center;
}
.feature-img img{
	max-width: 14.2rem;
	width: 100%;
}
.features-card-wrapper .expandFeature .arrow-circle{
	transform: rotate(180deg);
}
.feature-img, .feature-discription{
	display: none;
}
.features-card-wrapper .expandFeature .feature-img,
.features-card-wrapper .expandFeature .feature-discription{
	display: block;
}
.features-card-wrapper .features-card.expandFeature{
	flex: 1.35;
}
.features-card-wrapper .features-card.expandFeature .feature-card-inner-wrapper{
    box-shadow: 0 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.15);
	bottom: 0;
	position: absolute;
}

/*modal css*/
.main-page-wrapper .modal-wrapper {
    align-items: center;
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.70);
    bottom: 0;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    padding: 2rem 1rem;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    z-index:1000;
}
.main-page-wrapper .modal-body {
    background-color: #ffffff;
    border-radius: 1rem;
    flex-basis: 25.5rem;
    overflow-y: auto;
    padding: 2rem;
    position: relative;
    width: 100%;
}

.main-page-wrapper .modal-title {
    color: #1F232E;
    font-size: 1.9rem;
    font-weight: 800;
    line-height: 2.1rem;
}
.main-page-wrapper .modal-discription {
    color: #757575;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.25;
    margin: 0.7rem 0 1.4rem;
    padding-right: 1.5rem;
}
.main-page-wrapper .modal-discription .support-number {
    color: #757575;
    font-weight:600;
    text-decoration:none;
}
.sales-popup-close{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
}
.request-demo .modal-form {
    padding-right: 1.5rem;
    overflow-y: auto;
    max-height: 50vh;
}
.request-demo .modal-form::-webkit-scrollbar {
  width: 0.25rem;
}

.request-demo .modal-form::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.25rem grey; 
  border-radius: 0.5rem;
}
.request-demo .modal-form::-webkit-scrollbar-thumb {
  background: #3A823A; 
  border-radius: 0.5rem;
}
.request-demo .modal-form::-webkit-scrollbar-thumb:hover {
  background: #b9d9d9; 
}
.submitReqBtn {
  cursor: wait;
}
.modal-form .input-box {
    margin-bottom: 0.75rem;
    position: relative;
}
.modal-form .input-box label{
    color: #757575;
    font-size: 0.7rem;
    font-weight: 600;
    left: 1.1rem;
    line-height: 1;
    margin: auto;
    position: absolute;
    top: 0.8rem;
    transition: 0.25s;
    z-index: 0;
}
.modal-form .input-box.invalid label{
    color:#AD0000;
    font-size: 0.5rem;
    top: 0.48rem;
    z-index: 1;
}
.modal-form .input-box.invalid input{
    border-color:#AD0000;
    background: #FFF4F4;
}
.modal-form .input-box.invalid:after, .modal-form .input-box.invalid::after{
    align-items: center;
    content: url('../../assets/warning.svg');
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0.8rem;
    height: 2.35rem;
    z-index: 1;
}
.modal-form .input-box input {
    border: 0.05rem solid rgba(117,117,117,0.15);
    border-radius: 5rem;
    background-color: transparent;
    color: #1F232E;
    font-size: 0.7rem;
    font-weight: 600;
    height: 2.25rem;
    padding: 0.80rem 2.1rem 0.25rem 1rem;
    position: relative;
    width: 100%;
    z-index: 1;
}
.modal-form .input-box input:focus + label,
.modal-form .input-box input:focus-within + label,
.modal-form .input-box label.filled{
	font-size: 0.5rem;
	top: 0.48rem;
}
.modal-form .input-box input:focus,
.modal-form .input-box input:focus-visible{
	border: 0.05rem solid rgba(117,117,117,0.15);
	outline: 0;
}
.phone-code{
	grid-column-gap: 0.2rem;
}
.modal-form .solid-primary-btn{
	margin: auto;
	margin-top: 1.5rem;
	max-width: 13.65rem;
	width: 100%;
}
.modal-form .solid-primary-btn:hover{
	color: #3A823A;
}
.form-btn{
	padding-left: 1.5rem;
}

@media screen and (max-width: 1800px) {
    .banner-heading-container {
        padding: 13.6rem 0 10.25rem;
        flex-basis: 41%;
    }
    .banner-title {
        padding: 1.2rem 0 1.6rem;
    }
    .banner-wrapper, .brand-wrapper, .footer-wrapper {
        border-radius: 2.5rem;
    }
    .fe-headline {
        font-size: 3.1rem;
        line-height: 1.056;
    }
    .banner-title .main-banner-title{
        font-size:2.5rem;
    }
    .banner-discription{
        font-size:0.8rem;
    }
    .feature-card-title {
        font-size: 1.2rem;
        line-height: 1.3rem;
    }
    .user-friendly-title-wrapper{
        flex-basis:36rem;
        padding-right:1rem;
    }
    .user-friendly-card-wrapper .user-card {
        flex-basis: 13.8rem;
    }
}

@media screen and (max-width: 1500px) {
    .banner-wrapper{
        margin-top:-5.75rem;
    }
    .main-container .tab-img{
        top:0;
    }
    .banner-heading-container {
        padding: 11.3rem 0 8.85rem;
    }
    .banner-title{
        padding:1rem 0;
    }
    .banner-title .main-banner-title{
        font-size:2.1rem;
        line-height:1.07;
    }
    .banner-discription{
        font-size:0.8rem;
    }
    button.request-btn {
        font-size: 0.8rem;
        line-height: 1.25;
        max-width:8rem;
        padding:0.45rem;
    }
    .subheadline-btn {
        font-size: 0.7rem;
        height: 1.25rem;
    }
    .features-section{
        padding:5.5rem 0;
    }
    .features-section .features-card-wrapper{
        gap:0.75rem;
    }
    .fe-headline{
        font-size:2.6rem;
        line-height:1.05;
    }
    .features-section .features-title-wrapper{
        margin-bottom:3.35rem;
    }
    .features-section .features-title-wrapper .fe-headline {
        margin: 0.55rem 0 0rem;
    }
    .feature-card-inner-wrapper{
        min-height:9.55rem;
    }
    .features-title-discription{
        max-width:28rem;
    }
    .feature-card-title {
        font-size: 1rem;
        line-height: 1.1;
        padding-right: 2.2rem;
    }
    .feature-point {
        font-size: 0.75rem;
        line-height: 1;
    }
    .arrow-circle img{
        width:0.9rem;
    }
    .arrow-circle{
        width:1.7rem;
        height:1.7rem;
    }
    .standard-point .standard-point-title{
        font-size:1rem;
        line-height:1;
    }
    .standard-bullet-point{
        width:1.2rem;
        height:1.2rem;
    }
    .standard-point-wrapper{
        flex-basis:33rem;
    }
    .standard-point {
        margin-bottom: 1.3rem;
    }
    .standard-point p {
        padding-left: 2rem;
    }
    .choose-section .subheadline-btn {
        margin-bottom: 1rem;
        max-width: 7rem;
    }
    .choose-section{
        padding:5.5rem 0;
    }
    .user-friendly-section .subheadline-btn{
        margin-bottom:1rem;
    }
    .user-friendly-discription, .features-title-discription {
        font-size: 0.8rem;
        line-height: 1.5;
        margin-top: 0.5rem;
    }
    .user-friendly-card-wrapper .user-card:first-child{
        margin-bottom:5rem;
    }
    .user-friendly-card-wrapper .user-card .card-box{
        margin-bottom:1rem;
    }
    .user-friendly-card-wrapper .user-card .card-box .card-title{
        font-size:1rem;
    }
    .user-friendly-card-wrapper .user-card .card-box{
        padding:1rem;
    }
    .user-friendly-card-wrapper .user-card .card-box .card-icon{
        width:1.6rem;
        height:1.6rem;
    }
    .user-friendly-card-wrapper .user-card .card-box .card-icon img{
        width:1rem;
    }
    .user-friendly-section{
        padding:5.5rem 0;
    }
    .user-friendly-card-wrapper:before, .user-friendly-card-wrapper::before{
        height:13.7rem;
        border-radius:0 2.5rem 2.5rem 0;
    }
    .user-card:nth-child(1):after, .user-card:nth-child(1):after,
    .user-card:nth-child(3):after, .user-card:nth-child(3):after {
        left: auto;
        right: 0.25rem;
        top: 2.5rem;
    }
    .user-card:nth-child(2):after, .user-card:nth-child(2):after {
        right:-0.2rem;
        top:9.5rem;
    }
    .user-card:nth-child(3):after, .user-card:nth-child(3):after {
        top:2.4rem;
    }
    .user-friendly-card-wrapper:after, .user-friendly-card-wrapper::after{
        height:13.8rem;
    }
    .brand-box {
        flex-basis: 33.33%;
    }
    .brand-wrapper {
        padding: 3.5rem 4rem;
    }
    .procurement-wrapper {
       padding: 4rem 0 5.5rem
    }
    .solid-primary-btn{
        font-size:0.8rem;
        padding:0.45rem;
    }
    .standard-btn{
        font-size:0.7rem;
        height:1.25rem;
        max-width:10rem;
    }
    .user-friendly-card-wrapper{
        grid-column-gap:7%;
    }
    .user-friendly-card-wrapper .user-card{
        flex-basis:13rem;
    }
}

@media screen and (max-width: 1350px) {
    .banner-heading-container{
        flex-basis:42%;
    }
    .user-friendly-card-wrapper .user-card {
        flex-basis: 11.8rem;
    }
    .user-card:nth-child(1):after, .user-card:nth-child(1):after, 
    .user-card:nth-child(3):after, .user-card:nth-child(3):after{
        right:-0.15rem;
    }
    .user-friendly-card-wrapper {
        grid-column-gap: 5%;
    }
}

@media screen and (max-width: 1200px) {
    .banner-wrapper, .brand-wrapper, .footer-wrapper {
        border-radius: 2rem;
    }
    .main-nav .w-50 {
        flex-basis: inherit;
    }
    .banner-wrapper{
        border-radius:2rem;
        margin-top: -5rem;
    }
    .banner-title .main-banner-title {
        font-size: 1.7rem;
    }
    .banner-title {
        padding: 0.75rem 0;
    }
    .banner-discription{
        font-size:0.65rem;
        max-width:16rem;
    }
    .main-container .tab-img-container {
        top: 6.5rem;
    }
    button.request-btn {
        font-size: 0.65rem;
        max-width: 6.35rem;
        padding: 0.345rem;
    }
    .banner-heading-container{
        padding:9.1rem 0 7.1rem;
        flex-basis:40.6%;
    }
    .navbar-brand a{
        max-width:6.9rem;
    }
    .standard-btn {
        max-width: fit-content;
        padding: 0 0.682rem;
    }
    .subheadline-btn {
        border-radius: 1rem;
        padding: 0 0.55rem;
    }
    .fe-headline {
        font-size: 2.05rem; 
    }
    .features-section .features-title-wrapper .fe-headline {
        margin-bottom: 2.7rem;
    }
    .features-section .features-title-wrapper .fe-headline {
        margin: 0.55rem 0 0;
    }
    .features-section .features-title-wrapper {
        max-width: 20rem;
    }
    .feature-card-title {
        font-size: 0.8rem;
        max-width: 8.5rem;
        padding: 0;
    }
    .feature-point{
        font-size:0.6rem;
    }
    .feature-card-inner-wrapper {
        border-radius: 1rem;
        min-height: 7.65rem;
    }
    .arrow-circle{
        width:1.35rem;
        height:1.35rem;
    }
    .arrow-circle img{
        width:0.7rem;
    }
    .feature-discription{
        font-size:0.65rem;
    }
    .expandFeature .feature-card-title {
        max-width: fit-content;
    }
    .features-card-wrapper .features-card.expandFeature .feature-card-inner-wrapper {
        height: auto;
    }
    .choose-section .subheadline-btn {
        margin-bottom: 0.55rem;
        padding: 0 0.25rem;
    }
    .standard-point-wrapper{
        padding-top:2.45rem;
    }
    .choose-section {
        padding: 2.5rem 0;
    }
    .standard-point .standard-point-title{
        font-size:0.8rem;
        margin-bottom:0.3rem;
    }
    .standard-bullet-point{
        width:0.8rem;
        height:0.8rem;
    }
    .standard-point p{
        padding-left:1.5rem;
    }
    .standard-point .standard-point-dis{
        font-size:0.65rem;
    }
    .user-friendly-card-wrapper .user-card .card-box {
        max-width: 8.5rem;
        margin-bottom: 0.5rem;
        padding: 0.75rem;
        width: 100%;
    }
    .user-friendly-card-wrapper {
        grid-column-gap: 8%;
    }
    .user-friendly-card-wrapper .user-card{
        flex-basis:9rem;
    }
    .user-friendly-card-wrapper .user-card:first-child{
        margin-bottom:4rem;
    }
    .user-friendly-card-wrapper .user-card .card-discription{
        font-size:0.65rem;
    }
    .user-friendly-card-wrapper .user-card .card-box .card-title{
        font-size:0.8rem;
    }
    .user-friendly-section{
        padding:3rem 0 ;
    }
    .user-friendly-card-wrapper:before, .user-friendly-card-wrapper::before{
        border-width:0.2rem;
        height:11.7rem;
        top:2.2rem;
    }
    .user-card:nth-child(2):after, .user-card:nth-child(2) .user-card:nth-child(1):after, 
    .user-card:nth-child(1):after, .user-card:nth-child(3):after, .user-card:nth-child(3):after{
        width:0.5rem;
        height:0.5rem;
    }
    .user-card:nth-child(1):after, .user-card:nth-child(1):after,
    .user-card:nth-child(3):after, .user-card:nth-child(3):after {
        right:-0.95rem;
        top:2.05rem;
    }
    .user-card:nth-child(3):after, .user-card:nth-child(3):after{
        top:2.35rem;
    }
    .user-card:nth-child(2):after, .user-card:nth-child(2):after {
        right: -0.15rem;
        top: 8.2rem;
    }
    .user-friendly-card-wrapper:after, .user-friendly-card-wrapper::after{
        border-width:0 0 0.2rem 0;
        height:11.7rem;
        top:2.2rem;
    }
    .brand-wrapper {
        border-radius: 2rem;
        margin: 2rem 0;
        padding: 2rem 3rem;
    }
    .brand-discription{
        font-size:0.75rem;
        line-height:1.5;
    }
    .brand-headline{
        margin:0.75rem 0;
    }
    .procurement-wrapper{
        padding:3rem 0;
    }
    .pro-discription{
        font-size:0.75rem;
        line-height:1.5;
    }
    .pro-headline{
        padding:0.5rem 0;
    }
    .features-section {
        padding: 4.5rem 0;
    }
    .user-friendly-discription, .features-title-discription {
        font-size: 0.75rem;
    }
    .modal-title{
        font-size:1.6rem;
        max-width:13rem;
        line-height:1.2;
    }
    .main-page-wrapper .modal-discription {
        margin: 0.5rem 0 1rem;
    }
    .main-page-wrapper .modal-body {
        padding: 1.5rem;
    }
    .modal-form .solid-primary-btn{
        margin-top:1rem;
    }
    .main-page-wrapper .modal-title{
        font-size:1.6rem;
    }
}
@media screen and (max-width: 1024px) {
    .banner-heading-container{
        padding-bottom:5rem;
    }
    .user-friendly-card-wrapper {
        grid-column-gap: 5%;
    }
    .user-card:nth-child(1):after, .user-card:nth-child(1):after, 
    .user-card:nth-child(3):after, .user-card:nth-child(3):after {
        right: -0.5rem;
    }
}

@media screen and (max-width: 900px) {
    .banner-wrapper, .brand-wrapper, .footer-wrapper {
        border-radius: 1.4rem;
    }
    .body-right-bg, .body-left-bg {
        max-width: 30%;
    }
    .banner-wrapper {
        margin-top: -3.8rem;
    }
    .banner-title .main-banner-title {
        font-size: 1.4rem;
    }
    .banner-discription{
        font-size:0.6rem;
    }
    button.request-btn{
        font-size:0.6rem;
        max-width:5.5rem;
    }
    .banner-heading-container{
        padding:6rem 0 3.9rem;
    }
    .tab-img{
        top:0;
    }
    .features-section{
        padding:3rem 0 1.5rem;
    }
    .fe-headline{
        font-size:1.5rem;
    }
    .feature-card-title{
        font-size:0.65rem;
    }
    .feature-card-inner-wrapper {
        border-radius: 0.9rem;
        min-height: 5.75rem;
        padding: 0.75rem;
    }
    .feature-discription {
        font-size: 0.6rem;
    }
    .features-section .features-card-wrapper{
        gap:0.35rem;
    }
    .arrow-circle {
        height: 1rem;
        right: 0.5rem;
        top: 0.5rem;
        width: 1rem;
    }
    .arrow-circle img{
        width:0.55rem;
    }
    .features-section .features-title-wrapper {
        margin-bottom: 2rem;
    }
    .features-section .features-title-wrapper .fe-headline{
        margin:0.5rem 0 0;
    }
    .features-section .features-title-wrapper{
        max-width:25rem;
    }
    .feature-point {
        font-size: 0.5rem;
    }
    .choose-section{
        padding:1.5rem 0;
    }
    .standard-point .standard-point-title{
        font-size:0.65rem;
    }
    .standard-point .standard-point-dis{
        font-size:0.6rem;
    }
    .standard-bullet-point {
        height: 0.7rem;
        width: 0.7rem;
    }
    .standard-point p{
        padding-left:1.2rem;
    }
    .user-friendly-discription, .features-title-discription {
        font-size: 0.7rem;
    }
    .user-friendly-card-wrapper .user-card {
        flex-basis: 6.8rem;
    }
    .user-friendly-card-wrapper .user-card .card-discription{
        font-size:0.6rem;
        line-height:1.25;
    }
    .user-friendly-card-wrapper .user-card .card-box{
        padding:0.5rem;
        border-radius:0.5rem;
    }
    .user-friendly-card-wrapper .user-card .card-box .card-title {
        font-size: 0.75rem;
    }
    .user-friendly-card-wrapper .user-card .card-box .card-icon{
      height:1.2rem;
      width:1.2rem;
    }
    .user-friendly-card-wrapper .user-card .card-box .card-icon img{
        width:0.8rem;
    }
    .user-friendly-section{
        padding:1.5rem 0;
    }
    .user-friendly-section .main-container{
        grid-column-gap:0.75rem;
    }
    .user-friendly-card-wrapper {
        grid-column-gap: 12%;
    }
    .user-friendly-card-wrapper:before, .user-friendly-card-wrapper::before{
        right:-1rem;
    }
    .user-friendly-card-wrapper:before, .user-friendly-card-wrapper::before,
    .user-friendly-card-wrapper:after, .user-friendly-card-wrapper::after{
        height: 10.5rem;
        top: 1.9rem;
    }
    .user-card:nth-child(2):after, .user-card:nth-child(2):after {
        right: -1.15rem;
        top: 7.1rem;
    }
    .user-card:nth-child(1):after, .user-card:nth-child(1):after,
    .user-card:nth-child(3):after, .user-card:nth-child(3):after {
        left: calc(100% + 0.8rem);
        right: auto;
        top: 1.75rem;
    }
    .user-card:nth-child(3):after, .user-card:nth-child(3):after{
        top:1.45rem;
    }
    .brand-img-box{
        flex-basis:30%;
    }
    .brand-box{
        flex-basis:40%;
    }
    .brand-discription, .pro-discription {
        font-size: 0.7rem;
    }
    .brand-wrapper {
        border-radius: 2rem;
        margin: 1.5rem 0;
        padding: 1.5rem 2rem;
    }
    .procurement-wrapper{
        padding:1.5rem 0;
    }
    .solid-primary-btn{
        font-size:0.7rem;
        line-height:1.25;
        padding:0.35rem;
    }
}
@media screen and (max-width: 767px){
    .user-friendly-card-wrapper .user-card {
        flex-basis: 5.77rem;
    }
    .user-friendly-card-wrapper {
        grid-column-gap: 8%;
    }
    .user-card:nth-child(1):after, .user-card:nth-child(1):after,
    .user-card:nth-child(3):after, .user-card:nth-child(3):after {
        left: 106%;
    }
    .user-card:nth-child(3):after, .user-card:nth-child(3):after {
        top: 1.45rem;
    }
    .user-friendly-section .main-container{
        padding-right:2.5rem;
    }
    .procurement-row img {
        max-width: 100%;
    }
}
@media screen and (max-width: 600px) {
    .body-right-bg{
	    max-width: 45%;
	    top: 6.5rem;
    }
    .body-left-bg{
	    bottom: 17rem;
	    left: -1rem;
	    margin: auto;
	    max-width: 50%;
    }
    .main-hero-section{
	    flex-wrap: wrap;
    }
    .banner-heading-container{
	    flex-basis: 17.15rem;
	    margin: auto;
	    padding: 5rem 0 2rem;
	    text-align: center;
    }
    .banner-wrapper {
        border-radius: 1.5rem;
        background-image: url(../../assets/mo-home-banner.png);
        margin-top: -3.7rem;
        padding-bottom: 16rem;
    }
    .banner-title .main-banner-title{
        font-size:1.6rem;
        line-height:1.8rem;
    }
    .main-container .tab-img-container{
        flex-basis: 100%;
        top:0;
    }
    .tab-img{
	    left: -3rem;
    }
    .standard-btn, .choose-section .subheadline-btn{
	    font-size: 0.7rem;
        min-height:1.45rem;
	    height: max-content;
	    max-width: fit-content;
	    padding: 0.1rem 0.57rem;
	    width: fit-content;
    }
    .banner-discription{
	    font-size: 0.8rem;
	    margin: auto;
    }
    button.request-btn{
        align-items:center;
        font-size:0.9rem;
        height:2.4rem;
        max-width:100%;
    }
    .banner-title{
        padding:0.75rem 0 1.7rem;
    }
    .fe-headline {
        font-size: 1.7rem;
    }
    .subheadline-btn{
	    font-size: 0.7rem;
	    height: auto;
	    line-height: 1;
	    max-width: fit-content;
	    padding: 0.3rem 0.75rem;
	    width: fit-content;
    }
    .features-section .features-title-wrapper{
	    margin-bottom: 1.6rem;
    }
    .features-section .features-card-wrapper {
        flex-direction: column;
        gap: 0.45rem;
    }
    .feature-card-inner-wrapper{
	    min-height: 5rem;
    }
    .feature-card-title{
	    font-size: 0.9rem;
	    max-width: 10rem;
    }
    .feature-point{
	    font-size: 0.7rem;
    }
    .feature-img{
        padding:0 2.45rem;
    }
    .arrow-circle {
        bottom: 0;
        height: 1.6rem;
        margin: auto;
        right: 0.9rem;
        top: 0;
        width: 1.6rem;
    }
    .expandFeature .arrow-circle{
	    bottom: auto;
	    top: 1rem;
    }
    .arrow-circle img{
	    width: 0.75rem;
    }
    .features-card-wrapper .features-card.expandFeature .feature-card-inner-wrapper{
	    position: unset;
    }
    .features-section .features-card-wrapper .features-card:hover .feature-card-inner-wrapper {
        box-shadow: 0 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.15);
        margin-top: 0;
    }
    .features-section .features-card-wrapper .features-card:hover .feature-card-inner-wrapper .arrow-circle {
        margin-top: auto;
    }
    .features-section .features-card-wrapper .features-card .arrow-circle{
        transition:0s;
    }
    .choose-section {
        padding-top: 2.4rem;
    }
    .choose-section .main-container {
        flex-wrap: wrap;
    }
    .choose-section .subheadline-btn{
        margin-bottom:0.85rem;
    }
    .standard-point-wrapper {
        padding-top: 1.4rem;
    }
    .standard-point .standard-point-title{
	    font-size: 1rem;
    }
    .standard-point .standard-point-dis, .user-friendly-discription,
    .user-friendly-card-wrapper .user-card .card-discription, .brand-discription,
    .pro-discription, .feature-discription,
    .main-page-wrapper .modal-discription, .features-title-discription {
        font-size: 0.8rem;
        line-height: 1.5;
    }
    .standard-bullet-point{
	    height: 1.2rem;
	    width: 1.2rem;
    }
    .standard-point p{
	    padding-left: 2.2rem;
    }
    .user-friendly-section .main-container{
	    flex-wrap: wrap;
        padding:0 1rem;
    }
    .user-friendly-section .user-friendly-discription {
        max-width: 100%;
    }
    .user-friendly-title-wrapper{
        padding-right:0;
    }
    .user-friendly-card-wrapper .user-card:first-child, .user-friendly-card-wrapper .user-card {
        flex-basis: 12.8rem;
        padding: 0 0 1.7rem;
        margin: 0 auto;
    }
    .user-friendly-card-wrapper .user-card .card-box{
	    border-radius: 0.7rem;
	    margin: 0 auto 0.6rem;
	    padding: 1rem;
    }
    .user-friendly-card-wrapper .user-card .card-discription{
        min-height:3.15rem;
	    text-align: center;
    }
    .user-friendly-card-wrapper .user-card .card-box .card-icon{
	    height: 1.55rem;
	    width: 1.55rem;
    }
    .user-friendly-card-wrapper .user-card .card-box .card-icon img{
	    width: 1rem;
    }
    .user-friendly-card-wrapper .user-card .card-box .card-title{
	    font-size: 1rem;
    }
    .user-friendly-discription{
        margin-top:0.75rem;
	    padding-bottom: 1.5rem;
    }
    .user-friendly-wrapper{
        padding:0 1rem;
    }
    .user-card:nth-child(2), .user-card:nth-child(2) {
        position: relative;
    }
    .user-card:nth-child(3):after, .user-card:nth-child(3){
        order:4;
        padding-bottom:0;
    }
    .user-friendly-card-wrapper .user-card:last-child {
        margin-bottom: 0;
    }
    .user-friendly-card-wrapper:before, .user-friendly-card-wrapper::before, 
    .user-friendly-card-wrapper:after, .user-friendly-card-wrapper::after{
        display:none;
    }
    .user-card:nth-child(1):before, .user-card:nth-child(1)::before,
    .user-card:nth-child(4):before, .user-card:nth-child(4)::before {
        border: 0.25rem dotted #d9d9d9;
        border-left: 0;
        border-radius: 0 2.5rem 2.5rem 0;
        content: '';
        height: 100%;
        position: absolute;
        right: -1.5rem;
        top: 2.5rem;
        width: 3.6rem;
        z-index: -1;
    }
    .user-card:nth-child(2):before, .user-card:nth-child(2)::before,
    .user-card:nth-child(3):before, .user-card:nth-child(3)::before {
        border: 0.25rem dotted #d9d9d9;
        border-right: 0;
        border-radius: 2.5rem 0 0 2.5rem;
        content: '';
        height: 100%;
        position: absolute;
        left: -1.5rem;
        top: 2.5rem;
        width: 3.6rem;
        z-index: -1;
    }
    .user-card:nth-child(1):after, .user-card:nth-child(1)::after,
    .user-card:nth-child(4):after, .user-card:nth-child(4)::after {
        background-color: #3A823A;
        border-radius: 50%;
        content: '';
        height: 0.55rem;
        left: auto;
        position: absolute;
        right: -1.65rem;
        top: calc(50% + 2rem);
        width: 0.55rem;
    }
    .user-card:nth-child(2):after, .user-card:nth-child(2)::after {
        left:-1.65rem;
        right:auto;
        top: calc(50% + 2rem);
    }
    .user-card:nth-child(3):after, .user-card:nth-child(3)::after{
        display:none;
    }
    .user-card:nth-child(3):before, .user-card:nth-child(3)::before {
        display: block;
        border-radius: 0;
        border-width: 0 0 0.2rem;
        height: auto;
        width: 15rem;
        left: -72%;
    }
    .brand-wrapper {
        border-radius: 1.5rem;
        background-image: url(../../assets/brand-bg-mobile.png);
        flex-wrap: wrap;
        padding: 2rem 0;
    }
    .brand-img-box, .brand-box {
        flex-basis: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    .desktop-brand-logo{
        display:none;
    }
    .mo-brand-logo {
        display: block;
    }
    .brand-box{
	    margin: auto;
	    max-width: 13.55rem;
	    padding: 1rem 0;
    }
    .procurement-row {
        flex-direction: column-reverse;
    }
    .pro-discription{
        padding-bottom:1rem;
    }
    .phone-code{
	    flex-wrap: wrap;
    }
    .modal-form .input-box{
	    width: 100%;
    }
    .main-page-wrapper .modal-content {
        padding-right: 0;
    }
    .main-page-wrapper .modal-title {
        max-width: calc(100% - 1.5rem);
    }
    .main-page-wrapper .modal-body {
        padding: 1.5rem 1rem;
        flex-basis:100%;
        border-radius:1.5rem 1.5rem 0 0;
    }
    .main-page-wrapper .modal-wrapper{
        padding:1rem 0 0;
        align-items:flex-end;
    }
    .main-page-wrapper .modal-title {
        font-size: 1.4rem;
        max-width:13rem;
    }
    .modal-form .solid-primary-btn {
        font-size:0.8rem;
        height:2.4rem;  
        max-width:100%;
    }
    .main-page-wrapper .form-btn {
        padding-left:0;
    }
}