/* LANDING */
section.types { display: block; }

.hero-slider-wrap { display: block; margin: auto; width: 100%; }

.wrapper-full { max-width: 100%; }

.types-inner { display: block; min-height: calc(100vh - 142px); position: relative; padding-top: 52%; padding: 0; width: 100%; }

.types-bg { height: 100%; left: 0; position: absolute; top: 0; width: 100%; }

    .types-bg:after { content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.7); -webkit-transition: opacity 0s ease; -o-transition: opacity 0s ease; transition: opacity 0s ease; opacity: 0; -webkit-transition-delay: 0.5s; -o-transition-delay: 0.5s; transition-delay: 0.5s; }

.types-bg-active:after { opacity: 1; -webkit-transition-delay: 0s; -o-transition-delay: 0s; transition-delay: 0s; }

.types-img { height: 100%; left: 0; -webkit-transition: opacity 0.5s ease; -o-transition: opacity 0.5s ease; opacity: 0; overflow: hidden; position: absolute; top: 0; transition: opacity 0.5s ease; width: 100%; }

.types-img-active { opacity: 1; }

.bg-b-lazy { -webkit-background-size: cover; background-size: cover; }

.types-img img,
.types-img video,
.types-img iframe { bottom: -50em; display: block; height: auto; left: -50em; margin: auto; max-height: 150%; max-width: 150%; min-height: 100%; min-width: 100%; object-fit: cover; object-position: center top; position: absolute; right: -50em; top: -50em; width: auto; }

.types-list { align-items: center; display: flex; height: calc(100vh - 6.35em); justify-content: center; position: relative; width: 100%; }

    .types-list:before { content: ""; height: 100%; left: calc(100% / 2); position: absolute; -webkit-transition: all 0.5s ease; -o-transition: all 0.5s ease; transition: all 0.5s ease; width: 1px; z-index: 1; }

    .types-list:after { content: ""; height: 100%; left: calc((100% / 2) * 2); position: absolute; -webkit-transition: all 0.5s ease; -o-transition: all 0.5s ease; transition: all 0.5s ease; width: 1px; z-index: 1; }

.types-list-active:before,
.types-list-active:after { background-color: rgba(255, 255, 255, 0.1); }

.types-list .card:nth-child(1) .card-bg { background-image: url(../../assets/landing/products-types-hero.jpg); /*background-position: -170% 0px;*/ background-position:center center; }

.types-list .card:nth-child(2) .card-bg {
    background-image: url(../../assets/landing/room-types-hero.jpg); /*background-position: -170% 0px;*/
    background-position: center center;
}

 .types-list .card:nth-child(3) .card-bg {
    background-image: url(../../assets/landing/typical-layouts-hero.jpg);
    background-position: -117% 0px;
} 

/* KEYFRAMES */
@keyframes fadeIn {
    from { opacity: 0; }

    to { opacity: 1; }
}

.fadeIn { animation-name: fadeIn; }

.types .card {
    align-items: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: inline-block;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    position: relative;
    transition: 0.25s;
    vertical-align: middle;
    /*width: calc(100% / 2);*/
    text-decoration: none;
    flex:1;
}

.types-list:hover .types-card .card-content,
.types-list:active .types-card .card-content { opacity: 0.1; }

.types-list .types-card:hover .card-content,
.types-list .types-card:active .card-content { opacity: 1; }

.types .card-bg { height: 100%; left: 0; position: absolute; -webkit-transition: opacity 0.5s ease; -o-transition: opacity 0.5s ease; transition: opacity 0.5s ease; top: 0; width: 100%; z-index: -2; }

.types .card-opacity .card-content { opacity: 0.2; }

.types .card-opacity .card-bg { opacity: 0; }

.types .card-mask { background: linear-gradient(90deg, #1b1b1b 0%, rgba(27, 27, 27, 0) 100%); background: -moz-linear-gradient(90deg, #1b1b1b 0%, rgba(27, 27, 27, 0) 100%); background: -webkit-linear-gradient(90deg, #1b1b1b 0%, rgba(27, 27, 27, 0) 100%); height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; }

.types .card-content {
    color: #fff;
    display: inline-block;
    left: 0;
    max-width: 400px;
    margin: 0 auto;
    min-height: 62px;
    padding: 1em;
    /*    padding: 4.4em;*/
    cursor: pointer;
    position: relative;
    text-align: left;
    -webkit-transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
    text-decoration: none;
    width: 100%;
}

.types .types-card:nth-child(2) .card-content {  padding: 0 0.9rem 0 2.8rem;
   
}

.types .card-h2 { line-height: 36px; }

.hero-subtitle.card-h2 { color: #ffffff; display: block; font-size: 4.25rem; font-weight: 700; line-height: 1.059em; margin: 0 auto; max-width: 17.5em; position: relative; text-align: left; text-decoration: none; width: 100%; }

.card-content-inner { display: none; max-width: 17.5rem; padding-top: 1.4em; position: relative; width: 100%; }

.types-card:hover .card-content-inner { display: block; }

.active .hero-tile-image { opacity: 1; }

.hero-tile-image img { bottom: -50em; display: block; left: -50em; margin: auto; max-height: 150%; max-width: 150%; min-height: 100%; min-width: 100%; object-fit: cover; position: absolute; right: -50em; top: -50em; transform-origin: center; transition: 0.25s; }

.hero-tile-image.one img { object-position: -8%; }

.hero-tile-image.two img { object-position: 50%; }

.hero-tile-image.three img { object-position: 116%; }

.hero-tile-content { display: none; max-width: 17.85em; position: relative; transition: 0.25s; width: 100%; }

.hero-tile-count { color: #fff; display: inline-block; font-size: 0.991em; letter-spacing: 0.1em; line-height: 1em; margin: 0 0 1.4em 0; padding: 0.5em 0; position: relative; text-transform: uppercase; }

.hero-tile-title { color: #fff; display: block; font-size: 4em; font-weight: 700; letter-spacing: 0.1em; line-height: 1em; padding: 0 0 0.66em 0; position: relative; text-transform: uppercase; }

.hero-border-overlay,
.hero-noborder-overlay { bottom: 0; display: block; height: auto; left: 0; min-height: 100%; position: absolute; width: 100%; }

    .hero-border-overlay:hover,
    .hero-border-overlay:active,
    .hero-noborder-overlay:hover,
    .hero-noborder-overlay:active { background: linear-gradient(90.23deg, rgba(0, 0, 0, 0.2301) 0.21%, rgba(0, 0, 0, 0) 104.27%); }

    .hero-border-overlay::after,
    .hero-border-overlay:after { background: rgba(255, 255, 255, 0.4); bottom: 0; content: ""; display: block; height: 91.2%; position: absolute; right: 0; width: 1px; }

.hero-tile-inner { display: block; padding: 18.9em 8em 0; margin-top: 87px; overflow: hidden; position: relative; text-align: left; width: 100%; }

.hero-arrow { display: none; position: absolute; top: 8em; left: -2em; width: 100%; }

.hero-tile-inner:hover .hero-arrow { display: block; }

.hero-tile:hover .hero-tile-content,
.hero-tile:active .hero-tile-content { display: block; }

.hero-tile-desc { color: #ffffff; display: block; font-size: 0.8rem; line-height: 1.4em; position: relative; }

.hero-button-wrap { display: block; padding: 0; position: relative; width: 100%; }

.hero-tile-link { background: #fff; border-radius: 1.5em; color: #1f1f1f; display: inline-block; font-size: 0.9rem; font-weight: 700; margin-top: 2.7em; padding: 0.7em 2.7em 0.7em 1.55em; position: relative; text-decoration: none; }

    .hero-tile-link::before,
    .hero-tile-link:before { color: #1f1f1f; content: "\e5c8"; display: block; font-family: "Material Symbols Rounded"; font-size: 1.3em; font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48; height: 1.2em; position: absolute; right: 0.6em; top: 0.4em; width: 1.2em; }

    .hero-tile-link:hover,
    .hero-tile-link:active { color: #fff; background: #1f1f1f; }

        .hero-tile-link:hover:before,
        .hero-tile-link:hover:before,
        .hero-tile-link:active::before,
        .hero-tile-link:active:before { color: #fff; }

.img-banner.video-banner { bottom: -50em; display: block; height: auto; left: -50em; margin: auto; min-height: 150%; min-width: 150%; object-fit: cover; position: absolute; right: -50em; top: -50em; }

.img-banner.video-banner { height: 100%; width: 2000px; }

/* LANDING OVERLAY */
.product-layout-title.inline-block-flex { font-size: 1em; padding: 0 0 0.4em 0; }

.product-layout-desc.inline-block-flex { padding: 0 0 1.6em 0; }

.product-type .nav-block-item .product-layout-wrapper.inline-block-flex { align-items: flex-start; align-content: flex-start; justify-content: flex-start; padding: 0; }

    .product-type .nav-block-item .product-layout-wrapper.inline-block-flex .product-layout-inner { background: #f3f3f3; border-radius: 0.3em; display: inline-block; padding: 1.2em 1.9em 0.95em 1.6em; position: relative; }

.product-type .nav-block-item:nth-child(1) .product-layout-cta.inline-block-flex { clear: both; float: right; margin: 0 0 0 auto; padding: 0.6em 2.5em 0.65em 1.4em; }

.product-layout-cta.inline-block-flex::after,
.product-layout-cta.inline-block-flex:after { font-size: 1.5em; right: 0.4em; top: 0.4em; }

.type-back-link { color: #757575; display: inline-block; font-size: 0.8em; left: 0.1em; line-height: 1.2em; position: absolute; text-decoration: none; top: 5.5em; vertical-align: middle; }

    .type-back-link .material-symbols-rounded { display: inline-block; margin-top: 0.1em; position: relative; vertical-align: top; }

.product-type .nav-block-item,
.typical-layout .nav-block-item,
.room-type .nav-block-item { max-width: 10em; margin-bottom: 1.1em; }

.product-type .nav-block-wrap { gap: 0.8em; }

.typical-layout .nav-drop-layout-wrap { gap: 1.7em; }

.typical-layout .nav-block-wrap,
.room-type .nav-block-wrap { gap: 0.7em; }

.typical-layout-cats .nav-block-wrap{width:100%;}
.typical-layout-cats .nav-overlay-product .nav-block-item{flex:0 1 18%;}

.typical-layout .nav-block-img-wrap::before,
.typical-layout .nav-block-img-wrap:before { padding: 99.1% 0 0 0; }

.room-type .nav-drop-layout-wrap { gap: 1em; justify-content: flex-start; }

.room-type .nav-drop-title { width: 13.4%; }

.room-type .nav-block-wrap { width: 83.5%; }

.room-type .nav-block-img-wrap::before,
.room-type .nav-block-img-wrap:before { padding: 100% 0 0 0; }

.room-type .nav-block-item { margin-bottom: 1.8em; }

.typical-layout .nav-block-item { margin-bottom: 2em; }
.nav-overlay-center.home-room-overlay .nav-block-wrap {
    width: 100%
}
.nav-overlay-product .nav-block-item {
    flex: 0 1 18%;
}
@media screen and (max-width: 1500px) {
    /* LANDING */
    .card-content .hero-subtitle.card-h2 {
        font-size: 3rem;
    }

    .types .card-content { padding: 0 1.3rem 0 1.7rem; }

    .types .types-card:nth-child(3) .card-content { padding: 0 1.3rem 0 2.7rem; }

    .hero-tile-link { font-size: 0.8rem; }

    /* OVERLAY */
    .type-back-link { top: 4em; }

    #browseproductType .nav-block-item:nth-child(1) { flex: 0 1 40.2%; }

    .product-layout-title.inline-block-flex { font-size: 0.9em; }

    .product-layout-desc.inline-block-flex { font-size: 0.75em; }
}

@media screen and (max-width: 1200px) {
    /* LANDING */
    .card-content .hero-subtitle.card-h2 {
        font-size: 2.5rem;
    }

    .hero-tile-desc { font-size: 0.75rem; }

    .hero-tile-link { font-size: 0.75rem; margin-top: 3em; }

        .hero-tile-link::before,
        .hero-tile-link:before { font-size: 1.2em; top: 0.5em; }
    
    /* OVERLAY */
    .nav-overlay.typical-layout .nav-drop-title,
    .nav-overlay.room-type .nav-drop-title,
    .nav-overlay.product-type .nav-drop-title { font-size: 1.2em; }

    .type-back-link { font-size: 0.75em; top: 3em; }

    .nav-overlay.typical-layout .nav-block-title,
    .nav-overlay.product-type .nav-block-title,
    .nav-overlay.room-type .nav-block-title { font-size: 0.75em; }

    #browseproductType .nav-block-item:nth-child(1) { flex: 0 1 100%; max-width: 31.4em; min-height: 10em; width: 100%; }

    .product-type .nav-block-item .product-layout-wrapper.inline-block-flex .product-layout-inner { height: calc(100% - 0.4em); width: 100%; }
}

@media screen and (max-width: 900px) {
    /* LANDING */
    .types-inner { height: fit-content; min-height: 100%; }

    .types-list { 
        align-items: flex-start; 
        flex-wrap: wrap; /*height: calc(100vh - 11.75em)*/
        flex-direction:column;
        height:100%; 
        justify-content: flex-start; 
        min-height:calc(100vh - 144px);
    }

    .types .card { display: flex; /*height: 11.1rem*/ height:50%; position: relative; width: 100%; }

    .types .card-content { 
        display: block; 
        margin: auto; 
        max-width: 25em; 
        min-height: 39px; 
        padding: 0 1.4rem; 
        width: 100%; 
        flex:initial;
    }

    .types .types-card:nth-child(2) .card-content,
    .types .types-card:nth-child(3) .card-content { padding: 0 1.4rem; }

    .types .types-list .card { border-top: 1px solid #333; min-height:15rem;}

    .types-list:hover .types-card .card-content,
    .types-list:active .types-card .card-content { opacity: unset; }

    .types-list .types-card:hover .card-content,
    .types-list .types-card:active .card-content { opacity: unset; }

    .hero-subtitle.card-h2 { font-size: 2rem; }

    .hero-tile-desc { font-size: 0.7rem; }

    .hero-tile-link { font-size: 0.8rem; margin-top: 1.1em; padding: 0.5em 2.4em 0.6em 1.35em; }

        .hero-tile-link::before,
        .hero-tile-link:before { top: 0.4em; }

    .types-list::before,
    .types-list:before,
    .types-list::after,
    .types-list:after { height: 1px; left: 0; top: calc(100% / 3); width: 100%; }

    .types .types-list.active .card.active { align-items: flex-end; height: 69.4%; width: 100%; }

    .types .types-list.active .card { border-top: 1px solid #333; height: 15.3%; width: calc(100% / 1); }

    .types-card:hover .card-content-inner { display: none; }

    .types-list::before,
    .types-list:before,
    .types-list::after,
    .types-list:after { display: none; }

    .types-list.active .card.active .card-content-inner { display: block; padding-top: 0.2em; padding-bottom: 1.5em; }

    .types .card-opacity .card-content,
    .types-list:hover .types-card .card-content,
    .types-list:active .types-card .card-content { opacity: 1; padding: 0 1.4rem 0; }

    .types .card-opacity.active .card-content { padding: 0 1.4rem 1.6rem; }

    /* OVERLAY */
    .typical-layout .nav-drop-layout-wrap,
    .room-type .nav-drop-layout-wrap,
    .product-type .nav-drop-layout-wrap { flex-wrap: wrap; }

    .nav-overlay.typical-layout .nav-drop-title,
    .nav-overlay.room-type .nav-drop-title,
    .nav-overlay.product-type .nav-drop-title { font-size: 1.1em; width: 100%; }

    .type-back-link { font-size: 0.7em; }

    .nav-overlay.typical-layout .nav-block-title,
    .nav-overlay.product-type .nav-block-title,
    .nav-overlay.room-type .nav-block-title { font-size: 0.7em; }

    .nav-overlay.typical-layout .nav-block-wrap { width: 95%; }

    .product-type .nav-block-item { flex: 0 1 30.83%; }

    #browserproductType .nav-block-item:nth-child(1) { flex: 0 1 95.6%; max-width: 100%; }

    .types .card-mask { background: #1b1b1b; opacity: 0.65; }

    .types .card-bg { background-position: center !important; }
    .card-content .hero-subtitle.card-h2 {
        font-size: 2rem;
    }
    .hero-tile-desc {
        font-size: 0.7rem;
        line-height: 1.3em;
    }
    .hero-tile-link {
        font-size: .8rem;
        padding: 0.7em 2.7em 0.5em 1.55em;
    }
    .nav-overlay-product .nav-block-wrap{
        grid-column-gap:1.5%;
    }
    .typical-layout-cats .nav-overlay-product .nav-block-item,
    .nav-overlay-product .nav-block-item {
        flex: 0 1 32.33%;
        margin-bottom: 1rem;
    }
    .nav-overlay-product .nav-block-wrap{
        width:100%;
    }
}

@media screen and (max-width: 600px) {
    .nav-overlay-product .nav-block-wrap {
        grid-column-gap: 4%;
    }

    .typical-layout-cats .nav-overlay-product .nav-block-item,
    .nav-overlay-product .nav-block-item {
        flex: 0 1 48%;
        margin-bottom: 0.7rem;
    }

    .nav-block-title{
        font-size:0.7rem;
    }
    /* LANDING */
    .icon-cta.wishlist-cell,
    .icon-cta.cart-cell {
        font-size: 0.65rem;
        padding: 0.484em 0.5em 0.354em 1em;
    }

    .icon-cta.delete-cell { margin-top: 1.2em; }

    /* OVERLAY */
    .type-back-link { font-size: 0.6em; top: 3.5em; }

    .typical-layout .nav-block-item,
    .room-type .nav-block-item { flex: 0 1 46%; }

    .nav-overlay.typical-layout .nav-block-title,
    .nav-overlay.product-type .nav-block-title,
    .nav-overlay.room-type .nav-block-title { font-size: 0.6em; }

    #browseproductType .nav-block-item:nth-child(1) { height: 100%; min-height: 11em; }

    .product-type .nav-block-item { flex: 0 1 47%; }
}
@media screen and (max-width: 360px) {
    /* LANDING */
    .card-content .hero-subtitle.card-h2 {
        font-size: 1.8rem;
    }
}