/* TYPICAL */
.page-section {
	display: block;
	margin: 0 0 1.2em 0;
	position: relative;
}

.mobile-gallery {
	display: none;
}

.prod-grid-row {
	position: relative;
}

.typical-header-wrap {
	padding: 3em 0 0 0;
}

.typical-header-wrap .product-breadcrumbs-wrap a {
	font-size: 0.7em;
}

.page-main-title {
	line-height: 1em;
	max-width: 10em;
	padding: 0.7em 0 0.3em;
}

.support-tips-wrap {
	align-items: flex-start;
	display: flex;
	gap: 2.67%;
	justify-content: space-between;
	position: relative;
}

.support-tip-item {
	display: inline-block;
	flex: 1 1 23%;
	position: relative;
}

.support-tip-icon {
	display: block;
	left: 0;
	position: absolute;
	top: 0;
}

.support-tip-icon.battery-icon {
	top: 0.3em;
}

.support-tip-icon .material-symbols-rounded {
	font-size: 2.2em;
}

.support-tip-icon.battery-icon .material-symbols-rounded {
	font-size: 1.8em;
	transform: rotate(-180deg);
}

.support-tip-content {
	display: block;
	padding: 3.5em 0 0.8em;
	position: relative;
}

.support-tip-title {
	color: #1f1f1f;
	display: block;
	font-size: 0.9rem;
	font-weight: 700;
	line-height: 1.2em;
	padding-bottom: 0.2em;
	position: relative;
}

.support-tip-desc {
	color: #757575;
	display: block;
	font-size: 0.9em;
	line-height: 1.45em;
	position: relative;
}

.table-layout-wrap {
	border-spacing: 0 0.4em;
	display: table;
	padding: 4.95em 0.7em 0 0;
	position: relative;
	vertical-align: middle;
}

.prod-grid-header,
.cart-grid-header,
.cart-grid-row {
	align-items: stretch;
	display: flex;
	justify-content: space-between;
	position: relative;
	width: 100%;
}

.prod-grid-row {
	display: table-row;
}

.typical-products--coll {
	color: #757575;
	display: table-cell;
	padding: 0 1.3em 0 0;
	vertical-align: middle;
}

.typical-products--coll .cart-grid-col:nth-child(1) {
	max-width: 10em;
}

.prod-grid-header {
	padding-bottom: 0.8em;
}

.prod-grid-header .prod-grid-col {
	flex: 1 1 auto;
}

.prod-grid-header .prod-grid-col.prod-desc {
	flex: 0 1 100%;
	margin-left: 4.7em;
	max-width: 29rem;
}

.prod-grid-row {
	border: 1px solid #1f1f1f;
	border-radius: 0.3em;
	box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
	justify-content: space-between;
	margin-bottom: 0.4em;
	opacity: 0.4;
}

.prod-grid-row.checked {
	opacity: 1;
}

.prod-grid-col.prod-unit-price {
	margin-left: 0;
}

.prod-grid-col.icon-wrap {
	justify-content: center;
}

.prod-grid-select-wrap {
	margin: 0 1.5em 0 1.5em;
}

.prod-grid-image-wrap {
	border-radius: 6px 0px 0px 6px;
	height: auto;
	min-width: 6.4em;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.prod-grid-image-wrap::before,
.prod-grid-image-wrap:before {
	content: "";
	display: block;
	padding: 102.89% 0 0 0;
	position: relative;
}

.prod-grid-image-wrap img {
	display: block;
	height: 100%;
	left: 0;
	object-fit: cover;
	object-position: center;
	position: absolute;
	top: 0;
	width: 100%;
}

.prod-grid-icon {
	align-items: center;
	border: 1px solid #1f1f1f;
	border-radius: 100%;
	display: flex;
	height: 1.8rem;
	justify-content: center;
	position: relative;
	width: 1.8rem;
}

.prod-grid-icon .material-symbols-rounded {
	font-size: 1rem;
}

.prod-grid-action-wrap {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	width: 3.9rem;
}

.checked .prod-grid-icon {
	background: #1f1f1f;
	color: #fff;
}

.square-default-cta {
	border: 1px solid #1f1f1f;
	border-radius: 0.3em;
	color: #1f1f1f;
	display: inline-block;
	font-size: 0.8rem;
	line-height: 1.1em;
	padding: 0.9em 3em 0.8em 1em;
	position: relative;
	text-align: center;
	text-decoration: none;
	vertical-align: top;
}

.square-default-cta::after,
.square-default-cta:after {
	color: #1f1f1f;
	content: "\e5c8";
	display: block;
	font-family: "Material Symbols Rounded";
	font-size: 1.6em;
	font-variation-settings: "FILL" 0, "wght" 200, "GRAD" 0, "opsz" 48;
	position: absolute;
	right: 0.7em;
	top: 0.6em;
}

.prod-pricing-label-wrap {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-right: 6em;
	position: relative;
	width: 11.05rem;
}

.prod-pricing-label {
	font-size: 0.8rem;
}

.pricing-total-wrap {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	margin-right: 0;
	padding: 0;
	position: relative;
	width: 11.5rem;
}

.prod-grid-total {
	color: #1f1f1f;
	display: block;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.1em;
	position: relative;
}

.typical-prod-desc .prod-desc-title {
	line-height: 1em;
	padding-bottom: 0.35em;
}

.prod-desc-color,
.cart-desc-color {
	font-weight: 500;
	padding-bottom: 0;
}

.prod-desc-color span,
.cart-desc-color span {
	position: relative;
}

.prod-desc-color span.prod-desc-color-label,
.prod-desc-color-label,
.cart-desc-color span.prod-desc-color-label {
	display: none;
}

.prod-desc-color span::after,
.prod-desc-color span:after,
.cart-desc-color span::after,
.cart-desc-color span:after {
	border-radius: 100%;
	content: "";
	display: inline-block;
	height: 1rem;
	position: absolute;
	right: -1.4rem;
	top: 0;
	width: 1rem;
}

.prod-desc-color span.teal-bak::after,
.prod-desc-color span.teal-bak:after,
.cart-desc-color span.teal-bak::after,
.cart-desc-color span.teal-back:after {
	background: #49a5b1;
}

.prod-desc-color span.lime-bak::after,
.prod-desc-color span.lime-bak:after,
.cart-desc-color span.lime-bak::after,
.cart-desc-color span.lime-bak:after {
	background: #cfdb54;
}

.prod-desc-color span.orange-bak::after,
.prod-desc-color span.orange-bak:after,
.cart-desc-color span.orange-bak::after,
.cart-desc-color span.orange-bak:after {
	background: #db6827;
}

.prod-desc-color span.lt-gray-bak::after,
.prod-desc-color span.lt-gray-bak:after,
.cart-desc-color span.lt-gray-bak::after,
.cart-desc-color span.lt-gray-bak:after {
	background: #d4c4b9;
}
	.prod-desc-color .color-icons {
		height: 1rem;
		margin: -0.05em 0 0 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 1rem;
	}

	.subtotal-wrap {
		align-items: flex-end;
		display: flex;
		justify-content: flex-end;
		padding: 0 0.7em 0 0;
		position: relative;
	}

.subtotal-inner {
	display: block;
	max-width: 16.65em;
	padding-top: 0.8em;
	position: relative;
	width: 100%;
}

.mobile-price-label,
.mobile-unit-label,
.mobile-label {
	display: none;
}

.subtotal-cart-wrap {
	display: block;
	position: relative;
	width: 100%;
}

.subtotal-cart-item {
	border-bottom: 1px solid #c4c4c4;
	display: block;
	padding: 2.4em 0 0.3em;
	position: relative;
}

.subtotal-cart-item.subtotal-buttons-wrap {
	border: none;
}

.subtotal-buttons-wrap {
	padding: 1.2em 0;
}

.subtotal-cart-row {
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	padding-bottom: 1em;
	position: relative;
}

.subtotal-cart-title {
	color: #1f1f1f;
	display: inline-block;
	font-size: 1em;
	line-height: 1.1em;
	position: relative;
	vertical-align: top;
}

.subtotal-cart-desc {
	color: #1f1f1f;
	display: inline-block;
	font-size: 1em;
	font-weight: 700;
	line-height: 1.1em;
	position: relative;
	vertical-align: top;
}

.subtotal-simple-link {
	color: #1f1f1f;
	display: block;
	font-size: 0.8em;
	line-height: 1.1em;
	margin-right: 0;
	position: relative;
	text-align: right;
	text-decoration: underline;
}

.subtotal-simple-link:hover,
.subtotal-simple-link:focus-visible {
	text-decoration: none;
}

.add-cart-cta:hover,
.add-cart-cta:focus-visible {
	background: #fff;
	color: #1f1f1f;
}

.wishlist-cta {
	border: 1px solid #c4c4c4;
	border-radius: 0.3em;
	color: #757575;
	display: inline-block;
	font-size: 1em;
	line-height: 1.1em;
	margin-top: 0.4em;
	padding: 0.75em 1em 0.864em 1em;
	position: relative;
	text-align: center;
	text-decoration: none;
	vertical-align: top;
	width: 100%;
}

.wishlist-cta:hover,
.wishlist-cta:focus-visible {
	background: #1f1f1f;
	border: 1px solid #1f1f1f;
	color: #fff;
}

.wishlist-cta span {
	display: inline-block;
	vertical-align: middle;
}

.wishlist-cta span::after,
.wishlist-cta span:after {
	color: #757575;
	content: "\e87d";
	display: inline-block;
	font-family: "Material Symbols Rounded";
	font-size: 1.6em;
	font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;
	line-height: 0;
	position: relative;
	right: -0.2rem;
	top: 0.5rem;
}

.room-slider-nav {
	display: block;
	height: 17em;
	pointer-events: none;
	position: absolute;
	top: 12em;
	width: 100%;
	z-index: 7;
}

.room-slider-wrap .slick-arrow,
.bought-together-inner .slick-arrow {
	background: #fff;
	border: none;
	border-radius: 50%;
	bottom: 0.4rem;
	box-shadow: 0.1rem 0.1rem 0.6rem rgb(0 0 0 / 15%);
	cursor: pointer;
	display: block;
	font-size: 0;
	height: 3.1rem;
	margin: -1.225rem 0 0 0;
	position: absolute;
	right: 3.25rem;
	top: auto;
	width: 3.1rem;
	z-index: 2;
}

.room-slider-wrap .slick-arrow:hover,
.room-slider-wrap .slick-arrow:focus-visible {
	background: #f5f5f5;
}

.bought-together-inner .slick-arrow {
	background: #f5f5f5;
	bottom: 6rem;
	box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
	right: -4.6rem;
}

.bought-together-inner .slick-arrow:hover,
.bought-together-inner .slick-arrow:focus-visible {
	background: #fff;
}

.room-slider-wrap .slick-arrow::after,
.room-slider-wrap .slick-arrow:after,
.bought-together-inner .slick-arrow::after,
.bought-together-inner .slick-arrow:after {
	bottom: 0;
	color: #1f1f1f;
	content: "\e5c4";
	display: block;
	font-family: "Material Symbols Rounded";
	font-size: 1.2rem;
	height: 1em;
	left: 0;
	line-height: 1em;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 1em;
}

.room-slider-wrap .slick-next::after,
.room-slider-wrap .slick-next:after,
.bought-together-inner .slick-next::after,
.bought-together-inner .slick-next:after {
	content: "\e5c8";
}

.room-slider-wrap .slick-prev,
.bought-together-inner .slick-prev {
	left: auto;
	right: 6.9rem;
}

.bought-together-inner .slick-prev {
	right: -1rem;
}

.room-slider-wrap {
	display: block;
	position: relative;
	width: calc(((100vw - 100%) / 2) + 100%);
}

.room-slider-wrap.slick-slider .slick-track {
	align-items: stretch;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	gap: 0.95em;
}

.room-tile {
	display: inline-block;
	max-width: 48.55em;
	overflow: visible;
	padding-right: 0.95em;
	position: relative;
	text-decoration: none;
	transition: 0.25s;
	vertical-align: top;
	white-space: normal;
}

.room-tile-inner {
	display: block;
	height: 100%;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.room-tile-image-wrap {
	border-radius: 0.4em;
	display: block;
	height: 17.75rem;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.room-slider-wrap .slick-slide {
	position:relative;
	width: auto;
}

.room-tile-image-wrap img {
	display: block;
	height: 100%;
	left: 0;
	object-fit: cover;
	object-position: center;
	/*position: absolute;*/
	top: 0;
	width: 100%;
}

.product-overlay-wrap {
	display: none;
}

.prod-grid-col.prod-desc {
	max-width: 28em;
	position: relative;
	width: 100%;
}

.typical-products--coll:nth-child(2) {
	border-bottom: 1px solid #1f1f1f;
	border-top: 1px solid #1f1f1f;
}

.typical-products--coll:nth-child(1) {
	border-bottom: 1px solid #1f1f1f;
	border-left: 1px solid #1f1f1f;
	border-radius: 0.3em 0 0 0.3em;
	border-top: 1px solid #1f1f1f;
	padding-top:0.25em;
	padding-left:0.25em;
}

.typical-products--coll:nth-child(4) {
	border-bottom: 1px solid #1f1f1f;
	border-top: 1px solid #1f1f1f;
}

.typical-products--coll:nth-child(3) {
	border-bottom: 1px solid #1f1f1f;
	border-top: 1px solid #1f1f1f;
}

.typical-products--coll:nth-child(5) {
	border-bottom: 1px solid #1f1f1f;
	border-top: 1px solid #1f1f1f;
}

.typical-products--coll:nth-child(6) {
	border-bottom: 1px solid #1f1f1f;
	border-top: 1px solid #1f1f1f;
}

.typical-products--coll:nth-child(7) {
	border-bottom: 1px solid #1f1f1f;
	border-radius: 0 0.3em 0.3em 0;
	border-right: 1px solid #1f1f1f;
	border-top: 1px solid #1f1f1f;
}

.prod-grid-button-wrap .wishlist-cell {
	margin-top: 0;
}

.typical-products--coll .prod-grid-col.prod-cta-wrap {
	margin: 0 2.5em 0 1.2em;
	width: max-content;
}

.prod-grid-col.prod-unit-price {
	min-width: 6.85em;
	width: 100%;
}

.prod-grid-col.prod-total-price {
	min-width: 7.15em;
	width: 100%;
}

.typical-products--coll .cart-grid-select-wrap {
	margin: 0 2.5em 0 0;
}

.table-layout-wrap .my-product-title-coll:nth-child(2) {
	padding-left: 0;
}

.my-product-title-coll:nth-child(4) {
	padding-left: 1em;
}

.my-product-title-coll:nth-child(5) {
	padding-left: 1.75em;
}

.table-layout-wrap .my-product-title-coll {
	padding-bottom: 0.3em;
}

.typical-table {
	margin-top: 1em;
}

.typical-subtotal-wrap .subtotal-cart-item {
	margin-right: 0;
}
.typical-page .page-section, .typical-page .typical-header-wrap {
	padding: 0
}
.typical-layout-product .typical-table-layout-wrap {
	width:100%;
}
.typical-layout-product .typical-table-layout-wrap .prod-grid-image-wrap img{
	left:0;
	bottom:0;
	object-fit:contain;
}
.typical-layout-product .my-product-title-coll.product-quantity {
	padding-left: 0;
}
.typical-layout-product .prod-desc-color:has(label.color-select) {
	margin-bottom: 0.25rem;
}

.slider-section .lightbox {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index:991;
}

.slider-section .lightbox-content {
	height:auto;
	max-width: 90%;
	max-height: 80%;
}

.slider-section .close {
	position: absolute;
	top: 1em;
	right: 1em;
	color: white;
	font-size: 1.3em;
	cursor: pointer;
	background: #666;
	width: 1.2em;
	height: 1.2em;
	border-radius: 50%;
	line-height: 1.2;
	text-align: center;
}

@media screen and (max-width: 1500px), print {
	.typical-products--coll .prod-grid-col.prod-unit-price {
		margin-left: 0;
		min-width: 2.85em;
	}

	.typical-products--coll .prod-grid-col.prod-cta-wrap {
		margin: 0 1.6em 0 1.2em;
	}

	.prod-grid-image-wrap {
		min-width: 5.5em;
	}

	.typical-products--coll .cart-grid-select-wrap {
		margin: 0 1.8em 0 1.8em;
	}

	.table-layout-wrap .my-product-title-coll:nth-child(3) {
		padding-left: 2.55em;
	}

	.typical-subtotal-wrap .subtotal-cart-item {
		padding: 1.8em 0 0.3em;
	}

	.prod-grid-image-wrap::before,
	.prod-grid-image-wrap:before {
		padding: 104.19% 0 0 0;
	}

	.typical-products--coll .prod-grid-col.prod-desc {
		max-width: 35.1em;
		padding-right: 1.2em;
	}

	.typical-products--coll .prod-grid-col.prod-total-price {
		min-width: 9.65em;
		width: 100%;
	}

	.typical-page .default-center {
		padding: 0 2em;
	}

	.typical-products--coll .cart-grid-select-wrap {
		margin: 0 1.1em 0 1.1em;
	}

	.typical-products--coll .prod-grid-col.prod-total-price {
		min-width: 6.95em;
		width: 100%;
	}

	.typical-products--coll .prod-grid-button-wrap {
		margin: 0 0.4em 0 0.6em;
	}

	.typical-products--coll .prod-grid-col.prod-cta-wrap {
		margin: 0 0.8em 0 1.2em;
	}

	.prod-grid-image-wrap::before,
	.prod-grid-image-wrap:before {
		padding: 103.5% 0 0 0;
	}

	.typical-table-layout-wrap {
		width: 100%;
	}

	.subtotal-inner {
		max-width: 16.8em;
	}
}


@media screen and (max-width: 1366px) {
	.typical-products--coll {
		color: #757575;
		display: table-cell;
		padding: 0 0.8em 0 0;
	}

	.prod-grid-image-wrap {
		min-width: 6.5em;
	}

	.prod-grid-image-wrap::before,
	.prod-grid-image-wrap:before {
		padding: 107.6% 0 0 0;
	}
}


@media screen and (max-width: 1200px), print {
	.room-tile-image-wrap{
		height:15rem
	}
	.typical-products--coll .prod-grid-col.prod-cta-wrap {
		margin: 0 0.7em 0 0.8em;
		width: max-content;
	}

	.prod-grid-button-wrap {
		margin: 0 0.5em 0 0.5em;
	}

	.prod-grid-col.prod-unit-price {
		min-width: 3.75em;
		width: 100%;
	}

	.typical-products--coll .cart-grid-select {
		height: 2.3rem;
		min-width: 4.2em;
	}

	.typical-products--coll .cart-select-wrapper {
		min-width: 3.1em;
	}

	.typical-products--coll .cart-grid-select-wrap {
		min-width: 3.1em;
	}

	.typical-products--coll .cart-grid-select-wrap {
		margin: 0 0.5em 0 0;
	}

	.prod-grid-col.prod-unit-price {
		margin-left: 0.4em;
	}

	.prod-grid-col.prod-total-price {
		min-width: 5.55em;
		width: 100%;
	}

	.prod-grid-image-wrap {
		min-width: 6em;
	}

	.prod-grid-image-wrap::before,
	.prod-grid-image-wrap:before {
		padding: 104.19% 0 0 0;
	}

	.my-product-title-row {
		display: contents;
	}

	.table-layout-wrap .my-product-title-coll:nth-child(3) {
		padding-left: 0;
	}

	.prod-grid-action-wrap {
		padding: 0 1.5em 0 0.75em;
		width: 2rem;
	}

	.prod-grid-icon {
		height: 1.3rem;
		width: 1.3rem;
	}

	.prod-grid-action-wrap {
		width: 2rem;
	}

	.typical-table-layout-wrap .my-product-title-row {
		display: table-row;
	}
	.typical-products--coll:nth-child(6) .subtotal-cart-item.subtotal-buttons-wrap{
		margin-right:0;
	}
}

@media screen and (max-width: 1024px), print {
	.prod-grid-button-wrap {
		margin: 0 0.4em 0 0.7em;
	}

	.prod-grid-action-wrap {
		padding: 0 0.7em 0 0.65em;
		width: 2.5rem;
	}

	.prod-grid-col.prod-unit-price {
		margin-left: 0.8em;
	}

	.typical-products--coll .prod-grid-col.prod-total-price {
		min-width: 0.55em;
		width: 100%;
	}

	.cart-grid-select {
		background-position: right 4% center;
		font-size: 0.8rem;
		height: 1.8rem;
		min-width: 4.375em;
	}

	.cart-select-wrapper.arrow-button:after {
		font-size: 1em;
	}

	.prod-grid-button-wrap {
		margin: 0 0.1em 0 0.2em;
	}

	.prod-grid-button-wrap {
		margin: 0 0.4em 0 0.1em;
	}

	.typical-products--coll .prod-grid-col.prod-cta-wrap {
		margin: 0;
	}

	.typical-products--coll .prod-grid-col.prod-desc {
		padding-right: 0;
	}
	.typical-products--coll:nth-child(6) .subtotal-cart-item.subtotal-buttons-wrap .add-cart-cta{
		font-size:0.8em;
		padding:0.6em;
	}

}

@media screen and (max-width: 1000px), print {
	.typical-products--coll {
		padding: 0 0.6em 0 0;
	}

	.typical-products--coll .prod-grid-action-wrap {
		padding: 0 1.5em 0 0.75em;
		width: 2.4rem;
	}

	.typical-products--coll .prod-grid-button-wrap {
		margin: 0 0.1em 0 0.2em;
	}
	.my-product-title-coll:nth-child(5) {
		padding-left: 0.65em;
	}
	.my-product-title-coll:nth-child(4) {
		padding-left: 0.3em;
	}
}

@media screen and (max-width: 900px) {
	.typical-page .typical-table-layout-wrap {
		padding-right:0rem;
	}
	.typical-page .subtotal-inner {
		width: 100%;
		max-width:100%;
	}
	.typical-page .typical-add-button {
		max-width: fit-content;
	}
	.typical-page .typical-subtotal-wrap .subtotal-cart-item.subtotal-buttons-wrap {
		text-align: right;
	}
	.typical-page .prod-grid-row {
		max-width: 100%;
		margin-bottom: 0.75rem;
	}
	.room-tile-image-wrap {
		height: 12rem
	}
	.table-layout-wrap {
		display: block;
		position: relative;
		width: 100%;
	}

	.my-product-title-row {
		display: none;
	}

	.typical-products--coll:nth-child(1) {
		border: 0;
	}

	.typical-products--coll:nth-child(2) {
		border: 0;
	}

	.typical-products--coll:nth-child(3) {
		border: 0;
	}

	.typical-products--coll:nth-child(4) {
		border: 0;
	}

	.typical-products--coll:nth-child(5) {
		border: 0;
	}

	.typical-products--coll:nth-child(6) {
		border: 0;
	}

	.typical-products--coll:nth-child(7) {
		border: 0;
	}

	.prod-grid-row {
		display: flex;
	}

	.typical-products--coll {
		color: #757575;
		display: inline-flex;
	}

	.typical-products--coll .prod-grid-image-wrap,
	.typical-products--coll .cart-grid-select-wrap {
		display: block;
		left: 0.05em;
		min-width: unset;
		position: absolute;
		top: 0;
		width: 4.5em;
	}

	.typical-products--coll .cart-select-wrapper {
		left: 0.7em;
		min-width: 3.1em;
		position: relative;
		top: 5.4em;
	}

	.typical-products--coll .cart-grid-select {
		height: 1.9rem;
	}

	.typical-products--coll.cart-img {
		display: inline-block;
		left: 0.65em;
		position: absolute;
		top: 0.65em;
	}

	.typical-products--coll.typical-prod-grid-col {
		flex: 6;
		margin-left: 8.2em;
		position: relative;
	}

	.cart-total-wrap.mobile-gallery.typical-products--coll {
		padding: 1.3em 0 0 1.6em;
		text-align: end;
	}

	.typical-cart-total-wrap {
		padding: 0.8em 0 0 0;
	}

	.typical-cart-total-wrap .cart-grid-inner p.cart-grid-total {
		padding: 0;
	}

	.cart-total-wrap.mobile-gallery.typical-cart-total-wrap {
		margin-left: 5.9em;
		max-width: 20em;
		padding: 1.2em 0 0 0;
		width: 100%;
	}

	.typical-cart-total-wrap.cart-total-wrap .cart-datawrap {
		padding: 0;
	}

	.typical-cart-total-wrap .cart-grid-total-price-wrap .cart-grid-inner {
		text-align: -webkit-auto;
	}

	.typical-cart-total-wrap .cart-grid-unit-price-wrap {
		margin-left: 0.85em;
	}

	.table-layout-wrap.typical-table-layout-wrap {
		padding-top: 1em;
	}

	.typical-products--coll {
		padding: 0 1.6em 0 0;
	}

	.typical-subtotal-wrap .subtotal-cart-item {
		max-width: 100%;
		width: 100%;
	}

	.room-tile {
		padding: 0 0.15em;
	}
	.typical-table-layout-wrap .my-product-title-row {
		display: none;
	}
	.typical-page .default-center{
		padding:0 1rem;
	}

	.typical-layout-product .typical-table-layout-wrap .typical-products--coll .cart-grid-select-wrap{
		position:absolute;
		top:5.9rem;
		left:0.7rem;
	}
	.typical-layout-product .typical-table-layout-wrap .typical-products--coll.cart-img {
		position: unset;
		padding-top: 0;
		padding-left: 0;
	}
	.typical-layout-product .typical-table-layout-wrap .typical-products--coll .prod-grid-image-wrap{
		position:relative;
		max-width:5rem;
	}

	.typical-layout-product .typical-table-layout-wrap .typical-products--coll .cart-select-wrapper {
		top:unset;
		left:0;
	}
	.typical-layout-product .typical-table-layout-wrap .typical-products--coll:nth-child(6) {
		padding-right: 0;
		order: 7;
		text-align: center;
		display: flex;
		justify-content: flex-start;
		width: 100%;
		margin-left:7.9rem;
	}
	.typical-layout-product .typical-table-layout-wrap .typical-products--coll:nth-child(6) .subtotal-cart-item.subtotal-buttons-wrap {
		margin: 0;
	}
	.typical-layout-product .typical-table-layout-wrap .typical-products--coll .prod-grid-price-wrap,
	.typical-layout-product .typical-table-layout-wrap .typical-products--coll .prod-grid-total-wrap {
		padding: 0
	}
	.typical-layout-product .typical-table-layout-wrap .typical-products--coll .prod-grid-col.prod-desc {
		margin-left: 0.5rem;
	}
	.typical-layout-product .typical-table-layout-wrap .typical-products--coll:nth-child(2) {
		width: calc(100% - 150px);
	}
	.typical-layout-product .typical-table-layout-wrap .typical-products--coll:nth-child(4),
	.typical-layout-product .typical-table-layout-wrap .typical-products--coll:nth-child(5) {
		display: none;
	}
	.typical-layout-product .typical-table-layout-wrap .cart-total-wrap.mobile-gallery.typical-cart-total-wrap {
		display: block;
		margin-left:7.9rem;
		max-width:unset;
	}
	.typical-layout-product .typical-table-layout-wrap .cart-total-wrap.mobile-gallery.typical-cart-total-wrap.cart-total-wrap .cart-datawrap{
		justify-content:flex-start;
	}
	.typical-layout-product .typical-table-layout-wrap .cart-grid-total-price-wrap, .cart-grid-unit-price-wrap {
		width: 5rem;
	}
	.typical-layout-product .typical-table-layout-wrap .cart-total-wrap .mobile-price-label {
		width: fit-content;
		text-align: right;
	}
	.typical-page .room-slider-wrap .slick-arrow {
		height:2.1rem;
		width:2.1rem;
	}
	.typical-page .room-slider-wrap .slick-next {
		right: 1rem;
	}
	.typical-page .room-slider-wrap .slick-arrow.slick-prev {
		right: 3.9rem;
	}
}

@media screen and (max-width: 600px) {
	.room-tile-image-wrap {
		height: 18rem;
	}
	.subtotal-inner {
		padding-top: 0.4em;
	}

	.typical-subtotal-wrap .subtotal-cart-item {
		padding: 1.8em 0 0.55em;
	}

	.prod-grid-col.prod-desc {
		flex: 6;
		margin-left: 9.7em;
	}

	.prod-grid-col.prod-desc.typical-prod-desc {
		margin-left: 7.3em;
	}

	.cart-total-wrap.mobile-gallery.typical-cart-total-wrap {
		margin-left: 5.6em;
	}

	.cart-total-wrap.mobile-gallery.typical-cart-total-wrap {
		padding: 0.9em 0 0 0;
	}

	.subtotal-cart-item.subtotal-buttons-wrap {
		display: block;
		margin: auto;
		/* max-width: 16.65em; */
		padding: 0.9em 0 0 0;
		position: relative;
		width: 100%;
	}

	.subtotal-wrap {
		padding: 0.3em 0 1em;
	}

	.subtotal-cart-item.subtotal-buttons-wrap {
		padding: 0.9em 0 0 0;
	}

	.page-main-title {
		padding: 0.4em 0 0.6em;
	}
	.typical-layout-product .typical-table-layout-wrap .typical-products--coll:nth-child(2) {
		width: calc(100% - 5.5rem);
	}
	.typical-layout-product .typical-table-layout-wrap .typical-products--coll {
		padding-right:0.8rem;
		padding-left:0;
	}
	.typical-layout-product .typical-table-layout-wrap .cart-total-wrap.mobile-gallery.typical-cart-total-wrap{
		margin-left:5.6rem;
	}
	.typical-layout-product .typical-table-layout-wrap .typical-products--coll:nth-child(6){
		margin-left:0;
		max-width:100%;
	}
	.typical-layout-product .typical-table-layout-wrap .typical-products--coll:nth-child(6) .subtotal-cart-item.subtotal-buttons-wrap{
       max-width:fit-content;
	   margin-left:5.6rem;
	}
	.typical-layout-product .typical-cart-total-wrap .cart-grid-unit-price-wrap {
		margin-left: 0;
	}
	.typical-layout-product .cart-grid-unit-price-wrap .cart-grid-inner {
		width: fit-content;
	}
	.typical-layout-product .typical-table-layout-wrap .cart-grid-total-price-wrap, 
	.typical-layout-product .typical-table-layout-wrap .cart-grid-unit-price-wrap {
		width: fit-content;
		max-width: fit-content;
		min-width:fit-content;
	}
	.typical-page .default-center {
		padding: 0 0.8rem;
	}
	.typical-layout-product .typical-table-layout-wrap .typical-products--coll .prod-grid-col.prod-desc{
		margin-left:0;
	}
	.typical-layout-product .typical-table-layout-wrap .cart-total-wrap .cart-datawrap {
		grid-column-gap:1.35rem;
	}
}

/* Print media Style */

@media print {
	.default-outer-wrapper {
		max-height: inherit;
		height: auto;
		min-height: auto;
	}
	.typical-page .default-center,
	.table-layout-wrap,
	.support-tip-content,
	.typical-layout-main-wrapper .product-breadcrumbs-wrap, .typical-page .product-breadcrumbs-wrap {
		padding: 0;
		font-size: 16px;
	}
	.prod-grid-total,
	.prod-desc-title {
		font-size: 0.8rem;
	}
	.prod-grid-row {
		box-shadow: none;
		padding: 1em 0;
		border: 0;
	}
	.prod-grid-col {
		margin: 1em 0;
	}
	.color-dots.large.color-select::after, 
  .color-dots.large.color-select:after {
      background-color: transparent !important;
			height: 2.3rem;
			width: 2.3rem;
  }
}