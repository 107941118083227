/* NAV */
header .nav-wrap.docked {
    background: #fff;
}

.banner-visible .nav-wrap.docked {
    margin-top: 2.2em;
}

.banner-visible .nav-wrap {
    margin-top: 2.2em;
}

.banner-visible {
    display: block;
    position: relative;
    margin-bottom: 2.35em;
}
/* NAV */
/* PRODUCT DETAIL */
.color-dots-img-wrap {
    border-radius: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.color-dots.large {
    cursor: pointer;
    height: 2.8rem;
    margin-bottom: 0.5em;
    overflow: visible;
    width: 2.8rem;
    position: relative
}

    .color-dots.large .color-dots.large {
        height: 2.7rem;
        width: 2.7rem;
    }
/*.color-dots.large img{
    bottom: 0;
    display:block;
    height: 100%;
    left: 0;
    object-fit:contain;
    position: absolute;
    right: 0;
    top: 0;
    width:100%;
}
*/
.product-header-wrap {
    display: block;
    padding: 2em 0 0 0;
    position: relative;
}

.product-detail-inner {
    display: flex; /*justify-content: space-between;*/
    gap: 7.95em;
    margin: 0 auto;
    max-width: 80em;
    padding: 0;
    position: relative;
    width: 100%;
}

/*.product-detail-left { display: inline-block; position: relative; max-width: 43.4em; margin: 0; width: 70%;*/ /*width: 100%;*/ /*}*/

.product-detail-right {
    display: inline-block;
    padding: 3.45em 0 0 0;
    width: 100%;
    max-width: 22.5em;
    position: relative; /*width: calc(100% - 48.75em);*/
}

.prod-detail-content-wrap {
    display: block;
    max-width: 22.4em;
    padding-bottom: 4.2em;
    position: relative;
    width: 100%;
}

.pro-phipping-wrap {
    padding-bottom: 1.5em;
}

.prod-detail-content.collapsed,
.prod-detail-content.expanded {
    border-bottom: 1px solid #e3e3e3;
    display: block;
    position: relative;
    transition: all .25s ease-in-out;
    width: 100%;
}
/*.prod-detail-content.collapsed {
    padding: 0 0.8em 0.672em;
}*/
.prod-detail-content.expanded {
    /*height: 100%;*/
    transition: .25s ease-in-out;
}

.prod-detail-content-trigger {
    color: #1f1f1f;
    cursor: pointer;
    display: block;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1.2em;
    padding: 1.05em 3em 1.05em 0;
    /*padding: 1.25em 3em 1.05em 0;*/
    /*padding: 1.25em 3em .65em 0;*/
    position: relative;
    text-decoration: none;
}

    .prod-detail-content-trigger::before,
    .prod-detail-content-trigger:before {
        color: #1c1b1f;
        content: "\e5cf";
        display: block;
        font-family: "Material Symbols Rounded";
        font-size: 1.7em;
        font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
        height: auto;
        line-height: 1em;
        margin: -0.5em 0 0 0;
        position: absolute;
        transition: 0.25s;
        transform-origin: center;
        right: 0.15rem;
        top: 51.5%;
    }

.expanded .prod-detail-content-trigger::before,
.expanded .prod-detail-content-trigger:before {
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
    top: 49.5%;
}

.prod-detail-content-collapse-wrap {
    display: block;
    /*font-size: 0;*/
    height: 0;
    line-height: 2.5em;
    opacity: 0;
    overflow: hidden;
    position: relative;
    transition: .25s;
}

.expanded .prod-detail-content-collapse-wrap {
    height: 100%;
    font-size: 1em;
    opacity: 1;
    transition: .25s ease-in-out;
}

.prod-detail-content-collapse {
    display: block;
    padding: 0;
    position: relative;
}

.expanded .prod-detail-content-collapse {
    font-size: 1em;
    padding: 0 0 1em;
    transition: all .25s ease-in-out;
}

.prod-detail-content {
    display: block;
    padding-top: 0;
    visibility: visible;
    
}

    .prod-detail-content p {
        color: #757575;
        display: block;
        line-height: 1.2em;
        position: relative;
    }

.prod-shipping-est-title {
    color: #1f1f1f;
    display: block;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1.4em;
    position: relative;
}

.prod-shipping-wrap {
    align-items: stretch;
    display: flex;
    gap: 0.5em;
    justify-content: space-between;
    padding-top: 0.7em;
    position: relative;
    width: 100%;
}

.product-detail-price-button-wrap {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 1.5em;
}

.product-power-wrap {
    display: block;
    position: relative;
    padding: 0.8em 0 0 0;
    text-align: end;
    width: 100%;
}

.color-dots.add {
    background: #fff;
}

.prod-detail-cta {
    background: #1f1f1f;
    border: 1px solid #1f1f1f;
    border-radius: 0.3em;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.2em;
    padding: 0.8em 2.5em 0.8em;
    position: relative;
    transition: .3s ease-in-out;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    width: 100%;
}

    .prod-detail-cta:hover {
        border-color: #C3C3C3;
        background: #fff;
        color: #757575;
    }

    .prod-detail-cta.wislist-button {
    }

    .prod-detail-cta.power,
    .prod-detail-cta.wislist-button {
        background: transparent;
        border: 1px solid #c4c4c4;
        color: #1f1f1f;
        transition: .3s ease-in-out;
    }

.add-my-product-wrapper {
    display: inline-block;
    position: relative;
    width: 100%;
}

.prod-detail-cta.power:hover,
.prod-detail-cta.wislist-button:hover {
    background: #1f1f1f;
    border: 1px solid #1f1f1f;
    color: #fff;
}

.prod-detail-cta.power {
    background: #1f1f1f;
    border: 1px solid #1f1f1f;
    color: #fff;
    cursor: pointer;
}

.bought-together-outer {
    display: block;
    padding-top: 8em;
    padding-bottom: 9em; /*Not matching mockup with this*/
    position: relative;
    width: 100%;
}

.bought-together-title {
    color: #1f1f1f;
    display: block;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 0.8em;
    padding-bottom: 0.15em;
    position: relative;
}

.bought-together-inner { /*min-width: 110vw;*/
    width: calc(((100vw - 100%) / 2) + 90%);
}

    .bought-together-inner .slick-list {
        display: block;
        overflow: visible;
        position: relative;
        width: 100%;
    }

    .bought-together-inner .slick-track {
        align-items: stretch;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 1em;
    }

.bought-together-tile {
    color: #1e1e1e;
    display: inline-block;
    padding: 0.8em 0 0.5em 0;
    position: relative;
    text-decoration: none;
    transition: 0.25s;
    vertical-align: top;
    max-width: 18.35em;
    width: 100%;
}

    .bought-together-tile.slick-cloned {
        pointer-events: none;
    }

    .bought-together-tile.slick-slide {
        opacity: 0;
    }

    .bought-together-tile.slick-active,
    .bought-together-tile.slick-active + .bought-together-tile + .bought-together-tile {
        opacity: 1;
    }

.bought-together-tile-inner {
    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.bought-together-tile-image-wrap {
    border-radius: 0.3rem;
    display: block;
    height: 19.75rem;
    margin: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
}

    .bought-together-tile-image-wrap img {
        display: block;
        height: 100%;
        left: 0;
        object-fit: contain;
        position: absolute;
        top: 0;
        width: 100%;
    }

.bought-together-content {
    display: block;
    position: relative;
    padding-top: 0;
    width: 100%;
}

.bought-together-title-amount-wrap {
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    gap: 0.4em;
    position: relative;
    width: 100%;
}

.bought-together-tile-title {
    color: #1f1f1f;
    display: inline-block;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1.2em;
    padding: 0.5em 0 0.6em 0;
    position: relative;
    width: 100%;
}

.bought-together-amount {
    color: #1f1f1f;
    display: inline-block;
    font-size: 0.9em;
    line-height: 1.2em;
    padding: 0.5em 0.2em 0.6em 0;
    position: relative;
}

.product-credit-desc {
    color: #1f1f1f;
    display: block;
    font-size: 0.65em;
    line-height: 1.2em;
    max-width: 34.6em;
    padding-bottom: 0.9em;
    position: relative;
    width: 100%;
}

.product-detail-icon-wrap {
    border-radius: 100%;
    border: 1px solid #757575;
    display: inline-block;
    display: none;
    cursor: pointer;
    height: 1.9rem;
    position: absolute;
    right: 0;
    top: -1.1em;
    width: 1.9rem;
    z-index: 1;
}

/*.product-detail-icon-wrap.favorite {
    background: #757575;
    }*/
.product-detail-right .product-detail-icon-wrap::after,
.product-detail-right .product-detail-icon-wrap:after {
    color: #757575;
    content: "\e87d";
    display: inline-block;
    font-family: "Material Symbols Rounded";
    font-size: 1.7em;
    font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;
    line-height: 1.1em;
    position: absolute;
    right: 0.35em;
    top: 0.35em;
}

.product-detail-icon-wrap:hover::after,
.product-detail-icon-wrap:hover:after,
.product-detail-icon-wrap:focus-visible::after,
.product-detail-icon-wrap:focus-visible:after {
    color: #757575;
    font-variation-settings: "FILL" 1;
}

.product-detail-icon-wrap.favorite::after,
.product-detail-icon-wrap.favorite:after,
.product-detail-icon-wrap.favorite::after,
.product-detail-icon-wrap.favorite:after {
    color: #1f1f1f;
    font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 48;
}

.product-detail-title {
    color: #1f1f1f;
    display: block;
    font-size: 2.5em;
    font-weight: 700;
    line-height: 1.05em;
    max-width: 10em;
    padding-bottom: 0.2em;
    position: relative;
    width: 100%;
}

.product-detail-price-amount {
    color: #1f1f1f;
    display: inline-block;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.3em;
    position: relative;
    vertical-align: baseline;
}

.product-detail-list {
    display: block;
    margin: 1.4em 0 3.15em 0;
    position: relative;
    color: #757575;
    line-height: 1.2em;
    font-size: 0.8em;
}

    .product-detail-list li {
        color: #757575;
        display: block;
        font-size: 1em;
        line-height: 1.35em;
        max-width: 23em;
        padding: 0.47em 0 0.27em 1.5em;
        position: relative;
    }

        .product-detail-list li::before,
        .product-detail-list li:before {
            color: #757575;
            content: "\e876";
            display: block;
            font-family: "Material Symbols Rounded";
            font-size: 1.3em;
            font-weight: 400;
            left: -0.3em;
            line-height: 1em;
            position: absolute;
            top: 0.3em;
        }

.product-dimensions-wrap {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 0.7em;
    /* padding-bottom: 2.3em;*/
    padding-bottom: 1.4em;
    position: relative;
    width: 100%;
}

.prod-dim-inner {
    display: block;
    position: relative;
    width: 100%;
}

.product-dimension-item {
    display: inline-block;
    flex: 0 1 44.7%;
    position: relative;
}

.product-dimension-title {
    color: #1f1f1f;
    display: block;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1.2em;
    padding-bottom: 0.7em;
    position: relative;
}

.product-dimension-desc {
    color: #757575;
    display: block;
    font-size: 0.7em;
    line-height: 1.2em;
    position: relative;
}

.product-dimension-light {
    font-weight: 400;
}

.product-dimension-number-wrap {
    align-items: center;
    display: flex;
    gap: 0.35em;
    justify-content: flex-start;
    position: relative;
}

.product-dimension-number {
    border: 1px solid #919191;
    border-radius: 0.3em;
    color: #1f1f1f;
    cursor: pointer;
    display: inline-block;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1em;
    padding: 1em 0.38888888em;
    position: relative;
    vertical-align: middle;
    text-decoration: none;
    transition: 0.25s;
}

    .product-dimension-number.label span {
        display: block;
        text-align: center;
        min-width: 2.222222222em;
    }

    .product-dimension-number.selected {
        background: #1f1f1f;
        box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
        color: #fff;
    }

.product-detail-add-on-wrap {
    align-items: stretch;
    border: 1px solid #C3C3C3;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.9em;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    width: 100%;
}

    .product-detail-add-on-wrap.PeoductSelected {
        border: 1px solid #1f1f1f;
        box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
    }

.product-add-on-image-wrap { /*border-radius: 6px 0px 0px 6px;*/
    display: block;
    height: auto;
    overflow: hidden;
    position: relative;
    width: 5.9em;
}

    .product-add-on-image-wrap img {
        display: block;
        height: 100%;
        left: 0;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0;
        width: 100%;
    }

.product-add-on-content-wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 19em;
    padding: 0.55em 0.4em 0.67em;
    position: relative;
    width: 100%;
}

.product-add-on-content-wrap-withoutimage {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 23em;
    padding: 0.55em 0.4em 0.67em;
    position: relative;
    width: 100%;
}
.product-add-on-content {
    display: inline-block;
    flex: 1 1 100%;
    position: relative;
    vertical-align: middle;
}

    .product-add-on-content .product-dimension-title {
        line-height: 1.1em;
        padding-bottom: 0;
    }

    .product-add-on-content .product-dimension-desc {
        padding-bottom: 0.3em;
    }

.product-add-on-method {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.product-add-on-method-inner {
    background: #fff;
    border: 1px solid #575757;
    border-radius: 50%;
    color: #575757;
    display: inline-block;
    height: 1.35rem;
    margin: 0 0.5em 0 0.5em;
    overflow: hidden;
    position: relative;
    transition: 0.25s;
    vertical-align: middle;
    width: 1.35rem;
}

    /*.selected .product-add-on-method-inner { background: #1f1f1f; color: #fff; }*/

    .product-add-on-method-inner .material-symbols-rounded {
        bottom: 0;
        display: block;
        font-size: 0.9em;
        height: 1em;
        left: 0;
        line-height: 1em;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 1em;
    }

        .product-add-on-method-inner .material-symbols-rounded.dark {
            display: none;
        }

/*.selected .product-add-on-method-inner .material-symbols-rounded { display: none; }*/

.selected .product-add-on-method-inner .material-symbols-rounded.dark {
    /*background: #1f1f1f;*/
    color: #fff;
    display: block;
    z-index: 0;
}

    .selected .product-add-on-method-inner .material-symbols-rounded.dark::before,
    .selected .product-add-on-method-inner .material-symbols-rounded.dark:before {
        background: #1f1f1f;
        content: '';
        display: inline-block;
        height: 1.35rem;
        left: -.25em;
        position: absolute;
        top: -.25em;
        width: 1.35rem;
        z-index: -1;
    }

.product-add-on-plus-amount {
    color: #1f1f1f;
    display: block;
    font-size: 0.7em;
    font-weight: 700;
    line-height: 1.2em;
    position: relative;
}

.mobile-gallery {
    display: none;
}

.desktop-gallery {
    display: block;
    /*display: inline-block;
  max-width: 48.75em;
  position: relative;
  width: 70%;*/
}
/*
.product-info-gallery-wrap { 
  bottom: auto; 
  display: block; 
  height: calc(100vh - 8em); 
  left: 0; 
  max-width: 57%; 
  padding: 0rem 1.2em 2em 1.2em; 
  position: absolute; 
  right: 0; 
  top: 7rem; 
  width: calc(100vw - 17px);
}
*/
.product-info-gallery-wrap.prod-middle {
    bottom: auto;
    position: fixed;
    top: 0;
}

/*.product-info-gallery-wrap.prod-bottom { bottom: 0; position: absolute;*/ /*top: auto;*/ /*}*/

.product-details-default-wrap > .default-center {
    max-width: 100%;
}

.product-detail-inner {
    max-width: 100%;
}

.product-info-gallery-wrap.prod-bottom {
    max-width: 100%;
    position: sticky;
    top: 7em;
    padding: 0 0 3em;
    width: 100%;
}

.product-detail-left {
    width: 100%;
    max-width: 50.95em;
    position: relative;
    top: 3em;
}

.product-info-gallery-wrap.scroller {
    margin: auto;
    position: fixed;
    top: 8.2rem;
}

.product-gallery-info-inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    z-index: 1;
}

.product-gallery-thumb-row {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .3em;
    height: 100%;
    left: 0;
    position: relative;
    top: 0;
}

.product-gallery-container {
    display: inline-block;
    position: relative;
    vertical-align: top;
    max-width: 44.5em;
    max-height: 38.4em;
    margin: 0 0 0 3.8em;
    width: 100%
}

.product-gallery-slide {
    display: block;
    position: relative;
    width: 100%;
}

    .product-gallery-slide img,
    .product-gallery-slide .product-item-image img {
        display: block;
        height: 100%;
        left: 0;
        object-fit: contain;
        position: absolute;
        top: 0;
        width: 100%;
    }

.product-gallery-thumb-column {
    border: 1px solid #e3e3e3;
    border-radius: 0.1em;
    cursor: pointer;
    display: block;
    position: relative;
}

    .product-gallery-thumb-column::before,
    .product-gallery-thumb-column:before {
        content: "";
        display: none;
        height: 0;
        padding: 127.35% 0 0 0;
        position: relative;
        width: 0;
    }

    .product-gallery-thumb-column img {
        display: block;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: relative;
        top: 0;
        width: 100%;
    }

.product-gallery-caption-container {
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;
    width: 100%;
    padding: 2.5em 0 0;
}

.product-gallery-caption {
    color: #757575;
    display: block;
    font-size: 0.9em;
    line-height: 1.2em;
    position: relative;
    text-align: center;
}

/*.product-gallery-image-wrap::before,
.product-gallery-image-wrap:before { content: ""; display: block; height: 0; padding: 104.5% 0 0 0; position: relative; width: 0; }*/
.product-gallery-image-wrap .product-item-image::before,
.product-gallery-image-wrap .product-item-image:before {
    content: "";
    display: block;
    height: 0;
    padding: 71.5% 0 0 0; /*padding: 104.5% 0 0 0;*/
    position: relative;
    width: 0;
}

.product-gallery-image-wrap {
    display: block;
    max-width: 44.5em;
    max-height: 38.4em; /*max-height: 38.4em;*/
    overflow: hidden;
    position: relative;
    width: 100%;
}

.product-gallery-container .slick-track {
    display: block;
    position: relative;
    width: 100%;
}

/* TYPICAL */
.prod-grid-header {
    padding-bottom: 0.8em;
}

    .prod-grid-header .prod-grid-col { /*flex: 1;*/
        flex: 1 1 auto;
    }

        .prod-grid-header .prod-grid-col.prod-desc { /*flex: 3;*/ /*flex: 1;*/
            flex: 0 1 100%;
            margin-left: 4.7em;
            max-width: 29rem;
        }

.prod-grid-select-wrap {
    margin: 0 1.5em 0 1.5em;
}

/* .prod-grid-action-wrap {
    display: inline-block;
    padding: 0 1.5em;
    position: relative;
    vertical-align: middle;
    width: 6.4rem;
    } */
/*TODO need js to add this - checked*/
.square-default-cta {
    border: 1px solid #1f1f1f;
    border-radius: 0.3em;
    color: #1f1f1f;
    display: inline-block;
    font-size: 0.8rem;
    line-height: 1.1em;
    padding: 0.9em 3em 0.8em 1em;
    position: relative;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
}

    .square-default-cta::after,
    .square-default-cta:after {
        color: #1f1f1f;
        content: "\e5c8";
        display: block;
        font-family: "Material Symbols Rounded";
        font-size: 1.6em;
        font-variation-settings: "FILL" 0, "wght" 200, "GRAD" 0, "opsz" 48;
        position: absolute;
        right: 0.7em;
        top: 0.6em;
    }

.prod-pricing-label-wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-right: 6em; /*Watch this working responsive*/
    position: relative;
    width: 11.05rem;
}

.prod-pricing-label {
    font-size: 0.8rem;
}

.pricing-total-wrap {
    align-items: center;
    display: flex;
    justify-content: flex-end; /*justify-content: space-between;*/
    margin-right: 1.7em;
    padding: 0;
    position: relative; /*width: 12.9rem;*/
    width: 11.5rem;
}

.prod-desc-color span::after,
.prod-desc-color span:after,
.cart-desc-color span::after,
.cart-desc-color span:after {
    right: -1.2rem;
}

.prod-desc-color span.lime-bak::after,
.prod-desc-color span.lime-bak:after {
    background: #cfdb54;
}

.prod-desc-color span.orange-bak::after,
.prod-desc-color span.orange-bak:after {
    background: #db6827;
}

.prod-desc-color span.lt-gray-bak::after,
.prod-desc-color span.lt-gray-bak:after {
    background: #d4c4b9;
}

.mobile-unit-label,
.mobile-label {
    display: none;
}

.subtotal-cart-item { /* border-bottom: 1px solid #c4c4c4;
    display: block;
    padding: 2.4em 0 .3em;
    position: relative; */
    display: inline-block;
    max-width: 18rem;
    padding: 0;
    position: relative;
    vertical-align: middle;
    margin-right: 2em;
}

.subtotal-buttons-wrap {
    padding: 1.2em 0;
}

.subtotal-simple-link:hover,
.subtotal-simple-link:focus-visible {
    text-decoration: none;
}

/* .add-cart-cta {
    background: #1f1f1f;
    border: 1px solid #1f1f1f;
    border-radius: .3em;
    color: #fff;
    display: inline-block;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.1em;
    padding: .8em 1em .9em 1em;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: .25s;
    vertical-align: top;
    width: 100%;
    } */
.add-cart-cta:hover,
.add-cart-cta:focus-visible {
    background: #fff;
    color: #1f1f1f;
}

.wishlist-cta:hover,
.wishlist-cta:focus-visible {
    border: 1px solid #1f1f1f;
    background: #1f1f1f;
    color: #fff;
}

.wishlist-cta span {
    display: inline-block;
    vertical-align: middle;
}

.prod-grid-select {
    font-size: 1rem;
    min-width: 4.2em;
    height: 2.3rem;
    padding: 0.55em 1.45em 0.55em 1.2em;
}

.room-slider-nav {
    display: block;
    height: 17em;
    pointer-events: none;
    position: absolute;
    top: 12em;
    width: 100%;
    z-index: 7;
}

.room-slider-wrap .slick-arrow,
.bought-together-inner .slick-arrow {
    background: #fff;
    border: none;
    border-radius: 50%;
    bottom: 0.4rem;
    box-shadow: 0.1rem 0.1rem 0.6rem rgb(0 0 0 / 15%);
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 3.1rem;
    right: 3.25rem;
    margin: -1.225rem 0 0 0;
    position: absolute;
    top: auto;
    width: 3.1rem;
    z-index: 2;
}

    .room-slider-wrap .slick-arrow:hover,
    .room-slider-wrap .slick-arrow:focus-visible {
        background: #f5f5f5;
    }

.bought-together-inner .slick-arrow {
    background: #f5f5f5;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
    bottom: 6rem;
    right: -4.6rem;
}

    .bought-together-inner .slick-arrow:hover,
    .bought-together-inner .slick-arrow:focus-visible {
        background: #fff;
    }

    .room-slider-wrap .slick-arrow::after,
    .room-slider-wrap .slick-arrow:after,
    .bought-together-inner .slick-arrow::after,
    .bought-together-inner .slick-arrow:after {
        bottom: 0;
        color: #1f1f1f;
        content: "\e5c4";
        display: block;
        font-family: "Material Symbols Rounded";
        font-size: 1.2rem;
        height: 1em;
        left: 0;
        line-height: 1em;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 1em;
    }

.room-slider-wrap .slick-next::after,
.room-slider-wrap .slick-next:after,
.bought-together-inner .slick-next::after,
.bought-together-inner .slick-next:after {
    content: "\e5c8";
}

.room-slider-wrap .slick-prev,
.bought-together-inner .slick-prev {
    left: auto;
    right: 6.9rem;
}

.bought-together-inner .slick-prev {
    right: -1rem;
}

.room-slider-wrap.slick-slider .slick-track {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.95em;
}

.product-overlay-wrap {
    display: none;
}

/* LANDING OVERLAY */
.product-layout-title.inline-block-flex {
    font-size: 1em;
    padding: 0 0 0.4em 0;
}

.product-layout-desc.inline-block-flex {
    padding: 0 0 1.6em 0;
}

.product-type .nav-block-item .product-layout-wrapper.inline-block-flex {
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    padding: 0;
}

    .product-type .nav-block-item .product-layout-wrapper.inline-block-flex .product-layout-inner {
        background: #f3f3f3;
        border-radius: 0.3em;
        display: inline-block;
        padding: 1.2em 1.9em 0.95em 1.6em;
        position: relative;
    }

.product-type .nav-block-item:nth-child(1) .product-layout-cta.inline-block-flex {
    clear: both;
    float: right;
    margin: 0 0 0 auto;
    padding: 0.6em 2.5em 0.65em 1.4em;
}

.product-layout-cta.inline-block-flex::after,
.product-layout-cta.inline-block-flex:after {
    font-size: 1.5em;
    right: 0.4em;
    top: 0.4em;
}

.type-back-link {
    color: #757575;
    display: inline-block;
    font-size: 0.8em;
    left: 0.1em;
    line-height: 1.2em;
    position: absolute;
    text-decoration: none;
    top: 5.5em;
    vertical-align: middle;
}

    .type-back-link .material-symbols-rounded {
        display: inline-block;
        margin-top: 0.1em;
        position: relative;
        vertical-align: top;
    }

.product-type .nav-block-item,
.typical-layout .nav-block-item,
.room-type .nav-block-item {
    max-width: 10em;
    margin-bottom: 1.1em;
}

.product-type .nav-block-wrap {
    gap: 0.8em;
}

.typical-layout .nav-drop-layout-wrap {
    gap: 1.7em;
}

.typical-layout .nav-block-wrap,
.room-type .nav-block-wrap {
    gap: 0.7em;
}

.typical-layout .nav-block-img-wrap::before,
.typical-layout .nav-block-img-wrap:before {
    padding: 99.1% 0 0 0;
}

.room-type .nav-drop-layout-wrap {
    gap: 1em;
    justify-content: flex-start;
}

.room-type .nav-drop-title {
    width: 13.4%;
}

.room-type .nav-block-wrap {
    width: 83.5%;
}

.room-type .nav-block-img-wrap::before,
.room-type .nav-block-img-wrap:before {
    padding: 100% 0 0 0;
}

.room-type .nav-block-item {
    margin-bottom: 1.8em;
}

.typical-layout .nav-block-item {
    margin-bottom: 2em;
}

.product-detail-left-wrap {
    max-width: 34.4em;
    position: relative;
    width: 100%;
    margin-left: 4.8em;
}

.product-left-img-wrap {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative; /* padding: 127.4% 0 0 0; */
    padding: 116.7% 0 0 0;
}

    .product-left-img-wrap img {
        bottom: 0;
        left: 0;
        object-fit: cover;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }

.product-gallery-wrapper {
    position: absolute;
    top: 12.1em;
    left: 0.9em;
    max-width: 3.6em;
    width: 100%;
}

.product-detail-gallery-outer {
    max-width: 3.6em;
    width: 100%;
}

.product-detail-gallery-wrap {
    margin: 0.3em 0;
    position: relative;
}

.product-gallery-img-wrap {
    border: 1px solid #e3e3e3;
    border-radius: 0.2em;
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    padding: 97.2% 0 0 0;
}

    .product-gallery-img-wrap img {
        bottom: 0;
        left: 0;
        object-fit: cover;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }

/* .product-gallery-wrapper{
    display:block;
    position: relative;
    width: 100%;
    } */
.prod-shipping-wrap .cart-select-arrow {
    min-width: 5em;
    height: 2.8532rem;
}

/* .product-dimension-number-wrap .product-dimension-number.selected {
    } */
.default-center.bought-together-default {
    max-width: 100%;
    padding-left: 9.5em;
    width: 100%;
}

.Images-selected {
    color: #757575;
    display: block;
    font-size: 0.55em;
    font-weight: 400;
    line-height: 1.2em;
    position: relative;
    width: 100%;
    text-align: center;
}

.padding-ddesktop-gallery {
    padding-top: 0;
}

.product-gallery-thumb-row .slick-slide div {
    height: 100%;
    width: 100%;
}

.product-gallery-thumb-column {
    border: 1px solid #e3e3e3;
    border-radius: 0.1em;
    cursor: pointer;
    display: block;
    min-height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}
/*.slick-slide div .product-gallery-thumb-column {
  border: none;
  border-radius: 0.1em;
  cursor: pointer;
  display: block;
  left: 0;
  min-height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}*/
.product-gallery-thumb-row .slick-slide div > .product-gallery-thumb-column {
    display: block !important;
    width: 100%;
}

.product-details-wrap .slick-slide > div {
    padding: 0 1.7em 0 0;
}

.product-gallery-thumb-column::before,
.product-gallery-thumb-column:before {
    content: "";
    display: none;
    height: 0;
    padding: 127.35% 0 0 0;
    position: relative;
    width: 0;
}

.product-gallery-thumb-row .product-gallery-thumb-column .product-item-image::before,
.product-gallery-thumb-row .product-gallery-thumb-column .product-item-image:before {
    content: "";
    display: none;
    display: block;
    height: 0;
    padding: 127.35% 0 0 0;
    position: relative;
    width: 0;
}

.product-gallery-thumb-column img {
    display: block;
    height: 3.4em;
    left: 0;
    object-fit: contain;
    object-position: center;
    position: absolute;
    top: 0;
    width: 3.5em;
}

/*.product-details-color-wrap .radio-btn:checked + .color-select::before,
.radio-btn:checked + .color-select:before { border: 2px solid #1f1f1f; box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25); border-radius: 100%; content: ""; display: block; height: 3.3rem; position: absolute; width: 3.3rem; top: -0.25em; left: -0.25em; }
*/



.product-details-color-wrap .radio-btn:checked + .color-select,
.radio-btn:checked + .color-select {
    outline: 2px solid #1F1F1F;
    outline-offset: 4px;
}

    /*.product-details-color-wrap .radio-btn:checked + .color-select::after,
.radio-btn:checked + .color-select:after {
    align-items: center;
    color: #1c1b1f;
    content: "\e876";
    display: flex;
    font-family: "Material Symbols Rounded";
    font-size: 1.3em;
    font-variation-settings: "wght" 500;
    height: 3.3rem;
    justify-content: center;
    left: -0.2em;
    position: absolute;
    top: -0.2em;
    width: 3.3rem;
    mix-blend-mode: difference;
    -webkit-filter: invert(100%); 
    filter: invert(100%);
}*/
    .product-details-color-wrap .radio-btn:checked + .color-select::after,
    .radio-btn:checked + .color-select:after {
        align-items: center;
        content: "";
        background: url("../../assets/check-drop-shadow.png");
        background-repeat: no-repeat;
        background-size: 1.6rem auto;
        background-position: 0.3em 0.3em;
        display: flex;
        height: 100%;
        left: -0.2em;
        position: absolute;
        top: -0.2em;
        width: 100%;
    }

.radio-btn {
    display: none;
}

    .radio-btn:checked + .product-dimension-number {
        background: #1f1f1f;
        box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
        color: #fff;
        transform: scale(1.0714);
    }

.product-gallery-thumb-row .slick-slide { /*background: #666 !important;*/
    width: 72px !important;
    height: 72px;
    display: block !important;
    float: none;
    border: 1px solid #e3e3e3;
    border-radius: 0.1em;
    cursor: pointer;
    display: block;
    position: relative;
    margin-bottom: 0.3em;
}

.product-gallery-thumb-row .slick-track {
    position: relative;
    max-width: max-content;
    left: 0;
    transform: translate3d(0px, 0px, 0px) !important;
}

.product-gallery-thumb-row .slick-list {
    overflow: visible;
}

.product-gallery-container .slick-arrow,
.product-gallery-thumb-row .slick-arrow {
    display: none !important;
}

.container {
    position: relative;
}

.slider {
    font-family: Arial;
    width: 500px;
    display: block;
    margin: 0 auto;
}

    .slider h3 {
        background: #fff;
        color: #3498db;
        font-size: 36px;
        line-height: 100px;
        margin: 10px;
        padding: 2%;
        position: relative;
        text-align: center;
    }

    .slider .action {
        display: block;
        margin: 100px auto;
        width: 100%;
        text-align: center;
    }

        .slider .action a {
            display: inline-block;
            padding: 5px 10px;
            background: #f30;
            color: #fff;
            text-decoration: none;
        }

            .slider .action a:hover {
                background: #000;
            }

.prod-dim-inner .product-dimension-color-wrap {
    gap: 0 0.95em;
}

.product-detail-price {
    color: #1f1f1f;
    display: inline-block;
    font-size: 0.7em;
    line-height: 1.1em;
    position: relative;
    vertical-align: baseline;
}

.mobile-heading {
    display: none;
}

.product-gallery-slider-img-wrap {
    display: block;
    overflow: hidden;
    width: 100%;
}

.product-required-error {
    color: #eb0000;
}

.product-required-label-wrap {
    display: block;
    padding: .3rem 0;
    position: relative;
}

.product-required-label {
    color: #eb0000;
    font-size: 0.9rem;
    font-weight: 700;
}

.error-listing {
    display: block;
    position: relative;
    width: 100%;
}

.error-listing-ul {
    display: block;
    margin: 2rem 0 0.5em;
    position: relative;
    width: 100%;
}

    .error-listing-ul li {
        color: #eb0000;
        font-size: .9rem;
        font-weight: 700;
        line-height: 1.2em;
        position: relative;
    }

.wishlist-btn {
    border-color: #C3C3C3;
    background: #fff;
    color: #757575;
}

    .wishlist-btn:hover {
        background-color: #1f1f1f;
        color: #fff;
    }

.product-gallery-image-wrap.comming-soon-wrap {
    display: block;
    max-width: 39.5em;
    position: relative;
    width: 100%;
}

.images-comming-soon-left {
    display: block;
    position: relative;
    max-width: 37.5em;
    width: 100%;
}

.product-gallery-title-wrap {
    display: block;
    position: relative;
    width: 100%;
    padding: 71.5% 0 0 0;
    background: #f5f5f5;
}

.slick-slide.slick-active.slick-current.reponsive-width {
    width: 100%;
}


.prod-detail-content.para{
    font-size:0.8em;
}
/*PDF LINk*/
    .prod-detail-content.para a {
        text-decoration: underline;
        color: #757575;
    }
.cart-grid-unit-buttons .myproduct-icon.wishlist-cell .cta-cell-wrap::after,
.cart-grid-unit-buttons .myproduct-icon.wishlist-cell .cta-cell-wrap:after {
    content: "\e2c8";
}

.go-back{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: none;
    border-radius: 0;
    color: #2B2B2B;
    cursor: pointer;
    display: inline-block;
    font-size: .8em;
    font-weight: 500;
    line-height: 1.25em;
    padding: 1.1875em 1.125em;
    position: relative;
    text-decoration: none;
    vertical-align: middle;
}
.go-back::before {
    content: '\e2ea';
    display: inline-block;
    font-family: "Material Symbols Rounded";
    font-size: .8em;
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
    height: 1em;
    line-height: 1em;
    margin: -0.2em .5em 0 0;
    position: relative;
    vertical-align: middle;
    width: 1em;
}
/*modal css start*/
.video-modal {
    align-items: center;
    background: #323232;
    bottom: 0;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 900;
}

    .video-modal .video-event-wrap {
        padding: 0 1em;
    }

        .video-modal .video-event-wrap::after {
            display: none;
        }

    .video-modal .close-modal {
        align-items: center;
        background-color: #5d5d5d;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        height: 2rem;
        justify-content: center;
        position: absolute;
        right: 1em;
        top: 1em;
        width: 2rem;
        z-index:901;
        /*height: 1.5em;*/
        /*width: 1.5em;*/
    }

    .video-modal .arrow-modal.next,
    .video-modal .arrow-modal.prev {
        align-items: center;
        background-color: #5d5d5d;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        height: 2rem;
        justify-content: center;
        position: absolute;
        right: 1em;
        bottom: 1em;
        width: 2rem;
        /*height: 1.5em;*/
        /*width: 1.5em;*/
    }

    .video-modal .arrow-modal.prev {
        right: auto;
        left: 1em;
    }

        .video-modal .close-modal span,
        .video-modal .arrow-modal.next span,
        .video-modal .arrow-modal.prev span {
            color: #fff;
            font-size: 1.1em;
            /*font-size: 0.9em;*/
        }

            .video-modal .arrow-modal.next span.arrow-wrap,
            .video-modal .arrow-modal.prev span.arrow-wrap {
                display: block;
                height: 1rem;
                position: relative;
                width: 1rem;
                /*height: 1em;*/
                /*width: 1em;*/
            }

                .video-modal .arrow-modal.next span.arrow-wrap img,
                .video-modal .arrow-modal.prev span.arrow-wrap img {
                    display: block;
                    height: 100%;
                    left: 0;
                    object-fit: cover;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }

    .video-modal .video-event-wrap, .video-modal .video-box {
        height: 42.95em;
        max-height: 100%;
        min-height: auto;
        width: auto;
        /*max-width: 55em;*/
        /*width: 55em;*/
    }
    .video-modal .video-box, .video-modal .video-event-wrap {
        height: 35.95em;
        max-height: 100%;
        min-height: auto;
        width: auto;
    }
    .video-modal .video-arrows-wrap {
        display: block;
        margin: auto;
        max-width: 85em;
        position: absolute;
        top: 55%;
        transform: translateY(-50%);
        width: 100vw;
        z-index: 999;
    }
    .video-modal .video-event-wrap.news-video iframe {
        display: block;
        height: 35.95em;
        object-fit: cover;
        position: relative;
        width: 75em;
    }
    .video-modal .video-event-wrap {
        padding: 0 1em;
    }

/* MEDIA */
@media screen and (max-width: 1800px) {
        .product-detail-left {
        max-width: 49em;
    }
}

@media screen and (max-width: 1600px) {
    .product-detail-left {
        max-width: 44em;
    }
}

@media screen and (max-width: 1500px) {
    /* PRODUCT DETAIL */
    .default-center.bought-together-default {
        padding-left: 1.2em;
    }

    .product-credit-desc {
        max-width: 100%;
    }

    .product-gallery-slider-img-wrap {
        overflow: hidden;
    }

    .product-gallery-container {
        max-width: 100%;
        margin: 0 0 0 3.8em;
        overflow: hidden
    }

    .bought-together-default {
        padding-left: 1.2em;
    }

    .color-dots.large {
        height: 2.5rem;
        width: 2.5rem;
    }

        .color-dots.large .color-dots.large {
            height: 2.4rem;
            width: 2.4rem;
        }

        .color-dots.large.color-select::before,
        .color-dots.large.color-select:before,
        .color-dots.large.color-select::after,
        .color-dots.large.color-select:after {
            height: 3rem;
            width: 3rem;
        }

    .product-gallery-caption {
        font-size: 0.8em;
    }

    .bought-together-tile-image-wrap {
        height: 17.75rem;
    }

    .product-gallery-info-inner {
        max-width: 100%;
        width: 100%;
        justify-content: flex-start
    }

    .product-info-gallery-wrap {
        max-width: 62%;
    }

    .product-results,
    .product-clear {
        font-size: 0.7em;
    }

    .product-filter-active,
    .product-item-filter-trigger,
    .container-checkbox,
    .container-radio,
    .show-all-title {
        font-size: 0.8em;
    }

    .product-detail-left {
        max-width: 39.7em;
    }

    .product-detail-right {
        padding: 3.5em 0 0 0;
    }

    .prod-detail-cta {
        font-size: .9em;
        padding: .9em 2em .7em;
    }

    .product-detail-title {
        font-size: 2em;
    }

    .product-detail-inner {
        gap: 3.95em;
        justify-content: space-between;
    }

    .images-comming-soon-left {
        max-width: 22.5em;
        width: 100%;
    }
}

@media screen and (max-width: 1300px), print {
    .product-gallery-container {
        max-width: 26em;
        margin: 0 0 0 3em;
    }

    .product-detail-left {
        max-width: 40em;
    }
}

@media screen and (max-width: 1200px), print {
    /*product images-comming-soon*/
    .product-gallery-thumb-row.thamb-galler-comming-soon {
        display: none;
    }

    .product-info-gallery-wrap.comming-soon-img-gallery {
        max-width: 100%;
    }
    /* PRODUCT DETAIL */
    .product-details-color-wrap .radio-btn:checked + .color-select::before, .radio-btn:checked + .color-select:before {
        height: 3rem;
        width: 3rem;
    }

    .product-details-color-wrap .radio-btn:checked + .color-select::after,
    .radio-btn:checked + .color-select:after {
        align-items: center;
        font-size: 1.1em;
        height: 3rem;
        left: -0.15em;
        position: absolute;
        top: -0.25em;
        width: 2.8rem;
    }

    .page-main-title {
        font-size: 2.1em;
    }

    .product-detail-left {
        max-width: 30em;
        width: 100%;
    }

    label.product-dimension-number.label {
        font-size: 0.8em;
    }

    .product-gallery-caption {
        font-size: 0.65em;
    }

    .bought-together-tile {
        width: 100%;
    }

    .color-dots.large.color-select::before,
    .color-dots.large.color-select:before,
    .color-dots.large.color-select::after,
    .color-dots.large.color-select:after {
        height: 2.8rem;
        width: 2.8rem;
    }

    .prod-shipping-select {
        font-size: 1em;
        height: 2.7em;
        min-width: 4.45em;
    }

    .bought-together-tile-image-wrap {
        height: 15.75rem;
    }

    .product-gallery-info-inner {
        max-width: 31.5em;
    }

    .product-gallery-container {
        max-width: 90%;
        margin: 0 0 0 2.15em;
        width: 100%
    }

    .product-info-gallery-wrap {
        max-width: 78%;
    }

    .prod-shipping-wrap .cart-select-arrow {
        font-size: 1em;
        height: 2.7em;
        min-width: 4.45em;
    }

    .product-detail-icon-wrap {
        height: 1.6rem;
        width: 1.6rem;
        top: -.4em;
    }

    .product-detail-right .product-detail-icon-wrap::after,
    .product-detail-right .product-detail-icon-wrap:after {
        font-size: 1.6em;
        line-height: 0;
        right: .25em;
        top: .84em;
    }
}

@media screen and (max-width: 1024px), print {
    .product-info-gallery-wrap.prod-bottom {
        position: relative;
        top: 0;
        padding: 0;
    }
}

@media screen and (max-width: 1000px), print {

    /* PRODUCT-SLIDER */
    .product-gallery-image-wrap {
        max-width: 22.4em;
    }

    .product-gallery-thumb-row .slick-track {
        top: 0.5em;
    }

    .product-detail-left {
        max-width: 22em;
        width: 100%;
    }
    /* PRODUCT-SLIDER */
    .bought-together-tile-title, .bought-together-amount {
        font-size: 0.7em;
    }
}

@media screen and (max-width: 900px) {

    /* PRODUCT DETAIL */
    .default-center.bought-together-default {
        padding: 0 0.8em;
    }

    .product-details-color-wrap .radio-btn:checked + .color-select::before,
    .radio-btn:checked + .color-select:before {
        height: 2.75rem;
        width: 2.75rem;
        top: -0.3em;
        left: -0.3em;
    }

    .product-details-color-wrap .radio-btn:checked + .color-select::after,
    .radio-btn:checked + .color-select:after {
        top: -0.3em;
        height: 2.75rem;
        width: 2.55rem;
    }

    .prod-shipping-wrap .cart-select-arrow {
        height: 2.7rem;
    }

    .prod-detail-cta {
        padding: 0.9em 2em 0.79em;
        white-space: nowrap;
    }

    .product-gallery-caption {
        font-size: 0.55em;
    }

    .bought-together-tile {
        padding: 0.65em 0 0.5em 0;
    }

    .bought-together-amount {
        padding: 0.5em 0.5em 0.6em 0;
    }

    .product-detail-inner {
        flex-direction: column;
        gap: 1em;
    }

    .bought-together-outer {
        padding-top: 3em;
    }

    .product-gallery-slider-img-wrap {
        padding: 0;
    }

    .product-header-wrap .default-center {
        padding: 0;
    }

    .product-details-wrap .slick-slide > div {
        padding: 0 0.6em 0 0;
    }

    .product-detail-price .product-detail-price-amount {
        font-size: 0.9rem;
    }
    /* .product-details-default-wrap .product-header-wrap {
    padding: 1.5em 0.8em 0;
    } */
    .product-gallery-wrapper {
        display: none;
    }

    /*      .product-detail-left {
            max-width: 12.1em;
            margin: 0 auto;
        }*/

    .product-detail-desktop-heading {
        display: none;
    }

    .product-detail-list {
        padding: 1em 0 2.15em 0;
    }
    /* .product-detail-left {
    max-width: 0;
    } */
    .product-detail-left-wrap {
        margin-left: 0;
    }

    .product-detail-right {
        max-width: 25em;
        margin: auto;
        width: 100%;
        padding: 0;
    }

    /* .bought-together-tile {
            width: 100%;
        }*/

    .mobile-gallery {
        display: block;
    }

        .mobile-gallery .product-info-gallery-wrap {
            display: block;
            height: 100%;
            margin: auto;
            max-width: 25em;
            padding: 1.4em 0 1.3em;
            position: relative;
            top: 0;
            width: 100%;
        }

    .product-gallery-thumb-row {
        bottom: 0;
        flex-direction: row;
        height: auto;
        order: 2;
        top: auto;
        width: 100%;
    }

    .product-detail-title {
        font-size: 1.6em;
        line-height: 0.9em;
        width: 100%;
        max-width: 100%;
        padding-bottom: 0.3em;
        padding-right: 2em;
    }

    .product-gallery-slide::before,
    .product-gallery-slide:before {
        padding: 89% 0 0 0;
    }

    .product-gallery-container {
        margin: 0 0 0 0;
        padding-left: 0;
        width: 100%;
    }

    .product-gallery-slide img {
        object-fit: contain;
    }

    .product-gallery-info-inner {
        display: block;
    }

    .prod-dim-inner .product-dimension-color-wrap {
        gap: 0 0.5em;
        margin-top: 0;
        padding: 0.3em 0 0;
    }

    .color-dots.large {
        height: 2.15rem;
        width: 2.15rem;
        margin-bottom: 0.25em;
    }

        .color-dots.large .color-dots.large {
            height: 2.05rem;
            width: 2.05rem;
        }


        .color-dots.large.color-select::before,
        .color-dots.large.color-select:before,
        .color-dots.large.color-select::after,
        .color-dots.large.color-select:after {
            height: 2.65rem;
            width: 2.65rem;
        }

    .product-dimensions-wrap {
        flex-wrap: nowrap;
        gap: 0.5em;
        padding-bottom: 2em;
    }

    .product-add-on-method-inner {
        margin: 0 0.2em 0 0.7em;
    }

    .product-detail-add-on-wrap {
        margin-bottom: 0.3em;
    }

    .product-add-on-content-wrap {
        padding: 0.25em 0.4em 0.1em;
    }

    .prod-detail-content .prod-detail-content-trigger::before,
    .prod-detail-content .prod-detail-content-trigger:before {
        right: 0.4em;
    }

    .prod-shipping-wrap {
        padding-top: 1.4em;
    }

    .prod-shipping-select {
        background-position: right 10% center;
        padding: 0; /*padding: 0.733em 1.6em 0.733em 0.6em;*/
    }

    .bought-together-tile-image-wrap {
        height: 11.15rem;
    }

    .bought-together-outer .product-item-image::before,
    .bought-together-outer .product-item-image:before {
        padding: 110% 0 0 0;
    }

    .product-detail-icon-wrap {
        height: 1.6rem;
        width: 1.6rem;
        top: 0.45em;
    }

    .product-gallery-thumb-row {
        display: none;
    }

    .product-gallery-container .slick-dots li {
        margin: 0 2.1px;
        width: 8px;
        height: 6px;
    }

        .product-gallery-container .slick-dots li button,
        .product-gallery-container .slick-dots li button:before {
            width: 6px;
            height: 6px;
            padding: 0;
        }

            .product-gallery-container .slick-dots li button:before {
                font-size: 27px;
            }

    .product-gallery-image-wrap {
        max-width: 25em;
    }

    .product-gallery-caption-container {
        margin: auto;
        max-width: 25em;
        width: 100%;
        padding: 0.8em 0 0;
    }

    .product-info-gallery-wrap .slick-dots {
        bottom: -25px;
    }

    .product-dimension-desc {
        font-size: 0.6em;
    }

    .prod-shipping-wrap {
        gap: 0.35em;
    }

    .product-info-gallery-wrap.prod-bottom {
        display: block;
        height: 100%;
        margin: auto;
        max-width: 25em;
        padding: 0;
        position: relative;
        top: 0;
        width: 100%;
    }

    .product-detail-right .product-detail-icon-wrap::after,
    .product-detail-right .product-detail-icon-wrap:after {
        font-size: 1.7em;
        right: .18em;
        top: .75em;
    }

    .mobile-heading {
        display: block;
        position: relative;
    }

    .product-detail-left {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        max-width: 100%;
        width: 100%;
        top: 0;
    }

    .mobile-heading .product-header-wrap {
        padding: 0 0 2rem 0;
    }
}

@media screen and (max-width: 600px) {

    /* PRODUCT DETAIL */
    /*  .bought-together-tile {
            width: 100%;
        }*/

    .bought-together-tile-image-wrap .product-item-reaction {
        padding: 0.45em 0.5em 0.45em 0.2em;
    }

    .bought-together-tile-title,
    .bought-together-amount {
        font-size: 0.7em;
    }

    .bought-together-tile-title {
        line-height: 1.1em;
    }

    .bought-together-content {
        padding-top: 0.2em;
    }

    .bought-together-color-wrap {
        gap: 0.2em;
        margin-top: -0.5em;
    }

        .bought-together-color-wrap .color-dots {
            height: 0.9em;
            width: 0.9em;
        }

    .bought-together-inner .slick-arrow {
        right: 0;
    }

    .bought-together-inner .slick-prev {
        right: 2.5rem;
    }

    .product-gallery-slider-img-wrap {
        padding: 0 1rem;
    }

    .errorImage {
        font-size: 0.8em;
    }
}

@media screen and (max-width: 450px) {
    .product-dimension-number {
        padding: 0.94em 0.328em;
    }

    .product-gallery-slider-img-wrap {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        padding: 0;
    }
    /*PRODUCT*/
    .product-gallery-container {
        margin: 0 0 0 0;
    }

    .product-item-image.product-item-recommend-inner .product-layout-wrapper {
        padding: 2em 0.45em 0.8em;
        padding-top: calc(100% - 9.4em);
    }

    /* OVERLAY */
    .product-type .nav-block-item {
        flex: 0 1 46.5%;
    }

    .product-gallery-image-wrap {
        max-width: 100%;
        margin: auto;
        width: 100%
    }

        .product-gallery-image-wrap::before,
        .product-gallery-image-wrap:before {
            padding: 127.3% 0 0 0;
        }

    .product-detail-list {
        padding: 1.55em 0 1.75em 0;
    }

    .product-dimension-number {
        padding: 0.94em 0.328em;
    }

    .radio-btn:checked + .product-dimension-number {
        padding: 0.8965em 0.286em;
    }

    .product-dimensions-wrap {
        flex-wrap: nowrap;
        gap: 0.2em;
    }

    .product-dimensions-wrap {
        padding-bottom: 2.15em;
    }

    .product-add-on-image-wrap { /* border-radius: 6px 0px 0px 6px; */
        width: 5.6em;
    }

    .product-detail-add-on-wrap {
        margin-bottom: 0.4em;
    }

    .cart-grid-select-wrap {
        min-width: 4.45em;
    }

    .product-details-wrap .slick-slide > div {
        padding: 0 0.6em 0 0;
    }

    .product-header-wrap .default-center {
        padding: 0;
    }

}

.play-icon-button img,
.play-icon img {
    object-fit: scale-down;
    align-items: center;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: #0000004d;
    border-radius: 59%;
    bottom: 0;
    cursor: pointer;
    height: 3.2em;
    margin: auto;
    left: 0;
    width: 3.2em;
    position: absolute;
    right: 0.9em;
    top: 0;
    z-index: 1;
}

.play-icon img {
    right: 3.8em;
}

/* Print media Style */
@media print {
    body {
        height: auto !important;
        min-height:auto !important;
        padding: 0 10px;
    }
    .bought-together-outer.product-details-wrap,
    .product-gallery-thumb-row,
    .go-back {
        display: none !important;
    }
    .product-detail-inner {
        gap: 1em;
    }
    .cart-grid-select {
        padding: 0.55em 1.25em 0.55em 1.1em;
    }
    .product-detail-right {
        padding-top: 0;
    }
    .product-gallery-container {
        padding: 0;
        margin: 0;
        max-width: 100%;
    }
    .product-gallery-info-inner {
        max-width: 13.5em;
    }
    .product-detail-left {
        max-width: 15em;
    }
    .product-deatis-page-wrap,
    .default-outer-wrapper {
        min-height: auto;
    }
    .default-center,
    .product-header-wrap,
    .prod-detail-content-wrap {
        font-size: 19px;
    }
    .prod-detail-content-collapse-wrap {
        height: 100%;
        font-size: 1em;
        opacity: 1;
        padding: 0.5em 0;
        transition: .25s ease-in-out;
    }
}