.validation-error-message.mt-5 {
    margin-top: 5px;
}
/* LOGIN */
.login-page-wrap .login-button-alt {
    border: none;
    background-color: #fff;
    cursor: pointer;
}

.login-page .nav-wrap { display: none; }

.login-page-wrap {
    align-items: center;
    background: linear-gradient(232deg, #538B9B 32.85%, #306A7A 78.3%);
    background: -moz-linear-gradient(232deg, #538B9B 32.85%, #306A7A 78.3%);
    background: -webkit-linear-gradient(232deg, #538B9B 32.85%, #306A7A 78.3%);
    display: flex;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#538B9B', endColorstr='#306A7A',GradientType=1 );
    height: auto;
    justify-content: center;
    min-height: calc(100vh - 4em);
    position: relative;
    top: 0;
    width: 100%;
    z-index: 1;
}

.login-page-wrap::before,
.login-page-wrap:before {
  background: rgb(250,250,250);
  background: -moz-linear-gradient(28deg, rgba(250,250,250,0) 0%, rgba(255,255,255,.5) 89%);
  background: -webkit-linear-gradient(28deg, rgba(250,250,250,0) 0%, rgba(255,255,255,.5) 89%);
  background: linear-gradient(28deg, rgba(250,250,250,0) 0%, rgba(255,255,255,.5) 89%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fafafa",endColorstr="#ffffff",GradientType=1);
  content: '';
  display: none;
  height: 100%;
  position: absolute;
  width: 100%;
}
.dark .login-page-wrap::before,
.dark .login-page-wrap:before {
  background: rgb(250,250,250);
  background: -moz-linear-gradient(28deg, rgba(250,250,250,0) 0%, rgba(0,0,0,.5) 89%);
  background: -webkit-linear-gradient(28deg, rgba(250,250,250,0) 0%, rgba(0,0,0,.5) 89%);
  background: linear-gradient(28deg, rgba(250,250,250,0) 0%, rgba(0,0,0,.5) 89%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fafafa",endColorstr="#000000",GradientType=1);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

.login-form-wrap { display: block; left: 0; margin: 2em auto; max-height: 100%; max-width: 29.8em; position: relative; right: 0; width: 100%; }

.login-form-inner { background: #fff; border-radius: .8em; box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25); display: block; padding: 2.2em 4.6em 3.5em; position: relative; }

.bottom-wrap { margin-top: 1em; }

.login-form-inner.bottom-wrap { padding: 1.2em 4.6em 1.3em; }

.login-form-row { display: flex; margin: .4em 0 0 0; position: relative; width: 100%; }

.login-form-row:first-child { margin-top: 0; }

.login-form-row.forgot-cell { margin-bottom: -.3em; margin-top: -.4em; }

.login-form-row.separator-cell { margin-bottom: .8em; }

.login-form-cell { display: inline-block; flex: 1 1 auto; position: relative; vertical-align: top; }

.login-form-logo { display: inline-block; margin: auto; max-width: 14.35em; position: relative; }

.login-form-row .login-logo-cell .logo-title { font-size: 1em; padding-left: 2.6em; }

.login-logo-cell { align-items: center; align-content: center; display: flex; justify-content: center; margin: auto; padding: 1.3em 0 1.1em; position: relative; text-decoration: none; vertical-align: middle; width: 14.05em; }

.login-logo-cell .login-form-logo { max-width: 7.5em; }

.login-form-field { display: block; position: relative; width: 100%; }

/*.login-label { border: 1px solid transparent; color: #2d2d2d; display: block; font-size: .8em; font-weight: 400; left: 0; line-height: 1.2em; padding: 1.05em 1em 0.75em 1em; pointer-events: none; position: absolute; top: 0; transition: .25s; z-index: 1; }*/
.login-label {
    border: 1px solid transparent;
    color: #757575;
    display: block;
    font-size: .5rem;
    font-weight: 400;
    left: .9rem;
    letter-spacing: .01em;
    line-height: 1em;
    pointer-events: none;
    position: absolute;
    top: 1em;
    z-index: 1;
}
.input-focused .login-label,
.has-value .login-label { color: #757575; letter-spacing: .01em; line-height: 1em; margin: 0; }

.login-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid #1f1f1f;
    border-radius: 0.4em;
    color: #1f1f1f;
    display: block;
    font-size: .8em;
    font-weight: 400;
    line-height: 1.375em;
    margin-bottom: .6em;
    padding: 1.438em 1.1em 0.5em;
    position: relative;
    width: 100%;
}

input[type=text].login-input:disabled {
    background: #ECECEC;
    border: 1px solid #D9D9D9;
}
.login-form-forgot-link { color: #757575; display: block; float: right; font-size: .6em; line-height: 1.2em; padding: .45em 0 .25em 0; text-decoration: underline; transition: .25s; }
    .login-form-forgot-link:hover,
    .login-form-forgot-link:focus-visible { color: #1f1f1f; }

.login-submit,
.outline-submit,
.login-back {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #1f1f1f;
    border: none;
    border-radius: 3em;
    border: 1px solid #e3e3e3;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: .8rem;
    font-weight: 700;
    line-height: 1.25em;
    margin-top: 0.8em;
    padding: 0.8rem 1.5rem 0.8rem 1.5rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: .25s;
    width: 100%;
}

.outline-submit, .login-back { background: #fff; border: 1px solid #1f1f1f; color: #1f1f1f; }

.login-submit:hover,
.login-submit:focus-visible { background: #fff; border: 1px solid #1f1f1f; color: #1f1f1f; }

.outline-submit:hover,
.outline-submit:focus-visible,
.login-back:hover, 
.login-back:focus-visible { background: #1f1f1f; color: #fff; }

.send-code,
.icon-cta {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid #1f1f1f;
    border-radius: 0.2em;
    color: #2d2d2d;
    cursor: pointer;
    display: block;
    font-size: 0.8em;
    font-weight: 400;
    line-height: 1.5em;
    padding: 0.725rem 2.6rem 0.725rem 0.9rem;
    position: relative;
    width: 100%;
}

.login-horizontal-line { color: #1f1f1f; display: inline-block; font-size: .5em; overflow: hidden; position: relative; text-align: center; width: 100%; }

    .login-horizontal-line::before,
    .login-horizontal-line:before,
    .login-horizontal-line::after,
    .login-horizontal-line:after { background-color: #1f1f1f; content: ''; display: inline-block; height: 1px; position: relative; vertical-align: middle; width: 50%; }

    .login-horizontal-line::before,
    .login-horizontal-line:before { right: .5em; margin-left: -50%; }

    .login-horizontal-line::after,
    .login-horizontal-line:after { left: .5em; margin-right: -50%; }

.login-form-disclaimer { color: #676767; display: block; font-size: .7em; line-height: 1.1em; padding: .8em 0 0; position: relative; text-align: center; }

.prod-grid-header-title,
.cart-grid-header-title { color: #757575; display: inline-block; font-size: .8rem; left: 2.6em; line-height: 1.2em; position: absolute; top: -2.3em; }

.prod-desc .prod-grid-header-title { left: 0; }

.select-wrap .prod-grid-header-title { left: auto; right: 1.7em; }

.prod-grid-header-title.header-right { left: auto; right: 0; }

.prod-grid-header-wrap,
.cart-grid-header-wrap { align-items: center; display: flex; position: absolute; justify-content: space-between; top: 0; width: 11.5rem; }

.validation-summary-valid ul li { color: #AD0000; }
.input-validation-error { border-color: #AD0000; }

.login-form-cell .send-code {
    border-radius: 3em;
    transition: .25s;
}

    .login-form-cell .send-code:hover {
        background: #1f1f1f;
        color: #fff;
    }
@media screen and (max-width: 1500px) {
    .login-form-inner, .login-form-inner.bottom-wrap {
    padding: 1em 2.3em 1.15em;
  }
}
@media screen and (max-width: 1200px) {
  .login-form-inner, .login-form-inner.bottom-wrap {
    padding: 1.2em 2.3em 1.75em;
  }
}
@media screen and (max-width: 900px) {
  .login-form-inner {
    padding: 1.2em 1.15em 2.5em;
  }
  .login-form-wrap {
    margin: 2em 1.15em;
  }
    .login-input {
        padding: 1.425em 1.1em .451em;
    }
    .login-label{
        font-size:.6rem;
    }
    .send-code, .icon-cta {
        padding: 0.7rem 2.6rem 0.7rem 0.9rem;
    }
}
/*@media screen and (max-width: 600px) {
  .login-form-wrap {
    margin: 2em 1.15em;
  }
}*/

.validation-error-message {
    display: block;
    font-size: .85em;
    line-height: 1.2em;
    margin: 0 0 .85em 0;
    position: relative;
    color: #ad0000;
}