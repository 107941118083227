/*@font-face {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 100 900; 
    src: url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap') format('woff2');
}*/
@font-face {
	font-family: 'Lato';
	src: url('../assets/fonts/lato/Lato-HairlineItalic.woff2') format('woff2'), url('../assets/fonts/lato/Lato-HairlineItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src: url('../assets/fonts/lato/Lato-BoldItalic.woff2') format('woff2'), url('../assets/fonts/lato/Lato-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src: url('../assets/fonts/lato/Lato-LightItalic.woff2') format('woff2'), url('../assets/fonts/lato/Lato-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src: url('../assets/fonts/lato/Lato-Bold.woff2') format('woff2'), url('../assets/fonts/lato/Lato-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src: url('../assets/fonts/lato/Lato-Regular.woff2') format('woff2'), url('../assets/fonts/lato/Lato-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src: url('../assets/fonts/lato/Lato-BlackItalic.woff2') format('woff2'), url('../assets/fonts/lato/Lato-BlackItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src: url('../assets/fonts/lato/Lato-Black.woff2') format('woff2'), url('../assets/fonts/lato/Lato-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src: url('../assets/fonts/lato/Lato-Light.woff2') format('woff2'), url('../assets/fonts/lato/Lato-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Lato Hairline';
	src: url('../assets/fonts/lato/Lato-Hairline.woff2') format('woff2'), url('../assets/fonts/lato/Lato-Hairline.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src: url('../assets/fonts/lato/Lato-Italic.woff2') format('woff2'), url('../assets/fonts/lato/Lato-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

a{color:#333;}

.w-100{
    width:100%;
}
.w-50 {
	flex-basis: 50%;
}

.d-flex {
	display: flex;
}

.justify-between {
	justify-content: space-between;
}
.navbarClose{
	display:none;
}
.items-center {
	align-items: center;
}
.flex-center {
	align-items: center;
	display: flex;
	justify-content: center;
}
.main-container {
	font-family: 'Lato';
	margin-left: auto;
	margin-right: auto;
	max-width: 85rem;
	padding: 0 1rem;
	width: 100%;
}
.main-nav{
	display: flex;
	justify-content: space-between;
	padding: 3.25rem 0 1rem;
	position: relative;
}

.nav-bg{
	min-height:8.5rem;
}

.main-nav .solid-primary-btn.nav-link {
	cursor: pointer;
	height: 2.25rem;
	margin: 0 1rem;
	max-width: 6.25rem;
	width: 100%;
}
.main-nav .menu-bar .navbar-nav{
	align-items: center;
	justify-content: flex-end;
}
.main-nav .menu-bar .navbar-nav .nav-item .nav-link{
	color: #ffffff;
	font-size: 0.9rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.8rem;
	padding: 0.2rem 1.2rem;
	text-shadow: 0px -1px 2px black;
}

.main-nav .menu-bar .navbar-nav .nav-item{
	display: flex;
}
.main-nav .menu-bar .navbar-nav .nav-item.menu-icon{
	display:none;
}
.navbar-brand a{
	display: inline-flex;
	max-width: 11.05rem;
	width: 100%;
}
.lock-btn, .menu-btn{
	display: none;
}
.userlogin-btn{
	width:12rem;
}
.userlogin-btn button.nav-link {
	display: none;
}
.header-modal {
	background: #FFF;
	border-radius: 1rem;
	box-shadow: 0 0.2rem 1rem 0.5rem rgba(0, 0, 0, 0.25);
	padding: 1rem;
	position: absolute;
	right: 0;
	top: -0.7rem;
	z-index: 1;
}
.header-modal .modal-form .input-box{
	margin-bottom: 0.25rem;
}
.header-modal .modal-form .input-box input{
	background: transparent;
	width: 9.45rem;
}
.header-modal .modal-form .input-box label{
	z-index: -1;
}
.waitMessage.solid-primary-btn {
	cursor: wait;
}
.modal-form .input-box label.filled{
	z-index:1;
}
.header-modal .modal-form .solid-primary-btn {
	height: 2.25rem;
	margin-top: 0.75rem;
	transition: ease-in-out 0.3s;
}
.header-modal .modal-form .solid-primary-btn:hover{
	color: #3A823A;
}
.header-modal-title {
	display:flex;
	align-items:center;
	color: #000;
	font-size: 0.9rem;
	font-weight: 700;
	line-height: 1;
	padding: 0 0 0.8rem;
}
.header-modal-title img{
	width:0.9rem;
	margin-right:0.2rem;
}
.main-nav .solid-primary-btn.nav-link:hover {
	border: 0.1rem solid #3A823A;
}
.footer-wrapper {
	background-image: url(../assets/footer-bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #21232f;
	border-radius: 3rem;
	padding: 5.5rem 0 2.1rem;
}
.footer-btn{
		float:right;
	max-width: 9.6rem;
	width: 100%;
}
.solid-primary-btn {
	align-items: center;
	background: #3A823A;
	border: 0.1rem solid #3A823A;
	border-radius: 5rem;
	color: #FFF;
	cursor: pointer;
	display: flex;
	font-size: 0.9rem;
	font-weight: 700;
	justify-content: center;
	line-height: 1.3rem;
	padding: 0.65rem;
	text-decoration: none;
	transition: ease-in-out 0.3s;
}
.solid-primary-btn:hover{
	background: transparent;
	color: #fff;
}
.footer-logo{
	display: inline-flex;
	margin-bottom: 1.4rem;
	max-width: 10rem;
}
.web-info, a.web-info{
	color: #ffffff;
	display: block;
	font-size: 0.9rem;
	font-weight: 500;
	line-height: 1.4rem;
	text-decoration: none;
}
.footer-links{
	padding-top: 8.7rem;
}
.footer-links ul li a{
	color: rgba(255,255,255,0.75);
	text-decoration: none;
}
.footer-links ul li{
	color: rgba(255,255,255,0.75);
	font-size: 0.8rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.2rem;
	padding-right: 1.5rem;
}
.footer-links ul li::marker{
	background-color: rgba(255,255,255,0.75);
	border-radius: 50%;
	height: 0.01rem;
	width: 0.01rem;
}
.footer-links ul{
	display: flex;
	flex-wrap: wrap;
	list-style-type: disc;
}
.footer-links ul li:first-child{
	list-style-type: none;
}
.close-btn{
	background-color: #E1ECE1;
	border-radius: 50%;
	cursor: pointer;
	height: 1.9rem;
	position: absolute;
	right: 0.8rem;
	top: 1rem;
	transition: 0.2s;
	width: 1.9rem;
}
.close-btn img{
	height: 1.2rem; 
	width: 1.2rem;
}
.close-btn:hover{
	background: transparent;
	border: 0.05rem solid #3A823A;
}
.header-modal .close-btn {
	display:none;
}
.closeing-menu {
	background: transparent;
	bottom: 0;
	display:none;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}
.mobNavbar .closeing-menu {
	display: block;
}
.forgot {
	display: block;
	position: relative;
	padding-top: 0.25rem;
	text-align: center;
	width: 100%;
}
.forgot a {
	display: block;
	font-size: 0.6rem;
	font-weight: 600;
	color: #525252;
}
.forgot a:link, .forgot a:visited{text-decoration:none;}
.forgot a:hover, .forgot a:active{text-decoration:underline;}

.request-demo .validation-error-message,.modal-form .validation-error-message {
	font-size: 0.6rem;
	padding-top: 0.35rem;
	padding-bottom: 0;
	margin: 0;
}
.header-modal .modal-form .validation-error-message {
	padding-top:0;
}
.request-demo .form-btn{text-align:center;}
.request-demo .submitting{font-weight:bold; padding:2rem 0 0.25rem 0;}
.request-demo .thank-you{padding:1.5rem 0 0.5rem;}
.request-demo .thank-you .support-number{
	color:#000000;
	text-decoration:none;
	font-weight:700;
}
.main-nav .w-50 {
	flex-basis: inherit;
	align-items: center;
}
@media screen and (max-width: 1800px) {
	.main-container{
		padding:0 5rem;
	}
	.navbar-brand a{
		max-width:10.4rem;
	}
}
@media screen and (max-width: 1500px) {
	.nav-bg{
		min-height:7.5rem;
	}
	.main-nav .w-50.navbar-brand{
		display:flex;
	}
	.navbar-brand a {
		max-width: 8.65rem;
	}
	.main-nav .menu-bar .navbar-nav .nav-item .nav-link {
		font-size: 0.7rem;
		line-height: 1.4rem;
	}
	.main-nav .solid-primary-btn.nav-link {
		height: 1.8rem;
		margin: 0 0.5rem;
		max-width: 5rem;
		width: 100%;
	}
	.main-nav{
		padding-top:2.75rem;
	}
	.footer-wrapper {
		padding: 4rem 0 2rem;
	}

	.footer-links {
		padding-top: 6rem;
	}

	.footer-logo {
		margin-bottom: 1rem;
	}
	.footer-btn {
		max-width: 8.5rem;
	}
}
@media screen and (max-width: 1350px) {
	.main-container {
		padding: 0 4rem;
	}
	
}
@media screen and (max-width: 1200px) {
	.main-nav {
		padding-top: 2rem;
	}
	.nav-bg{
		min-height:6rem;
	}
	.navbar-brand a {
		max-width: 7rem;
	}
	.main-nav .menu-bar .navbar-nav .nav-item .nav-link.solid-primary-btn.nav-link {
		border: 0.1rem solid #3A823A;
		height: 1.5rem;
		margin: 0;
		padding: 0.2rem 1rem;
	}
	.main-nav .menu-bar .navbar-nav .nav-item .nav-link {
		font-size: 0.65rem;
		padding: 0.2rem 0.75rem;
	}
	.header-modal-title {
		font-size: 0.8rem;
	}
	.header-modal-title img{
		width:0.8rem;
	}
	.header-modal{
		padding:0.75rem;
	}
	.userlogin-btn{
		width:11.3rem;
	}
	.header-modal .modal-form .solid-primary-btn {
		font-size: 0.85rem;
		height: 2rem;
	}
	.main-container {
		padding: 0 3.5rem;
	}
	.footer-wrapper {
		padding: 3rem 0 1.5rem;
	}
	.footer-links {
		padding-top: 4rem;
	}
	.footer-logo {
		max-width: 7.5rem;
	}
	.web-info {
		font-size: 0.75rem;
		line-height: 1.5;
	}
	.footer-links ul li {
		font-size: 0.7rem;
	}
	.footer-wrapper {
		border-radius: 2rem;
	}
}

@media screen and (max-width: 1024px) {
	.main-container {
		padding: 0 2rem;
	}
}
@media screen and (max-width: 900px) {
	.main-nav{
		padding-top:1.6rem;
	}
	.nav-bg{
		min-height:4.5rem;
	}
	.navbar-brand a {
		max-width: 6rem;
	}
	.main-container {
		padding: 0 2rem;
	}
	.main-nav .menu-bar .navbar-nav .nav-item .nav-link {
		font-size: 0.55rem;
		line-height: 1.1rem;
		padding: 0 0.5rem;
	}

	.main-nav .menu-bar .navbar-nav .nav-item .nav-link.solid-primary-btn.nav-link {
		height: 1.2rem;
		padding: 0.2rem 0.8rem;
	}
	
	.footer-btn {
		max-width: 7rem;
	}
	.footer-links ul li{
		padding-right:1.2rem;
	}
	.footer-links ul li{
		font-size:0.6rem;
	}
	.footer-logo{
		margin-bottom:0.5rem;
	}
	.footer-wrapper{
		padding:2rem 0 1.5rem;
	}
}
@media screen and (max-width: 767px) {
	.nav-bg{
		min-height:3.5rem;
	}
	.header-modal {
		display: none;
	}
	.userlogin-btn {
		width: auto;
	}
	.userlogin-btn .header-modal.openModal {
		display: block;
		position:fixed;
		left:0;
		right:0;
		bottom:0;
	}
	.main-nav .menu-bar .navbar-nav .nav-item .nav-link.solid-primary-btn.nav-link {
		display: flex;
	}
	.main-container{
		padding: 0 0.8rem;
	}
	.navbar-brand a {
		max-width: 8.25rem;
	}
	.lock-modal-wrapper.lockModal {
		position: fixed;
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
		background: rgba(0, 0, 0, 0.70);
		display: block;
		height: 100vh;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100vw;
	}
	.lockModal .header-modal{
		display:block;
		left:0;
		right:0;
		bottom:0;
	}
	.header-modal {
		bottom: -2.5rem;
		left: -0.9rem;
		right: -0.9rem;
		top: auto;
	}
	.header-modal-title {
		display: flex;
		color: #1F232E;
		font-size: 1rem;
		font-weight: 800;
		line-height: 1.5rem;
		padding:0.2rem 0 0.7rem;
	}
	.header-modal-title img {
		width: 1rem;
	}
	.header-modal .modal-form .input-box input{
		width:100%;
	}
	.header-modal .modal-form .solid-primary-btn{
		height:2.4rem;
		margin-top:1.5rem;
	}
	.header-modal {
		border-radius: 1.5rem 1.5rem 0 0;
	}
	.header-modal .close-btn {
		display:flex;
		right: 0.8rem;
		top: 1rem;
		transition: 0s;
	}
	.openModalHeader .nav-item a, .openModalHeader .navbarClose,
	.openModalHeader.main-nav .menu-bar .navbar-nav .nav-item.menu-icon {
		display: none;
	}
	.openModalHeader.main-nav .menu-bar .navbar-nav{
		background-color:transparent;
	}
	.openModalHeader .forgot a {
		display: block;
		margin-bottom: 0.5rem;
	}
	.footer-wrapper{
		border-radius: 1.5rem;
	}
	.footer-logo-wrap{
		flex-wrap: wrap;
		text-align: center;
		width:100%;
	}
	.footer-logo-col{
		margin-bottom: 4rem;
		width: 100%;
	}
	.footer-btn{
		margin: auto;
		max-width: 17.15rem;
	}
	.footer-logo{
		max-width: 10rem;
	}
	.web-info{
		font-size: 0.8rem;
	}
	.footer-logo-wrap .solid-primary-btn{
		font-size: 0.9rem;
		padding: 0.545rem;
	}
	.footer-links ul{
		justify-content: center;
	}
	.footer-links ul li:first-child{
		text-align: center;
		width: 100%;
	}
	.footer-links ul li:first-child, .footer-links ul li:last-child{
		padding-right: 0;
	}
	.footer-links ul li{
		padding-right: 1rem;
	}
	.footer-links{
		padding-top: 5rem;
	}
	.footer-wrapper{
		padding: 2.5rem 0 1.5rem;
		background-position:right;
	}
	.menu-bar {
		/*display: none;*/
		backdrop-filter: blur(10px);
		background: rgba(0, 0, 0, 0.75);
		bottom: 0;
		height: 100vh;
		left: 0;
		overflow: hidden;
		position: fixed;
		top: 0;
		transition: width 0.3s ease-in-out;
		width: 0;
		z-index: 5;
	}
	.main-nav .navbar-brand.w-50{
		align-items: center;
		display: flex;
		flex-basis: 100%;
		justify-content: space-between;
	}
	.lock-btn, .menu-btn{
		display: block;
	}
	.main-nav{
		padding-top: 0.75rem;
	}
	.menu-btn{
		cursor: pointer;
	}
	.menu-bar.mobNavbar{
		display: block;
		right: 0;
		width: 100vw;
	}
	.main-nav .menu-bar .navbar-nav{
		align-items: flex-start;
		background-color:#ffffff;
		flex-direction: column;
		height: 100%;
		justify-content: flex-start;
		padding: 2rem 0.9rem;
		position:relative;
		max-width:17rem;
		width:92%;
	}
	.main-nav .menu-bar .navbar-nav .nav-item .nav-link{
		color:#333333;
	}
	.main-nav .menu-bar .navbar-nav .nav-item:last-child{
		margin-top: auto;
		max-width: 100%;
		width: 100%;
	}
	.main-nav .menu-bar .navbar-nav .nav-item .nav-link{
		font-size: 0.9rem;
		line-height: 1.75;
		margin-bottom: 1rem;
		padding:0;
	}
	.main-nav .menu-bar .navbar-nav .nav-item .nav-link.solid-primary-btn.nav-link{
		color:#ffffff;
		height: 2.4rem;
		max-width: 17rem;
		width: 100%;
	}
	.main-nav .menu-bar .navbar-nav .nav-item .nav-link.solid-primary-btn.nav-link:hover{
		color:#3A823A
	}
	.navbarClose{
		display: block;
		position: absolute;
		right: 0.8rem;
		top: 1.15rem;
		height:0.85rem;
		width:0.85rem;
	}
	.navbarClose button {
		background-color: transparent;
		border: 0;
		height:0.85rem;
		width:0.85rem;
	}
	.navbarClose img{
		filter: brightness(0%);
	}
	.main-nav .menu-bar .navbar-nav .nav-item.menu-icon {
		display: block;
		font-size: 0.7rem;
		padding: 0 0 1rem 0;
		margin-top: -0.75rem;
	}
}
@media screen and (max-width: 600px) {
	.banner-wrapper.nav-bg{
		min-height:3.5rem;
		padding-bottom:0 !important;
	}
}

.privacy-policy, .terms{padding:20px; max-width:60rem; margin:0 auto; font-family:'MetaPro'}
.privacy-policy p, .terms p{color:#757575; padding-bottom:2em;}
.privacy-policy ul, .terms ul{list-style:decimal; padding-top:2em;}
.privacy-policy ul ul, .terms ul ul{list-style:disc; padding-left:1em;}
.privacy-policy li, .terms li{color:#757575; padding-bottom:1.5em;}